import React from "react";
import { CopyToClipboard } from "./icons";

function MessageBox({ title, desc, setMessage, setWhatsappContent}) {
  return (
    <div className="message-box">
      <div className="message-title d-flex justify-content-between">
        <div className="title">{title}</div>
        <span
          onClick={() => {
            if(setMessage){
              setMessage(desc);
            }
            if(setWhatsappContent){
              setWhatsappContent(desc);
            }
          }}
        >
          <CopyToClipboard />
        </span>
      </div>
      <div className="message-description">{desc}</div>
    </div>
  );
}

export default MessageBox;
