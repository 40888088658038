/* eslint-disable */
import React, {useState, useEffect} from "react";
import {Calendar} from 'rsuite';
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import "rsuite/dist/rsuite.css";
import {$crud} from "../services/CrudFactory";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import {useDataParams} from "../hooks";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {FilterChipContainer} from "../components";
import {BarChart, BarChart2, PieChart} from "../components/charts";
import {BarChart3} from "../components/charts/BarChart3";
import {cloneDeep} from "lodash";

export function Report() {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState({
        followup: [],
        callLog: [],
        workingHours: []
    })
    const initialState = {
        date: "",
        count: ""
    }
    const [callLogData, setCallLogData] = useState({
        connected: initialState,
        disconnected: initialState,
        inits: initialState,
        incomings: initialState,
        missed: initialState,
        outgoings: initialState

    })
    const user = useSelector(state => state.userReducer);
    const filterWithValues = useSelector(state => state.filterReducer).withValues
    const dispatch = useDispatch();
    const {setParams, params} = useDataParams();
 

    function getTodoList(date) {
        const day = moment(date).format("YYYY-MM-DD");
        const foundObj = data?.find(d => moment(d.day).isSame(moment(day)));
        if (foundObj) {
            return {...foundObj}
        } else {
            return {
                loginTime: null,
                talkTime: null,
                count: null,
                totalCalls: null,
                logoutTime: null
            }
        }

    }

    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
    
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    

    function renderCell(date) {
        const item = getTodoList(date);
        return (
            <ul className="calendar-todo-list" style={{listStyleType: "none", padding: 0}}>
                <li style={{textAlign: "start", paddingLeft: "20px", marginBottom: "2px"}}>
                    <Chip label={item.loginTime ? `Login- ${moment(item.loginTime).format("LT")}` : "Login- N/A"}
                          size="small" className="login"/>
                </li>
                <li style={{textAlign: "start", paddingLeft: "20px", marginBottom: "2px"}}>
                    <Chip label={item.count ? `Followups- ${item.count}` : "FollowUps- N/A"} size="small"
                          className="followups"/>
                </li>
                <li style={{textAlign: "start", paddingLeft: "20px", marginBottom: "2px"}}>
                    {/* <Chip label={item.talkTime ? `Talk Time- ${item.talkTime}` : "Talk Time- N/A"} size="small"
                          className="talktime"/> */}
                    <Chip label={item.talkTime ? `Talk Time- ${formatTime(item.talkTime)}` : "Talk Time- N/A"} size="small"
                          className="talktime"/>
                </li>
                <li style={{textAlign: "start", paddingLeft: "20px"}}>
                    <Chip label={item.totalCall ? `Total Calls- ${item.totalCall}` : "Total Calls- N/A"} size="small"
                          className="totalcalls"/>
                </li>
                <li style={{textAlign: "start", paddingLeft: "20px"}}>
                    <Chip label={item.logoutTime ? `Logout- ${moment(item.logoutTime).format("LT")}` : "Logout- N/A"}
                          size="small" className="logout"/>
                </li>
            </ul>
        );
    }

    function concatAndMergeArrays(arr1, arr2, arr3, arr4, key) {
        const combinedArray = [...arr1, ...arr2, ...arr3, ...arr4];
        const groupedObjects = combinedArray.reduce((acc, obj) => {
            const keyValue = obj[key];
            if (!acc[keyValue]) {
                acc[keyValue] = [];
            }
            acc[keyValue].push(obj);
            return acc;
        }, {});

        return Object.values(groupedObjects).map(v => {
            if (v.length > 1) {
                return Object.assign({}, ...v);
            } else {
                return {...v[0]};
            }
        });
    }
    

    // function concatAndMergeArrays(arr1, arr2, arr3, key) {
    //     const combinedArray1 = arr1.concat(arr2);
    //     const combinedArray = combinedArray1.concat(arr3);
    //     const groupedObjects = combinedArray.reduce((acc, obj) => {
    //         const keyValue = obj[key];
    //         if (!acc[keyValue]) {
    //             acc[keyValue] = [];
    //         }
    //         acc[keyValue].push(obj);
    //         return acc;
    //     }, {});
    //     return Object.values(groupedObjects).map(v => {
    //         if (v.length > 1) {
    //             return {...v[0], ...v[1], ...v[2]}
    //         } else {
    //             return {...v[0]}
    //         }
    //     });
    // }

    const retrieveData = async ({month, year}) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data: {dailyFollowUps, dailyWorkingHour, totalCalls, totalTime},
            } = await $crud.retrieve("web/daily-followUps-by-working-hours", {
                userId: filterWithValues?.SelectedUser ?? user?.id,
                month,
                year
            });
            setData(concatAndMergeArrays(dailyFollowUps, dailyWorkingHour, totalCalls,totalTime,  "day"))
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }

    const retrieveChartData = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data
            } = await $crud.retrieve(`web/${url}`, {
                monthly: true,
                userId: filterWithValues?.SelectedUser ?? user?.id,
            });
            return data;
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }

    function formatLogData(data) {
        const organizedData = [];

        data.forEach(entry => {
            const {type, date, TotalDuration, count} = entry;

            let dateObject = organizedData?.find(obj => obj.date === date);

            if (!dateObject) {
                dateObject = {date};
                organizedData.push(dateObject);
            }

            dateObject[type] = +count;
        });
        return organizedData;
    }

    useEffect(() => {
        retrieveData({month: moment().format("MM"), year: moment().format("YYYY")});
    }, [filterWithValues]);

    useEffect(() => {
        // setParams({month: moment().format("MM"), year: moment().format("YYYY")})
        const s = {
            "Call Connected": "connected",
            "Call Disconnected": "disconnected",
            "Call Init": "inits",
            "Incoming": "incomings",
            "Missed Call": "missed",
            "Outgoing Connected": "outgoings"
        }
        Promise.all([
            retrieveChartData("user-report-follow-up"),
            retrieveChartData("user-report-call-log"),
            retrieveChartData("user-report-working-hours")
        ]).then(res => {
            // !!res[1].callLogReport.length && res[1].callLogReport.reduce((acc, curr) => {
            //     if (acc.date == curr.date) {
            //         setCallLogData(prevState => {
            //             return {...prevState, [s[acc.type]]: {date:curr.date, count:curr.count, duration:curr.TotalDuration}}
            //         })
            //     }
            //     acc = cloneDeep(curr)
            //     return acc;
            // })
            setChartData({
                followup: res[0].followUpReport,
                callLog: formatLogData(res[1].callLogReport),
                workingHours: res[2].workingHoursReport
            })
        });
        // setChartData({
        //     followup:followUpdata.followUpReport,
        //     callLog:callLogData.callLogReport,
        //     workingHours:workingHourData.workingHoursReport
        // })

    }, [filterWithValues]);

    useEffect(() => {
        const handleResize = () => {
          const windowWidth = window.innerWidth;
          const divElement = document.getElementById("reportResponsive"); 
    
          if (divElement) {
            if(windowWidth >= 750 && windowWidth <=910){
                divElement.style.top  = "180px";
            }else{
                divElement.style.top  = "130px";
                divElement.style.bottom  = "100px";
            }
          }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    return (
        <React.Fragment>
            <>

                {/*<Box className="container-fluid top-navbar" style={{top: "130px", position:"fixed"}}>*/}
                {/*    */}
                {/*</Box>*/}
                <Box sx={{
                    position: "fixed",
                    top: 130,
                    bottom: 70,
                    width: "calc(100%)",
                    overflow: "scroll",
                    padding: "15px",
                }} className="tableHeader-color" id = "reportResponsive">
                    <FilterChipContainer id = "reportFilterResponsive"/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box
                                // className="calendar-container"
                            >
                                <Calendar
                                    compact
                                    bordered
                                    renderCell={renderCell}
                                    // locale="hours"
                                    onChange={(a) => retrieveData({
                                        month: moment(a).format("MM"),
                                        year: moment(a).format("YYYY")
                                    })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                // className="calendar-container "
                            >
                                <Grid container flexDirection="row">
                                    <Grid item xs={12} sm={6} md={3} lg={2} textAlign="center" p={2}>
                                        <Box p={2} sx={{
                                            borderRadius: "10px",
                                            backgroundColor: theme => theme.palette.primary.main
                                        }}>
                                            <Typography variant="caption">july Admission</Typography>
                                            <Typography variant="h5">5</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2} textAlign="center" p={2}>
                                        <Box p={2} sx={{
                                            borderRadius: "10px",
                                            backgroundColor: theme => theme.palette.primary.main
                                        }}>
                                            <Typography variant="caption">Total Follow Ups</Typography>
                                            <Typography variant="h5">556</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2} textAlign="center" p={2}>
                                        <Box p={2} sx={{
                                            borderRadius: "10px",
                                            backgroundColor: theme => theme.palette.primary.main
                                        }}>
                                            <Typography variant="caption">New Leads Assign</Typography>
                                            <Typography variant="h5">100</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2} textAlign="center" p={2}>
                                        <Box p={2} sx={{
                                            borderRadius: "10px",
                                            backgroundColor: theme => theme.palette.primary.main
                                        }}>
                                            <Typography variant="caption">Interested Leads Value Total</Typography>
                                            <Typography variant="h5">6015422</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2} textAlign="center" p={2}>
                                        <Box p={2} sx={{
                                            borderRadius: "10px",
                                            backgroundColor: theme => theme.palette.primary.main
                                        }}>
                                            <Typography variant="caption">Revenue Generated</Typography>
                                            <Typography variant="h5">905040</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{boxShadow: theme => theme.shadows[8], p: 3}}
                                         className="tableHeader-color">
                                        <BarChart
                                            chartID={"bar-chart1"}
                                            chartTitle="Follow Up Data"
                                            style={{aspectRatio: "16/9"}}
                                            chartData={chartData.followup}
                                            userId={filterWithValues?.SelectedUser ?? user?.id}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>

                                    <Box sx={{boxShadow: theme => theme.shadows[8], p: 3}}
                                         className="tableHeader-color">
                                        <BarChart2
                                            chartID={"bar-chart2"}
                                            chartTitle="Working/Break Time"
                                            style={{aspectRatio: "16/9"}}
                                            chartData={chartData.workingHours}
                                            userId={filterWithValues?.SelectedUser ?? user?.id}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{boxShadow: theme => theme.shadows[8], p: 3}}
                                         className="tableHeader-color">
                                        <BarChart3
                                            chartID="pie-cart1"
                                            chartTitle="Call Details"
                                            style={{aspectRatio: "16/9"}}
                                            chartData={chartData.callLog}
                                            userId={filterWithValues?.SelectedUser ?? user?.id}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </>

        </React.Fragment>
    );
}

