/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    InputLabel,
    TextField,
    DialogActions,
} from "@mui/material"
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch, useSelector} from "react-redux";


export const WhatsAppManagerModal = (props) => {
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const {open, onClose, onSubmit, data} = props;

    const cancel = () => {
        onClose();
        setParams({});
    }
    // const retrieveUrl = async () => {
    //     try {
    //         dispatch({
    //             type: "LOADER",
    //             loader: true
    //         });
    //         const {data} = await $crud.retrieve("web/server-user-url",{id:props?.id});
    //         return data;
    //     } catch (e) {
    //         dispatch({
    //             type: "SNACKBAR",
    //             snackBar: {type: "error", message: e.message}
    //         })
    //     } finally {
    //         dispatch({
    //             type: "LOADER",
    //             loader: false
    //         });
    //     }
    // }

    // const handleSubmit = async () =>{
    //     try {
    //         dispatch({
    //             type: "LOADER",
    //             loader: true
    //         });
    //         const {type, message, data} = await $crud.post(
    //             !props?.id ? "create/web/server-user-url" : "update/web/server-user-url",{
    //                 ...params
    //             }
    //         );
    //
    //         dispatch({
    //             type: "SNACKBAR",
    //             snackBar: {type, message},
    //         });
    //         // retrieveUsers().then(res=>{
    //         //     dispatch(users(res.users))
    //         // })
    //         onSubmit(data);
    //         cancel();
    //     } catch (e) {
    //         dispatch({
    //             type: "SNACKBAR",
    //             snackBar: {type: "error", message: e.message}
    //         })
    //     } finally {
    //         dispatch({
    //             type: "LOADER",
    //             loader: false
    //         });
    //     }
    // }
    useEffect(() => {
        setParams({accessKey: data})
    }, [])
    console.log({data})
    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                }
            }
        } TransitionComponent={Slide} fullWidth open={open}>
            <div className="tableHeader-color">
                <DialogTitle>Access key for WhatsApp Manager</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Access Key <span>*</span></InputLabel>
                            </Grid>
                            <TextField
                                required
                                size="small"
                                value={params?.accessKey || ""}
                                onChange={(e) => handleChange("accessKey", e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1"> CRM WhatsApp Number</InputLabel>
                            </Grid>
                            <TextField
                                type="number"
                                size="small"
                                value={params?.crmWhatsApp || ""}
                                onChange={(e) => handleChange("crmWhatsApp", e.target.value)}
                                fullWidth
                            />
                        </Grid> */}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                    <Button className="ms-2" onClick={() => {
                        onSubmit(params)
                    }} variant="contained" color="primary" size="small">Submit</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};
