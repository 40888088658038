/* eslint-disable */

import React, {useState} from 'react';

const FirebaseOtpCheck = async (otp,e) => {
    // e.preventDefault();
    const code = String(otp);
    let user = {};
    let type = "error";
    let message = "";


    await window.confirmationResult.confirm(code).then((result) => {

        user = result.user.uid;
        type = "success";
        message = "Verification Successfully";
    }).catch((error) => {
        message = error.message;
    });

    const response = {
        type,
        message,
        user,
    };


    return response;

}

export default FirebaseOtpCheck;
