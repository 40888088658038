import React, { useEffect, useState } from "react";
import { Grid, InputLabel, MenuItem, TextField } from "@mui/material";
// import { useDataParams } from "../../../hooks";
import { ImageUploader } from "../../ImageUploader";
import { USER_PROFILE_BASE_URL } from "../../../constants";
import { $crud } from "../../../services/CrudFactory";

export function Contact({formProps}) {
  const { params, handleChange, setParams } = formProps;
  const [departments, setDepartments] = useState([]);

  const getDepartments = async ()=>{
    try {
        const {data:{departments}} = await $crud.retrieve("web/departments");
        setDepartments(departments);
    } catch (error) {
        console.log(error);
    }
  };

  useEffect(()=>{
    getDepartments();
  },[]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageUploader
          name="photo"
          id="photo"
          file={params?.photo}
          src={
            params?.photo?.type
              ? `${URL.createObjectURL(params?.photo)}`
              : params?.photo
              ? `${USER_PROFILE_BASE_URL}${params?.photo}`
              : ""
          }
          onClick={() =>
            setParams((prev) => {
              return { ...prev, photo: "" };
            })
          }
          onChange={(file) => {
            handleChange("photo", file);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Name</InputLabel>
        </Grid>
        <TextField
          required
          value={params?.name || ""}
          onChange={(e) => handleChange("name", e.target.value)}
          placeholder="Enter Name"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Phone</InputLabel>
        </Grid>
        <TextField
          value={params?.phoneNo || ""}
          onChange={(e) => handleChange("phoneNo", e.target.value)}
          placeholder="Enter phone"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Email</InputLabel>
        </Grid>
        <TextField
          value={params?.email || ""}
          onChange={(e) => handleChange("email", e.target.value)}
          placeholder="Enter Email"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Department</InputLabel>
        </Grid>
        <TextField
          required
          value={params?.departmentId || ""}
          onChange={(e, child) => {
            // console.log(e,child, "--e");
            handleChange("departmentName", child?.props?.children);
            handleChange("departmentId", e.target.value);
          }}
          size="small"
          select
          fullWidth
        >
          {departments.map((item) => (
            <MenuItem key={item?.id} value={item?.id}>
              {item?.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {/* <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Designation</InputLabel>
        </Grid>
        <TextField
          required
          value={params?.designation || ""}
          onChange={(e) => handleChange("designation", e.target.value)}
          fullWidth
          size="small"
        />
      </Grid> */}
    </Grid>
  );
}
