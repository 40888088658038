/* eslint-disable */
import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, Divider, Grid, Button, Slide, InputLabel, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputField} from "../components/inputs";
import classNames from "classnames";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";


export const DepartmentModal = (props) => {
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const cancel = () => {
        props.onClose();
        setParams({});
    }

    const createDepartment = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {type, message} = await $crud.post(!props.department?.id ? "create/web/department" : "update/web/department", {
                ...params,
            });
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                    if (props.department?.id){
                        setParams(props?.department);
                    }
                    // retrieveSources()
                }
            }
        } TransitionComponent={Slide} fullWidth open={props.open}>
            <div className="tableHeader-color">
                <DialogTitle >{props?.department?.id ? "Update" : "Add"} Department</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Name<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                value={params?.name || ""}
                                placeholder="Enter Name"
                                size="small"
                                onChange={(e) => handleChange("name", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Description</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                size="small"
                                value={params?.description || ""}
                                placeholder="Enter Description"
                                onChange={(e) => handleChange("description", e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                        <Button className="ms-2" onClick={() => {
                            createDepartment();
                        }} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};
