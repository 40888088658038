/* eslint-disable */
import React, {useState, useEffect} from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent
} from "@mui/material";
import {$crud} from "../services/CrudFactory";
import {useDispatch} from "react-redux";
import Box from "@mui/material/Box";
// import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import funnel from 'highcharts/modules/funnel';
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
import {DashboardStatusCard, DashboardUserCard} from "../components/cards";
import { WindowOutlined } from "@mui/icons-material";
// import {charts} from "../helpers/chartOptions";
// import {PieChart, BarChart} from "../components/charts"


export async function retrieveTodayFollowupsByUserId() {
    const {data: {todayFollowupsByUserId}} = await $crud.retrieve("web/today-followups-by-userId");
    return todayFollowupsByUserId;
};

export function Dashboard() {
    const [todayFollowupsByUserId, setTodayFollowupsByUserId] = useState([]);
    const [dashboardData, setDashboardData] = useState({})
    const [marginTop, setMarginTop] = useState(0);

    const dispatch = useDispatch();
    const retrieveDashboardData = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data} = await $crud.retrieve("web/dashboard-count");
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };
    funnel(Highcharts)


    const status = [
        {
            name: "Raw",
            color: theme => theme.customColors.red.main,
            countKey: "raw",
            description: "Status Description For Raw"
        },
        {
            name: "In-Progress",
            color: theme => theme.customColors.purple.main,
            countKey: "in_progress",
            description: "Status Description For In Progress"
        },
        {
            name: "Interested",
            color: theme => theme.customColors.gray.main,
            countKey: "interested",
            description: "Status Description For Interested"
        },
        {
            name: "Won",
            color: theme => theme.customColors.green.main,
            countKey: "converted",
            description: "Status Description For Won"
        },
        {
            name: "Not Interested",
            color: theme => theme.customColors.yellow.main,
            countKey: "not_interested",
            description: "Status Description For Not Interested"
        },
    ];
    useEffect(() => {
        retrieveTodayFollowupsByUserId().then(res => {
            setTodayFollowupsByUserId(res)
        });
        retrieveDashboardData().then(res => {
            setDashboardData(res);
        });
    }, [])

    useEffect(() => {
        const handleResize = () => {
          const windowWidth = window.innerWidth;
          const divElement = document.getElementById("dashboard"); 
    
          if (divElement) {
            if(windowWidth >= 768 && windowWidth <=865){
                divElement.style.top  = "160px";
                setMarginTop("-60px");
            }else if (windowWidth >=866 && windowWidth <= 1020) {
              divElement.style.top = "113px";
              setMarginTop("-40px");
            }
            else if (windowWidth >= 1021 && windowWidth <= 1186) {
              divElement.style.top = "80px";
              setMarginTop("14px");
            }
            else {
              divElement.style.top = "80px";
              setMarginTop("-20px");
            }
          }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    return (
        <React.Fragment>
            <Box id = "dashboard" style={{
                position: "fixed",
                top: "80px",
                bottom: "70px",
                width: "calc(100%)",
                overflow: "scroll",
                padding: 15
            }}>
                <Grid container id spacing={2} mb={2} sx={{ marginTop }} >
                    <Grid item xs={12} sx = {{mt: {sm:5,md:3,lg:0}}}>
                        <Typography variant="h5"  >Status Wise Leads</Typography>
                    </Grid>
                    {
                        status.map((status) => {
                            return (
                                <Grid item xs={3} sm = {3} md = {3} lg ={2} key={status?.name}>
                                    <DashboardStatusCard
                                        status={status}
                                        dashboardData={dashboardData}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">User Assigned Leads</Typography>
                    </Grid>
                    {
                        dashboardData?.usersFollowUp?.length && dashboardData?.usersFollowUp?.map((value, index) => {
                            return (
                                value?.dailyWorkingHours?.length ? (
                                    <Grid item xs={3} sm={4} md={3} key={index}>
                                      <DashboardUserCard value={value} />
                                    </Grid>
                                  ) : null
                                
                            )
                        })
                    }
                </Grid>
                {/* <Grid container spacing={2} my={2}> */}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Card className="tableHeader-color">*/}
                    {/*        <CardContent>*/}
                    {/*            /!*<HighchartsReact highcharts={Highcharts} options={charts?.barChartOptions}/>*!/*/}
                    {/*            <PieChart chartID={"bar-chart1"} style={{aspectRatio:"1"}}/>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Card className="tableHeader-color">*/}
                    {/*        <CardContent>*/}
                    {/*            /!*<HighchartsReact highcharts={Highcharts} options={charts?.barChartOptions}/>*!/*/}
                    {/*            <BarChart chartID={"bar-chart2"} style={{aspectRatio:"1"}}/>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Card className="tableHeader-color">*/}
                    {/*        <CardContent>*/}
                    {/*            <HighchartsReact highcharts={Highcharts}*/}
                    {/*                             options={charts?.stckedHorizontalChartOptions}/>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Card className="tableHeader-color">*/}
                    {/*        <CardContent>*/}
                    {/*            <HighchartsReact highcharts={Highcharts}*/}
                    {/*                             options={charts?.stckedHorizontalChartOptions}/>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Card className="tableHeader-color">*/}
                    {/*        <CardContent>*/}
                    {/*            <HighchartsReact highcharts={Highcharts} options={charts?.funnelChartOptions}/>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Card className="tableHeader-color">*/}
                    {/*        <CardContent>*/}
                    {/*            <HighchartsReact highcharts={Highcharts} options={charts?.funnelChartOptions}/>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Card className="tableHeader-color">*/}
                    {/*        <CardContent>*/}
                    {/*            <HighchartsReact highcharts={Highcharts} options={charts?.columnChartOptions}/>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Card className="tableHeader-color">*/}
                    {/*        <CardContent>*/}
                    {/*            <HighchartsReact highcharts={Highcharts} options={charts?.columnChartOptions}/>*/}
                    {/*        </CardContent>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}

                {/* </Grid> */}
            </Box>
        </React.Fragment>
    );
}
