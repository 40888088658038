import React from "react";

export function LocationAttachment() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <g filter="url(#filter0_d_554_3215)">
                <rect x="10" y="6" width="50" height="50" rx="25" fill="#EB427A" />
                <path d="M60 31C60 24.3696 57.3661 18.0107 52.6777 13.3223C47.9893 8.63392 41.6304 6 35 6C28.3696 6 22.0107 8.63392 17.3223 13.3223C12.6339 18.0107 10 24.3696 10 31L35 31H60Z" fill="#D23B6D" />
                <path d="M35 40C33.7369 38.9226 32.5662 37.7415 31.5 36.469C29.9 34.558 28 31.712 28 29C27.9993 27.6149 28.4095 26.2608 29.1787 25.109C29.9479 23.9572 31.0415 23.0594 32.3211 22.5294C33.6007 21.9993 35.0087 21.8608 36.3671 22.1313C37.7255 22.4018 38.9731 23.0692 39.952 24.049C40.6038 24.6978 41.1203 25.4695 41.4719 26.3193C41.8234 27.1692 42.0029 28.0803 42 29C42 31.712 40.1 34.558 38.5 36.469C37.4338 37.7415 36.2631 38.9226 35 40ZM35 26C34.2044 26 33.4413 26.316 32.8787 26.8786C32.3161 27.4413 32 28.2043 32 29C32 29.7956 32.3161 30.5587 32.8787 31.1213C33.4413 31.6839 34.2044 32 35 32C35.7957 32 36.5587 31.6839 37.1213 31.1213C37.6839 30.5587 38 29.7956 38 29C38 28.2043 37.6839 27.4413 37.1213 26.8786C36.5587 26.316 35.7957 26 35 26Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_554_3215" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_554_3215" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_554_3215" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
