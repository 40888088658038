import React from "react";
import Checkbox from "@mui/material/Checkbox";

export function InputField(props) {
    const {
        labelName,
        type,
        name,
        placeholderName,
        onChange,
        value,
        id,
        style,
        whatsapp,
        error,
        required,
        onInput
    } = props;
    return (
        <>
            <div>
                <div className="d-flex justify-content-between align-baseline align-items-center">
                    <label
                        htmlFor={id}
                        className={
                            "form-label  d-flex justify-content-between"
                        }
                        style={{display: labelName ? "block" : "none"}}
                    >
                        {labelName}
                        {required && " *"}
                    </label>
                    {whatsapp && (
                        <Checkbox
                            onChange={(e) => props.onCheckBoxChange(e.target.checked)}
                            style={{padding: 0}}
                            size="small"
                        />
                    )}
                </div>
                <input
                    type={type}
                    id={id}
                    className="form-control"
                    style={{style, borderRadius: 0}}
                    value={value}
                    name={name}
                    onInput={onInput}
                    aria-describedby="NameHelp"
                    placeholder={placeholderName}
                    onChange={(e) => onChange(e.target.value)}
                />
                {!!error && (
                    <label style={{fontSize: "12px", color: "#ed1919e3"}}>
                        {error}
                    </label>
                )}
            </div>
        </>
    );
}
