const initialState = false;

const clickDetection2 = (state = initialState, action) => {
    switch (action.type) {
        case "DETECTION2":
            return (state = action.payload);
        default:
            return state;
    }
};

export default clickDetection2;
