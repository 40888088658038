/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    Tooltip, Typography
} from "@mui/material";
import {ReactPagination} from "../components/Pagination";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch} from "react-redux";
import {Edit} from "@mui/icons-material"
import {DepartmentModal} from "../dialogs/Department";
import {columns} from "../helpers/tableData";
import DynamicTable from "../components/DynamicTable";
export const Department = () => {
    const dispatch = useDispatch();
    const [department,setDepartment] = useState(null);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const [departments,setDepartments] = useState([]);
    const retrieveDepartments =async () =>{
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {data:{departments,total}} = await $crud.retrieve("web/department",{
                limit,
                page
            });
            setDepartments(departments);
            setTotal(total);
        }catch (e) {
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message:e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }
    const statusChange = async (id)=>{
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {type,message} = await $crud.post("create/web/status/department", {
                id
            });
            retrieveDepartments();
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
        }catch (e) {
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message:e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    useEffect(()=>{
        retrieveDepartments();
    },[limit,page]);

    return (
        <>
            <div>
                <div className='row w-100 g-2 mt-2'>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setDepartment({})} buttonName="Add Department"/>
                    </div>
                </div>
            </div>

                <DepartmentModal onSave={retrieveDepartments} department={department} onClose={()=>setDepartment(null)} open={!!department}/>
            <DynamicTable
                rows={departments}
                columns={columns?.department}
                total={total}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}
                onEdit={(value) => {
                    setDepartment(value)
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
        </>
    );
};

