import * as React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  DialogContentText,
  TextField,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useDataParams } from "../hooks";

export function TransferLeadsConfirmationModal(props) {
  const { onClose, title, content, buttonText, open, onOk } = props;
  const users = useSelector((state) => state.usersReducer);
  const {params, setParams} = useDataParams();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{
        className: "tableHeader-color",
      }}
      aria-describedby="alert-dialog-description"
    >
      <div className="tableHeader-color">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            select
            size="small"
            value={`${params?.user_id}` || ""}
            onChange={(e) => setParams({user_id:e.target.value})}
            sx={{
              svg: { color: "#fff" },
            }}
          >
            {users.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={()=>onOk(params)} autoFocus variant="contained" disabled = {!params?.user_id}>
            {buttonText || "Yes"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
