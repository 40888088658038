import React from "react";

export function DocumentAttachment() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <g filter="url(#filter0_d_554_3223)">
                <rect x="10" y="6" width="50" height="50" rx="25" fill="#5F66C9" />
                <path d="M60 31C60 24.3696 57.3661 18.0107 52.6777 13.3223C47.9893 8.63392 41.6304 6 35 6C28.3696 6 22.0107 8.63392 17.3223 13.3223C12.6339 18.0107 10 24.3696 10 31L35 31H60Z" fill="#5157AB" />
                <path d="M37.5933 21.6666H28.2199C27.8898 21.6754 27.5765 21.814 27.3481 22.0524C27.1197 22.2909 26.9945 22.6098 26.9999 22.94V39.06C26.9945 39.3901 27.1197 39.7091 27.3481 39.9475C27.5765 40.1859 27.8898 40.3246 28.2199 40.3333H41.7799C42.11 40.3246 42.4233 40.1859 42.6517 39.9475C42.8802 39.7091 43.0053 39.3901 42.9999 39.06V26.9466L37.5933 21.6666ZM36.9999 27.6666V22.8933L41.8666 27.6666H36.9999Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_554_3223" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_554_3223" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_554_3223" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
