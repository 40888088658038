export const ERRORS = {
    // name: {
    //     required: true,
    //     name: "Course Name"
    // },
    // duration:{
    //     required: true,
    //     name:"Duration"
    // },
    // fee:{
    //     required:true,
    //     name:"Fee"
    // },
    // lead_source_name:{
    //     required:true,
    //     name:"Source Name"
    // }
    // emiDownPayment:{
    //     required:true,
    //     name:"Emi Down Payment"
    // }
};
