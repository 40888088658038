/* eslint-disable */
import React, { useState} from "react";
import {ActionButton} from "../components/buttons";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch, useSelector} from "react-redux";
import {StatusModal} from "../dialogs/StatusModal";
import {statuses} from "../redux/actions";
import {retrieveStatuses} from "./LoginPage";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";

export const Statuses = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(null);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const allStatuses = useSelector(state => state.statusReducer);

    const statusChange=async (id) => {
            try {
                dispatch({
                    type: "LOADER",
                    loader: true
                });
                const {type, message} = await $crud.post("create/web/status/statuses", {
                    id
                });
                retrieveStatuses().then(res => {
                    dispatch(statuses(res?.statuses))
                });
                dispatch({
                    type: "SNACKBAR",
                    snackBar: {type, message}
                })
            } catch (e) {
                dispatch({
                    type: "SNACKBAR",
                    snackBar: {type: "error", message: e.message}
                })
            } finally {
                dispatch({
                    type: "LOADER",
                    loader: false
                });
            }
        }
    const defaultStatusChange = async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("update/web/status", {
                id,
                defaultStatus:true
            });
            retrieveStatuses().then(res => {
                dispatch(statuses(res?.statuses))
            });
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    return (
        <>
            <div>
                <div className='row w-100 g-2'>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setStatus({})} buttonName="Add Statuses"/>
                    </div>
                </div>
            </div>
            <DynamicTable
                rows={allStatuses}
                columns={columns?.status}
                style={{maxHeight: "calc(100vh - 250px)"}}
                total={total}
                onRowClick={() => {
                }}
                onDefaultStatusChange={(id)=>{
                    defaultStatusChange(id)
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}
                onEdit={(value) => {
                    setStatus(value)
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
                <StatusModal 
                    onSave={(data) => {
                    }}
                    status={status}
                    onClose={() => setStatus(null)}
                    open={!!status}
                />
        </>
    );
};

