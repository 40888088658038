/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    FormGroup,
    FormControlLabel,
    Grid,
    Button,
    Slide,
    TextField,
    Typography,
    IconButton,
    Checkbox, InputLabel, MenuItem, Autocomplete, InputAdornment, OutlinedInput, Box, Chip, Select,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import dayjs from "dayjs";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch, useSelector} from "react-redux";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {Close, Visibility, VisibilityOff} from "@mui/icons-material";
import {TEAM_LEADER_ROLE, USER_DOCUMENT_BASE_URL, USER_PROFILE_BASE_URL} from "../constants";
import {ImageUploader} from "../components/ImageUploader";
import {retrieveUser, retrieveUsers} from "../pages";
import {current_user, users} from "../redux/actions";

export const useStyles = makeStyles((theme) => ({
    bg: {
        backgroundColor: theme.palette.primary.dark,
    },
    titleColor: {
        color: "#fff",
    },
    textColor: {
        color: theme.palette.primary.light,
    },
}));
export const EmployeeDialog = (props) => {
    const days = [
        "Monday",
        "Tuesday",
        "Wednessday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const styles = useStyles({});
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const [shiftDays, setShiftDays] = useState([]);
    const [telecallerIds, setTelecallerIds] = useState([]);
    const [phoneDevices, setPhoneDevices] = useState([]);
    const [laptopDevices, setLaptopDevices] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [degrees, setDegrees] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [roles, setRoles] = useState([]);
    const [branches, setBranches] = useState([]);
    const [telecallers, setTelecallers] = useState([]);
    const [passwordNew, setPasswordNew] = useState(props?.employee);
    const [shiftDetails, setShiftDetails] = useState([
        {
            startTime: dayjs(new Date()),
            endTime: dayjs(new Date()),
            assignPhone: "",
            assignLaptop: "",
        },
    ]);

    const [showPassword, setShowPassword] = React.useState(false);
    const user = useSelector(state => state.userReducer)
    const allUsers = useSelector(state => state.usersReducer)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const cancel = () => {
        props.onClose();
        setParams({});
        setShiftDetails([
            {
                startTime: dayjs(new Date()),
                endTime: dayjs(new Date()),
                assignPhone: "",
                assignLaptop: "",
            },
        ]);
        setTelecallerIds([]);
        setShiftDays([]);
    };
    const retrivePhoneDevices = async () => {
        const {
            data: {devices},
        } = await $crud.retrieve("web/device-type", {
            deviceType: "phone",
        });
        setPhoneDevices(devices);
    };
    const retrieveRoles = async () => {
        const {
            data: {roles},
        } = await $crud.retrieve("web/roles");
        setRoles(roles);
    };
    const retriveLaptopDevices = async () => {
        const {
            data: {devices},
        } = await $crud.retrieve("web/device-type", {
            deviceType: "laptop",
        });
        setLaptopDevices(devices);
    };
    const retriveBranches = async () => {
        const {
            data: {branches},
        } = await $crud.retrieve("web/branches", {
            limit: 100,
            page: 1,
        });
        setBranches(branches);
    };
    const createUser = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const formData = new FormData();
            Object.keys(params)?.map((key) => {
                if (props.employee?.id && key === "shiftDetails") {
                } else {
                    formData.append(key, params[key]);
                }
            });
            formData.append("shiftDays", JSON.stringify(shiftDays));
            formData.append("shiftDetails", JSON.stringify(shiftDetails));
            if (props?.employee?.role?.name === TEAM_LEADER_ROLE) formData.append("telecallerIds", JSON.stringify(telecallerIds));
            const {type, message} = await $crud.post(
                !props.employee?.id ? "create/web/user" : "update/web/user",
                formData
            );
            // await $crud.post("create/web/user", formData);
            retrieveUsers().then(res => {
                dispatch(users(res?.users))
            })
            retrieveUser(user?.id).then(res => {
                dispatch(current_user(res))
            })
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    const retrieveColleges = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {colleges, total}} = await $crud.retrieve("web/colleges");
            setColleges(colleges);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    const retrieveDegrees = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {degreeAndDiplomas, total}} = await $crud.retrieve("web/degree-and-diplomas");
            setDegrees(degreeAndDiplomas);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    const retrieveUniversities = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {boardAndUniversities, total}} = await $crud.retrieve("web/board-and-universities");
            setUniversities(boardAndUniversities);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    const retrieveUsers1 = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {users}} = await $crud.retrieve("web/users");
            setTelecallers(users)

        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    return (
        <Dialog
            maxWidth={"lg"}
            TransitionProps={{
                onEnter: () => {
                    if (props.employee?.id) {
                        setParams(Object.fromEntries(
                            Object.entries(props?.employee).filter(([key, value]) => value !== null)
                        ));
                        setShiftDetails(props?.employee?.shiftDetails);
                        setShiftDays(props?.employee?.shifts?.map((item) => item?.day));
                        setTelecallerIds(props?.employee?.tellecaller?.map(tc => tc.id))
                    }
                    retrivePhoneDevices();
                    retriveLaptopDevices();
                    retrieveRoles();
                    retriveBranches();
                    // retriveBranches();
                    retrieveUniversities();
                    retrieveDegrees();
                    retrieveColleges();
                    retrieveUsers1();
                },
            }}
            TransitionComponent={Slide}
            fullWidth
            open={props.open}
        >
            <div className="employee-dialog">
                <DialogTitle className={styles.titleColor}>
                    {props?.device?.id ? "Update" : "Add"} Employees
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Name</InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.name || ""}
                                onChange={(e) => handleChange("name", e.target.value)}
                                placeholder="Enter Name"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Father's Name</InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.fatherName || ""}
                                onChange={(e) => handleChange("fatherName", e.target.value)}
                                placeholder="Enter Father Name"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">WhatsApp Number</InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.whatsAppNo || ""}
                                onChange={(e) => handleChange("whatsAppNo", e.target.value)}
                                placeholder="Enter WhatsApp Number"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Mobile Number</InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.mobileNo || ""}
                                onChange={(e) => handleChange("mobileNo", e.target.value)}
                                placeholder="Enter Mobile Number"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">City</InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.city || ""}
                                onChange={(e) => handleChange("city", e.target.value)}
                                placeholder="Enter City"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Email</InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.email || ""}
                                onChange={(e) => handleChange("email", e.target.value)}
                                placeholder="Enter Email"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">DOB</InputLabel>
                            </Grid>
                            <TextField
                                size="small"
                                value={params?.dob || ""}
                                onChange={(e) => handleChange("dob", e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                fullWidth
                                type="date"
                                style={{
                                    svg: {color: "#fff"}
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Address</InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.completeAddress || ""}
                                onChange={(e) => handleChange("completeAddress", e.target.value)}
                                placeholder="Enter Address"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Role</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                select
                                variant="outlined"
                                value={`${params?.roleId}` || ""}
                                color="primary"
                                onChange={(e) => handleChange("roleId", e.target.value)}
                            >
                                {roles?.map((role) => (
                                    <MenuItem key={role?.id} value={role?.id}>{role?.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {
                            params?.roleId === 4 && <Grid item xs={12} sm={4}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <InputLabel className="mb-1">Team Leader</InputLabel>
                                </Grid>
                                <TextField
                                    fullWidth
                                    size="small"
                                    select
                                    variant="outlined"
                                    value={`${params?.teamLeaderId}` || ""}
                                    color="primary"
                                    onChange={(e) => handleChange("teamLeaderId", e.target.value)}
                                >
                                    {telecallers?.filter(au => au.roleId === 3)?.map((user) => (
                                        <MenuItem key={user?.id} value={user?.id}>{user?.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        }
                        {
                            params?.roleId === 3 && <Grid item xs={12} sm={4}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <InputLabel className="mb-1">Tele Callers</InputLabel>
                                </Grid>
                                <Select
                                    multiple
                                    color="primary"
                                    fullWidth
                                    value={telecallerIds || []}
                                    input={<OutlinedInput id="select-multiple-chip"/>}
                                    size="small"
                                    onChange={(e) => {
                                        const {value} = e.target;
                                        setTelecallerIds(value)
                                    }}
                                    renderValue={(selected) => (<Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                            {selected?.map((value) => (
                                                <Chip
                                                    color="primary"
                                                    size="small"
                                                    key={value}
                                                    label={telecallers.filter(user => value === user?.id).map(c => c.name)}
                                                />
                                            ))}
                                        </Box>
                                    )

                                    }
                                >
                                    {telecallers?.filter(u => u.roleId == 4 && !u.teamLeaderId || u.teamLeaderId == params?.id).map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item.id}
                                        >
                                            <Checkbox
                                                checked={!!telecallerIds?.length && !!telecallerIds?.find(i => i == item?.id)}
                                            />
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        }
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">College/Institute</InputLabel>
                            </Grid>
                            <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                size="small"
                                disableClearable
                                value={params?.college || {name: ""}}
                                onChange={(event, newValue) => {
                                    if (typeof newValue !== "string") {
                                        handleChange("collegeId", newValue.id);
                                        handleChange("college", newValue)
                                    }
                                }}
                                options={colleges}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                    return <Grid {...props}>{option?.name}</Grid>
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={(e) => {
                                            handleChange("collegeId", e.target.value)
                                            handleChange("college", {name: e.target.value})
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                            {/*<Autocomplete*/}
                            {/*    freeSolo*/}
                            {/*    id="free-solo-2-demo"*/}
                            {/*    size="small"*/}
                            {/*    // disableClearable*/}
                            {/*    value={params?.collegeId || {}}*/}
                            {/*    onChange={(event, newValue) => {*/}
                            {/*        handleChange("collegeId", newValue.id);*/}
                            {/*    }}*/}
                            {/*    options={colleges}*/}
                            {/*    getOptionLabel={(option) => option.name}*/}
                            {/*    // value={params?.universityId || ""}*/}
                            {/*    renderOption={(props, option) => {*/}
                            {/*        return <Grid {...props}>{option?.name}</Grid>*/}
                            {/*    }}*/}
                            {/*    onInputChange={(e, newInputValue) => {*/}
                            {/*        handleChange("collegeId", newInputValue);*/}
                            {/*    }}*/}
                            {/*    renderInput={(params) => (*/}
                            {/*        <TextField*/}
                            {/*            {...params}*/}
                            {/*            InputProps={{*/}
                            {/*                ...params.InputProps,*/}
                            {/*                type: 'search',*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Degree/Diploma</InputLabel>
                            </Grid>
                            <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                size="small"
                                disableClearable
                                value={params?.degree || {name: ""}}
                                onChange={(event, newValue) => {
                                    if (typeof newValue !== "string") {
                                        handleChange("degreeId", newValue.id);
                                        handleChange("degree", newValue)
                                    }
                                }}
                                options={degrees}
                                getOptionLabel={(option) => option.name}

                                renderOption={(props, option) => {
                                    return <Grid {...props}>{option?.name}</Grid>
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={(e) => {
                                            handleChange("degreeId", e.target.value)
                                            handleChange("degree", {name: e.target.value})
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                            {/*<Autocomplete*/}
                            {/*    freeSolo*/}
                            {/*    id="free-solo-2-demo"*/}
                            {/*    size="small"*/}
                            {/*    disableClearable*/}
                            {/*    value={params?.degreeId || ""}*/}
                            {/*    onChange={(event, newValue) => {*/}
                            {/*        handleChange("degreeId", newValue.id);*/}
                            {/*    }}*/}
                            {/*    options={degrees}*/}
                            {/*    getOptionLabel={(option) => option.name}*/}
                            {/*    // value={params?.universityId || ""}*/}
                            {/*    renderOption={(props, option) => {*/}
                            {/*        return <Grid {...props}>{option?.name}</Grid>*/}
                            {/*    }}*/}
                            {/*    onInputChange={(e, newInputValue) => {*/}
                            {/*        handleChange("degreeId", newInputValue);*/}
                            {/*    }}*/}
                            {/*    renderInput={(params) => (*/}
                            {/*        <TextField*/}
                            {/*            {...params}*/}
                            {/*            // onChange={(e) => {*/}
                            {/*            //     handleChange("degreeId", e.target.value);*/}
                            {/*            // }}*/}
                            {/*            InputProps={{*/}
                            {/*                ...params.InputProps,*/}
                            {/*                type: 'search',*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">University</InputLabel>
                            </Grid>
                            <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                size="small"
                                disableClearable
                                value={params?.university || {name: ""}}
                                onChange={(event, newValue) => {
                                    if (typeof newValue !== "string") {
                                        handleChange("universityId", newValue.id);
                                        handleChange("university", newValue)
                                    }
                                }}
                                options={universities}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                    return <Grid {...props}>{option?.name}</Grid>
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={(e) => {
                                            handleChange("universityId", e.target.value)
                                            handleChange("university", {name: e.target.value})
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                            {/*<Autocomplete*/}
                            {/*    freeSolo*/}
                            {/*    id="free-solo-2-demo"*/}
                            {/*    size="small"*/}
                            {/*    disableClearable*/}
                            {/*    onChange={(event, newValue) => {*/}
                            {/*        handleChange("universityId", newValue.id);*/}
                            {/*    }}*/}
                            {/*    options={universities}*/}
                            {/*    getOptionLabel={(option) => option.name}*/}
                            {/*    value={params?.universityId || ""}*/}
                            {/*    renderOption={(props, option) => {*/}
                            {/*        return <Grid {...props}>{option?.name}</Grid>*/}
                            {/*    }}*/}
                            {/*    onInputChange={(e, newInputValue) => {*/}
                            {/*        handleChange("universityId", newInputValue);*/}
                            {/*    }}*/}
                            {/*    renderInput={(params) => (*/}
                            {/*        <TextField*/}
                            {/*            {...params}*/}

                            {/*            InputProps={{*/}
                            {/*                ...params.InputProps,*/}
                            {/*                type: 'search',*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Year Of Passing</InputLabel>
                            </Grid>
                            <TextField
                                type="number"
                                value={params?.yearOfPassing || ""}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                        if (e.target.value.length <= 4) {
                                            handleChange("yearOfPassing", e.target.value);
                                        }
                                    }
                                }}
                                placeholder="Enter Year Of Passing"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        {!params?.id && <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Password</InputLabel>
                            </Grid>
                            <TextField
                                required
                                type={showPassword ? 'text' : 'password'}
                                value={params?.password || ""}
                                onChange={(e) => handleChange("password", e.target.value)}
                                placeholder="Enter Password"
                                fullWidth
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}

                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff style={{color: "#FFF"}}/> :
                                                    <Visibility style={{color: "#FFF"}}/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>}
                        
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Follow Up Limit</InputLabel>
                            </Grid>
                            <TextField
                                type = "number"
                                value = {params?.limit || ""}
                                onChange={(e) => handleChange("limit", e.target.value)}
                                placeholder="Enter followup limit"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6"> Onboard Details</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Date Of Joining</InputLabel>
                            </Grid>
                            <TextField
                                size="small"
                                value={params?.dateOfJoining || ""}
                                onChange={(e) => handleChange("dateOfJoining", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                fullWidth
                                type="date"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Basic Salary</InputLabel>
                            </Grid>
                            <TextField
                                value={params?.basicSalary || ""}
                                onChange={(e) => handleChange("basicSalary", e.target.value)}
                                placeholder="Enter Basic Salary"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Branch</InputLabel>
                            </Grid>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                value={`${params?.branchId}` || ""}
                                onChange={(e) => handleChange("branchId", e.target.value)}
                            >
                                {branches.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Incentive Details</InputLabel>
                            </Grid>
                            <TextField
                                multiline
                                rows={5}
                                value={params?.incentiveDetails || ""}
                                onChange={(e) => handleChange("incentiveDetails", e.target.value
                                )}
                                placeholder="Enter Incentive Details"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Shift Details</Typography>
                        </Grid>
                    </Grid>
                    {shiftDetails?.map((item, index) => (
                        <Grid container spacing={2}>
                            <Grid item md={3} sm={3} lg={3}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <InputLabel className="mb-1">Shift Start Time</InputLabel>
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={item?.startTime || ""}
                                        onChange={(newValue) => {
                                            setShiftDetails((prev) => {
                                                const temp = [...prev];
                                                const exist = temp.find((s, i) => i === index);
                                                if (exist) {
                                                    exist.startTime = newValue;
                                                    return temp;
                                                }
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                {...params}
                                                sx={{
                                                    svg: {color: "#fff"}
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={3} sm={3} lg={3}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <InputLabel className="mb-1">Shift End Time</InputLabel>
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={item?.endTime || ""}
                                        onChange={(newValue) => {
                                            setShiftDetails((prev) => {
                                                const temp = [...prev];
                                                const exist = temp.find((s, i) => i === index);
                                                if (exist) {
                                                    exist.endTime = newValue;
                                                    return temp;
                                                }
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                {...params}
                                                sx={{
                                                    svg: {color: "#fff"}
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <InputLabel className="mb-1">Assign Phone</InputLabel>
                                </Grid>
                                <TextField
                                    fullWidth
                                    size="small"
                                    select
                                    variant="outlined"
                                    value={item?.assignPhone || ""}
                                    color="primary"
                                    onChange={(e) => {
                                        setShiftDetails((prev) => {
                                            const temp = [...prev];
                                            const exist = temp.find((s, i) => i === index);
                                            if (exist) {
                                                exist.assignPhone = e.target.value;
                                                return temp;
                                            }
                                        });
                                    }}
                                >
                                    {phoneDevices?.map((phone) => (
                                        <MenuItem key={phone?.id} value={phone?.id}>{phone?.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <InputLabel className="mb-1">Assign Laptop</InputLabel>
                                </Grid>
                                <TextField
                                    fullWidth
                                    size="small"
                                    select
                                    variant="outlined"
                                    value={item?.assignLaptop || ""}
                                    color="primary"
                                    onChange={(e) => {
                                        handleChange("roleId", e.target.value)
                                        setShiftDetails((prev) => {
                                            const temp = [...prev];
                                            const exist = temp.find((s, i) => i === index);
                                            if (exist) {
                                                exist.assignLaptop = e.target.value;
                                                return temp;
                                            }
                                        });
                                    }}
                                >
                                    {laptopDevices?.map((laptop) => (
                                        <MenuItem key={laptop?.id} value={laptop?.id}>{laptop?.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} display="flex" gap={2} justifyContent="flex-end">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                        setShiftDetails((prev) => {
                                            const temp = [...prev];
                                            return temp.filter((s, i) => i !== index);
                                        });
                                    }}>
                                    Delete
                                </Button>

                                <Button
                                    variant="contained"
                                    color="warning"
                                    size="small"
                                    onClick={() => {
                                        setShiftDetails((prev) => {
                                            return [
                                                ...prev,
                                                {
                                                    startTime: dayjs(new Date()),
                                                    endTime: dayjs(new Date()),
                                                    assignPhone: "",
                                                    assignLaptop: "",
                                                },
                                            ];
                                        });
                                    }}>
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Typography variant="h6">Shift Days</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        {days?.map((item) => (
                            <Grid item md= {2} lg={1.5}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={shiftDays?.includes(item)}
                                                onChange={(e) => {
                                                    setShiftDays((prev) => {
                                                        const temp = [...prev];
                                                        if (temp?.includes(item)) {
                                                            return temp.filter((s) => s !== item);
                                                        } else {
                                                            return [...prev, item];
                                                        }
                                                    });
                                                }}
                                                sx={{
                                                    svg: {color: "#fff"}
                                                }}
                                            />
                                        }
                                        label={item}
                                    />
                                </FormGroup>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Typography variant="h6"> Employee Bank Details</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Account Number</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                value={params?.accountNumber || ""}
                                onChange={(e) => handleChange("accountNumber", e.target.value)}
                                placeholder="Enter Account Number"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">IFSC</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                value={params?.ifsc || ""}
                                onChange={(e) => handleChange("ifsc", e.target.value.toUpperCase())}
                                placeholder="Enter Ifsc"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Branch</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                value={params?.branch || ""}
                                onChange={(e) => handleChange("branch", e.target.value)}
                                placeholder="Enter Branch"
                            />
                            {/*<CustomSelect*/}
                            {/*    multiple*/}
                            {/*    color="primary"*/}
                            {/*    fullWidth*/}
                            {/*    value={interestedInIds}*/}
                            {/*    input={<OutlinedInput id="select-multiple-chip"/>}*/}
                            {/*    size="small"*/}
                            {/*    onChange={(e) => {*/}
                            {/*        const {value} = e.target;*/}
                            {/*        setInterestedInIds([...value])*/}
                            {/*    }}*/}
                            {/*    renderValue={(selected) => (<Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>*/}
                            {/*            {selected?.map((value) => (*/}
                            {/*                <Chip*/}
                            {/*                    color="primary"*/}
                            {/*                    size="small"*/}
                            {/*                    key={value?.id}*/}
                            {/*                    label={value?.name}*/}
                            {/*                />*/}
                            {/*            ))}*/}
                            {/*        </Box>*/}
                            {/*    )*/}

                            {/*    }*/}
                            {/*>*/}
                            {/*    {courses?.map((item, index) => (*/}
                            {/*        <MenuItem*/}
                            {/*            key={index}*/}
                            {/*            value={item}*/}
                            {/*        >*/}
                            {/*            <Checkbox*/}
                            {/*                checked={!!interestedInIds?.length && interestedInIds?.find(i => i?.id == item?.id)}*/}
                            {/*            />*/}

                            {/*            {item?.name}*/}
                            {/*        </MenuItem>*/}
                            {/*    ))}*/}
                            {/*</CustomSelect>*/}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Description</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                multiline
                                rows={4}
                                value={params?.description || ""}
                                onChange={(e) => handleChange("description", e.target.value)}
                                placeholder="Enter Description"
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <Typography variant="h6">API Key for OTP Manager</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Api key</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth   
                                size="small"
                                // value={params?.apiKey || ""}
                                // onChange={(e) => handleChange("apiKey", e.target.value)}
                                placeholder="Enter Api key"
                            />
                        </Grid> */}


                        <Grid item xs={12}>
                            <Typography variant="h6">Documents</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={4} sm={4} lg={4}>
                            <ImageUploader
                                name="photo"
                                id="photo"
                                file={params?.photo}
                                src={
                                    params?.photo?.type ?
                                        `${URL.createObjectURL(params?.photo)}`
                                        :
                                        params?.photo ?
                                            `${USER_PROFILE_BASE_URL}${params?.photo}`
                                            : ""
                                }
                                onClick={() =>
                                    setParams((prev) => {
                                        return {...prev, photo: ""};
                                    })
                                }
                                onChange={(file) => {
                                    handleChange("photo", file)
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={4} lg={4}>
                            <ImageUploader
                                name="aadharCardBack"
                                id="aadharCardBack"
                                file={params?.aadharCardBack}
                                src={params?.aadharCardBack?.type ?
                                    `${window?.URL.createObjectURL(params?.aadharCardBack)}`
                                    :
                                    `${USER_DOCUMENT_BASE_URL}${params?.aadharCardBack}`}
                                onClick={() =>
                                    setParams((prev) => {
                                        return {...prev, aadharCardBack: ""};
                                    })
                                }
                                onChange={(file) => {
                                    handleChange("aadharCardBack", file)
                                }}
                            />

                        </Grid>
                        <Grid item md={4} sm={4} lg={4}>
                            <ImageUploader
                                name="aadharCardFront"
                                id="aadharCardFront"
                                file={params?.aadharCardFront}
                                src={params?.aadharCardFront?.type ?
                                    `${window?.URL.createObjectURL(params?.aadharCardFront)}`
                                    :
                                    `${USER_DOCUMENT_BASE_URL}${params?.aadharCardFront}`}
                                onClick={() =>
                                    setParams((prev) => {
                                        return {...prev, aadharCardFront: ""};
                                    })
                                }
                                onChange={(file) => {
                                    handleChange("aadharCardFront", file)
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={4} lg={4}>
                            <ImageUploader
                                name="declarationForm"
                                id="declarationForm"
                                file={params?.declarationForm}
                                src={params?.declarationForm?.type ?
                                    `${window?.URL.createObjectURL(params?.declarationForm)}`
                                    :
                                    `${USER_DOCUMENT_BASE_URL}${params?.declarationForm}`}
                                onClick={() =>
                                    setParams((prev) => {
                                        return {...prev, declarationForm: ""};
                                    })
                                }
                                onChange={(file) => {
                                    handleChange("declarationForm", file)
                                }}
                            />

                        </Grid>
                        <Grid item md={4} sm={4} lg={4}>
                            <ImageUploader
                                name="panCard"
                                id="panCard"
                                file={params?.panCard}
                                src={params?.panCard?.type ?
                                    `${window.URL.createObjectURL(params?.panCard)}`
                                    :
                                    `${USER_DOCUMENT_BASE_URL}${params?.panCard}`}
                                onClick={() =>
                                    setParams((prev) => {
                                        return {...prev, panCard: ""};
                                    })
                                }
                                onChange={(file) => {
                                    handleChange("panCard", file)
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={4} lg={4}>
                            <ImageUploader
                                name="offerLetter"
                                id="offerLetter"
                                file={params?.offerLetter}
                                src={params?.offerLetter?.type ?
                                    `${window?.URL.createObjectURL(params?.offerLetter)}`
                                    :
                                    `${USER_DOCUMENT_BASE_URL}${params?.offerLetter}`}
                                onClick={() =>
                                    setParams((prev) => {
                                        return {...prev, offerLetter: ""};
                                    })
                                }
                                onChange={(file) => {
                                    handleChange("offerLetter", file)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs
                        className="d-flex justify-content-end align-items-center mt-3"
                    >
                        <Button
                            variant="outlined"
                            onClick={cancel}
                            color="primary"
                            size="small"
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ms-2"
                            onClick={() => {
                                createUser();
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Submit
                        </Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};
