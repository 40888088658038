import React from "react";
import {Delete} from "@mui/icons-material";
import {Button, Tooltip, Grid, Typography} from "@mui/material";

export function CustomFilterCard(props) {
    const {filter, sx, onDelete} = props;


    return (
        <Tooltip title={
            Object.entries(JSON.parse(filter.filtersWithLabels)).map(([key, value]) => {
                return (<Grid container key={key}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6}><Typography variant="caption">{key}</Typography></Grid>
                            <Grid item xs={6}><Typography variant="caption">{value}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>);
            })
        }
        >
            <Button variant="outlined" size="small" endIcon={<Delete onClick={()=>onDelete(filter?.id)}/>} sx={sx}>
                {filter.title}
            </Button>
        </Tooltip>
    );
}