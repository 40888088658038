import React from "react";
import Divider from "@mui/material/Divider";
import { ActionButton } from "../components/buttons";
import { SelectBox } from "../components/inputs";
import Footer from "../components/Footer";

export function Media() {
    return (
        <>
            <div className="container-fluid top-navbar">
                <div className='row w-100 g-2'>
                    <div className='mb-3 col-3 d-flex'>
                        <ActionButton buttonName="New Lead" />
                        <ActionButton buttonName={<i className="bi bi-download"></i>} />
                        <ActionButton buttonName={<i className="bi bi-upload"></i>} />
                        <ActionButton buttonName={<i className="bi bi-file-bar-graph"></i>} />
                        <Divider orientation="vertical" className='TopNavBardivider' variant="middle" flexItem />
                    </div>
                    <div className='col-2'>
                        <SelectBox selectName="Assigned" style={{ borderRadius: "5px", border: "1px solid #5C4FDD" }} />
                    </div>
                    <div className='col-2'>
                        <SelectBox selectName="Status" style={{ borderRadius: "5px", border: "1px solid #5C4FDD" }} />
                    </div>
                    <div className='col-2'>
                        <SelectBox selectName="Source" style={{ borderRadius: "5px", border: "1px solid #5C4FDD" }} />
                    </div>
                    <div className='col-3'>
                        <input className="form-control InputBox" type="search" placeholder="Search" aria-label="Search" />
                    </div>
                </div>
                <div> 
                 
                </div>
                <Footer pageName="Media"/>
            </div>
        </>
    );
}

