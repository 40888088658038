/* eslint-disable */
import React, {useRef} from "react";
import {Button} from "@mui/material";
import EmailEditor from "react-email-editor";

export function TemplateGenerator(){
    const emailEditorRef = useRef(null);

    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {
            const { design, html } = data;
        });
    };

    const onReady = (unlayer) => {
        console.log(unlayer);

        // editor is ready
        // you can load your template here;
        // the design json can be obtained by calling
        // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)

        // const templateJson = { DESIGN JSON GOES HERE };
        // unlayer.loadDesign(templateJson);
    };
    return (
        <div>
                <Button variant="contained" onClick={exportHtml} sx={{mb:2}}>Export HTML</Button>

            <EmailEditor ref={emailEditorRef} onReady={onReady} minHeight={"calc(100vh - 120px)"}/>
        </div>
    );
}