/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    Tooltip, Typography
} from "@mui/material";
import {ReactPagination} from "../components/Pagination";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch} from "react-redux";
import {Edit} from "@mui/icons-material"
import {BranchModal} from "../dialogs/BranchModal";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";

export const Branch = () => {
    const dispatch = useDispatch();
    const [branch, setBranch] = useState(null);
    const [showBranchModal, setShowBranchModal] = useState(false);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const [branches, setBranches] = useState([]);
    const retrieveBranches = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {branches, total}} = await $crud.retrieve("web/branches", {
                limit,
                page
            });
            setBranches(branches);
            setTotal(total);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    const statusChange=async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("create/web/status/branch", {
                id
            });
            retrieveBranches();
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        retrieveBranches();
    }, [limit, page]);

    return (
        <>
            <div>
                <div className='row w-100 g-2'>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setShowBranchModal(true)} buttonName="Add Branch"/>
                    </div>
                </div>
            </div>

            <DynamicTable
                rows={branches}
                columns={columns?.branch}
                total={total}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}
                onEdit={(value) => {
                    setShowBranchModal(true);
                    setBranch(value)
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
            <BranchModal
                open={showBranchModal}
                onClose={()=> {
                    setBranch(null)
                    setShowBranchModal(false)
                }}
                branch={branch}
                onSave={()=>retrieveBranches()}
            />
        </>
    );
};

