import React from "react";
import Template from "./template-11.svg";

export const TemplateAttachment = () => {
  return (
    <div
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        background: "linear-gradient(#34e7e4, #00d8d6)",
        color: "white",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow:"0 0 10px rgba(0,0,0,0.4)",
      }}
    >
      <img src={Template} width="20" height="20" />
    </div>
  );
};
