const initialState = {
    showModal: false,
    edit_id: null
};

const leadFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case "EDITLEAD":
            return (state = action.payload);
        case "RESETEDITLEAD":
            return (state = initialState);
        default:
            return state;
    }
};

export default leadFormReducer;
