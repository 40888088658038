import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import { BrowserRouter } from "react-router-dom";
import {StyledEngineProvider} from "@mui/material/styles";
import {HashRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    // <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <HashRouter>
                <App/>
            </HashRouter>
        </StyledEngineProvider>
    // </React.StrictMode>
);
