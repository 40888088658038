/* eslint-disable */
import React from "react";
import {Avatar, Tooltip, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import moment from "moment";
import {baseUrl, USER_PROFILE_BASE_URL} from "../../../constants";
import {useSelector} from "react-redux";

export function ActivityLog({lead}) {
    const users = useSelector(state => state.usersReducer);
    const getUser = (id) => {
        const name = users?.filter(u => u.id === Number(id))[0]?.name;
        const photo = users?.filter(u => u.id === Number(id))[0]?.photo;
        return {name, photo}
    }
    return (
        <TableContainer
            component={Paper}
            style={{maxHeight: "100%"}}
            className="table-container tableHeader-color"
            elevation={1}
        >
            <Table stickyHeader sx={{minWidth: 650}} size="small">
                <TableHead>
                    <TableRow className="tableHeader-color">
                        <TableCell className='tableHeader-color'>Operation</TableCell>
                        <TableCell className='tableHeader-color'>User</TableCell>
                        <TableCell className='tableHeader-color'>Date & Time</TableCell>
                        <TableCell className='tableHeader-color'>Source IP</TableCell>
                        <TableCell className='tableHeader-color'>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className='tablebody-color'>
                    {
                        lead?.activityLogs?.map((item) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1}
                                          className='tablebody-color'
                                >
                                    <TableCell>{item?.type}</TableCell>
                                    <TableCell>
                                        <Tooltip placement="top"
                                                 title={getUser(item?.userId)?.name || "N/A"}>
                                            <Avatar
                                                src={USER_PROFILE_BASE_URL + getUser(item?.userId)?.photo}/>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell style={{minWidth:"180px"}}>{moment(item?.createdAt).format("DD/MM/YYYY | LT")}</TableCell>
                                    <TableCell>
                                        {item?.sourceIp}
                                    </TableCell>
                                    <TableCell>{item?.description}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

