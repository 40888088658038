/* eslint-disable */
import React, {useEffect, useState, useCallback} from "react";
import {Tune} from "@mui/icons-material";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    Slide,
    Slider,
    DialogActions,
    Button,
    TextField,
} from "@mui/material";
import {DateRangePicker} from "rsuite";
import "rsuite/dist/rsuite.css";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {
    addCustomFilter,
    addFilterWithLabels,
    addFilterWithValues,
    removeCustomFilter,
    setCustomFilters
} from "../redux/actions";
import {$crud} from "../services/CrudFactory";
import {ADMIN_ROLE, SUPER_ADMIN_ROLE, TEAM_LEADER_ROLE} from "../constants";
import Tooltip from "@mui/material/Tooltip";
import {useDataParams} from "../hooks";
import {CustomFilterCard} from "./cards";
import {isEmpty} from "lodash";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import AsyncAutocomplete from "./inputs/AsyncAutocomplete";

function CustomFilterPageModal(props) {
    const {open, onClose, onSave} = props;
    const {params, setParams, handleChange} = useDataParams();
    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {

                }
            }
        } TransitionComponent={Slide} fullWidth open={open} onClose={onClose}>
            <Grid className="tableHeader-color filter-container">
                <DialogTitle>Add Filters</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Title</InputLabel>
                            </Grid>
                            <TextField
                                value={params?.title || ""}
                                onChange={(e) => handleChange("title", e.target.value
                                )}
                                placeholder="Enter Title"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        onClose();
                        setParams({});
                    }} size="small" variant="outlined">Cancel</Button>
                    <Button onClick={() => {
                        onSave(params);
                        onClose();
                        setParams({});
                    }} variant="contained" size="small">Save</Button>
                </DialogActions>
            </Grid>
        </Dialog>
    )
}

function sleep(duration) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, duration);
    });

}

function FilterModal(props) {
    const {open, onClose} = props;
    const [showCustomPageModal, setShowCustomPageModal] = useState(false);
    const [filters, setFilters] = useState(null);
    const [filtersWithLabels, setFiltersWithLabels] = useState(null);
    const [branches, setBranches] = useState([]);
    const [dataType, setDataType] = useState("");
    const [degrees, setDegrees] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [leadTypes, setLeadTypes] = useState([]);
    const [tags, setTags] = useState([]);
    const [callStatuses, setCallStatuses] = useState([]);
    // const [customFilters, setCustomFilters] = useState([]);
    const allStatuses = useSelector(state => state.statusReducer);
    const courses = useSelector(state => state.courseReducer);
    const sources = useSelector(state => state.sourcesReducer);
    const user = useSelector(state => state.userReducer);
    const users = useSelector(state => state.usersReducer);
    const customFilters = useSelector(state => state.filterReducer).customFilters;
    const reminderOptions = [{name: "No Reminder", value: "0"}, {name: "Reminder", value: "1"}];
    const {afterToday} = DateRangePicker;
    const dispatch = useDispatch();
    const courseYears = [{id: 1, name: "I Year"}, {id: 2, name: "II Year"}, {id: 3, name: "III Year"}, {
        id: 4,
        name: "IV Year"
    }]
    let branchId = localStorage.getItem("branchInfo");
    let branchInfo = JSON.parse(branchId);

    const addFilters = (name, value, label) => {
        if (value === "any") {
            if (filters.hasOwnProperty(name)) {
                setFilters(prev => {
                    delete prev[name];
                    return {...prev};
                });
                setFiltersWithLabels(prev => {
                    delete prev[name];
                    return {...prev};
                });
            }
            return
        } else {

            setFilters(prev => {
                return {...prev, [name]: value}
            })
            setFiltersWithLabels(prev => {
                return {...prev, [name]: label}
            })
        }
    }
    const createPage = async (data) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {message, type, data: {customFilter}} = await $crud.post("create/web/custom-filter", {
                ...data,
                filtersWithValues: JSON.stringify(filters),
                filtersWithLabels: JSON.stringify(filtersWithLabels),
            });
            dispatch(addCustomFilter(customFilter));
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })

        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    const retrieveData = useCallback(async (url, params = {}) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data} = await $crud.retrieve(url, params);
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    },[dataType])

    const handleDelete = async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {message, type} = await $crud.post("delete/web/custom-filter", {id});
            dispatch(removeCustomFilter(id));
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });

        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    useEffect(() => {
        open && Promise.all([
            retrieveData("web/custom-filters")
        ])
            .then(res => {
                dispatch(setCustomFilters(res[5].customFilters))
            })
            .catch(error => console.error(error))
    }, [open]);

    return (
        <React.Fragment>
            <Dialog maxWidth={"lg"} TransitionProps={
                {
                    onEnter: () => {
                        setFilters({});
                    }
                }
            } TransitionComponent={Slide} fullWidth open={open} onClose={onClose}>
                <Grid className="tableHeader-color filter-container">
                    <DialogTitle>Add Filters</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <select
                                    onChange={(e) => addFilters("remainder", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}
                                    className="form-select selectBox"
                                    style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}
                                    aria-label="Default select example"
                                >
                                    <option value="any">Select Follow Up</option>
                                    {
                                        reminderOptions.map(option => <option key={option?.value}
                                                                              value={option?.value}>{option?.name}</option>)
                                    }
                                </select>
                            </Grid>
                            {[SUPER_ADMIN_ROLE, ADMIN_ROLE, TEAM_LEADER_ROLE].includes(user?.role?.name) && (
                                <Grid item xs={3}>
                                    <select
                                        onChange={(e) => addFilters("assignTo", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}
                                        className="form-select selectBox"
                                        style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}
                                        aria-label="Default select example"
                                        data-label={users?.find(u => u.id === filters?.assignTo)?.name}
                                    >
                                        <option value="any">Assign To</option>
                                        {users?.map((item) => (
                                            <option key={item?.id} value={item?.id}>{item?.name}</option>
                                        ))}
                                    </select>
                                </Grid>
                            )}
                            <Grid item xs={3}>
                                <select
                                    onChange={(e) => addFilters("statusId", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}
                                    className="form-select selectBox"
                                    style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}
                                    aria-label="Default select example"
                                    data-label={allStatuses?.find(u => u.id === filters?.statusId)?.name}
                                >
                                    <option value="any">Select Status</option>
                                    {allStatuses?.map((item) => (
                                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3}>
                                <select
                                    onChange={(e) => addFilters("sourceId", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}
                                    className="form-select selectBox"
                                    style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}
                                    aria-label="Default select example"
                                    data-label={sources?.find(u => u.id === filters?.sourceId)?.name}
                                >
                                    <option value="any">Select Source</option>
                                    {sources?.map((item) => (
                                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3}>
                                <select
                                    onChange={(e) => addFilters("interestedInId", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}
                                    className="form-select selectBox"
                                    style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}
                                    aria-label="Default select example"
                                    data-label={courses?.find(u => u.id === filters?.interestedInId)?.name}
                                >
                                    <option value="any">Select Interest</option>
                                    {courses?.map((item) => (
                                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3}>
                                <select
                                    onChange={(e) => addFilters("attendedBy", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}
                                    className="form-select selectBox"
                                    style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}
                                    aria-label="Default select example"
                                    data-label={users?.find(u => u.id === filters?.attendedBy)?.name}
                                >
                                    <option value="any">Attended By</option>
                                    {users?.map((item) => (
                                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3}>
                                <AsyncAutocomplete
                                    fetchOptions={async () => {
                                        const {branches: data} = await retrieveData("web/branches");
                                        setBranches(data)
                                    }}
                                    onChange={(e, value) => addFilters("branchId", value?.id, value?.name)}
                                    options={branches}
                                    style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}
                                    fullWidth
                                    setOptions={setBranches}
                                    size="small"
                                    label="Branches"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AsyncAutocomplete
                                    fetchOptions={async () => {
                                        const {colleges: data} = await retrieveData("web/colleges");
                                        setColleges(data)
                                    }}
                                    onChange={(e, value) => addFilters("collegeId", value?.id, value?.name)}
                                    options={colleges}
                                    fullWidth
                                    setOptions={setColleges}
                                    size="small"
                                    label="College"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AsyncAutocomplete
                                    fetchOptions={async () => {
                                        const {boardAndUniversities: data} = await retrieveData("web/board-and-universities");
                                        setUniversities(data)
                                    }}
                                    onChange={(e, value) => addFilters("universityId", value?.id, value?.name)}
                                    options={universities}
                                    fullWidth
                                    setOptions={setUniversities}
                                    size="small"
                                    label="University"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AsyncAutocomplete
                                    fetchOptions={async () => {
                                        const {degreeAndDiplomas: data} = await retrieveData("web/degree-and-diplomas");
                                        setDegrees(data)
                                    }}
                                    onChange={(e, value) => addFilters("degreeId", value?.id, value?.name)}
                                    options={degrees}
                                    fullWidth
                                    setOptions={setDegrees}
                                    size="small"
                                    label="Degree"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AsyncAutocomplete
                                    fetchOptions={async () => {
                                        const {leadTypes: data} = await retrieveData("web/lead-types");
                                        setLeadTypes(data)
                                    }}
                                    onChange={(e, value) => addFilters("leadTypeId", value?.id, value?.name)}
                                    options={leadTypes}
                                    fullWidth
                                    setOptions={setLeadTypes}
                                    size="small"
                                    label="Lead Type"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AsyncAutocomplete
                                    fetchOptions={async () => {
                                        if (branchInfo?.id) {
                                            const {tags: data} = await retrieveData("web/sub-tags", {branchId: branchInfo?.id});
                                            setTags(data)
                                        } else {
                                            dispatch({
                                                type: "SNACKBAR",
                                                snackBar: {
                                                    type: "error",
                                                    message: "You don't support any branch. Cannot Get Tags"
                                                }
                                            })
                                        }
                                    }}
                                    onChange={(e, value) => addFilters("tag", value?.id, value?.name)}
                                    options={tags}
                                    fullWidth
                                    setOptions={setTags}
                                    size="small"
                                    label="Tag"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AsyncAutocomplete
                                    fetchOptions={async () => {
                                        await sleep(500);
                                        setCallStatuses([{
                                            id: "Not Reachable",
                                            name: "Not Reachable"
                                        }, {id: "Not Picked", name: "Not Picked"}, {
                                            id: "Answered",
                                            name: "Answered"
                                        }, {id: "Rejected", name: "Rejected"}])
                                    }}
                                    onChange={(e, value) => addFilters("callStatus", value?.id, value?.name)}
                                    options={callStatuses}
                                    fullWidth
                                    setOptions={setCallStatuses}
                                    size="small"
                                    label="Call Status"
                                />
                            </Grid>
                            {/*<Grid item xs={3}>*/}
                            {/*    <select*/}
                            {/*        onChange={(e) => addFilters("branchId", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}*/}
                            {/*        className="form-select selectBox"*/}
                            {/*        style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}*/}
                            {/*        aria-label="Default select example"*/}
                            {/*        data-label={branches?.find(u => u.id === filters?.branchId)?.name}*/}
                            {/*    >*/}
                            {/*        <option value="any">Select Branch</option>*/}
                            {/*        {branches?.map((item) => (*/}
                            {/*            <option key={item?.id} value={item?.id}>{item?.name}</option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={3}>*/}
                            {/*    <select*/}
                            {/*        onChange={(e) => addFilters("callStatus", e.target.value, e.target.value)}*/}
                            {/*        className="form-select selectBox"*/}
                            {/*        style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}*/}
                            {/*        aria-label="Default select example"*/}
                            {/*    >*/}
                            {/*        <option value="any">Call Status</option>*/}
                            {/*        {["Not Reachable", "Not Picked", "Answered", "Rejected"]?.map((item) => (*/}
                            {/*            <option key={item} value={item}>{item}</option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={3}>*/}
                            {/*    <select*/}
                            {/*        onChange={(e) => addFilters("degreeId", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}*/}
                            {/*        className="form-select selectBox"*/}
                            {/*        style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}*/}
                            {/*        aria-label="Default select example"*/}
                            {/*    >*/}
                            {/*        <option value="any">Select Degree/Diploma</option>*/}
                            {/*        {degrees?.map((item) => (*/}
                            {/*            <option key={item?.id} value={item?.id}>{item?.name}</option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={3}>*/}
                            {/*    <select*/}
                            {/*        onChange={(e) => addFilters("collegeId", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}*/}
                            {/*        className="form-select selectBox"*/}
                            {/*        style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}*/}
                            {/*        aria-label="Default select example"*/}
                            {/*    >*/}
                            {/*        <option value="any">Select College</option>*/}
                            {/*        {colleges?.map((item) => (*/}
                            {/*            <option key={item?.id} value={item?.id}>{item?.name}</option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={3}>*/}
                            {/*    <select*/}
                            {/*        onChange={(e) => addFilters("universityId", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}*/}
                            {/*        className="form-select selectBox"*/}
                            {/*        style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}*/}
                            {/*        aria-label="Default select example"*/}
                            {/*    >*/}
                            {/*        <option value="any">Select University</option>*/}
                            {/*        {universities?.map((item) => (*/}
                            {/*            <option key={item?.id} value={item?.id}>{item?.name}</option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={3}>*/}
                            {/*    <select*/}
                            {/*        onChange={(e) => addFilters("leadTypeId", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}*/}
                            {/*        className="form-select selectBox"*/}
                            {/*        style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}*/}
                            {/*        aria-label="Default select example"*/}
                            {/*    >*/}
                            {/*        <option value="any">Select LeadType</option>*/}
                            {/*        {leadTypes?.map((item) => (*/}
                            {/*            <option key={item?.id} value={item?.id}>{item?.name}</option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={3}>*/}
                            {/*    <select*/}
                            {/*        onChange={(e) => addFilters("tag", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}*/}
                            {/*        className="form-select selectBox"*/}
                            {/*        style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}*/}
                            {/*        aria-label="Default select example"*/}
                            {/*    >*/}
                            {/*        <option value="any">Select Tag</option>*/}
                            {/*        {tags?.map((item) => (*/}
                            {/*            <option key={item?.id} value={item?.id}>{item?.name}</option>*/}
                            {/*        ))}*/}
                            {/*    </select>*/}
                            {/*</Grid>*/}
                            {/* <Grid item xs={3}> */}
                            {/* <select
                                    onChange={(e) => addFilters("yearOfPassing", e.target.value, e.target.options[e.target.options.selectedIndex].innerText)}
                                    className="form-select selectBox"
                                    style={{borderRadius: "5px", border: "1px solid #5C4FDD"}}
                                    aria-label="Default select example"
                                >
                                    <option value="any">Select Course Year</option>
                                    {courseYears?.map((item) => (
                                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                                    ))}
                                </select> */}

                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker label={'Course Current Year'} views={['year']}
                                    size="small"
                                    fullWidth
                                        onChange = {(date)=> console.log(date, "----------date")}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}

                            {/* </Grid> */}

                            <Grid item xs={3}>
                                <DateRangePicker placeholder="Created From - Created To" appearance="subtle"
                                                 shouldDisableDate={afterToday()} onChange={(value) => {
                                    addFilters("date", JSON.stringify({
                                        from: moment(`${value[0]}`).format("YYYY-MM-DD HH:MM:SS"),
                                        to: moment(`${value[1]}`).format("YYYY-MM-DD HH:MM:SS")
                                    }), JSON.stringify({
                                        from: moment(`${value[0]}`).format("YYYY-MM-DD"),
                                        to: moment(`${value[1]}`).format("YYYY-MM-DD")
                                    }))
                                }}/>
                            </Grid>
                            <Grid item xs={3}>
                                <DateRangePicker placeholder="FollowUp From - FollowUp To" appearance="subtle"
                                                 shouldDisableDate={afterToday()} onChange={(value) => {
                                    addFilters("followUpDateRange", JSON.stringify({
                                        from: moment(`${value[0]}`).format("YYYY-MM-DD HH:MM:SS"),
                                        to: moment(`${value[1]}`).format("YYYY-MM-DD HH:MM:SS")
                                    }), JSON.stringify({
                                        from: moment(`${value[0]}`).format("YYYY-MM-DD"),
                                        to: moment(`${value[1]}`).format("YYYY-MM-DD")
                                    }))
                                }}/>
                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                            <Grid item xs={3} px={2}>
                                <InputLabel> Lead Value </InputLabel>
                                <Slider
                                    track="inverted"
                                    aria-labelledby="track-inverted-range-slider"
                                    step={20}
                                    getAriaValueText={(value) => value}
                                    valueLabelFormat={(value) => value + "k"}
                                    color="primary"
                                    onChange={(e,) => {
                                        const {value} = e.target;
                                        addFilters("leadValue", JSON.stringify({
                                            from: value[0],
                                            to: value[1]
                                        }), JSON.stringify({from: value[0] * 1000, to: value[1] * 1000}))
                                    }}
                                    defaultValue={[20, 80]}
                                    valueLabelDisplay="auto"
                                    marks={
                                        [
                                            {value: 0, label: "0"},
                                            {value: 20, label: "20k"},
                                            {value: 40, label: "40k"},
                                            {value: 60, label: "60k"},
                                            {value: 80, label: "80k"},
                                            {value: 100, label: "100k"}
                                        ]
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel>Lead Score</InputLabel>
                                <Slider
                                    track="inverted"
                                    aria-labelledby="track-inverted-range-slider"
                                    step={20}
                                    getAriaValueText={(value) => value}
                                    valueLabelFormat={(value) => value + "%"}
                                    color="primary"
                                    onChange={(e) => {
                                        const {value} = e.target;
                                        addFilters("leadScore", JSON.stringify({
                                            from: value[0],
                                            to: value[1]
                                        }), JSON.stringify({from: value[0] + "%", to: value[1] + "%"}))
                                    }}
                                    defaultValue={[20, 80]}
                                    valueLabelDisplay="auto"
                                    marks={[{value: 0, label: "0%"}, {value: 20, label: "20%"}, {
                                        value: 40,
                                        label: "40%"
                                    }, {value: 60, label: "60%"}, {value: 80, label: "80%"}, {
                                        value: 100,
                                        label: "100%"
                                    }]}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel>Lead FollowUp Counts</InputLabel>
                                <Slider
                                    track="inverted"
                                    aria-labelledby="track-inverted-range-slider"
                                    step={20}
                                    getAriaValueText={(value) => value}
                                    color="primary"
                                    onChange={(e) => {
                                        const {value} = e.target;
                                        addFilters("followupCount", JSON.stringify({
                                            from: value[0],
                                            to: value[1]
                                        }), JSON.stringify({from: value[0], to: value[1]}))
                                    }}
                                    defaultValue={[20, 40]}
                                    valueLabelDisplay="auto"
                                    marks={[{value: 0, label: "0"}, {value: 20, label: "10"}, {
                                        value: 40,
                                        label: "20"
                                    }, {value: 60, label: "30"}, {value: 80, label: "40"}, {value: 100, label: "50"}]}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel>Lead Counts</InputLabel>
                                <Slider
                                    track="inverted"
                                    aria-labelledby="track-inverted-range-slider"
                                    step={20}
                                    getAriaValueText={(value) => value}
                                    color="primary"
                                    onChange={(e) => {
                                        const {value} = e.target;
                                        addFilters("leadCount", JSON.stringify({
                                            from: value[0],
                                            to: value[1]
                                        }), JSON.stringify({from: value[0], to: value[1]}))
                                    }}
                                    defaultValue={[40, 60]}
                                    valueLabelDisplay="auto"
                                    marks={
                                        [
                                            {value: 0, label: "0"},
                                            {value: 20, label: "5"},
                                            {value: 40, label: "10"},
                                            {value: 60, label: "15"},
                                            {value: 80, label: "20"},
                                            {value: 100, label: "25"}
                                        ]
                                    }
                                />

                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel>Last Follow Up (Days Ago)</InputLabel>
                                <Slider
                                    track="inverted"
                                    aria-labelledby="track-inverted-range-slider"
                                    // step={3}
                                    min={1}
                                    max={30}
                                    getAriaValueText={(value) => value}
                                    color="primary"
                                    onChange={(e) => {
                                        const {value} = e.target;
                                        addFilters("lastFollowUpAgo", value[0], value[0])
                                    }}
                                    defaultValue={[6]}
                                    valueLabelDisplay="auto"
                                    marks={
                                        [
                                            {value: 1, label: "1"},
                                            {value: 3, label: "3"},
                                            {value: 6, label: "6"},
                                            {value: 9, label: "9"},
                                            {value: 12, label: "12"},
                                            {value: 15, label: "15"},
                                            {value: 18, label: "18"},
                                            {value: 21, label: "21"},
                                            {value: 24, label: "24"},
                                            {value: 27, label: "27"},
                                            {value: 30, label: "30"}
                                        ]
                                    }
                                />

                            </Grid>

                        </Grid>
                        <Grid container spacing={2} mt={4}>
                            {
                                customFilters?.map(filter => <CustomFilterCard filter={filter} key={filter?.title}
                                                                               sx={{mr: 2}}
                                                                               onDelete={(id) => handleDelete(id)}/>)
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Tooltip title={"Create Custom Pages With Selected Filters. | Select One or More Filters."}>
                            <Button onClick={() => setShowCustomPageModal(true)} size="small" variant="outlined"
                                    disabled={isEmpty(filters)}>Create
                                Custom Filter</Button>
                        </Tooltip>
                        <Button onClick={onClose} size="small" variant="outlined">Cancel</Button>
                        <Button onClick={() => {
                            dispatch(addFilterWithLabels(filtersWithLabels))
                            dispatch(addFilterWithValues(filters))
                            onClose()
                        }} variant="contained" size="small">Apply</Button>
                    </DialogActions>
                </Grid>
            </Dialog>
            <CustomFilterPageModal open={showCustomPageModal} onClose={() => setShowCustomPageModal(false)}
                                   onSave={(data) => createPage(data)}/>
        </React.Fragment>
    )
}


export function Filter({style}) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <Button onClick={() => setOpen(!open)} variant="contained" sx={{minWidth: "30px"}} color="primary"
                    size="small" style={style}><Tune/></Button>
            {open && <FilterModal
                open={open}
                onClose={() => setOpen(false)}
            />}
        </React.Fragment>
    )
}