/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { Scheduler } from "smart-webcomponents-react/scheduler";
import "smart-webcomponents-react/source/styles/smart.default.css";
import { Window } from "smart-webcomponents-react/window";
import { Button, useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { $crud } from "../services/CrudFactory";
import { ADMIN_ROLE } from "../constants";

export function TeacherScheduler() {
    // const theme = useTheme();
  const [targetEvent, setTargetEvent] = useState(null);
  const [eventEditors, setEventEditors] = useState(null);
  const [data, setData] = useState([]);
  const userRole = useSelector((state) => state.userReducer)?.role?.name;
  const dispatch = useDispatch();

  let scheduler = useRef(null);
  let alertWindow = useRef(null);

  const today = new Date(),
    date = today.getDate(),
    year = today.getFullYear(),
    month = today.getMonth(),
    hourStart = 8,
    hourEnd = 20;

  const resources = [
    {
      label: "User",
      value: "userId",
      dataSource: [
        {
          label: "Rohit Singh",
          id: 1,
          type: "Trainer",
          backgroundColor: "#919191",
        },
        {
          label: "Ajay Singh",
          id: 2,
          type: "Trainer",
          backgroundColor: "#208056",
        },
        {
          label: "Ajay Shivhare",
          id: 3,
          type: "Trainer",
          backgroundColor: "#BFA300",
        },
        {
          label: "Vishnu Singh",
          id: 4,
          type: "Trainer",
          backgroundColor: "#7B60BF",
        },
      ],
    },
  ];

  const cellTemplate = (cell, value) => {
    //Set the cell Lunch Break  label
    // cell.textContent = value.getHours() === 14 ? 'Lunch Break' : '';
  };

  function notifyForRestrictions(event) {
    if (scheduler.current.isEventRestricted(event.detail.itemDateRange)) {
      openNotification();
    }
  }

  function setEventEditDetails(event) {
    const eventDetails = event.detail;

    setTargetEvent(eventDetails.item);
    setEventEditors(eventDetails.editors);
  }

  function clearEventEdit() {
    setTargetEvent(null);
  }

  function checkEventEdit() {
    if (!targetEvent || !eventEditors) {
      return;
    }

    const dateStart = eventEditors.dateStart
        .querySelector("[event-editor]")
        .value.toDate(),
      dateEnd = eventEditors.dateEnd
        .querySelector("[event-editor]")
        .value.toDate();

    if (
      scheduler.current.isEventRestricted({
        dateStart: dateStart,
        dateEnd: dateEnd,
      })
    ) {
      openNotification();
    }
  }

  async function saveState() {
    scheduler.current.saveState();
    const data = localStorage.getItem("smartSchedulerscheduler");
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const { type, message } = await $crud.post(
        "create/web/teacher-schedule",
        { data: JSON.parse(data).dataSource }
      );
      dispatch({
        type: "SNACKBAR",
        snackBar: { type, message },
      });
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  }

  function openNotification() {
    alertWindow.current.open();
  }

  async function getData() {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const { data } = await $crud.retrieve("web/teacher-schedule");
      return data;
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  }

  function init() {
    const today = new Date();

    alertWindow.current.nativeElement.addEventListener("confirm", () =>
      alertWindow.current.close()
    );
    scheduler.current.scrollToDate(
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6),
      true
    );

    const template = document.createElement("template");

    template.id = "groupTemplate";
    template.innerHTML = `<span className="col" title="User Name" style="color:{{backgroundColor}} !important">{{label}} </span>`;

    document.body.appendChild(template);

    scheduler.current.groupTemplate = template.id;
  }

  useEffect(() => {
    init();
  });
  useEffect(() => {
    getData().then((res) => {
      setData(res);
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const divElement = document.getElementById('schedular'); // Make sure to set an id on your div

      if (divElement) {
        if (windowWidth >= 768 && windowWidth <= 865) {
          divElement.style.top = "175px";
        } else if (windowWidth >= 866 && windowWidth <= 1186) {
          divElement.style.top = "133px";
        } else {
          divElement.style.top = "85px";
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
    id = "schedular"
      style={{
        position: "fixed",
        top: "100px",
        width: "calc(100% - 30px)",
        marginLeft: "13px",
      }}
    >
      <Scheduler
        ref={scheduler}
        id="scheduler"
        view="timelineDay"
        views={["timelineDay"]}
        groupOrientation={"vertical"}
        groups={["userId"]}
        resources={resources}
        headerTemplate={""}
        dataSource={data}
        cellTemplate={cellTemplate}
        hourStart={hourStart}
        hourEnd={hourEnd}
        auto-height-all-day-cells
        auto-width-all-day-cells
        // restrictedHours={[14]}
        filterable={true}
        showLegend={true}
        onResizeEnd={notifyForRestrictions}
        onDragEnd={notifyForRestrictions}
        onEditDialogOpen={setEventEditDetails}
        onEditDialogClose={clearEventEdit}
        onItemUpdate={checkEventEdit}
      ></Scheduler>
      <Window ref={alertWindow} id="alertWindow" label="Alert">
        <h4>Event cannot last within the Lunch Break !</h4>
      </Window>
      {userRole === ADMIN_ROLE && (
        <div className="options mt-2">
          <div
            className="option"
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <Button variant="contained" id="saveState" onClick={saveState}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
