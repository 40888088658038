/* eslint-disable */
import React,{useEffect} from "react";
// import {SelectBox2, InputField2} from "../components/inputs";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import {SpeedDial, IconButton} from "@mui/material";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
    Attachment, BankAttachment,
    DocumentAttachment, ImageAttachment,
    LocationAttachment, PersonAttachment,
    SendRightArrow, TextAttachment
} from "../components/icons";
// import Divider from "@mui/material/Divider";
import {Fab, Tooltip} from "@mui/material";
import {QrCodeScanner, ContentCopy} from "@mui/icons-material";
import WhatsAppQR from "../dialogs/WhatsAppQR";
import {$crud} from "../services/CrudFactory";
import {useDispatch, useSelector} from "react-redux";
import {FilterChipContainer} from "../components";
import {copyToClipboard} from "../helpers/functions";
import Typography from "@mui/material/Typography";
import {Filter} from "../components/Filter";

export function WhatsAppForm() {
    const [open, setOpen] = React.useState(false);
    const [openQRDialog, setOpenQRDialog] = React.useState(false);
    const [numbersCount, setNumbersCount] = React.useState(0);
    const [numbers, setNumbers] = React.useState([]);
    const [fullData, setFullData] = React.useState([]);
    const filters = useSelector((state)=>state.filterReducer).withValues;
    const dispatch = useDispatch();
    const suggestion = [
        {
            LeadName: "lead_name",
            FatherName: "Father's_name",
            EmailId: "email_id",
            LeadValue: "lead_value",
            AssigneeName: "assignee_name",
            City: "city",
            Course: "course"
        }
    ];
    const retrieveLeadNumbers = async () =>{
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data} = await $crud.retrieve("web/leads-mobile",{
                ...filters
            });
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };
    const actions = [
        {icon: <TextAttachment/>, name: "Text", bg: "linear-gradient(to right,  red, green)"},
        {icon: <BankAttachment/>, name: "Banks", bg: "linear-Gradient(blue ,red)"},
        {icon: <LocationAttachment/>, name: "Location", bg: "linear-Gradient(blue ,red)"},
        {icon: <PersonAttachment/>, name: "Contact", bg: "linear-Gradient(blue ,red)"},
        {icon: <DocumentAttachment/>, name: "Document", bg: "linear-Gradient(blue ,red)"},
        {icon: <ImageAttachment/>, name: "Image", bg: "linear-Gradient(blue ,red)"},

    ];
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(()=>{
        retrieveLeadNumbers().then(res=> {
            const leadMobileArray1 =  res.leadsMobile?.filter((item) => !!item?.mobile1)?.map((value) => {
                return value["mobile1"]
            })
            const leadMobileArray2 =  res.leadsMobile?.filter((item) => !!item.mobile2)?.map((value) => {
                return value["mobile2"]
            })
            const leadsMobile = [...leadMobileArray1, ...leadMobileArray2]
            setNumbersCount(res?.leadCounts);

            setNumbers(leadsMobile.join("\r\n"));
            setFullData(`Lead-ID\tName\tMobile1\tMobile2\tAssign To\r\n${res.leadsMobile.map(lm=>`${lm.id}\t${lm.name || "---"}\t${lm.mobile1}\t${lm.mobile2 || "---"}\t${lm?.user ? lm.user.name : "---"}`).join("\r\n")}`);
        });
    },[filters]);

    return (

        <div className='row w-100 px-2'  >
            <div className="col-12 whatsAppForm">
            <Tooltip>
                <Fab size="small" sx={{borderRadius: "5px", marginRight: "10px"}} color="primary"
                     onClick={() =>setOpenQRDialog(true)}><QrCodeScanner/></Fab>
            </Tooltip>
            <FilterChipContainer style={{position:"fixed",top:"100px",left:"250px"}}/>
                <Filter/>
                 {/*<div className='row g-2'>*/}
                {/*    <div className="col-6 d-flex align-items-center mt-3">*/}
                {/*        <div style={{marginTop: "-10px", marginRight: "10px"}}>Send WhatsApp</div>*/}
                {/*        <div style={{width: "240px"}}><SelectBox2 selectName="CustomSelect"/></div>*/}
                {/*    </div>*/}
                {/*    <div className="col-6  d-flex align-items-center mt-3">*/}
                {/*        <div style={{marginTop: "-10px", width: "23%"}}>Camping Name</div>*/}
                {/*        <div style={{width: "100%"}}><InputField2 placeholderName="Camping Name"/></div>*/}
                {/*    </div>*/}
                {/*    <Divider style={{marginLeft: "-1px"}} variant="middle"/>*/}
                {/*    <div className="col-3 "><SelectBox2 labelName="Source" selectName="CustomSelect"/></div>*/}
                {/*    <div className="col-3">*/}
                {/*        <div className='row g-1'>*/}
                {/*            <div className='col-6'>*/}
                {/*                <InputField2 labelName="Lead Value(To)" placeholderName="Value(To)"/>*/}
                {/*            </div>*/}
                {/*            <div className='col-6'>*/}
                {/*                <InputField2 labelName="Lead Value(From)" placeholderName="Value(From)"/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="Follow Up" selectName="CustomSelect"/></div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="Intersted In" selectName="CustomSelect"/></div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="Status" selectName="CustomSelect"/></div>*/}
                {/*    <div className="col-3">*/}
                {/*        <div className='row g-1'>*/}
                {/*            <div className='col-6'>*/}
                {/*                <InputField2 labelName="Lead Value(To)" placeholderName="Value(To)"/>*/}
                {/*            </div>*/}
                {/*            <div className='col-6'>*/}
                {/*                <InputField2 labelName="Lead Value(From)" placeholderName="Value(From)"/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="City" selectName="CustomSelect"/></div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="Known About Technosters Through"*/}
                {/*                                       selectName="CustomSelect"/></div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="Assignee" selectName="CustomSelect"/></div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="Lead Type(Course)" selectName="CustomSelect"/>*/}
                {/*    </div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="Degree/Diploma Certification"*/}
                {/*                                       selectName="CustomSelect"/></div>*/}
                {/*    <div className="col-3"><SelectBox2 labelName="Year Of Passing" selectName="CustomSelect"/></div>*/}
                {/*</div>*/}
            </div>
            <div className="col-12 whatsAppForm2 d-flex justify-content-between align-items-center my-5">
                <div>Total Selected Contacts</div>
                <div><Tooltip title={"Copy Full Data"}><IconButton onClick={()=> {
                    copyToClipboard(fullData);
                    dispatch({type:"SNACKBAR",snackBar:{type:"success",message:"Data Copied Successfully "}});
                }}><ContentCopy/></IconButton></Tooltip><Tooltip title={"Copy Only Numbers"}><IconButton onClick={()=> {
                    copyToClipboard(numbers);
                    dispatch({type:"SNACKBAR",snackBar:{type:"success",message:"Numbers Copied Successfully "}});
                }}><ContentCopy/></IconButton></Tooltip><Typography variant="body1" component={"span"}> {numbersCount}</Typography></div>
            </div>
            <div className="col-12 whatsAppForm3">
                <p className='mb-1'>
                    {suggestion.map((value) => {
                        return (
                            <>
                                <span>Lead Name:{value.LeadName} </span>
                                <span>Fathers Name:{value.FatherName} </span>
                                <span>Email Id:{value.EmailId} </span>
                                <span>Lead value:{value.LeadValue} </span>
                                <span>Assignee Name:{value.AssigneeName} </span>
                                <span>City:{value.City} </span>
                                <span>Course:{value.Course} </span>
                            </>
                        );
                    })}
                </p>
                <div className="d-flex mt-2 mb-2 whatsAppFileInput flex-wrap">
                    <div className="d-flex flex-column">
                        <label htmlFor="file">Upload Image(Max file size 1MB.)</label>
                        <input type="file"/>
                    </div>
                    <div className="d-flex flex-column">
                        <label htmlFor="file">Upload Image(Max file size 1MB.)</label>
                        <input type="file"/>
                    </div>
                    <div className="d-flex flex-column">
                        <label htmlFor="file">Upload Image(Max file size 1MB.)</label>
                        <input type="file"/>
                    </div>
                    <div className="d-flex flex-column">
                        <label htmlFor="file">Upload Image(Max file size 1MB.)</label>
                        <input type="file"/>
                    </div>
                    <div className="d-flex flex-column">
                        <label htmlFor="file">Upload Image(Max file size 1MB.)</label>
                        <input type="file"/>
                    </div>

                </div>
                <div className='d-flex'>
                    <TextareaAutosize
                        className='whatsApptexterea'
                        aria-label={100}
                        minRows={4}
                        maxlength="200"
                        placeholder="Type a message"
                        style={{width: "95%"}}
                    />
                    <div style={{marginLeft: "10px"}}>
                        <div style={{marginRight: "5px", width: "40px", height: "40px", marginBottom:4}}>
                            <Box sx={{transform: "translateZ(0px)", flexGrow: 1}}>
                                <SpeedDial
                                    ariaLabel="SpeedDial controlled open example"
                                    sx={{
                                        position: "absolute",
                                        bottom: -40,
                                        right: -18,
                                        "& .MuiFab-primary": {
                                            width: 40,
                                            height: 40,
                                            background: "transparent",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            "&:hover": {background: "transparent"}
                                        }
                                    }}
                                    icon={<span className="attachments-toggler"><Attachment/></span>}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    open={open}
                                >
                                    {actions.map((action) => (
                                        <SpeedDialAction
                                            key={action?.name}
                                            icon={action?.icon}
                                            tooltipTitle={action.name}
                                            sx={{
                                                background: "transparent",
                                                width: 50,
                                                height: 50,
                                                marginTop: "-10px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                boxShadow: "none",
                                                "&:hover": {background: "transparent"}
                                            }}
                                            onClick={() => handleClose(action?.name)}
                                        />
                                    ))}
                                </SpeedDial>
                            </Box>
                        </div>
                        <div className="msg-send-btn">
                            <SendRightArrow/>
                        </div>
                    </div>
                </div>
            </div>
            <WhatsAppQR
                open={openQRDialog}
                onClose={()=>setOpenQRDialog(false)}
            />
        </div>
    );
}

