export const callDataInitialState = {
    name: "",
    callStatus: "",
    socketId: "",
    incoming: false,
    fromPhone: false,
    mobile: "",
    onHold: false,
    onSpeaker: false,
    onMute: false,
    callLogs: [],
    callDuration: 0

};
const callDataReducer = (state = callDataInitialState, action) => {
    switch (action.type) {
        case "CALLDATA":
            state = {
                ...callDataInitialState,
                callLogs: state.callLogs,
                callDuration: state.callDuration,
                ...(action.payload)
            };
            return state;
        case "CALLLOG":
            state = {...state, callLogs: action.payload.callLog, callDuration: action.payload.callDuration};
            return state;

        case "CALLHOLD":
            state = {...state, onHold: !state.onHold};
            return state;

        case "CALLMUTE":
            state = {...state, onMute: !state.onMute};
            return state;

        case "CALLSPEAKER":
            state = {...state, onSpeaker: !state.onSpeaker};
            return state;

        default:
            return state;
    }
};


export default callDataReducer;