import React, { useState } from "react";
import { LeadModalSidebar } from "../../../../components/leadSidebar";
// import {shareableFiles} from "../../../../data/shareableFiles";
// import pdficon from "../../../../images/pdficon.svg";
import { WhatsappContentCard } from "../../../../components/cards";
import { AddCard } from "../../../../components/cards/whatsappContents/AddCard";
import { useSelector } from "react-redux";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../../../../constants";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export function WhatsAppDocuments(props) {
  const { setWhatsappContent, courses, setMessage} = props;
  // const [messageType, setMessageType] = useState("dm");
  const userRole = useSelector((state) => state.userReducer).role.name;
  // const files = shareableFiles.filter(file => file.type === messageType);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handleRadioChange = (index) => {
    setSelectedDocument((prevSelected) =>
      prevSelected === index ? null : index
    );
  };

  const containerStyle = {
    minWidth: 120,
    textAlign: "center",
    cursor: "pointer",
    position: "relative",
    border: "1px solid gray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <div className="row">
      <div className="col-3">
        <div className="lead-modal-sidebar">
          {/* <LeadModalSidebar setMessageType={setMessageType} data={shareableFiles}/> */}
          <LeadModalSidebar
            setState={setDocuments}
            data={courses}
            url="whatsapp-documents"
          />
        </div>
      </div>

      <div className="col-9">
        <RadioGroup sx={{ display: "flex", width: "100%" }}>
          <div className="lead-modal-message-container">
            <div
              className="inner"
              style={{
                display: "flex",
                width: "100",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              {/* {
                            files.map((file) => {
                                return (
                                    file.files.map((file, index) => {
                                        return (
                                            <WhatsappContentCard type="Document" data={file} key={index} setWhatsappContent = {setWhatsappContent}/>
                                        );
                                    })
                                );
                            })
                        } */}
              {documents?.map((document, index) => {
                return (
                  <div key={index}>
                    <FormControlLabel
                      sx={{ width: "100%", position: "relative" }}
                      className="content-card-with-redio"
                      key={index}
                      value={document?.title}
                      control={
                        <Radio sx={{ position: "absolute", top: 0, left: 0 }}  checked={selectedDocument === index}
                        onClick={() => handleRadioChange(index)}/>
                      }
                      label={
                        <WhatsappContentCard
                          type="Document"
                          data={document}
                          key={index}
                          setMessage={setMessage}
                          url="whatsapp-document"
                          setWhatsappContent={setWhatsappContent}
                          setState={setDocuments}
                        />
                      }
                    />
                    {/* <WhatsappContentCard type="Document" data={document} key={index} url = "whatsapp-document" setWhatsappContent = {setWhatsappContent} setState={setDocuments}/> */}
                  </div>
                );
              })}
              {(userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE) && (
                  <AddCard
                    type="Document"
                    url="whatsapp-document"
                    containerStyle={containerStyle}
                    courses={courses}
                    state={documents}
                    setState={setDocuments}
                  />
                )}
            </div>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}
