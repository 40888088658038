function maskMobile(data) {
  let number = data;
  return number?.replace(number.substr(3, 4), "****");
}

function maskEmail(data) {
  let email = data;
  return email?.replace(
    email.slice(email.indexOf("@") - 4, email.indexOf("@")),
    "****"
  );
}

export { maskMobile, maskEmail };
