/* eslint-disable */
import React from "react";
import moment from "moment";
import {Avatar, Tooltip} from "@mui/material";
import {stringAvatar} from "../helpers/functions";
import {EMPLOYEE_IMAGE_BASE_URL} from "../constants";
import Grid from "@mui/material/Grid";

export function ChatBox({messages}) {
    return (
        <>
            {messages?.map((lead, index) => {
                return (
                    <Grid container>
                        <Grid item xs={12} justifyContent="flex-end" display="flex">
                            <span
                                className="chat-date mt-2">{moment(lead?.createdAt).format("dddd, DD MMMM YYYY")}</span>
                        </Grid>
                        <Grid container >
                            <Grid item xs={2} mt={1}>
                                <Tooltip title={lead?.user?.name || "unknown"} placement="top-end">

                                    {lead?.user?.photo ? <Avatar
                                        {...stringAvatar(lead?.user?.name)}
                                        src={EMPLOYEE_IMAGE_BASE_URL + `${lead?.user?.photo}`}
                                        alt={lead?.user?.name}
                                        sx={{ width: 48, height: 48 }}
                                    /> : <Avatar {...stringAvatar(lead?.user?.name || "unknown")}/>}
                                </Tooltip>
                            </Grid>
                            <Grid item xs={10} className="chat-bubble">

                                <Grid className="message">{lead?.note}</Grid>
                                <Grid className="time">
                                    {moment(lead?.createdAt).format("LT")}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
}
