const LOADER = "LOADER";
const SNACKBAR = "SNACKBAR";

//Local
// const IMAGE_BASE_URL ="http://localhost:8421/";
// const USER_PROFILE_BASE_URL ="http://localhost:8421/user_profile/";
// const EMPLOYEE_IMAGE_BASE_URL ="http://localhost:8421/user_profile/";
// const USER_DOCUMENT_BASE_URL ="http://localhost:8421/user_documents/";
// const COURSE_PDF_BASE_URL ="http://localhost:8421/course_pdf/";
// const baseUrl="http://localhost:8421/api/";
// const BANK_LOGO_BASE_URL ="http://localhost:8421/bank_logo/";
// const REACT_APP_BASE_URL = "http://localhost:8000/";

//Development
// const IMAGE_BASE_URL = "https://dev-crm-node.technosters.co.in/";
// const USER_PROFILE_BASE_URL = "https://dev-crm-node.technosters.co.in/user_profile/";
// const EMPLOYEE_IMAGE_BASE_URL = "https://dev-crm-node.technosters.co.in/user_profile/";
// const USER_DOCUMENT_BASE_URL = "https://dev-crm-node.technosters.co.in/user_documents/";
// const COURSE_PDF_BASE_URL = "https://dev-crm-node.technosters.co.in/course_pdf/";
// const baseUrl = "https://dev-crm-node.technosters.co.in/api/";
// const BANK_LOGO_BASE_URL = "https://dev-crm-node.technosters.co.in/bank_logo/";
// const REACT_APP_BASE_URL = "https://crm-dashboard-react.technosters.co.in/";
// const WEB_API = "https://dev-whatsapp-node.technosters.co.in";

// test crm
// const IMAGE_BASE_URL = "https://crm-test-backend.technosters.co.in/";
// const USER_PROFILE_BASE_URL =
//   "https://crm-test-backend.technosters.co.in/user_profile/";
// const EMPLOYEE_IMAGE_BASE_URL =
//   "https://crm-test-backend.technosters.co.in/user_profile/";
// const USER_DOCUMENT_BASE_URL =
//   "https://crm-test-backend.technosters.co.in/user_documents/";
// const COURSE_PDF_BASE_URL =
//   "https://crm-test-backend.technosters.co.in/course_pdf/";
// const baseUrl = "https://crm-test-backend.technosters.co.in/api/";
// const BANK_LOGO_BASE_URL = "https://crm-test-backend.technosters.co.in/bank_logo/";
// const REACT_APP_BASE_URL = "https://crm-test.technosters.co.in/";
// const WEB_API = "https://test-whatsapp-node.technosters.co.in";

// Development Demo-Crm
// const IMAGE_BASE_URL = "https://demo-crm-node.technosters.co.in/";
// const USER_PROFILE_BASE_URL =
//   "https://demo-crm-node.technosters.co.in/user_profile/";
// const EMPLOYEE_IMAGE_BASE_URL =
//   "https://demo-crm-node.technosters.co.in/user_profile/";
// const USER_DOCUMENT_BASE_URL =
//   "https://demo-crm-node.technosters.co.in/user_documents/";
// const COURSE_PDF_BASE_URL =
//   "https://demo-crm-node.technosters.co.in/course_pdf/";
// const baseUrl = "https://demo-crm-node.technosters.co.in/api/";
// const BANK_LOGO_BASE_URL = "https://demo-crm-node.technosters.co.in/bank_logo/";
// const REACT_APP_BASE_URL = "https://demo-crm.technosters.co.in/";

// Development "Kangen"
// const IMAGE_BASE_URL = "https://kangen-node.technosters.co.in/";
// const USER_PROFILE_BASE_URL =
//   "https://kangen-node.technosters.co.in/user_profile/";
// const EMPLOYEE_IMAGE_BASE_URL =
//   "https://kangen-node.technosters.co.in/user_profile/";
// const USER_DOCUMENT_BASE_URL =
//   "https://kangen-node.technosters.co.in/user_documents/";
// const COURSE_PDF_BASE_URL = "https://kangen-node.technosters.co.in/course_pdf/";
// const baseUrl = "https://kangen-node.technosters.co.in/api/";
// const BANK_LOGO_BASE_URL =
//   "https://kangen-node.technosters.co.in/api/bank_logo/";
// const REACT_APP_BASE_URL = "https://kangen.technosters.co.in/";
// const WEB_API = "https://whatsapp-node.technosters.co.in";

// const IMAGE_BASE_URL ="https://https://b6d2-2401-4900-1c62-4893-8929-8a56-478c-20fd.ngrok-free.app/";
// const USER_PROFILE_BASE_URL ="https://https://b6d2-2401-4900-1c62-4893-8929-8a56-478c-20fd.ngrok-free.app/user_profile/";
// const EMPLOYEE_IMAGE_BASE_URL ="https://https://b6d2-2401-4900-1c62-4893-8929-8a56-478c-20fd.ngrok-free.app/user_profile/";
// const USER_DOCUMENT_BASE_URL ="https://https://b6d2-2401-4900-1c62-4893-8929-8a56-478c-20fd.ngrok-free.app/user_documents/";
// const COURSE_PDF_BASE_URL ="https://https://b6d2-2401-4900-1c62-4893-8929-8a56-478c-20fd.ngrok-free.app/course_pdf/";
// const baseUrl="https://https://b6d2-2401-4900-1c62-4893-8929-8a56-478c-20fd.ngrok-free.app/";

// //Production
const IMAGE_BASE_URL = "https://lead-crm.technosters.co.in/";
const USER_PROFILE_BASE_URL =
  "https://lead-crm.technosters.co.in/user_profile/";
const EMPLOYEE_IMAGE_BASE_URL =
  "https://lead-crm.technosters.co.in/user_profile/";
const USER_DOCUMENT_BASE_URL =
  "https://lead-crm.technosters.co.in/user_documents/";
const COURSE_PDF_BASE_URL = "https://lead-crm.technosters.co.in/course_pdf/";
const baseUrl = "https://lead-crm.technosters.co.in/api/";
const BANK_LOGO_BASE_URL = "https://lead-crm.technosters.co.in/bank_logo/";
const REACT_APP_BASE_URL = "https://lead-crm.technosters.co.in/";
const WEB_API = "https://whatsapp-node.technosters.co.in";

// CRM LANDSCREDIT
// const IMAGE_BASE_URL = "https://crm-node.landscredit.com/";
// const USER_PROFILE_BASE_URL = "https://crm-node.landscredit.com/user_profile/";
// const EMPLOYEE_IMAGE_BASE_URL =
//   "https://crm-node.landscredit.com/user_profile/";
// const USER_DOCUMENT_BASE_URL =
//   "https://crm-node.landscredit.com/user_documents/";
// const COURSE_PDF_BASE_URL = "https://crm-node.landscredit.com/course_pdf/";
// const baseUrl = "https://crm-node.landscredit.com/api/";
// const BANK_LOGO_BASE_URL = "https://crm-node.landscredit.com/bank_logo/";
// const REACT_APP_BASE_URL = "https://crm-node.landscredit.com/";

//Roles
const ADMIN_ROLE = "admin";
const MANAGER_ROLE = "manager";
const TEAM_LEADER_ROLE = "teamLeader";
const TELE_CALLER_ROLE = "teleCaller";
const SUPER_ADMIN_ROLE = "superAdmin";
const FIELD_MARKETING_ROLE = "fieldMarketing";

export {
  ADMIN_ROLE,
  MANAGER_ROLE,
  TEAM_LEADER_ROLE,
  TELE_CALLER_ROLE,
  IMAGE_BASE_URL,
  EMPLOYEE_IMAGE_BASE_URL,
  BANK_LOGO_BASE_URL,
  COURSE_PDF_BASE_URL,
  REACT_APP_BASE_URL,
  baseUrl,
  USER_DOCUMENT_BASE_URL,
  USER_PROFILE_BASE_URL,
  LOADER,
  SNACKBAR,
  SUPER_ADMIN_ROLE,
  FIELD_MARKETING_ROLE,
  WEB_API,
};
