import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useDataParams} from "../hooks";
import {$crud} from "../services/CrudFactory";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Slide,
    TextField
} from "@mui/material";

export function TransferLeadsModal(props){
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const users = useSelector(state=>state.usersReducer);
    const {open, onClose} = props;

    const cancel = () => {
        onClose();
        setParams({});
    };


    const handleSubmit = async () =>{
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("update/web/transfer-leads",{
                    ...params
                }
            );
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            cancel();
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    return (
        <Dialog maxWidth={"xs"} TransitionComponent={Slide} fullWidth open={open}>
            <div className="tableHeader-color">
                <DialogTitle>Lead&apos;s Transfer Modal</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Transfer Leads From</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={`${params?.transferFrom}` || ""}
                                onChange={(e) => handleChange("transferFrom", e.target.value)}
                                sx={{
                                    svg: {color: "#fff"}
                                }}
                            >
                                {users.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Transfer Leads To</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={`${params?.transferTo}` || ""}
                                onChange={(e) => handleChange("transferTo", e.target.value)}
                                sx={{
                                    svg: {color: "#fff"}
                                }}
                            >
                                {users.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                    <Button className="ms-2" onClick={handleSubmit} variant="contained" color="primary" size="small">Submit</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}