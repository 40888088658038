import React, { useEffect, useState } from "react";
// import {contacts} from "../../../../data/contactdata";
import { AddCard } from "../../../../components/cards/whatsappContents/AddCard";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../../../../constants";
import { useSelector } from "react-redux";
import { WhatsappContentCard } from "../../../../components/cards/whatsappContents";
import { $crud } from "../../../../services/CrudFactory";
import {  FormControlLabel, Radio, RadioGroup } from "@mui/material";

export function WhatsAppContacts(props) {
  const { setWhatsappContent , setMessage} = props;
  const [contacts, setContacts] = useState([]);
  const userRole = useSelector((state) => state.userReducer).role.name;
  const [selectedContact, setSelectedContact] = useState(null);

  const handleRadioChange = (index) => {
    setSelectedContact((prevSelected) =>
      prevSelected === index ? null : index
    );
  };

  const retrieveData = async () => {
    try {
      const { data } = await $crud.retrieve("web/contact-details");
      setContacts(data?.contact_details);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <RadioGroup sx={{ display: "flex", width: "100%" }}>
      <div className="row g-2" style={{ display: "flex", width: "100%",  }}>
        {contacts?.map((contact, index) => {
          return (
            // <FormControlLabel value="female" control={<Radio />} label="Female" />
            // <div key={index} style={{ width: "25%"}} >
            <div key={index} className="col-xl-3 col-lg-4 col-md-6" >
              <FormControlLabel
                sx={{ width: "100%", position:"relative" }}
                className="content-card-with-redio"
                key={index}
                value={contact?.name}
                control={<Radio sx={{position:"absolute", top:0, left:0}} checked={selectedContact === index}
                onClick={() => handleRadioChange(index)}/>}
                label={
                  <WhatsappContentCard
                    type="Contact"
                    data={contact}
                    setMessage={setMessage}
                    key={index}
                    url="contact-detail"
                    setWhatsappContent={setWhatsappContent}
                    setState={setContacts}
                  />
                }
              />
            </div>
          );
        })}

        {(userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE) && (
            <div className="col-xl-3 col-lg-4 col-md-6" style={{marginLeft:-10}}>
              <AddCard
                type="Contact"
                url="contact-detail"
                className="contact-card"
                containerStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
                state={contacts}
                setState={setContacts}
              />
            </div>
          )}
      </div>
    </RadioGroup>
  );
}
