/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    FormGroup,
    FormControlLabel, Checkbox
} from "@mui/material";
import {InputField} from "../components/inputs";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";
import {reRender, users} from "../redux/actions";
import {retrieveUsers} from "../pages";


export const AssignPermission = (props) => {
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const [permissions, setPermissions] = useState([]);
    const [assignPermissions, setAssignPermissions] = useState([]);
    const cancel = () => {
        props.onClose();
        setParams({});
    }

    const retrievePermissions = async () => {
        const {data: {permissions}} = await $crud.retrieve("web/permissions");
        setPermissions(permissions);
    }
    const updatePermissions = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            dispatch(reRender(false));

            const formData = new FormData();
            formData.append("userId", props.employee?.id);
            formData.append("permissionIds", JSON.stringify(assignPermissions));
            const {type, message} = await $crud.post("create/web/assign-permissions", formData);
            dispatch(reRender(true));
            retrieveUsers().then(res=>{
                dispatch(users(res?.users))
            })
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    return (
        <Dialog maxWidth={"md"} TransitionProps={
            {
                onEnter: () => {
                    if (props.employee?.id) {
                        setAssignPermissions(props.employee?.userPermissions?.map(p=>p.permissionId));
                    }
                    retrievePermissions()
                }
            }
        } TransitionComponent={Slide} fullWidth open={props.open}>
            <div className="tableHeader-color">
                <DialogTitle>Assign Permissions</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={1}>
                        {
                            permissions?.map(item => {
                                return <>
                                    <Grid item xs={12}>
                                        <h2 style={{fontSize: "14px", color: "#D0D2D6"}}>{item?.heading}</h2>
                                    </Grid>
                                    {
                                        item?.permission?.map(item => <Grid item md={3} sm={3} lg={3}>
                                            <div style={{borderRight: "1px solid #000"}}>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox onChange={() => {
                                                        setAssignPermissions(prev => {
                                                            const temp = [...prev];
                                                            if (temp.includes(item?.id)) {
                                                                return temp.filter(s => s !== item.id);
                                                            } else {
                                                                return [...prev, item?.id];
                                                            }
                                                        })
                                                    }} checked={assignPermissions?.includes(item?.id)}/>}
                                                                      style={{color: "#D0D2D6", fontSize: "12px"}}
                                                                      label={item?.name.toLowerCase()}/>
                                                </FormGroup>
                                            </div>
                                        </Grid>)
                                    }
                                </>
                            })
                        }


                        <Grid item xs className="d-flex justify-content-end align-items-center mt-5">
                            <Button variant="outlined" onClick={props.onClose} color="primary"
                                    size="small">Cancel</Button>
                            <Button className="ms-2" onClick={() => {
                                updatePermissions();
                            }} variant="contained" color="primary" size="small">Submit</Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </div>
        </Dialog>
    );
};
