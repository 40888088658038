import React from "react";

export function TodayFollowUpIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 14V11H18V14H15V16H18V19H20V16H23V14H20Z" />
            <path d="M13 16H11L6 21V16H4C2.9 16 2 15.1 2 14V5C2 3.9 2.9 3 4 3H18C19.1 3 20 3.9 20 5V9H16V12H13V16Z" />
        </svg>
    );
}


