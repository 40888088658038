    /* eslint-disable */

import React, {useEffect, useState} from "react";
import whatsApp from "../images/whatsApp.svg";
import {$crud} from "../services/CrudFactory";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Outlet} from "react-router-dom";
import {EMPLOYEE_IMAGE_BASE_URL, USER_PROFILE_BASE_URL} from "../constants";
import {capitalize, formatTime, stringAvatar} from "../helpers/functions";
import {Grid, Button, Typography, Menu, Avatar, Divider, Card} from "@mui/material";
import {
    AlarmOn,
    PlayArrow,
    Pause,
    Stop,
    Security,
    Settings,
    HeadsetMic,
    HelpOutline,
    LightMode,
    ModeNight
} from '@mui/icons-material';
import {Clock} from "./Clock";
import {pauseTimer, startTimer, stopTimer, resumeTimer} from "../redux/actions";
import moment from "moment";
import { usePagination } from "../hooks";
import { FooterDynamicTableModal } from "../dialogs/FooterDynamicTableModal";
export const themes = [{name: "light-theme", icon: <ModeNight/>}, {
    name: "dark-theme",
    icon: <LightMode/>
}];

function Footer(props) {
    const {count, pageName, callData} = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [theme, setTheme] = useState({name: localStorage.getItem("theme"), icon: <LightMode/>});
    const [params, setParams] = useState([]);
    const [isLogout, setIsLogout] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const render = useSelector((state) => state.renderLead.render1);
    const currentUser = useSelector((state) => state.userReducer);
    const isStarted = useSelector(state => state.timerReducer.isStarted);
    const isStopped = useSelector(state => state.timerReducer.isStopped);
    const isRunning = useSelector(state => state.timerReducer.isRunning);
    const isPaused = useSelector(state => state.timerReducer.isPaused);
    const workingDuration = useSelector(state => state.timerReducer.workingDuration);
    const breakDuration = useSelector(state => state.timerReducer.breakDuration);
    const currentBreakDuration = useSelector(state => state.timerReducer.currentBreakDuration);
    const currentWorkingDuration = useSelector(state => state.timerReducer.currentWorkingDuration);
    const callLog = useSelector(state=>state.callDataReducer).callLog;
    const callDuration = useSelector(state=>state.callDataReducer).callDuration;
    const [footerData, setFooterData] = useState([]);
    const [showFooterDynamicTableModal, setShowFooterDynamicTableModal] = useState(false);
    const { limit, page, setTotal, total, setLimit, setPage } = usePagination();
    const [title, setTitle] = useState("");
    const logout = async () => {
        await $crud.confirm();
        await $crud.post("create/web/logout");
        await localStorage.clear();
        navigate("/");
        window.location.reload(true);
    };

    // console.log(callData, "-----------callData");

    const getFooterData = async (value) => {
        try {
          dispatch({
            type: "LOADER",
            loader: true,
          });
          const {
            data:{data, count}, type
          } = await $crud.retrieve("web/footer-data", {
            [value]: true,
            page,
            limit,
          });
          if(type === "success"){
            setShowFooterDynamicTableModal(true);
          }
           setFooterData(data);
           setTotal(count);
        } catch (e) {
          dispatch({
            type: "SNACKBAR",
            snackBar: { type: "error", message: e.message },
          });
        } finally {
          dispatch({
            type: "LOADER",
            loader: false,
          });
        }
      };

    //   useEffect(()=>{
    //     getFooterData().then((res)=>{
    //           setFooterData(res?.rows);
    //           setTotal(res?.count);
    //       }).catch((error)=>console.log(error));
    //   }, [])

    // const retrieveLeadsCount = async () => {
    //     try {
    //         dispatch({
    //             type: "LOADER",
    //             loader: true,
    //         });
    //         const {
    //             data
    //         } = await $crud.retrieve("web/leads-count",{
    //             assignTo:currentUser?.id
    //         });
    //         setCount(data)
    //     } catch (e) {
    //         dispatch({
    //             type: "SNACKBAR",
    //             snackBar: {type: "error", message: e.message},
    //         });
    //     } finally {
    //         dispatch({
    //             type: "LOADER",
    //             loader: false,
    //         });
    //     }
    // }

    const retrieveCalls = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {data} = await $crud.retrieve("web/calls");
            setParams(data);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    function getTime(time) {
        const total = Number.parseInt(time / 1000, 10);
        const minutes = Number.parseInt((total / 60) % 60, 10);
        const seconds = Number.parseInt(total % 60, 10);
        const hours = Number.parseInt((minutes / 60) % 60, 10);
        return {
            total,
            minutes,
            seconds,
            hours
        };
    }


    function getFormattedTime(milliseconds) {
        const time = getTime(milliseconds);
        const hoursString = time.hours.toString().padStart(2, "00");
        const minsString = time.minutes.toString().padStart(2, "00");
        const secondsString = time.seconds.toString().padStart(2, "00");
        return `${hoursString}:${minsString}:${secondsString}`;
    }

    function changeThemeClass() {
        setTheme(old => themes.filter(theme => theme.name !== old.name)[0]);
    }

    useEffect(() => {
        retrieveCalls();
        // retrieveLeadsCount();
    }, [render]);

    useEffect(() => {
        document.body.className = "";
        document.body.classList.add(theme?.name);
        localStorage.setItem("theme", theme?.name);
    }, [theme]);

    return (
        <Grid container alignItems="center" className="navfooter  shadow-sm" px={2}>
            <Grid item xs={2} display="flex" alignItems="center" >
                <div className="footerManu">
                    <Avatar
                        src={USER_PROFILE_BASE_URL + currentUser?.photo}
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget)
                            setShowMenu(true)
                        }} aria-controls={showMenu ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={showMenu ? 'true' : undefined}
                    />
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={showMenu} 
                    id="account-menu"
                    onClose={() => setShowMenu(false)}
                    PaperProps={{
                        className: "tableHeader-color box-shadow",
                        elevation: 0,
                        sx: {
                            maxWidth: "360px",
                            padding: "10px 20px"
                        }
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>Daily Summary</Typography>
                            <Typography variant="subtitle2">Working Day Duration: {getFormattedTime(workingDuration?.milliseconds + Number(workingDuration?.database) )}</Typography>
                            <Typography variant="subtitle2">Break: {getFormattedTime(breakDuration?.milliseconds + Number(breakDuration?.database) )}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} >
                                {
                                    isStopped && !isStarted && <Grid item xs>
                                        <Button fullWidth variant="contained" startIcon={<PlayArrow/>} onClick={() => {
                                            dispatch(startTimer(moment().format("HH:MM:SS")))
                                        }}>Clock In</Button>
                                    </Grid>
                                }
                                {isStarted && <>
                                    {isRunning &&<Grid item xs>
                                        <Button fullWidth variant="contained"  startIcon={
                                            <Pause/>} color="warning" onClick={() => {
                                            dispatch(pauseTimer());
                                            setIsRefresh(true);
                                        }}>Break</Button>
                                    </Grid>}
                                    {isPaused && <Grid item xs>
                                        <Button fullWidth variant="contained" startIcon={
                                            <PlayArrow/>} color="success" onClick={() => {
                                            dispatch(resumeTimer())
                                        }}>Continue</Button>
                                    </Grid> }
                                    <Grid item xs>
                                        <Button fullWidth variant="contained" startIcon={
                                            <Stop/>} color="error" onClick={() => {
                                            dispatch(stopTimer({
                                                break:(parseInt(breakDuration.milliseconds,10)+parseInt(breakDuration.database,10)),work:(parseInt(workingDuration.milliseconds,10)+parseInt(workingDuration.database,10))}));
                                            setIsLogout(true);
                                        }}>Clock
                                            Out</Button>
                                    </Grid>
                                </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>

                            <Divider
                                sx={{
                                    width: "100%",
                                    marginTop: "5px"
                                }}
                            />
                        </Grid>

                        <Grid item xs={3} justifyContent="center" display="flex" alignItems="center">
                            {currentUser?.name ? <Avatar
                                style={{
                                    width: "46px",
                                    height: "46px"
                                }}
                                {...stringAvatar(currentUser?.name)}
                                src={EMPLOYEE_IMAGE_BASE_URL + `${currentUser?.photo}`}
                                alt={currentUser?.name}
                            />:<Avatar/>}
                        </Grid>

                        <Grid item xs={9}>
                            <Typography variant="subtitle2">{currentUser?.name}</Typography>
                            <Typography variant="caption"
                                        color="primary">{currentUser?.role?.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>

                            <Divider
                                sx={{
                                    width: "100%",
                                    marginTop: "5px"
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item>
                                    <Button variant="outlined" sx={{px: 0}} size="small"
                                            onClick={changeThemeClass}>
                                        {theme?.icon}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" sx={{px: 0}} size="small" onClick={() => {
                                        navigate("/passwordChange")
                                    }}>
                                        <Security/>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" sx={{px: 0}} size="small">
                                        <Settings/>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" sx={{px: 0}} size="small">
                                        <HeadsetMic/>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" sx={{px: 0}} size="small">
                                        <HelpOutline/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} justifyContent="flex-end" display="flex">
                            <Button variant="contained" onClick={() => logout()}> Sign Out </Button>
                        </Grid>
                    </Grid>
                </Menu>
                <Divider
                    orientation="vertical"
                    className="divider3"
                    variant="middle"
                    flexItem
                />
                {/* <h6>{capitalize(currentUser?.name) + ">"}</h6><h6>{pageName}</h6> */}
                <Grid item sx={{display:"flex", flexDirection:"column"}}>
                    <h6>{capitalize(currentUser?.name) + ">"}</h6>
                    {isStarted && isRunning && !isPaused && !isStopped &&
                    <Typography sx={{
                        color: theme => theme.palette.success.main,
                        fontWeight: 600,
                        fontSize: 16
                    }}>Working</Typography>}
                    {isStarted && isPaused && !isRunning && !isStopped &&
                    <Typography sx={{color: theme => theme.palette.warning.main, fontWeight: 600, fontSize: 16}}>On
                        Break</Typography>}
                    {!isStarted && isStopped &&
                    <Typography
                        sx={{color: theme => theme.palette.error.main, fontWeight: 600, fontSize: 16}}>Idle</Typography>}
                </Grid>
            </Grid>

            <Grid item xs={6.2}>
                <Grid container>
                    <Grid item xs={2} textAlign="center">
                        <Typography variant="subtitle1">{count?.todayFollowupCount || 0}</Typography>
                        <Typography variant="body1" sx = {smallScreen} onClick={() =>{
                            setTitle("Total Follow-Ups");
                            getFooterData("totalFollowUp");
                            }}>Total FollowUp</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        {/* <Typography variant="subtitle1">{(!!callData.callLogs && Number(callData?.callLogs?.find(cl=>cl.type === "Outgoing Connected")?.count) + Number(callData?.callLogs?.find(cl=>cl.type === "Incoming")?.count)) || "0"}</Typography> */}
                        <Typography variant="subtitle1">{(!!callData.callLogs && Number(callData?.callLogs?.find(cl=>cl.type === "Outgoing Connected")?.count || "0") + Number(callData?.callLogs?.find(cl=>cl.type === "Incoming")?.count || "0")) || "0"}</Typography>
                        <Typography variant="body1" sx = {smallScreen} onClick={() =>{
                            setTitle("Total Calls");
                            getFooterData("totalCalls");
                            }}>Total Calls</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <Typography variant="subtitle1">{(!!callData.callLogs && callData?.callLogs?.find(cl=>cl.type === "Incoming")?.count) || "0"}</Typography>
                        <Typography variant="body1" sx = {smallScreen} onClick={() => {
                            setTitle("Incoming Calls");
                            getFooterData("incomingCalls");
                            }}>Incoming Calls</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <Typography variant="subtitle1">{(!!callData.callLogs && callData?.callLogs?.find(cl=>cl.type === "Missed Call")?.count) || "0"}</Typography>
                        <Typography variant="body1" sx = {smallScreen} onClick={() =>{ 
                            setTitle("Missed Calls");
                            getFooterData("missedCalls");
                    }}>Missed Calls</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <Typography variant="subtitle1">{(!!callData.callLogs && callData?.callLogs?.find(cl=>cl.type === "Outgoing Connected")?.count) || "0"}</Typography>
                        <Typography variant="body1" sx = {smallScreen} onClick={() =>{ 
                            setTitle("Outgoing Calls");
                            getFooterData("outgoingCalls");
                        }}>Outgoing Calls</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <Typography variant="subtitle1">{formatTime(Number(callData.callDuration) * 1000) || "0"}</Typography>
                        <Typography variant="body1" sx = {smallScreen}>Total TalkTime</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs = {0.2}></Grid>
            <Grid item xs={1.8} display="flex" justifyContent="flex-end" >
                <Clock isLogout={isLogout} isRefresh={isRefresh}/>
            </Grid>
            <Grid item xs = {0.3}></Grid>
            <Grid item xs={1.5}  display="flex" justifyContent="flex-end" alignItems="center">
                <Typography className="footerLogo" sx={smallScreen}>CBSL Technologies</Typography>
            </Grid>
            
            <FooterDynamicTableModal 
                open = {showFooterDynamicTableModal}
                onClose = {()=>setShowFooterDynamicTableModal(false)}
                footerData = {footerData}
                setLimit = {setLimit}
                setPage = {setPage}
                total = {total}
                limit = {limit}
                page = {page}
                title = {title}
             />

            
            <Outlet/>
        </Grid>
    );
}

export default React.memo(Footer);

const smallScreen = {
    cursor:"pointer",

    "@media screen and (max-width:940px)":{
    fontSize:'12px'
}}