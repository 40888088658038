import React, {useEffect, useState} from "react";
import {LeadModal} from "../dialogs/leadModal";
import LeadForm from "../dialogs/LeadForm";
import {
    Grid
} from "@mui/material";
import {$crud} from "../services/CrudFactory";
import {useSelector, useDispatch} from "react-redux";
import {usePagination} from "../hooks/usePagination";
import {useDataParams} from "../hooks";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";
import {FilterChipContainer} from "../components";

export function Lead() {
    const [showLeadModal, setShowLeadModal] = useState(false);
    const [showLeadForm, setShowLeadForm] = useState(false);
    const [filtersWithValues, setFiltersWithValues] = useState({});
    const [leadId, setLeadId] = useState(null);
    const [leads, setLeads] = useState([]);

    const {
        limit,
        page,
        setTotal,
        total,
        setLimit,
        setPage
    } = usePagination();
    const dispatch = useDispatch();
    const {setParams} = useDataParams();

    const renderState = useSelector((state) => state.renderLead.render1);
    const currentUser = useSelector((state) => state.userReducer);
    const filters = useSelector(state => state.filterReducer);

    const retrieveVisitedStudentLeads = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data: {leads, total},
            } = await $crud.retrieve("web/leads", {
                ...filtersWithValues,
                assignTo: filtersWithValues?.assignTo || currentUser?.id,
                leadConvertTypeId:1,
                page,
                limit,
            });
            return {leads, total};
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };

    useEffect(() => {
        retrieveVisitedStudentLeads().then(res => {
            setLeads(res?.leads);
            setTotal(res?.total);
        }).catch(error => console.error(error));
    }, [limit, page, filtersWithValues, renderState]);

    useEffect(() => {
        setFiltersWithValues(filters?.withValues);
    }, [filters]);

    return (
        <>
            <Grid className="container-fluid top-navbar ">
                <FilterChipContainer/>
                <DynamicTable
                    rows={leads}
                    columns={columns?.studentLead}
                    style={{maxHeight: "calc(100vh - 250px)", position: "fixed", top: "150px", width: "calc(100% - 24px)"}}
                    total={total}
                    onEdit={(id) => {
                        setShowLeadForm(true);
                        setLeadId(id);
                    }}
                    onRowClick={(id) => {
                        setShowLeadModal(true);
                        setLeadId(id);
                    }}
                    paginationProps={{
                        limit: limit,
                        page: page,
                        onChangeLimit: (limit) => setLimit(limit),
                        onChangePage: (page) => setPage(page),
                    }}
                />
            </Grid>

            {showLeadModal && <LeadModal
                anchor="right"
                open={showLeadModal}
                onClose={() => {
                    setShowLeadModal(false);
                    setLeadId(null);
                    setParams({});
                }}
                leadId={leadId}

            />}


            {showLeadForm && <LeadForm
                leadId={leadId}
                anchor="right"
                open={showLeadForm}
                onClose={() => {
                    setShowLeadForm(false);
                    setLeadId(null);
                    setParams({});
                }}
                onOpen={(leadId) => {
                    setShowLeadModal(true);
                    setLeadId(leadId);
                }}
                onNew={() => {
                    setParams({});

                }}
            />}


        </>
    );
}


