/* eslint-disable */
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDLf2wjK79LbAKS4rBsu-gSIctjh7xBsLQ",
  authDomain: "cbsl-crm.firebaseapp.com",
  projectId: "cbsl-crm",
  storageBucket: "cbsl-crm.appspot.com",
  messagingSenderId: "1061085415933",
  appId: "1:1061085415933:web:3c223169b6567d8ccbf194",
  measurementId: "G-J737JBECZM"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let auth = firebase.auth();
export { auth, firebase };
// export default firebase;
