import React, {useEffect, useState, useCallback } from "react";
import {LeadModal} from "../dialogs/leadModal";
import LeadForm from "../dialogs/LeadForm";
import {Grid} from "@mui/material";
import {$crud} from "../services/CrudFactory";
import {useSelector, useDispatch} from "react-redux";
import {useDataParams, usePagination} from "../hooks";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";
import {AnnouncementPopUp, FilterChipContainer} from "../components";
import _ from "lodash";
import {useParams} from "react-router-dom";
import {resetEditLead} from "../redux/actions";

// import axios from "axios";


export function Leads() {
    const [showLeadModal, setShowLeadModal] = useState(false);
    const [showLeadForm, setShowLeadForm] = useState(false);
    // const [filtersWithLabel, setFiltersWithLabel] = useState({});
    // const [filtersWithValues, setFiltersWithValues] = useState({});
    const [leadId, setLeadId] = useState(null);
    const [leads, setLeads] = useState([]);

    const {limit, page, setTotal, total, setLimit, setPage} = usePagination();

    const dispatch = useDispatch();

    const {type} = useParams();
    const {setParams} = useDataParams();

    const renderState = useSelector((state) => state.renderLead.render1);
    const followUpData = useSelector((state) => state.followupReducer);
    const filters = useSelector((state) => state.filterReducer);
    const leadForEdit = useSelector((state) => state.leadFormReducer);


    const isFilersApplied = {
        filters: !_.isEmpty(filters?.withValues),
    };


    const retrieveStudentLeads = useCallback(async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data: {leads, total},
            } = await $crud.retrieve("web/fresh-leads", {
                ...filters?.withValues,
                ...isFilersApplied,
                [type]: true,
                page,
                limit,
            });
            return {leads, total};
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }, [limit, page, renderState, filters?.withValues, type]);
    // const retrieveStudentLeadsAxios = useCallback(async () => {
    //   try {
    //     dispatch({
    //       type: "LOADER",
    //       loader: true,
    //     });
    //     const {
    //       data: { leads, total },
    //     } = await axios.get("https://dev-crm-node.technosters.co.in/api/retrieve/web/fresh-leads?filters=false&lead=true&page=1&limit=1000", {
    //       headers:{
    //         Authorization:"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJuYW1lIjoic3VwZXIgYWRtaW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsImlhdCI6MTcxMzAwMTk1M30.6Fm8q47nL4hr_pkqZMh2UpPQBUsx5S-hKa4a719jPeo",
    //       }
    //     });
    //     console.log({leads, total});
    //   } catch (e) {
    //     dispatch({
    //       type: "SNACKBAR",
    //       snackBar: { type: "error", message: e.message },
    //     });
    //   } finally {
    //     dispatch({
    //       type: "LOADER",
    //       loader: false,
    //     });
    //   }
    // },[limit, page, renderState,filters?.withValues, type]);

    // useEffect(() => {
    //   setFiltersWithLabel(filters?.withLabels);
    //   setFiltersWithValues(filters?.withValues);
    // }, [filters]);
    useEffect(() => {

        if (leadForEdit?.showModal && leadForEdit?.edit_id) {
            setShowLeadForm(true);
            setLeadId(leadForEdit?.edit_id);
        }
    }, [leadForEdit?.showModal, leadForEdit?.edit_id]);

    useEffect(() => {
        retrieveStudentLeads()
            .then((res) => {
                setLeads(res?.leads);
                setTotal(res?.total);
            })
            .catch((error) => console.error(error));
    }, [limit, page, renderState, filters?.withValues, type]);

// useEffect(()=>{
//   retrieveStudentLeadsAxios();
// },[]);

    useEffect(() => {
        setPage(1);
    }, [type]);

    return (
        <>
            <Grid className="container-fluid top-navbar">
                {isFilersApplied && <FilterChipContainer/>}
                <AnnouncementPopUp/>
                    <DynamicTable
                        rows={leads}
                        columns={columns?.studentLead}
                        style={{
                            // maxHeight: "calc(100vh - 250px)",
                            maxHeight: "calc(100vh - 290px)",
                            position: "fixed",
                            top: "150px",
                            width: "calc(100% - 24px)",
                        }}
                        total={total}
                        onEdit={(id) => {
                            setShowLeadForm(true);
                            setLeadId(id);
                        }}
                        onRowClick={(id) => {
                            setShowLeadModal(true);
                            setLeadId(id);
                        }}
                        paginationProps={{
                            limit: limit,
                            page: page,
                            onChangeLimit: (limit) => setLimit(limit),
                            onChangePage: (page) => setPage(page),
                        }}
                    />
            </Grid>

            {(showLeadModal || followUpData?.openLeadModal) && <LeadModal
                anchor="right"
                open={showLeadModal || followUpData?.openLeadModal}
                onOpen={() => {
                }}
                onClose={() => {
                    setShowLeadModal(false);
                    setLeadId(null);
                    setParams({});
                }}
                leadId={leadId || followUpData?.id}
            />}

            {showLeadForm && <LeadForm
                leadId={leadId}
                anchor="right"
                open={showLeadForm}
                onClose={() => {
                    setShowLeadForm(false);
                    setLeadId(null);
                    setParams({});
                    dispatch(resetEditLead());
                }}
                onOpen={(leadId) => {
                    setShowLeadModal(true);
                    setLeadId(leadId);
                }}
                onNew={() => {
                    setParams({});
                }}
            />}
        </>
    );
}
