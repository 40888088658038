/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    InputLabel,
    TextField,
    MenuItem
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputField} from "../components/inputs";
import classNames from "classnames";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";


export const ContactDetailModal = (props) => {

    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    // const [roles,setRoles] = useState([]);
    const [departments,setDepartments] = useState([]);
    const cancel = () => {
        props.onClose();
        setParams({});
    }

    const createContactDetail = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });

            const {type, message} = await $crud.post(!props.contactDetail?.id ? "create/web/contact-detail" : "update/web/contact-detail", {
                ...params
            });
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    // const retrieveRoles = async () =>{
    //    const {data:{roles}} = await $crud.retrieve("/web/roles");
    //    setRoles(roles);
    // }

    const retrieveDepartments = async () =>{
      const {data:{departments}} = await $crud.retrieve("web/department",{
            limit:100,
            page:1
        });
      setDepartments(departments);
    }

    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                    if (props.contactDetail?.id){
                        setParams(props?.contactDetail);
                    }
                    // retrieveRoles();
                    retrieveDepartments();
                }
            }
        } TransitionComponent={Slide} fullWidth open={props.open}>
            <div className="tableHeader-color">
                <DialogTitle >Add Contact Details</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Name<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.name || ""}
                                onChange={(e) => handleChange("name", e.target.value)}
                                fullWidth
                                placeholder="Enter Name"
                                size="small"
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Role</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={`${params?.role}` || ""}
                                onChange={(e) => handleChange("role", e.target.value)}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Phone Number</InputLabel>
                            </Grid>
                            <TextField
                                value={params?.phoneNo || ""}
                                onChange={(e) => handleChange("phoneNo", e.target.value)}
                                fullWidth
                                placeholder="Enter Phone Number"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Email</InputLabel>
                            </Grid>
                            <TextField
                                value={params?.email|| ""}
                                onChange={(e) => handleChange("email", e.target.value)}
                                fullWidth
                                placeholder="Enter Email"
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Department</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={`${params?.departmentName}` || ""}
                                onChange={(e) => { 
                                    const selectedValue = e.target.value;
                                    // console.log(e, "----------e.target");
                                    const selectedId = departments.find(option => option.name == selectedValue)?.id || '';
                                    // console.log(selectedTag, "----------selectedTag");
                                    handleChange("departmentName", selectedValue);
                                    handleChange("departmentId", selectedId);
                                }}
                                // onChange={(e) => handleChange("departmentId", e.target.value)}
                            >
                                {departments.map((department) => (
                                    <MenuItem key={department.id} value={department.name}>
                                        {department.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                    </Grid>

                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                        <Button className="ms-2" onClick={() => {
                            createContactDetail();
                        }} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};
