import React, {useState, useEffect} from "react";
import {Grid, Typography, Box, Switch, Button, Fab, Tooltip} from "@mui/material";
import {Call} from "@mui/icons-material";
import {$crud} from "../services/CrudFactory";
import {useDispatch} from "react-redux";
import {retrieveSystemSettings} from "./LoginPage";
import {setSystemSettings} from "../redux/actions";

export const SystemSettings = () => {
    const [UISettings, setUISettings] = useState({isMobileHide: false});
    const dispatch = useDispatch();

    const updateSettings = async (item) => {
        const {name, value} = item;
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("update/web/system-settings", {[name]: value});

            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            retrieveSystemSettings()
                .then(res => dispatch(setSystemSettings(res)));
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    const retrieveData = async () => {
        const {data: {systemSettings}} = await $crud.get("retrieve/web/system-settings");
        return systemSettings[0];
    };

    useEffect(() => {
        retrieveData().then(res => setUISettings(res));
    }, []);


    return (
        <Grid container mb={2}>
            <Grid item xs={12}>
                <Typography variant="h5" component="span">UI Settings</Typography>
                <Box pl={5} display="flex" alignItems="center" my={2} sx={{border: "1px solid #fff", p: 2}}>
                    <Typography variant="subtitle2" component="span">Hide Mobile Number From Leads</Typography>
                    <Box ml={3}>
                        <Typography variant="caption">Off</Typography>
                        <Switch
                            checked={UISettings.isMobileHide}
                            onChange={async (e) => {
                                setUISettings(prev => {
                                    return {...prev, isMobileHide: e.target.checked};
                                });
                                await updateSettings({name: "isMobileHide", value: e.target.checked});
                            }}/>
                        <Typography variant="caption">On</Typography>
                    </Box>
                    <Tooltip title="Rendered Component">
                        <Box ml={3}>{!UISettings?.isMobileHide ?
                            <Button size="small" variant="contained">999999***9</Button> : <Fab color="primary" sx={{
                                width: "100%",
                                height: "100%",
                                aspectRatio: 1
                            }}><Call/></Fab>}</Box>
                    </Tooltip>
                </Box>
            </Grid>
        </Grid>
    );
};