/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Button,
  Slide,
  InputLabel,
  TextField,
  Typography,
  DialogActions,
  MenuItem,
  Autocomplete,
  Box,
  Switch,
} from "@mui/material";
import { useDataParams } from "../hooks/useDataParams";
import { useDispatch, useSelector } from "react-redux";
import { $crud } from "../services/CrudFactory";
import { retrieveUsers } from "../pages";
import { users, visitAndDemoRetrieve } from "../redux/actions";
import { formatDate, formateDateAndTime } from "../helpers/functions";

export const VisitModal = (props) => {
  const [branches, setBranches] = useState([]);
  const dispatch = useDispatch();
  const { params, handleChange, setParams } = useDataParams();
  const attendents = useSelector((state) => state.usersReducer);
  const { leadId, open, onClose, onSubmit } = props;
  // const visitAndDemo = useD

  // console.log(params, "-------params");

  const cancel = () => {
    onClose();
    setParams({});
  };
  const retrieveBranches = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const {
        data: { branches, total },
      } = await $crud.retrieve("web/branches");
      setBranches(branches);
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  const getlastScheduleVisit = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const {
        data: { lead },
      } = await $crud.retrieve("web/schedule-last-visit", { leadId });
      // console.log(lead, "-------lead");
      // setParams(lead);
      setParams({...lead, visitedAt:formateDateAndTime(lead?.visitedAt)})
     
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };
  const handleSubmit = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      // const {type, message, data: {visitedLead}} = await $crud.post(
      //     !props?.visitedId ? "create/web/visited-lead" : "update/web/visited-lead",{
      //         ...params
      //     }
      // );
      const {
        type,
        message,
        data: { visitedLead },
      } = await $crud.post("update/web/visited-lead", {
        ...params,
        leadId
      });
      dispatch(visitAndDemoRetrieve());
      dispatch({
        type: "SNACKBAR",
        snackBar: { type, message },
      });
      retrieveUsers().then((res) => {
        dispatch(users(res.users));
      });
      onSubmit();
      cancel();
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  useEffect(() => {
   open && retrieveBranches();
  }, []);

  return (
    <Dialog
      maxWidth={"xs"}
      TransitionProps={{
        onEnter: () => {
          getlastScheduleVisit();
          setParams({ ...params, leadId, isVisited: false });
        },
      }}
      TransitionComponent={Slide}
      fullWidth
      open={open}
    >
      <div className="tableHeader-color">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>Lead's Visit Detail</Typography>
          {/* <Box sx={{display:"flex", justifyContent:"space-between"}}>
                        <Typography mr={1}>Is Visited</Typography>
                        <Switch
                          checked={params?.isVisited || ""}
                          onChange={(e) => setParams({...params, isVisited:e.target.checked})}
                          size="small"
                          color="primary"
                        />
                    </Box> */}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <InputLabel className="mb-1">Visited At</InputLabel>
              </Grid>
              <TextField
                fullWidth
                select
                size="small"
                value={`${params?.branchId}` || ""}
                onChange={(e) => handleChange("branchId", e.target.value)}
                sx={{
                  svg: { color: "#fff" },
                }}
              >
                {branches.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <InputLabel className="mb-1">Visited On</InputLabel>
              </Grid>
              <TextField
                size="small"
                value={params?.visitedAt || ""}
                onChange={(e) => handleChange("visitedAt", e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="datetime-local"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <InputLabel className="mb-1">Attended By</InputLabel>
              </Grid>

              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                size="small"
                disableClearable
                // value={params?.attendedBy || ""}
                onChange={(event, newValue) => {
                  handleChange("attendedBy", newValue.id);
                }}
                options={attendents}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return <Grid {...props}>{option?.name}</Grid>;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => {
                      handleChange("attendedBy", e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <InputLabel className="mb-1">Reason For Visit</InputLabel>
              </Grid>
              <TextField
                fullWidth
                multiline
                rows={3}
                size="small"
                value={params?.reason || ""}
                onChange={(e) => {
                  handleChange("reason", e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={cancel}
            color="primary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            className="ms-2"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
          >
            Submit
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
