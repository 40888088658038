/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Grid, ListItem, ListItemText, List, Typography,
    Button, TextField, MenuItem, InputLabel,
    Table, TableBody, TableRow, TableCell
} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {useDataParams} from "../hooks";
import {ImageUploader} from "../components/ImageUploader";
import {$crud} from "../services/CrudFactory";
import Papa from "papaparse";
import {IMAGE_BASE_URL} from "../constants";

export function UploadCSV() {
    const [rows, setRows] = useState([]);
    const [branches, setBranches] = useState([]);
    const [leadCategories, setLeadCategories] = useState([]);
    const [leadConvertTypes, setLeadConvertTypes] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [tags , setTags] = useState([]);
    const statuses = useSelector(state => state.statusReducer);
    const sources = useSelector(state => state.sourcesReducer);
    const users = useSelector(state => state.usersReducer);
    const dispatch = useDispatch();
    let branchId =  localStorage.getItem("branchInfo");
    let branchInfo = JSON.parse(branchId);

    const retrieveTags = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data:{tags}} = await $crud.retrieve(url, {branchId:branchInfo?.id});
            setTags(tags)
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };
    
    const retrieveData = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data} = await $crud.retrieve(url);
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    const {handleChange, params, setParams} = useDataParams();

    useEffect(() => {
        (async () => {
            const [branches, colleges, boardAndUniversities, leadTypes, leadConvertTypes] = await Promise.all([
                retrieveData("web/branches"),
                retrieveData("web/colleges"),
                retrieveData("web/board-and-universities"),
                retrieveData("web/lead-types"),
                retrieveData("web/lead-convert-types"),
                // retrieveData("web/sub-tags"),
            ]);
            setUniversities(boardAndUniversities.boardAndUniversities);
            setColleges(colleges.colleges);
            setBranches(branches.branches);
            setLeadCategories(leadTypes.leadTypes);
            setLeadConvertTypes(leadConvertTypes.leadConvertTypes);
            // setTags(tags.tags)
        })();
    }, []);

    useEffect(()=>{
        if(!branchInfo?.id){
          dispatch({type: "SNACKBAR", snackBar: {type: "error", message: "You don't support any branch."}})
        }else{
            retrieveTags("web/sub-tags");
        }
    }, [])

    const uploadCsv = async () => {
        const formData = new FormData();
        Object.entries(params).map(([key, value]) => {
            formData.append(key, value);
        });
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post("create/web/upload-csv", formData);
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };

    const simulateCSV = () => {
        params?.csv && Papa.parse(params?.csv, {
            complete: function (result) {
                const obj = _.omit(params, ["csv"]);
                const keys = Object.keys(obj);
                const values = Object.values(obj);

                if (result?.data?.length) {
                    if (keys.length) {
                        result?.data?.map((data, index) => {
                            if (index === 0) {
                                setRows(prev => [...prev, [...data, ...keys]])
                            } else {
                                setRows(prev => [...prev, [...data, ...values]])
                            }
                        });
                    } else {
                        setRows(result?.data)
                    }
                }

            },
        });
    };

    return (
        <div style={{padding:"20px", position:"fixed", top:"100px", width:"100%", maxHeight:"80vh", overflowY:"scroll"}}>
            <Grid container
                  className="tableHeader-color"
            >
                <Grid item xs={12} px={4}>
                    <Typography variant="subtitle1">Instructions</Typography>
                    <List
                        sx={{
                            listStyleType: "disc",
                            pl: 4,
                        }}
                    >
                        <ListItem disablePadding sx={{
                            display: "list-item",
                        }}>
                            <ListItemText
                                primary={<Typography variant="caption"> Your CSV data should be in the format below. The
                                    first line of your CSV file should be the column headers as in the table example.
                                    Also make sure that your file is UTF-8 to avoid unnecessary encoding
                                    problems.</Typography>}

                            />
                        </ListItem>
                        <ListItem disablePadding sx={{
                            display: "list-item",
                        }}>
                            <ListItemText
                                primary={<Typography variant="caption"> the lead won&lsquo;t be imported if: - Lead
                                    mobile1 already exists </Typography>}/>
                        </ListItem>
                        <ListItem disablePadding sx={{
                            display: "list-item",
                        }}>
                            <ListItemText
                                primary={<Typography variant="caption">Upload CSV Before Selecting Ids From Select Fields Below.</Typography>}/>
                        </ListItem>
                        <ListItem disablePadding sx={{
                            display: "list-item",
                        }}>
                            <ListItemText
                                primary={<Typography variant="caption">Select Neccessary Ids From The Select Fields Below Before Generating Preview of CSV.</Typography>}/>
                        </ListItem>
                        <ListItem disablePadding sx={{
                            display: "list-item",
                        }}>
                            <ListItemText
                                primary={<Typography variant="caption">CSV Preview Will Only Contain One Record.</Typography>}/>
                        </ListItem>

                    </List>
                </Grid>
            </Grid>
            <Grid container
                  p={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item sx={12} justifyContent="space-between" display="flex" width="100%">
                            <Typography variant="subtitle1">Upload CSV</Typography>
                            <Button variant="contained" size="small" href={`${IMAGE_BASE_URL}sample_csv.csv`} sx={{color:"#fff","&:hover":{color:"#fff"}}}>Download Sample</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container
                  className="tableHeader-color"
                  p={2}>
                <Grid item xs={12} sx={{overflow: "scroll"}}>
                    {
                        !params?.csv ?
                            <ImageUploader
                                name="csv"
                                onChange={(file) => handleChange("csv", file)}
                                accept={".csv"}
                                id="csv"
                                onClick={() => setParams({})}
                                file={params?.csv}
                                required={true}
                            />
                            : rows.length ? <Table>

                                <TableBody>
                                    {
                                        rows.slice(0,2).map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    {
                                                        row.map((column, index) => {
                                                            return (
                                                                <TableCell key={index} className="tableHeader-color">{column}</TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            );
                                        })
                                    }

                                </TableBody>
                            </Table> : <Button variant="contained" onClick={simulateCSV} fullWidth>Simulate CSV</Button>
                    }
                </Grid>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={3}>
                        <InputLabel className="mb-1">Choose Lead Status</InputLabel>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.statusId}` || ""}
                            onChange={(e) => handleChange("statusId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {statuses.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className="mb-1">Choose Lead Source</InputLabel>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.sourceId}` || ""}
                            onChange={(e) => handleChange("sourceId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {sources.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className="mb-1">Choose Assignee</InputLabel>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.assignTo}` || ""}
                            onChange={(e) => handleChange("assignTo", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {users.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className="mb-1">Choose Lead Category</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            select
                            variant="outlined"
                            value={`${params?.leadTypeId}` || ""}
                            color="primary"
                            onChange={(e) => handleChange("leadTypeId", e.target.value)}
                        >
                            {leadCategories?.map((category) => (
                                <MenuItem key={category?.id} value={category?.id}>{category?.name}</MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose Branch</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.branchId}` || ""}
                            onChange={(e) => handleChange("branchId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {branches.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose College</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.collegeId}` || ""}
                            onChange={(e) => handleChange("collegeId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {colleges.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose University</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.universityId}` || ""}
                            onChange={(e) => handleChange("universityId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {universities.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose Convert Type</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.leadConvertTypeId}` || ""}
                            onChange={(e) => handleChange("leadConvertTypeId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {leadConvertTypes.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose Tag</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={params?.tag || ""}
                            // onChange={(e) =>{ 
                            //     handleChange("tagId", e.target.value);
                            //     handleChange("tag", e.target.value);
                            // }}
                            onChange={(e) => { 
                                const selectedValue = e.target.value;
                                // console.log(e, "----------e.target");
                                const selectedId = tags.find(option => option.name == selectedValue)?.id || '';
                                // console.log(selectedTag, "----------selectedTag");
                                handleChange("tag", selectedValue);
                                handleChange("tagId", selectedId);
                            }}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {tags.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12} justifyContent="flex-end" display="flex" mt={2}>
                    <Button variant="contained" size="small" onClick={uploadCsv}>Upload</Button>
                </Grid>
            </Grid>
        </div>
    );
}