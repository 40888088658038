/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    Modal,
    Box,
    Grid,
} from "@mui/material";
import { InputField } from "../components/inputs";
import { ReactPagination } from "../components/Pagination";
import { $crud } from "../services/CrudFactory";
import { usePagination } from "../hooks/usePagination";
import { useDispatch } from "react-redux";
import { useDataParams } from "../hooks/useDataParams";
import { EmployeeDialog } from "../dialogs/EmployeeDialog";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

 const useStyles = makeStyles((theme) => ({
    bg: {
        backgroundColor: "white",
    },
    titleColor: {
        color: "#fff",
    },
    textColor: {
        color: theme.palette.primary.light,
    },
}));

export const PasswordChange = () => {
    const styles = useStyles({});

    const dispatch = useDispatch();
    const { limit, setLimit, page, setPage, total, setTotal } = usePagination();
    const [employee, setEmployee] = useState(null);
    const { setFilter, filters } = useDataParams();
    const [employees, setEmployees] = useState([]);
    const [id, setId] = useState(null);
    const [empName, setEmpName] = useState(null);
    const [equal, setEqual] = useState(null);
    const { params, handleChange, setParams } = useDataParams();
    const [selectedButton, setSelectedButton] = useState(null);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        // bgcolor: "background.paper",
        bgcolor: "#161D31",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        color: "white",
    };

    const styleOnSelected = {
        color: "black",
        fontSize: "0.875rem",
    };
    const styleOnNotSelected = {
        color: "white",
        fontSize: "0.875rem",
    };
    const retrieveEmployees = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data: { employees, total },
            } = await $crud.retrieve("web/employees", {
                limit,
                page,
                ...filters,
            });
            setEmployees(employees);
            setTotal(total);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: { type: "error", message: e.message },
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };

    useEffect(() => {
        retrieveEmployees();
    }, [limit, page, filters]);

    const changePassword = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const { type, message } = await $crud.update("update/web/user", {
                ...params,
                id,
            });
            setTimeout(() => {
                handleClose();
            }, 1500);
            dispatch({
                type: "SNACKBAR",
                snackBar: { type, message },
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: { type: "error", message: e.message },
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };

    const getId = (item) => {
        setId(item?.id);
        setEmpName(item?.name);
    };

    useEffect(() => {
        if (params?.password == params["re-password"]) {
            setEqual(true);
        } else {
            setEqual(false);
        }
    }, [params]);

    function handleButtonClick(buttonIndex) {
        setSelectedButton(buttonIndex);
    }
    return (
        <>
            <div>
                <div className="row w-100 g-2 mt-2">
                    <div className="mb-3 col-6 d-flex">
                        {/* <ActionButton
              onClick={() => setEmployee({})}
              buttonName="Add Staff Member"
            /> */}
                        <Button variant="contained" style={{ marginLeft: "10px" }}>
                            <Link to={"/"} style={{ color: "white" }}>
                                Go To Dashboard
                            </Link>
                        </Button>
                    </div>
                    <div className="mb-3 col-3">
                        <div className="input-group">
                            <select
                                onChange={(e) => setFilter("status", e.target.value)}
                                className="form-select"
                                style={{
                                    backgroundColor: "#283046",
                                    border: "1px solid #5c3fdd",
                                }}
                                aria-label="Default select example"
                            >
                                <option value="any" selected>
                                    Status
                                </option>
                                <option value="1" selected>
                                    Active
                                </option>
                                <option value="0" selected>
                                    InActive
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3 col-3">
                        <input
                            onChange={(e) => setFilter("search", e.target.value)}
                            className="form-control InputBox"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                        />
                    </div>
                </div>
            </div>
            <TableContainer
                component={Paper}
                style={{ background: "#141D31" }}
                elevation={2}
            >
                <Table sx={{ minWidth: 650 }} size="medium" fontSize={12}>
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableHeader-color">Name</TableCell>
                            <TableCell className="tableHeader-color">Phone</TableCell>
                            <TableCell className="tableHeader-color">Emp.ID</TableCell>
                            <TableCell className="tableHeader-color">Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees?.map((item, i) => (
                            <TableRow
                                selected={selectedButton === i}
                                onClick={() => handleButtonClick(i)}
                                className={
                                    selectedButton === i
                                        ? "tableHeader-color-active-class"
                                        : "tableHeader-color"
                                }
                                style={{ fontSize: "50px" }}
                            >
                                <TableCell
                                    onClick={() => {
                                        handleOpen();
                                        getId(item);
                                    }}
                                    sx={
                                        selectedButton === i ? styleOnSelected : styleOnNotSelected
                                    }
                                >
                                    {item?.name || ""}
                                </TableCell>
                                <TableCell
                                    sx={
                                        selectedButton === i ? styleOnSelected : styleOnNotSelected
                                    }
                                >
                                    {item?.mobileNo || ""}
                                </TableCell>
                                <TableCell
                                    sx={
                                        selectedButton === i ? styleOnSelected : styleOnNotSelected
                                    }
                                >
                                    #{item?.id}
                                </TableCell>
                                <TableCell
                                    sx={
                                        selectedButton === i ? styleOnSelected : styleOnNotSelected
                                    }
                                >
                                    {item?.roles?.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!employees.length && (
                    <Typography className="text-center text-white font-weight-bold pt-2">
                        No Records Found
                    </Typography>
                )}
                <ReactPagination
                    total={total}
                    page={page}
                    setPageLimit={(limit) => setLimit(limit)}
                    onPageChange={(page) => setPage(page)}
                    limit={limit}
                />
                <EmployeeDialog
                    employee={employee}
                    onSave={retrieveEmployees}
                    onClose={() => setEmployee(null)}
                    open={!!employee}
                />

                {open && (
                    <div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Password Change for {empName}
                                </Typography>
                                <Grid item xs={12} sm={4} lg={4} md={4}>
                                    <InputField
                                        // value={params?.password || ""}
                                        required={true}
                                        labelName="Password"
                                        onChange={(value) => handleChange("password", value)}
                                        placeholderName="Enter Password"
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} lg={4} md={4}>
                                    <InputField
                                        // value={params?.password || ""}
                                        required={true}
                                        labelName="Re-type Password"
                                        onChange={(value) => handleChange("re-password", value)}
                                        placeholderName="Enter Password"
                                        type="password"
                                    />
                                </Grid>
                                {!equal && (
                                    <span style={{ color: "red" }}>Password is not equal</span>
                                )}
                                <br />
                                <Button
                                    variant="contained"
                                    sx={{ marginTop: "10px" }}
                                    onClick={() => changePassword()}
                                    disabled={!equal}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Modal>
                    </div>
                )}
            </TableContainer>
        </>
    );
};

