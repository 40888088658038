const initialState = {
    withValues: {},
    withLabels: {},
    customFilters:[],
    search: ""
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_FILTER_WITH_VALUES":
            return state = {...state, withValues: action.payload};

        case "ADD_FILTER_WITH_LABELS":
            return state = {...state, withLabels: action.payload};

        case "REMOVE_FILTER":
            return state = {
                withValues: Object.keys(state.withValues)
                    .filter(key => key != action.payload)
                    .reduce((acc, key) => {
                        acc[key] = state.withValues[key];
                        return acc;
                    }, {}),
                withLabels: Object.keys(state.withLabels)
                    .filter(key => key != action.payload)
                    .reduce((acc, key) => {
                        acc[key] = state.withLabels[key];
                        return acc;
                    }, {})
            };

        case "ADD_SEARCH_STRING":
            return state = {...state, search: action.payload};

        case "SET_CUSTOM_FILTERS":
            return state = {...state, customFilters: action.payload};

        case "ADD_CUSTOM_FILTER":
            return state = {...state, customFilters: [...(state.customFilters), action.payload]};

        case "REMOVE_CUSTOM_FILTER":
            return state = {
                ...state,
                customFilters: [...state.customFilters].filter(d=>d.id !== action.payload)
            };
        default:
            return state;
    }
};

export default filterReducer;
