const initialState = {
    isRunning: false,
    time: 0,
    isStopped: true,
};
const callTimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "STARTCALLTIMER":
            state = {...initialState, isRunning: action.payload};
            return state;

        case "UPDATECALLTIMER":
            state = {...state, time: Number(state.time) + Number(action.payload)};
            return state;

        case "STOPCALLTIMER":
            return state = initialState;

        default:
            return state;
    }
};


export default callTimerReducer;