/* eslint-disable */
import React from "react";
import {
  Button,
  InputLabel,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  TextField
} from "@mui/material";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";
import {$crud} from "../services/CrudFactory";
import { IMAGE_BASE_URL } from "../constants";



export function DownloadBtnModal({ open, close }) {
    const {params, handleChange} = useDataParams();
    const dispatch = useDispatch();
    
    const downloadApp = (url) =>{
        const a = document.createElement('a')
        a.href = `${IMAGE_BASE_URL}/crm_apps/${url}`
        a.download = "crmApp.apk"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
    const verifyFunction = async (e) => {
        await e.preventDefault()
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {app: {file}},type } = await $crud.post("create/web/crm-app-download", {
                ...params
            });
            if(file){
                dispatch({
                    type: "SNACKBAR",
                    snackBar: {
                        type,
                        message: "App downloading..."
                    }
                });
                downloadApp(file)
                close();
            }
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

  return (
    <Dialog maxWidth={"sm"} TransitionComponent={Slide} fullWidth open={open}>
      <div className="tableHeader-color">
        {/* <DialogTitle ></DialogTitle> */}
        {/* <Divider/> */}
        <DialogContent sx={{ maxHeight: "343px", overflowY: "scroll" }}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel className="mb-1">Phone</InputLabel>
            <TextField
              required
              type="number"
            //   value={params?.email || ""}
              onChange={(e) => handleChange("mobileNo", e.target.value)}
              placeholder="Enter Phone number"
              defaultValue=""
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="mb-1">Password</InputLabel>
            <TextField
              required
              type="password"
              defaultValue=""
            //   value={params?.email || ""}
              onChange={(e) => handleChange("password", e.target.value)}
              placeholder="Enter password"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid
            item
            xs={12}
            className="d-flex justify-content-end align-items-center mt-3"
          >
            <Button
              variant="outlined"
              onClick={() => {
                close();
              }}
              sx={{mx:2}}
              color="error"
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                verifyFunction(e)
            }}
              color="primary"
              size="small"
            >
              Continue
            </Button>
          </Grid>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
}
