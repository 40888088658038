/* eslint-disable */
import React, {useMemo, useCallback, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";


function sleep(duration) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, duration);
    });

}

export default function AsyncAutocomplete(props) {
    const {fetchOptions, options, setOptions, label = "Label", size = "small", onChange, fullWidth} = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const memoizedFetchOptions = useCallback(async () => {
        setLoading(true);
        await fetchOptions();
        setLoading(false);
    }, [label]);

    const memoizedOptions = useMemo(() => options, [options]);

    useEffect(() => {
        if (open) {
            memoizedFetchOptions();
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={onChange}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={memoizedOptions}
            loading={loading}
            componentsProps={{
                popper: {
                    className: "tableHeader-color"
                },
                clearIndicator: {
                    sx: {
                        svg: {
                            fill: theme => theme.palette.primary.main,
                        }
                    }
                },
                popupIndicator: {
                    sx: {
                        svg: {
                            fill: "#9747FF",
                            fontSize: 36
                        }
                    }
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    size={size}
                    sx={{
                        '&.MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#9747FF',
                            },
                            '&:hover fieldset': {
                                borderColor: '#9747FF',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#9747FF',
                            },
                        },
                    }}
                    fullWidth={!!fullWidth}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
