import React,{useState} from "react";
import { LeadModalSidebar } from "../../../components/leadSidebar";
import { preRecordedMessages } from "../../../data/preRecordedMessages";

export  function Email() {
  const [messageType, setMessageType] = useState("intro");

  return (
    <div className="row">
      <div className="col-lg-3 col-md-4">
        <div className="lead-modal-sidebar">
          <LeadModalSidebar setMessageType={setMessageType} messageType={messageType} data={preRecordedMessages} />
        </div>
      </div>
    </div>
  );
}

