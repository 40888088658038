import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import { WhatsappContent } from "../../../dialogs/WhatsappContent";

export function AddCard({
  containerStyle,
  type,
  url,
  className,
  courses,
  state,
  setState,
}) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <div
        style={containerStyle}
        onClick={() => setOpen(true)}
        className={className}
      >
        <Add />
      </div>
      {open && (
        <WhatsappContent
          open={open}
          onClose={() => setOpen(false)}
          type={type}
          url={url}
          courses={courses}
          setState={setState}
          state={state}
        />
      )}
    </React.Fragment>
  );
}
