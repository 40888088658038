/* eslint-disable */
import React, {useState, useEffect} from "react";
import DynamicTable from "../components/DynamicTable";
import {Grid, Switch, Tooltip} from "@mui/material";
import {usePagination} from "../hooks/usePagination";
import {ActionButton} from "../components/buttons";
import {Download, Edit, Publish} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {AppModal} from "../dialogs";
import {$crud} from "../services/CrudFactory";
import {useDispatch} from "react-redux"
import {IMAGE_BASE_URL} from "../constants";
import Checkbox from "@mui/material/Checkbox";

export function CBSLApps() {
    const [showAppModal, setShowAppModal] = useState(false);
    const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    // const rows = [
    //     {
    //         id: 1,
    //         name: "CBSL TeleCalling",
    //         version: "1.0",
    //         uploadedBy: {name: "Abhishek Singh"},
    //         isActive: false
    //     },
    //     {
    //         id: 2,
    //         name: "CBSL TeleCalling",
    //         version: "1.4",
    //         uploadedBy: {name: "Abhishek Singh"},
    //         isActive: true
    //     }
    // ]
    const columns = [
        {id: "id", title: "ID"},
        {id: "name", title: "Name"},
        {id: "version", title: "Version"},
        {id: "action", title: "Uploaded By", renderCell: ({value}) => value?.uploadedBy?.name},
        {id: "forceUpdate", title: "Force Update", renderCell: ({value, onStatusChange, onEdit, id, onDownload}) => {
               return( <Tooltip style={{cursor: "pointer"}} title="Force Update">
                    <Checkbox checked={value} onChange={(e) => {
                        e.stopPropagation();
                        onStatusChange(id,{forceUpdate:e.target.checked});
                    }} size="small"
                              color="primary"/>
                </Tooltip>)
            }},
        {id: "action", title: "Action", renderCell: ({value, onStatusChange, onEdit, id, onDownload}) => {
                return (
                    <React.Fragment>
                        <Tooltip style={{cursor: "pointer"}} title="Status">
                            <Switch checked={value?.isActive} onChange={(e) => onStatusChange(id,{isActive:e.target.checked})} size="small"
                                    color="primary"/>
                        </Tooltip>
                        <Tooltip onClick={() => onEdit(value)} style={{cursor: "pointer"}} title="Edit">
                            <Edit size="small" color="primary"/>
                        </Tooltip>
                        <Tooltip onClick={() => onDownload(value?.file)}  style={{cursor: "pointer"}} title="Download">
                            <Download size="small" color="primary"/>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }
    ]



    const retrieveData = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data,
            } = await $crud.retrieve(`web/${url}`);
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }
    const downloadApp = (url) =>{
        const a = document.createElement('a')
        a.href = `${IMAGE_BASE_URL}/crm_apps/${url}`
        a.download = `${IMAGE_BASE_URL}/crm_apps/${url}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const statusChange =async (id, data)=>{
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("update/web/crm-app", {
                id,
                ...data
            });
            retrieveData("crm-apps").then(res=> {
                setRows(res.crmApps);
            });
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        retrieveData("crm-apps").then(res=> {
            setRows(res.crmApps);
        })
    }, []);

    const {limit, setLimit, page, setPage, total} = usePagination();

    return (
        <>
            <Grid container mb={2}>
                <Grid item xs={12}>
                    <Button variant="contained" startIcon={<Publish/>} onClick={()=>setShowAppModal(true)}>App</Button>
                </Grid>
            </Grid>
            <DynamicTable
                rows={rows}
                columns={columns}
                style={{maxHeight: "calc(100vh - 250px)"}}
                total={total}
                onDelete={(id) => {
                    // setIdToRemove(id);
                }}
                onRowClick={() => {
                }}
                onStatusChange={(id, data) => {
                    statusChange(id, data);
                }}
                onDownload={(url)=>{downloadApp(url)}}
                onEdit={(value) => {
                    // setStatus(value);
                    setData(value);
                    setShowAppModal(true)
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
            <AppModal
                open={showAppModal}
                data={data}
                onClose={()=>setShowAppModal(false)}
            />
        </>
    )
}