/* eslint-disable */

import React from 'react';
import {firebase} from "./FirebaseConfig";
// import { getAuth, RecaptchaVerifier } from "firebase/auth";
const FirebaseRequestSend = async (mobile) => {

    let result = false;
    // const mobile = await localStorage.getItem("mobile");
    // const auth = getAuth();
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
            // FirebaseRequestSend(mobile);
        },
        defaultCountry: 'IN'
    });

    const phoneNumber = "+91" + mobile;

    const appVerifier = await window.recaptchaVerifier;
    await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            result = true;
        }).catch((error) => {
            console.error(error.message,"new SMS not sent");
        });

    return result;

}

export default FirebaseRequestSend;
