/* eslint-disable */
import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    TextField,
    InputLabel,
    MenuItem
} from "@mui/material";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch, useSelector} from "react-redux";
import {retrieveSources} from "../pages";
import {sources} from "../redux/actions";


export const SpendSourceModal = (props) =>{
    const dispatch = useDispatch();

    const {params,handleChange,setParams} = useDataParams();
    const currentUser = useSelector(state=>state.userReducer);
    const allSources = useSelector(state=>state.sourcesReducer);

    const cancel = () => {
        props.onClose();
        setParams({});
    }

    const createSpendSourceModal = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {type, message} = await $crud.post(!props.course?.id ? "create/web/lead-source-expense" : "update/web/lead-source-expense", {
                ...params,
                userId:currentUser?.id
            });
            retrieveSources().then(res=>dispatch(sources(res?.leadSources)));
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    return (
        <Dialog maxWidth={"xs"} TransitionComponent={Slide} fullWidth open={props.open}>
            <div className="tableHeader-color">
                <DialogTitle >Add Spend</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Choose Source</InputLabel>
                            </Grid>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                defaultValue={params?.sourceId || ""}
                                onChange={(e) => handleChange("sourceId", e.target.value)}
                            >
                                <MenuItem disabled value="">
                                    <em>Select Source</em>
                                </MenuItem>
                                {allSources.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className="mb-1">Expenditure</InputLabel>
                            <TextField
                                defaultValue={params?.expenditure || ""}
                                onChange={(e) => handleChange("expenditure", e.target.value
                                )}
                                placeholder="Enter Expenditure"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className="mb-1">Campaign ID</InputLabel>
                            <TextField
                                defaultValue={params?.campaignId || ""}
                                onChange={(e) => handleChange("campaignId", e.target.value
                                )}
                                placeholder="Enter Campaign ID"
                                fullWidth
                                size="small"
                            />

                        </Grid>

                    </Grid>

                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={props.onClose} color="primary" size="small">Cancel</Button>
                        <Button className="ms-2" onClick={()=>{
                            createSpendSourceModal();
                        }} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};
