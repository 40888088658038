/* eslint-disable */
import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, Divider, Grid, Button, Slide, InputLabel, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputField} from "../components/inputs";
import classNames from "classnames";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";
import {MapModal} from "./MapModal";


export const BranchModal = (props) => {
    const dispatch = useDispatch();
    const [showMapModal, setShowMapModal] = useState(false);
    const [newBranchLocation, setNewBranchLocation] = useState([]);
    const {params, handleChange, setParams} = useDataParams();
    const cancel = () => {
        props.onClose();
        setParams({});
    }

    const createBranch = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post(!props.branch?.id ? "create/web/branch" : "update/web/branch", {
                ...params,
            });
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    useEffect(() => {
        !!props?.branch?.id && setParams(props?.branch)
    }, [props?.branch])

    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                    if (props.branch?.id) {
                        setParams(props?.branch);
                    }
                    // retrieveSources()
                }
            }
        } TransitionComponent={Slide} fullWidth open={props.open}>
            <div className="tableHeader-color">
                <DialogTitle>Add Branch</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel className="mb-1">Branch Code</InputLabel>
                            <TextField
                                required
                                value={params?.branchCode || ""}
                                onChange={(e) => handleChange("branchCode", e.target.value)}
                                placeholder="Branch Code"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className="mb-1">Name</InputLabel>
                            <TextField
                                required
                                value={params?.name || ""}
                                onChange={(e) => handleChange("name", e.target.value
                                )}
                                placeholder="Enter Name"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className="mb-1">Email</InputLabel>
                            <TextField
                                required
                                value={params?.email || ""}
                                onChange={(e) => handleChange("email", e.target.value
                                )}
                                placeholder="Enter Email"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className="mb-1">Mobile 1</InputLabel>
                            <TextField
                                required
                                value={params?.mobileNo1 || ""}
                                onChange={(e) => handleChange("mobileNo1", e.target.value
                                )}
                                placeholder="Enter Mobile 1"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className="mb-1">Mobile 2</InputLabel>
                            <TextField
                                required
                                value={params?.mobileNo2 || ""}
                                onChange={(e) => handleChange("mobileNo2", e.target.value
                                )}
                                placeholder="Enter Mobile 2"
                                fullWidth
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel className="mb-1">Address</InputLabel>
                            <TextField
                                multiline
                                rows={2}
                                required
                                value={params?.address || ""}
                                onChange={(e) => handleChange("address", e.target.value
                                )}
                                placeholder="Enter Address"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className="mb-1">Pincode</InputLabel>
                            <TextField
                                required
                                value={params?.pincode || ""}
                                onChange={(e) => handleChange("pincode", e.target.value
                                )}
                                placeholder="Enter Pincode"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                        <InputLabel className="mb-1">City</InputLabel>
                        <TextField
                            required
                            value={params?.city || ""}
                            onChange={(e) => handleChange("city", e.target.value
                            )}
                            placeholder="Enter City"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                        <Grid item xs={6}>
                            <InputLabel className="mb-1">State</InputLabel>
                            <TextField
                                required
                                value={params?.state || ""}
                                onChange={(e) => handleChange("state", e.target.value
                                )}
                                placeholder="Enter State"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className="mb-1">Country</InputLabel>
                            <TextField
                                required
                                value={params?.country || ""}
                                onChange={(e) => handleChange("country", e.target.value
                                )}
                                placeholder="Enter Country"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className="mb-1">Description</InputLabel>
                            <TextField
                                multiline
                                rows={2}
                                required
                                value={params?.description || ""}
                                onChange={(e) => handleChange("description", e.target.value
                                )}
                                placeholder="Enter Description"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        {/* <Button variant="contained" onClick={() => setShowMapModal(true)} size="small">Set
                           Location</Button> */}
                        <Button variant="outlined" className="ms-2" onClick={cancel} color="primary"
                                size="small">Cancel</Button>
                        <Button className="ms-2" onClick={() => {
                            createBranch();
                        }} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
            <MapModal
                open={showMapModal}
                onClose={() => setShowMapModal(false)}
                branch={props?.branch}
                branches={props?.branches}
                setNewBranchLocation={setNewBranchLocation}
                newBranchLocation={newBranchLocation}
            />
        </Dialog>
    );
};
