/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Tooltip,
    Button,
    Switch,
    Slide,
    MenuItem,
    TextField, InputLabel
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputField} from "../components/inputs";
import {Add, AttachFile, Attachment, Delete, CloudUpload as CloudUploadIcon, CheckCircle} from "@mui/icons-material";
import {Editor} from '@tinymce/tinymce-react';
import {useDataParams} from "../hooks/useDataParams";
import classNames from "classnames";
import {$crud} from "../services/CrudFactory";
import {useDispatch, useSelector} from "react-redux";
import {retrieveCourses} from "../pages";
import {courses, current_user} from "../redux/actions";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import {Uploader} from "rsuite";
import {IMAGE_BASE_URL} from "../constants";

export const FeesModal = (props) => {
    const [itemPayload, setItemPayload] = useState({});
    // const [isEmi, setIsEmi] = useState(false)
    const [previewImages, setPreviewImages] = useState([]);
    const [removeImages, setRemoveImages] = useState([]);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loader);
    const {params, handleChange, setParams, handleBlur} = useDataParams();
    const [emi, setEmi] = useState([
        {totalMonthEMI: "", monthlyEMI: ""},
    ]);
    const [branches, setBranches] = useState([]);
    const [horizontal, setHorizontal] = useState('center');
    const user = useSelector(state => state.userReducer);

    // console.log(params, "-----params");
    // console.log(previewImages, "-----previewImages");

    const cancel = () => {
        props.onClose();
        setParams({});
        setEmi([
            {totalMonthEMI: "", monthlyEMI: ""}
        ])
        setPreviewImages([]);
        setRemoveImages([]);
        // props?.onSave();
    }


    const imagesPreview = () => {
        return previewImages?.map((image) => {
            if (typeof image === "object" && !("blobFile" in image)) {
                return {
                    url: image?.url,
                };
            } else if (typeof image === "object" && ("blobFile" in image)) {
                return {
                    url: URL.createObjectURL(image?.blobFile),
                }
            } else {
                return {
                    url: `${IMAGE_BASE_URL}course_images/${image}`
                }
            }
        });
    };

    const retriveBranches = async () => {
        const {
            data: {branches},
        } = await $crud.retrieve("web/branches", {
            limit: 100,
            page: 1,
        });
        setBranches(branches);
    };
    const createFeeChart = async () => {

        let formData = new FormData();
        Object.entries(params).map(([key, value]) => {
            const data = (typeof params[key] === "object") && !["images"].includes(key) ? JSON.stringify(params[key]) : params[key];
            if (["images"].includes(key) && params[key] && (typeof params[key] === "object")) {
                params[key]?.forEach((file) => {
                    if (file?.blobFile) formData.append(`${key}`, file.blobFile);
                });
            } else if (["pdf"].includes(key)) {
                formData.append(`${key}`, params[key])
            } else {
                formData.append(`${key}`, data);
            }
        })
        if (removeImages) formData.append("removeImages", JSON.stringify(removeImages));
        emi[0].totalMonthEMI && emi[0].monthlyEMI && formData.append("emi", JSON.stringify(emi));
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post(!props.course?.id ? "create/web/course" : "update/web/course", formData);
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
            cancel();
        } catch (e) {
            const {type, message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    useEffect(() => {
        retriveBranches();
    }, [])

    return (
        <>
            <Dialog maxWidth={"md"} TransitionProps={
                {
                    onEnter: () => {
                        if (props?.course?.id) {
                            setParams({...(props.course), userId: user?.id});
                            if (props?.course?.images) {
                                setPreviewImages(JSON.parse(props?.course?.images))
                            }

                            if (!!props.course?.fee_chart?.length) {
                                setEmi(props.course?.fee_chart)
                            } else {
                                setEmi([{totalMonthEMI: "", monthlyEMI: ""}])
                            }
                        }
                    }
                }
            } TransitionComponent={Slide} fullWidth open={props.open}>
                <div className="tableHeader-color">
                    <DialogTitle>Add Fees</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: "16px"
                                }}
                                xs={12}
                            >
                                <Uploader
                                    multiple
                                    listType="picture"
                                    name="images"
                                    accept="image/*"
                                    draggable={true}
                                    autoUpload={false}
                                    disabled={false}
                                    // fileListVisible={true}
                                    onChange={async (fileList) => {
                                        setPreviewImages([...fileList]);
                                        handleChange("images", fileList);
                                    }}
                                    onRemove={(file) => {
                                        if (file?.url) {
                                            const image = file?.url.split("/").pop();
                                            setRemoveImages([...removeImages, image]);
                                            setPreviewImages(
                                                previewImages?.filter((s) => s !== image)
                                            );
                                        }
                                    }}
                                    fileList={
                                        previewImages?.length
                                            ? imagesPreview()
                                            : null
                                    }
                                >
                                    <button type="button">
                                        <Add/>
                                    </button>
                                </Uploader>
                            </Grid>
                            <Grid item xs={12} display="flex" alignItems="center">
                                <label htmlFor="attachment-upload"
                                       className="  d-flex  align-items-center">
                                    <input
                                        type="file"
                                        style={{display: "none"}}
                                        id="attachment-upload"
                                        onChange={(e) => handleChange("pdf", e.target.files[0])}
                                    />
                                    <Tooltip title="Upload PDF">
                                        <Button component="span" variant="contained" startIcon={<CloudUploadIcon/>}>
                                            Upload PDF file
                                        </Button>
                                    </Tooltip>
                                </label>
                                {params?.pdf ? <CheckCircle sx={{fill: 'green', marginLeft: "5px"}}/> : null}
                            </Grid>
                            <Grid item xs={8} sm={8} lg={8} md={8}>
                                <InputField
                                    value={params?.name || ""}
                                    required={true} error={params?.errors?.name}
                                    onChange={value => handleChange("name", value)}
                                    style={{borderRadius: 0}}
                                    labelName="Course Name"
                                    placeholderName="Enter Course Name"
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} lg={4} md={4}>
                                <InputField
                                    value={params?.courseCode || ""}
                                    required
                                    error={params?.errors?.courseCode}
                                    onChange={value => handleChange("courseCode", value)}
                                    style={{borderRadius: 0}}
                                    labelName="Course Code"
                                    placeholderName="Enter Course Code"
                                    helperText="Should Be Unique"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4} md={4}>
                                {/*<InputField*/}
                                {/*    value={params?.branch_id || ""}*/}
                                {/*    required={true}*/}
                                {/*    error={params?.errors?.branch_id}*/}
                                {/*    labelName="Branch"*/}
                                {/*    onChange={value => handleChange("branch_id", value)}*/}
                                {/*    placeholderName="Enter Branch "*/}
                                {/*/>*/}
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <InputLabel className="mb-1">Branch</InputLabel>
                                </Grid>
                                <TextField
                                    select
                                    fullWidth
                                    size="small"
                                    value={`${params?.branchId}` || ""}
                                    sx={{mt: 0.5}}
                                    onChange={(e) => {
                                        handleChange("branchId", e.target.value)
                                        handleChange("branchCode", branches.find(branch => branch.id == e.target.value)?.branchCode)
                                    }}
                                >
                                    {branches.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4} md={4}>
                                <InputField
                                    value={params?.duration || ""}
                                    required={true}
                                    error={params?.errors?.duration}
                                    labelName="Course Duration"
                                    onChange={value => handleChange("duration", value)}
                                    placeholderName="Enter Course Duration"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4} md={4}>
                                <InputField
                                    value={params?.fee || ""}
                                    required={true}
                                    error={params?.errors?.fee}
                                    labelName="One Time Fees"
                                    onChange={value => handleChange("fee", value)}
                                    placeholderName="Enter one Time"
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={3} lg={3} md={3}>
                                <InputField
                                    value={params?.emiDownPayment}
                                    required={true}
                                    error={params?.errors?.emiDownPayment}
                                    labelName="EMI DownPayment"
                                    onChange={value => handleChange("emiDownPayment", value)}
                                    placeholderName="Enter Emi DownPayment"
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <Tooltip title="EMI Options" placement="right">
                                    <Switch checked={params?.isEmi}
                                            onChange={(e) => handleChange("isEmi", e.target.checked)}
                                            sx={{inputProps: {'aria-label': 'Show EMI Options'}}}/>
                                </Tooltip>
                            </Grid>

                            {params?.isEmi ? <Grid item xs={12} sm={11}>
                                <InputField
                                    value={params?.emiDownPayment}
                                    required={true}
                                    error={params?.errors?.emiDownPayment}
                                    labelName="EMI DownPayment"
                                    onChange={(value) => handleChange("emiDownPayment", value)}
                                    placeholderName="Enter Emi DownPayment"
                                />
                            </Grid> : null}

                            {params?.isEmi ?
                                emi?.map((emi, i) => <>
                                    <Grid item xs={12} sm={6}>
                                        <InputField
                                            type="number"
                                            value={emi.totalMonthEMI}
                                            onChange={value => setEmi(prev => {
                                                const temp = [...prev];
                                                const exist = temp.find((s, index) => index === i);
                                                if (exist) {
                                                    exist.totalMonthEMI = value;
                                                    return temp;
                                                } else {
                                                    return temp;
                                                }
                                            })}
                                            labelName="EMI Total Month"
                                            placeholderName="Enter EMI Total Month"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <InputField
                                            type="number"
                                            value={emi.monthlyEMI}
                                            onChange={value => setEmi(prev => {
                                                const temp = [...prev];
                                                const exist = temp.find((s, index) => index === i);
                                                if (exist) {
                                                    exist.monthlyEMI = value;
                                                    return temp;
                                                } else {
                                                    return temp;
                                                }
                                            })}
                                            labelName="EMI Monthly Payment"
                                            placeholderName="Enter Amount"
                                        />
                                    </Grid>

                                    <Grid item xs={2} sm={1} lg={1} md={1}
                                          className="d-flex justify-content-start align-items-end">

                                        {
                                            i === 0 && <Tooltip title="Add">
                                                <Fab size="small" color="primary" onClick={() => setEmi(prev => {
                                                    return [...prev, {totalMonthEMI: "", monthlyEMI: ""}]
                                                })} style={{borderRadius: "5px"}}>
                                                    <Add/>
                                                </Fab>
                                            </Tooltip>
                                        }

                                        {
                                            i !== 0 && <> <Tooltip title="Delete">
                                                <Fab size="small" color="error" onClick={() => {
                                                    setEmi(prev => {
                                                        return prev.filter((s, index) => index !== i);
                                                    })
                                                }} style={{borderRadius: "5px"}}>
                                                    <Delete/>
                                                </Fab>
                                            </Tooltip>
                                            </>
                                        }

                                    </Grid>
                                </>) : null
                            }
                            {/*<Grid item xs className="textEditor">*/}
                            {/*    <Grid container>*/}
                            {/*        <Grid item xs={11.5}>*/}
                            {/*            <Typography variant={"paragraph"}>Course Module</Typography>*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={0.5} display="flex" alignItems="center">*/}
                            {/*            <label htmlFor="attachment-upload"*/}
                            {/*                   className="  d-flex  align-items-center">*/}
                            {/*                <input type="file" style={{display: "none"}} id="attachment-upload"*/}
                            {/*                       onChange={(e) => handleChange("pdf", e.target.files[0])}/>*/}
                            {/*                <IconButton component="span" size="small">*/}
                            {/*                    <Tooltip title="Upload PDF">*/}
                            {/*                        <Attachment/>*/}
                            {/*                    </Tooltip>*/}
                            {/*                </IconButton>*/}
                            {/*            </label>*/}
                            {/*        </Grid>*/}
                            {/*    </Grid>*/}

                            {/*    <Editor*/}
                            {/*        value={params?.courseModule}*/}
                            {/*        name="job_description"*/}
                            {/*        init={{*/}
                            {/*            height: 200,*/}
                            {/*            menubar: false,*/}
                            {/*            plugins: [*/}
                            {/*                'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',*/}
                            {/*                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',*/}
                            {/*                'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'template'*/}
                            {/*            ],*/}
                            {/*            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +*/}
                            {/*                'alignleft aligncenter alignright alignjustify | ' +*/}
                            {/*                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',*/}
                            {/*            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'*/}
                            {/*        }}*/}
                            {/*        onEditorChange={value => handleChange("courseModule", value)}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                        </Grid>

                        <Grid item xs className="d-flex justify-content-end align-items-center mt-3">

                            <Button variant="outlined" onClick={cancel} color="primary" size="small"
                                    className="ms-2">Cancel</Button>
                            <Button disabled={loading} className="ms-2" onClick={async () => {
                                await ["name", "duration", "fee", "emiDownPayment"].map(async key => {
                                    await handleBlur(key, params[key]);
                                })

                                if (params?.errors && !Object.keys(params?.errors)?.length) {
                                    createFeeChart();
                                }
                                if (params?.errors === undefined) {
                                    createFeeChart();
                                }
                            }} variant="contained" color="primary" size="small">
                                {!loading ? "Submit" : "Submitting"}
                            </Button>
                        </Grid>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    )
        ;
};
