import React, { useEffect, useState } from "react";
import MessageBox from "../../../../components/MessageBox";
// import { preRecordedMessages } from "../../../../data/preRecordedMessages";
import { AddCard } from "../../../../components/cards/whatsappContents/AddCard";
import { $crud } from "../../../../services/CrudFactory";

export function WhatsAppTextTemplates({ setMessage, setWhatsappContent }) {
  // const data = preRecordedMessages.find((msgtype) => msgtype.type === "intro");
  const [textMessages, setTextMessages] = useState([]);

  const getTextMessages = async ()=>{
    try {
      const {data:{texts}} = await $crud.retrieve("web/whatsapp-texts");
      // console.log(data, "------data text");
      setTextMessages(texts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getTextMessages();
  },[]);

  return (
    <div className="row ">
      <div className="col-6 ">
        {textMessages?.map((msg, index) => {
          return (
            <MessageBox
              key={index}
              title={msg.title}
              desc={msg.body}
              setMessage={setMessage}
              setWhatsappContent={setWhatsappContent}
            />
          );
        })}
        {
          <AddCard
            type="Text"
            url="whatsapp-text"
            className="message-box"
            containerStyle={{ display: "flex", justifyContent: "center" }}
            state = {textMessages}
            setState={setTextMessages}
          />
        }
      </div>
    </div>
  );
}
