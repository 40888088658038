/* eslint-disable */
import React from "react";
import {Close} from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";

export function ImagePreview(props) {
    const {htmlFor, onClick, src} = props;
    return (
        <label
            className="document-box"
            style={{border: "none", position: "relative"}}
            htmlFor={htmlFor}
        >
            <div
                style={{
                    backgroundColor: "#000",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                }}
            >
                <Close
                    color="primary"
                    onClick={onClick}
                />
            </div>

            <img
                style={{ height: "100%",width:"100%" , objectFit:"contain"}}
                src={src}
            />

        </label>
    )
}

export function UploadButton({name, id}) {
    return (
        <label className="document-box" htmlFor={id}>
            <div className="text-center">
                <UploadIcon style={{fontSize: "40px"}}/>
                <p>{name.toUpperCase()}</p>
            </div>
        </label>
    )
}

export function ImageUploader(props) {
    const {name, onChange, id, onClick, src, file, required, accept} = props;

    return (
        <React.Fragment>
            <input
                onChange={(e) => {
                    onChange(e.target.files[0]);
                }}
                required={required}
                name={name}
                accept={accept}
                style={{display: "none"}}
                id={id}
                type="file"
            />
            {
                file ?
                    <ImagePreview
                        htmlFor={id}
                        src={src}
                        onClick={onClick}
                        file={file}
                    />
                    :
                    <UploadButton
                        name={name}
                        id={id}
                    />
            }
        </React.Fragment>
    )
}