import React from "react";
// import { IMAGE_BASE_URL } from "../../constants";
import { Map } from "../Map";
import { LocationOn } from "@mui/icons-material";

export function Locationcard(props) {
    const {
        name,
        // designation,
        latlng,
        address,
        
    } = props.data;

    // console.log(setMessage, "-----setMessage");

    let position = JSON.parse(latlng);
    const lat = position?.lat;
    const lng = position?.lng;
            // // console.log(lat, lng);
            // setWhatsappContent(`https://www.google.com/maps?q=${lat},${lng}`);

    return (
        <div className="contact-card" onClick={()=>props?.setMessage(`https://www.google.com/maps?q=${lat},${lng}`)}>
            <div className="location-card-header">
                <div className="location-preview-image">
                    <Map position = {position}/>
                </div>
                <div className="contact-title" >
                    <p className="m-0 p-0"><LocationOn/> {address} {name}</p>
                    {/* <span>{"(" + designation + ")"}</span> */}
                </div>
            </div>
            {/* <div className="contact-card-body">
                <p className="label">{"PHONE NUMBER"}</p>
                <p>{phoneNo}</p>
                <hr className='mt-1'/>
                <p className="label">{"EMAIL ID"}</p>
                <p>{email}</p>
                <hr className='mt-1'/>
                <p className="label">{"DEPARTMENT"}</p>
                <p>{departmentName}</p>
            </div> */}
        </div>
    );
}
