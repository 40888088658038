const initialState = false;

const sidebarLockerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SIDEBARLOCK":
            return  (state = action.payload);
        default:
            return state;
    }
};

export default sidebarLockerReducer;
