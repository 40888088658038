/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    Tooltip, Typography
} from "@mui/material";
import {ReactPagination} from "../components/Pagination";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch} from "react-redux";
import {Edit} from "@mui/icons-material"
import {BankDetailModal} from "../dialogs/BankDetailModal";
import {baseUrl} from "../constants";
import {columns} from "../helpers/tableData";
import DynamicTable from "../components/DynamicTable";

export const BankDetails = () => {
    const dispatch = useDispatch();
    const [bankDetail, setBankDetail] = useState(null);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const [bankDetails, setBankDetails] = useState([]);

    const retrieveBankDetails = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {bank_details, total}} = await $crud.retrieve("web/bank-details", {
                limit,
                page
            });
            setBankDetails(bank_details);
            setTotal(total);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    const statusChange=async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("create/web/status/bank-detail", {
                id
            });
            retrieveBankDetails();
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        retrieveBankDetails();
    }, [limit, page]);

    return (
        <>
            <div>
                <div className='row w-100 g-2 '>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setBankDetail({})} buttonName="Add Bank Details"/>
                    </div>
                </div>
            </div>
            <DynamicTable
                rows={bankDetails}
                columns={columns?.bankDetail}
                total={total}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}
                onEdit={(value) => {
                    setBankDetail(value)
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
            <BankDetailModal onSave={retrieveBankDetails} bankDetail={bankDetail}
                             onClose={() => setBankDetail(null)} open={!!bankDetail}/>
        </>
    );
};

