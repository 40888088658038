/* eslint-disable */
import React from "react";

export function LeadDetailField({title,desc,titleColor,descColor,className,showInput,onChange}) {
    return (
        <div className={className}>
            <p className='p-0 m-0 mb-1 detail-field-title' style={{ color: titleColor , fontSize: "12px" }}>{title}</p>

            {
                showInput ? <input onChange={e=>onChange(e.target.value)} value={desc} style={{background:"#283046",outline:"none",border:0,color: "#E4E4E4" , fontSize: "12px"}}/> : <p className='p-0 m-0 mb-2 detail-field-desc' style={{ color: descColor , fontSize: "14px" }}>{desc}</p>
            }

        </div>
    );
}

