export const preRecordedMessages = [
    {
        type:"intro",
        label:"Company Introduction",
        messages:[
            {
                title:"Welcome Message",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Best IT Firm of Agra",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 10%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 15%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
        ]
    },
    {
        type:"batch",
        label:"New Batch Info",
        messages:[
            {
                title:"Welcome Message",
                desc:"All New Batches Available At Our Branch"
            },
            {
                title:"New Batch for C Programming",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"New Batch for AWDP ",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"New Batch For Digital Marketing",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
        ]
    },
    {
        type:"offers",
        label:"Offers ",
        messages:[
            {
                title:"Welcome Message",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 5%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 10%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 15%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
        ]
    },
    {
        type:"course",
        label:"Course Details",
        messages:[
            {
                title:"Welcome Message",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 5%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 10%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 15%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
        ]
    },
    {
        type:"fees",
        label:"Fee Details",
        messages:[
            {
                title:"Welcome Message",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 5%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 10%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 15%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
        ]
    },
    {
        type:"contact",
        label:"Contact Details",
        messages:[
            {
                title:"Welcome Message",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 5%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 10%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 15%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
        ]
    },
    {
        type:"location",
        label:"Map Location",
        messages:[
            {
                title:"Welcome Message",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 5%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 10%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 15%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
        ]
    },
    {
        type:"sent",
        label:"Sent Messages",
        messages:[
            {
                title:"Welcome Message",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 5%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 10%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
            {
                title:"Offer Message for FLAT 15%",
                desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat proin ipsum morbi."
            },
        ]
    },
];