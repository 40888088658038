/* eslint-disable */
import React, {useEffect, useState} from "react";
import InputField from "../../../components/InputField";
import {ActionButton} from "../../../components/buttons";
import {valueDetails} from "../../../data/leadValueData";
import {useDataParams} from "../../../hooks/useDataParams";
import {$crud} from "../../../services/CrudFactory";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {EMPLOYEE_IMAGE_BASE_URL} from "../../../constants";
import {renderLead} from "../../../redux/actions";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Timeline from "@mui/lab/Timeline";
import {Avatar, Typography, Grid,Button} from "@mui/material";
import {makeStyles} from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    avatarWrapper: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translateX(-50%)',
            height: '100%',
            width: '2px',
            backgroundColor: theme.palette.text.primary,
        },
    },
}));

function CustomTimeline({valueData}) {
    const classes = useStyles();

    return (
        <Timeline >
            {valueData.map((data, index) => {
                return (
                    <TimelineItem>
                        <TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
                            <Grid display="flex" justifyContent="flex-end" alignItems="center">
                                <Typography variant="caption">{moment(data?.createdAt).format("DD-MM-YYYY") +" | "+moment(data?.createdAt).format('LT') }</Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="caption" color="primary">By:- {data?.user?.name}</Typography>
                            </Grid>
                        </TimelineOppositeContent>
                        <TimelineSeparator style={{minHeight:"100px"}}>
                            <TimelineDot style={{background:"#43C991"}}>
                                <Avatar src={EMPLOYEE_IMAGE_BASE_URL + `${data?.user?.photo}`}/>
                            </TimelineDot>
                            {index < valueData?.length-1 && <TimelineConnector sx={{background:"#43C991"}}/>}
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Grid>
                                <Typography color="#43C991">{data?.leadValue}</Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="caption">{data?.reason}</Typography>
                            </Grid>
                        </TimelineContent>
                    </TimelineItem>

                )
            })}
        </Timeline>
    )
}


export const LeadValue =  React.memo(function LeadValue({lead}) {
    const valueData = valueDetails.filter(value => value.id === 1);
    const {params, setParams, handleChange} = useDataParams();
    const [leadValues, setLeadValues] = useState([]);
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.userReducer);
    const renderState = useSelector((state) => state.renderLead.render1);

    const retrieveLeadValues = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {leadValues}} = await $crud.retrieve("web/lead-add-values", {
                leadId: lead.id
            });
            setLeadValues(leadValues);
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    const createLeadValue = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("update/web/add-lead-value", {
                ...params,
                leadId: lead?.id,
                sourceIp: window.location.host,
                userId: currentUser?.id
            });

            await $crud.post("update/web/lead", {
                leadValue: params?.leadValue,
                id: lead?.id,

            });

            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
            dispatch(renderLead({render1: !renderState}));
            setParams({});
            retrieveLeadValues();
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        retrieveLeadValues();
    }, [lead]);

    return (
        <div className="row">
            <div className="col-6">
                <div className="row">

                    <div className="col-12 col-md-12 col-lg-4">
                        <InputField Type="number" value={params.leadValue || ""} onChange={value => handleChange("leadValue", value)}
                                    labelName="New Lead Value" placeholderName="Enter Value" id="leadValue"/>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                        <InputField value={params.reason || ""} onChange={value => handleChange("reason", value)}
                                    labelName="Reason" placeholderName="Enter Reason" id="reason"/>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button variant="contained" onClick={() => createLeadValue()} >Update</Button>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="lead-value-container">
                    <div className="inner-container">
                        <CustomTimeline valueData={leadValues}/>
                    </div>
                </div>
            </div>
        </div>
    );
});

