const initialState = {
    isMobileHide: false,
};
const systemSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SETSYSTEMSETTINGS":
            state = {...initialState, ...(action.payload)};
            return state;


        default:
            return state;
    }
};


export default systemSettingsReducer;