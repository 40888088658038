import React from "react";
 
export function SettingsIocn() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M13.8194 22H10.1794C9.95133 22 9.7301 21.9221 9.5524 21.7792C9.3747 21.6362 9.2512 21.4368 9.2024 21.214L8.7954 19.33C8.25245 19.0921 7.73763 18.7946 7.2604 18.443L5.4234 19.028C5.20596 19.0973 4.97135 19.0902 4.75852 19.0078C4.54569 18.9254 4.36745 18.7727 4.2534 18.575L2.4294 15.424C2.31654 15.2261 2.27418 14.9958 2.30924 14.7708C2.3443 14.5457 2.4547 14.3392 2.6224 14.185L4.0474 12.885C3.98259 12.2961 3.98259 11.7019 4.0474 11.113L2.6224 9.816C2.45447 9.66177 2.34391 9.45507 2.30885 9.22978C2.27378 9.00449 2.31629 8.77397 2.4294 8.576L4.2494 5.423C4.36345 5.22532 4.54169 5.07259 4.75452 4.99019C4.96735 4.90778 5.20196 4.90066 5.4194 4.97L7.2564 5.555C7.5004 5.375 7.7544 5.207 8.0164 5.055C8.2694 4.913 8.5294 4.784 8.7954 4.669L9.2034 2.787C9.25197 2.5642 9.37523 2.36469 9.55274 2.22155C9.73026 2.07841 9.95136 2.00024 10.1794 2H13.8194C14.0474 2.00024 14.2685 2.07841 14.446 2.22155C14.6236 2.36469 14.7468 2.5642 14.7954 2.787L15.2074 4.67C15.7496 4.90927 16.2643 5.20668 16.7424 5.557L18.5804 4.972C18.7977 4.90292 19.0321 4.91017 19.2447 4.99256C19.4573 5.07495 19.6354 5.22753 19.7494 5.425L21.5694 8.578C21.8014 8.985 21.7214 9.5 21.3764 9.817L19.9514 11.117C20.0162 11.7059 20.0162 12.3001 19.9514 12.889L21.3764 14.189C21.7214 14.507 21.8014 15.021 21.5694 15.428L19.7494 18.581C19.6353 18.7787 19.4571 18.9314 19.2443 19.0138C19.0314 19.0962 18.7968 19.1033 18.5794 19.034L16.7424 18.449C16.2655 18.8003 15.751 19.0975 15.2084 19.335L14.7954 21.214C14.7466 21.4366 14.6233 21.6359 14.4458 21.7788C14.2683 21.9218 14.0473 21.9998 13.8194 22ZM11.9954 8C10.9345 8 9.91711 8.42143 9.16697 9.17157C8.41682 9.92172 7.9954 10.9391 7.9954 12C7.9954 13.0609 8.41682 14.0783 9.16697 14.8284C9.91711 15.5786 10.9345 16 11.9954 16C13.0563 16 14.0737 15.5786 14.8238 14.8284C15.574 14.0783 15.9954 13.0609 15.9954 12C15.9954 10.9391 15.574 9.92172 14.8238 9.17157C14.0737 8.42143 13.0563 8 11.9954 8Z"/>
</svg>
  );
}


