// /* eslint-disable */
// import React, { useState } from "react";
// import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// export function Location({formProps}){
//     // const {setParams } = formProps;

// console.log(formProps, "---------formProps from location");
// //   // Function to send the selected location to the backend
// //   const sendLocationToBackend = (latlng) => {
// //     // Replace this with your actual backend communication logic
// //     console.log('Sending location to backend:', latlng);
// //     // You can use fetch or any other method to send data to the backend here
// //   };

// function LocationMarker() {
//   const [position, setPosition] = useState(null);
//   const map = useMapEvents({
//     click(e) {
//       const { lat, lng } = e.latlng;
//       setPosition({ lat, lng });
//       // setParams({ lat, lng });
//       map.locate();
//     },
//     locationfound(e) {
//       setPosition(e.latlng);
//       map.flyTo(e.latlng, map.getZoom());
//     },
//   });

//   return position === null ? null : (
//     <Marker position={position}>
//       <Popup>You are here</Popup>
//     </Marker>
//   );
// }

//     const position = [51.505, -0.09];
//     const activeIcon = L.divIcon({
//         className: 'custom-marker-icon',
//         iconSize: [25, 41],
//         iconAnchor: [12, 41],
//         popupAnchor: [1, -34],
//         html: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="52" viewBox="0 0 256 256" xml:space="preserve">\n' +
//             '\n' +
//             '<defs>\n' +
//             '</defs>\n' +
//             '<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">\n' +
//             '\t<path d="M 45 90 c -1.415 0 -2.725 -0.748 -3.444 -1.966 l -4.385 -7.417 C 28.167 65.396 19.664 51.02 16.759 45.189 c -2.112 -4.331 -3.175 -8.955 -3.175 -13.773 C 13.584 14.093 27.677 0 45 0 c 17.323 0 31.416 14.093 31.416 31.416 c 0 4.815 -1.063 9.438 -3.157 13.741 c -0.025 0.052 -0.053 0.104 -0.08 0.155 c -2.961 5.909 -11.41 20.193 -20.353 35.309 l -4.382 7.413 C 47.725 89.252 46.415 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #9747ff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>\n' +
//             '\t<path d="M 45 45.678 c -8.474 0 -15.369 -6.894 -15.369 -15.368 S 36.526 14.941 45 14.941 c 8.474 0 15.368 6.895 15.368 15.369 S 53.474 45.678 45 45.678 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>\n' +
//             '</g>\n' +
//             '</svg>'
//     });

//     return (
//         <>
//             { position && <MapContainer   center={position} zoom={13} scrollWheelZoom={true} style={{height:"200px",width:"100%"}}>
//                     <TileLayer
//                         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//                         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                     />
//                     <LocationMarker/>
//                 </MapContainer> }
//             </>
//     );
// }

import React from "react";
import { Map } from "../../Map";
import { Box, Grid, InputLabel, TextField } from "@mui/material";

export const Location = ({ formProps }) => {
  const { handleChange, params } = formProps;
  return (
    <div>
      <Grid item sm={12} sx={{ height: "400px", width: "100%" }}>
        <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <InputLabel className="mb-1">Name</InputLabel>
            </Grid>
            <TextField
              required
              value={params?.name || ""}
              onChange={(e) => handleChange("name", e.target.value)}
              placeholder="Enter Name"
              fullWidth
              size="small"
            />
            <Grid container justifyContent="space-between" alignItems="center" mt={2}>
              <InputLabel className="mb-1">Address</InputLabel>
            </Grid>
            <TextField
              required
              value={params?.address || ""}
              onChange={(e) => handleChange("address", e.target.value)}
              placeholder="Enter Address"
              fullWidth
              size="small"
            />
          </Grid>
        <Box
          sx={{ height: "80%", width: "100%", mt:2 }}
          className="tableHeader-color"
        >
          <Map handleChange={handleChange} params={params} />
        </Box>
      </Grid>
    </div>
  );
};
