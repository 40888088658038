import React from "react";
import { IMAGE_BASE_URL } from "../../constants";

export function ContactCard(props) {
    const {
        name,
        // designation,
        photo,
        phoneNo,
        email,
        departmentName,
    } = props.data;

    return (
        <div className="contact-card" onClick={()=>props.setMessage(`Name : ${name}\nEmail : ${email}\nMobile No : ${phoneNo}`)}>
            <div className="contact-card-header">
                <div className="contact-profile-image">
                    <img src={photo != null ? `${IMAGE_BASE_URL}contact_images/${photo}` : "https://cdn-icons-png.flaticon.com/512/149/149071.png"} alt="Profile Image"/>
                </div>
                <div className="contact-title">
                    <p className="m-0 p-0">{name}</p>
                    {/* <span>{"(" + designation + ")"}</span> */}
                </div>
            </div>
            <div className="contact-card-body">
                <p className="label">{"PHONE NUMBER"}</p>
                <p>{phoneNo}</p>
                <hr className='mt-1'/>
                <p className="label">{"EMAIL ID"}</p>
                <p>{email}</p>
                <hr className='mt-1'/>
                <p className="label">{"DEPARTMENT"}</p>
                <p>{departmentName}</p>
            </div>
        </div>
    );
}
