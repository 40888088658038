import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Typography from "@mui/material/Typography";

export function ChartContainer({chartID,chartTitle="Chart Title", style, filterProps:{setFilterType}}){
    return(
        <React.Fragment>
            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                <Typography variant={"h5"}>{chartTitle}</Typography>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Select"
                    defaultValue="monthly"
                    helperText="Please Select Filter"
                    size="small"
                    onChange={(e)=>setFilterType(e.target.value)}
                    sx={{width:200}}
                >
                    {[{label:"Weekly", value:"weekly"},{label:"Monthly",value:"monthly"}].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box id={chartID} style={style}></Box>
        </React.Fragment>
    );
}