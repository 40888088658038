/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    InputLabel,
    TextField,
    MenuItem
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputField} from "../components/inputs";
import classNames from "classnames";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";
import {retrieveSources} from "../pages";
import {sources} from "../redux/actions";

export const useStyles = makeStyles((theme) => ({
    bg: {
        backgroundColor: theme.palette.primary.dark
    },
    titleColor: {
        color: "#fff"
    },
    textColor: {
        color: theme.palette.primary.light
    }
}));
export const LeadTypeTemplate = (props) => {
    const styles = useStyles({});
    const dispatch = useDispatch();
    const [courses, setCourses] = useState([]);
    const [templateType, setTemplateType] = useState([
        {
            name: "WhatsApp"
        },
        {
            name: "Sms"
        },
        {
            name: "email"
        }
    ]);
    const {params, handleChange, setParams} = useDataParams();
    const cancel = () => {
        props.onClose();
        setParams({});
    }
    const retrieveCourses = async () => {
        const {data} = await $crud.retrieve("web/course-all");
        setCourses(data);
    };

    const createLeadTypeTemplate = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {type, message} = await $crud.post(!props.leadTemplateType?.id ? "create/web/template-type-msg" : "update/web/template_type_msg", {
                ...params,
            });
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    useEffect(() => {
        retrieveCourses();
    }, []);
    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                    if (props.leadTemplateType?.id){
                        setParams(props?.leadTemplateType);
                    }
                }
            }
        } TransitionComponent={Slide} fullWidth open={props.open} onClose={()=> {
            setParams(null);
            props?.onClose();
        }}>
            <div className="tableHeader-color">
                <DialogTitle >Add Lead Type Template</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Choose Course<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={`${params?.courseId}` || ""}
                                onChange={(e) => handleChange("courseId", e.target.value)}
                            >
                                {courses.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>

                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Select Template Type<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={`${params?.templateType}` || ""}
                                onChange={(e) => handleChange("templateType", e.target.value)}
                            >
                                {templateType.map((option) => (
                                    <MenuItem key={option.name} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Message</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                size="small"
                                value={params?.message || ""}
                                placeholder="Enter Message"
                                onChange={(e) => handleChange("message", e.target.value)}
                            />

                        </Grid>
                    </Grid>

                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                        <Button className="ms-2" onClick={() => {
                            createLeadTypeTemplate();
                        }} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};
