/* eslint-disable */
import React, {useEffect, useState} from "react";
import {Grid, Button, Typography, Card} from "@mui/material";
import {AccessTimeOutlined} from "@mui/icons-material";
import {useTimer} from "react-use-precision-timer";

import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import {addWorkingTime, addBreakTime, renderLead, users, addTimerId} from "../redux/actions";
import Chip from "@mui/material/Chip";
import {$crud} from "../services/CrudFactory";
import {retrieveUsers} from "../pages";

// const breakTimeG = useSelector(state => state.timerReducer.breakDuration.milliseconds)
// const workingHoursG = useSelector(state => state.timerReducer.workingDuration.milliseconds)

export function Clock({isLogout}) {
    const [clock, setClock] = useState(moment().format('LTS'));
    const [isRefresh, setIsRefresh] = useState(false);
    const dispatch = useDispatch();
    const isStarted = useSelector(state => state.timerReducer.isStarted);
    const isStopped = useSelector(state => state.timerReducer.isStopped);
    const isRunning = useSelector(state => state.timerReducer.isRunning);
    const isResumed = useSelector(state => state.timerReducer.isResumed);
    const isPaused = useSelector(state => state.timerReducer.isPaused);
    const timerId = useSelector(state => state.timerReducer.timerId);
    const workingHours = useSelector(state => state.timerReducer.workingDuration.milliseconds)
    const totalWorkingHours = useSelector(state => state.timerReducer.totalWork)
    const breakTime = useSelector(state => state.timerReducer.breakDuration.milliseconds)
    const totalBreakTime = useSelector(state => state.timerReducer.totalBreak)
    // const [workingTime, setWorkingTime] = useState(workingHours)
    const userId = useSelector(state => state.userReducer).id;

    // const addTime = ()=>{
    //     setTimeout(()=>{
    //         dispatch(addWorkingTime({milliseconds:+workingHours + 1,string: getFormattedTime(+workingHours + 1) }))
    //     }, 1000)
    // }

    const stopWatch = useTimer({
            startImmediately: isStarted,
            delay: 1000,
        },
        () => {
            isRunning && dispatch(addWorkingTime({
                milliseconds:getElapsedResumedTime(),
                string: getFormattedTime(getElapsedRunningTime)
            }));
            // isRunning && dispatch({
            //     type:"ADD_CURRENT_WORKING_DURATION",
            // })
        }
    );

    const {start, pause, resume, stop, getElapsedRunningTime, getTotalElapsedPausedTime, getLastFireTime, getPeriodElapsedPausedTime, getElapsedResumedTime} = stopWatch;

    function getTime(callback) {
        const time = callback();
        const total = Number.parseInt(time / 1000, 10);
        const minutes = Number.parseInt((total / 60) % 60, 10);
        const seconds = Number.parseInt(total % 60, 10);
        const hours = Number.parseInt((minutes / 60) % 60, 10);
        return {
            total,
            minutes,
            seconds,
            hours
        };
    }

    function getFormattedTime(callback) {
        const time = getTime(callback);
        const hoursString = time.hours.toString().padStart(2, "00");
        const minsString = time.minutes.toString().padStart(2, "00");
        const secondsString = time.seconds.toString().padStart(2, "00");
        return `${hoursString}:${minsString}:${secondsString}`;
    }

    async function setDailySummary(action, data) {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message, data: {id, workingHours, breakTime}} = await $crud.post(
                "create/web/working-hours",
                {
                    ...data,
                    action
                }
            );

            id && dispatch(addTimerId(id));

            workingHours && dispatch(addWorkingTime({
                database: workingHours,
                milliseconds: 0
            }));

            breakTime && dispatch(addBreakTime({
                database: breakTime,
                milliseconds: 0
            }));

            setIsRefresh(true)
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });

        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }

    useEffect(() => {
        const pauseInterval = setInterval(() => {
            isPaused && !isRunning && dispatch(addBreakTime({
                milliseconds:breakTime + getPeriodElapsedPausedTime(),
                string: getFormattedTime(getTotalElapsedPausedTime)
            }));
            // isPaused && !isRunning && dispatch({
            //     type:"ADD_CURRENT_BREAK_DURATION",
            // })
        }, 1000);

        return () => clearInterval(pauseInterval);
    }, [isPaused, isRunning]);

    useEffect(() => {
        if (isStarted && isRunning && !workingHours) {
            start()
            setDailySummary("Login", {userId});
        }
    }, [isStarted]);

    useEffect(() => {
        if (isRunning && !isPaused && isResumed) {
            resume()
            setDailySummary("Continue", {id: timerId, breakTime});

        } else if (!isRunning && isPaused ) {
            pause()
            setDailySummary("Break", {id: timerId, workingHours});
        }


    }, [isRunning, isPaused]);

    useEffect(() => {
        if (isStopped && isLogout) {
            // dispatch({
            //     type:"EMPTY_CURRENT_WORKING_DURATION"
            // });
            // dispatch({
            //     type:"EMPTY_CURRENT_BREAK_DURATION"
            // });
            setDailySummary("Logout", {id: timerId, workingHours: totalWorkingHours, breakTime: totalBreakTime});
            stop()
        }
    }, [isStopped]);

    useEffect(() => {
        const clockInterval = setInterval(() => {
            setClock(moment().format('LTS'))
        }, 1000);

        return () => clearInterval(clockInterval);
    }, [])


    return (
        // <Grid container component={Card} alignItems="center" p={1} className="timer-container">
        //     <Grid item xs={5} display="flex" alignItems="center" justifyContent="center">
        //         {isStarted && isRunning && !isPaused && !isStopped &&
        //         <Typography sx={{
        //             color: theme => theme.palette.success.main,
        //             fontWeight: 600,
        //             fontSize: 16
        //         }}>Working</Typography>}
        //         {isStarted && isPaused && !isRunning && !isStopped &&
        //         <Typography sx={{color: theme => theme.palette.warning.main, fontWeight: 600, fontSize: 16}}>On
        //             Break</Typography>}
        //         {!isStarted && isStopped &&
        //         <Typography
        //             sx={{color: theme => theme.palette.error.main, fontWeight: 600, fontSize: 16}}>Idle</Typography>}
        //     </Grid>
        //     <Grid item xs={7}>
        //         <Chip 
        //             sx={{ height: 'auto'}}
        //             label={
        //                 <Grid container spacing={0.5} sx={smallScreen}>
        //                     <Grid item xs><AccessTimeOutlined color="primary"/></Grid>
        //                     <Grid item xs display="flex" alignItems="center">{clock}</Grid>
        //                 </Grid>
        //             }
        //         />
        //     </Grid>
        // </Grid>
        <Grid container>
            <Grid item xs = {3}>Time</Grid>
            <Grid container item xs = {9} spacing={0.5} sx={smallScreen}>
                <Grid item xs = {2}><AccessTimeOutlined color="primary"/></Grid>
                <Grid item xs = {1}></Grid>
                <Grid item xs={9} display="flex" alignItems="center">{clock}</Grid>
            </Grid>
        </Grid>
    );
}


const smallScreen = {
    "@media screen and (max-width:900px)":{
    fontSize:'10px'
}}