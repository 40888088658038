import React from "react";

export function SendRightArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" >
            <path d="M3.69426 9.99998L0.299262 1.26998C0.0632618 0.662975 0.655262 0.0819752 1.24126 0.288975L1.33426 0.327975L19.3343 9.32798C19.4503 9.38595 19.5494 9.47304 19.6217 9.58074C19.6941 9.68845 19.7373 9.81308 19.747 9.94246C19.7568 10.0718 19.7329 10.2015 19.6775 10.3189C19.6222 10.4362 19.5373 10.5372 19.4313 10.612L19.3343 10.67L1.33426 19.67C0.751262 19.961 0.117262 19.425 0.269262 18.822L0.299262 18.727L3.69426 9.99998L0.299262 1.26998L3.69426 9.99998ZM2.40226 2.53998L5.01226 9.24998H11.6393C11.8205 9.24998 11.9956 9.31562 12.1322 9.43474C12.2688 9.55387 12.3576 9.71842 12.3823 9.89797L12.3893 9.99998C12.3892 10.1814 12.3234 10.3566 12.2041 10.4932C12.0848 10.6298 11.92 10.7185 11.7403 10.743L11.6393 10.75H5.01026L2.40126 17.46L17.3223 9.99998L2.40126 2.53998H2.40226Z" />
        </svg>
    );
}

