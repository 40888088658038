/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    InputLabel,
    TextField,
    MenuItem
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputField} from "../components/inputs";
import classNames from "classnames";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";
import {capitalize} from "../helpers/functions";


export const DeviceModal = (props) => {
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const cancel = () => {
        props.onClose();
        setParams({});
    }

    const createDevice = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {type, message} = await $crud.post(!props.device?.id ? "create/web/device" : "update/web/device", {
                ...params,
            });
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }


    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {

                    if (props.device?.id){
                        setParams(props?.device);
                    }
                }
            }
        } TransitionComponent={Slide} fullWidth open={props.open}>
            <div className="tableHeader-color">
                <DialogTitle>{props?.device?.id ? "Update" : "Add"} Device</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Device<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={params?.deviceType || ""}
                                onChange={(e) => handleChange("deviceType", e.target.value)}
                            >
                                {["phone","laptop","tablet","computer"].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {capitalize(option)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Name<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.name || ""}
                                onChange={(e) => handleChange("name", e.target.value)}
                                placeholder="Enter Name"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Phone</InputLabel>
                            </Grid>
                            <TextField
                                value={params?.phone || ""}
                                onChange={(e) => handleChange("phone", e.target.value)}
                                placeholder="Enter Phone"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Name</InputLabel>
                            </Grid>
                            <TextField
                                value={params?.email || ""}
                                onChange={(e) => handleChange("email", e.target.value)}
                                placeholder="Enter Email"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Outgoing</InputLabel>
                            </Grid>
                            <TextField
                                value={params?.outgoing || ""}
                                onChange={(e) => handleChange("outgoing", e.target.value)}
                                placeholder="NA"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                        <Button className="ms-2" onClick={() => {
                            createDevice();
                        }} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};
