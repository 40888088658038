import React, {useEffect, useState} from "react";
import {LeadModal} from "../dialogs/leadModal";
import LeadForm from "../dialogs/LeadForm";
import {Grid} from "@mui/material";
import {$crud} from "../services/CrudFactory";
import {useSelector} from "react-redux";
import {useDataParams, usePagination} from "../hooks";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";
import {FilterChipContainer} from "../components";
import {isEmpty} from "lodash";
import {useParams} from "react-router-dom";

export function TodayFollowUp() {
    const [showLeadModal, setShowLeadModal] = useState(false);
    const [showLeadForm, setShowLeadForm] = useState(false);
    const [filtersWithValues, setFiltersWithValues] = useState({});
    const [leadId, setLeadId] = useState(null);
    const [leads, setLeads] = useState([]);

    const {
        limit,
        page,
        setTotal,
        total,
        setLimit,
        setPage
    } = usePagination();
    const {setParams} = useDataParams();
    const {id} = useParams();
    const renderState = useSelector((state) => state.renderLead.render1);
    const filters = useSelector(state => state.filterReducer);


    const retrieveCustomFilter = async () => {
        const {
            data: {customFilter}
        } = await $crud.retrieve("web/custom-filter", {id});
        return customFilter;
    };


    const retrieveStudentLeads = async (customFilters) => {
        const isFilersApplied = {
            filters: !isEmpty(JSON.parse(customFilters))
        };
        const filters =JSON.parse(customFilters);
        const {data: {leads, total}} = await $crud.retrieve("web/fresh-leads", {
            ...filters,
            ...isFilersApplied,
            page,
            limit,
        });
        return {leads, total};
    };


    useEffect(() => {
        setFiltersWithValues(filters?.withValues);
    }, [filters]);


    useEffect(() => {
        retrieveCustomFilter(id).then(res => {
            if (!res) return;

            retrieveStudentLeads(res.filtersWithValues).then(res => {
                setLeads(res?.leads);
                setTotal(res?.total);
            }).catch(error => console.error(error));
        });
    }, [id, limit, page, filtersWithValues, renderState]);

    return (
        <>
            <Grid className="container-fluid top-navbar ">
                <FilterChipContainer/>
                <DynamicTable
                    rows={leads}
                    columns={columns?.studentLead}
                    total={total}
                    style={{
                        maxHeight: "calc(100vh - 250px)",
                        position: "fixed",
                        top: "150px",
                        width: "calc(100% - 24px)"
                    }}
                    onEdit={(id) => {
                        setShowLeadForm(true);
                        setLeadId(id);
                    }}
                    onRowClick={(id) => {
                        setShowLeadModal(true);
                        setLeadId(id);
                    }}
                    paginationProps={{
                        limit: limit,
                        page: page,
                        onChangeLimit: (limit) => setLimit(limit),
                        onChangePage: (page) => setPage(page),
                    }}
                />

            </Grid>

            <LeadModal
                anchor="right"
                open={showLeadModal}
                onClose={() => {
                    setShowLeadModal(false);
                    setLeadId(null);
                    setParams({});
                }}
                leadId={leadId}

            />


            <LeadForm
                leadId={leadId}
                anchor="right"
                open={showLeadForm}
                onClose={() => {
                    setShowLeadForm(false);
                    setLeadId(null);
                    setParams({});
                }}
                onOpen={(leadId) => {
                    setShowLeadModal(true);
                    setLeadId(leadId);
                }}
                onNew={() => {
                    setParams({});

                }}
            />


        </>
    );
}


