import * as React from "react";
import {Button, DialogTitle, DialogContent, DialogActions, Dialog, DialogContentText} from "@mui/material";

export function ConfirmationModal(props) {
    const {onClose, title, content, buttonText, open, onOk} = props;

    return (

        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            PaperProps={{
                className:"tableHeader-color",
            }}
            aria-describedby="alert-dialog-description"
        >
            <div className="tableHeader-color">
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained">Cancel</Button>
                    <Button onClick={onOk} autoFocus variant="contained">
                        {buttonText || "Yes"}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}
