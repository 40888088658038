/* eslint-disable */
import React, {useEffect, useState} from "react";
import {$crud} from "../../../services/CrudFactory";
import {useDispatch} from "react-redux";
import {usePagination} from "../../../hooks";
import DynamicTable from "../../../components/DynamicTable";
import {columns} from "../../../helpers/tableData";

export function CallDetails(lead) {
    const dispatch = useDispatch();
    const [callLogs, setCallLogs] = useState([]);
    const {limit, page, total, setTotal, setLimit, setPage} = usePagination();
    const retrieveCalling = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {data} = await $crud.retrieve("web/call-logs-by-lead-id", {
                leadId: lead?.lead?.id,
                limit,page
            });
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };


    useEffect(() => {
        retrieveCalling().then(res=> {
            setCallLogs(res.callLogs)
            setTotal(res.total)
        })
    }, [limit, page]);
    return (
        <DynamicTable
            rows={callLogs}
            columns={columns?.callLogs}
            style={{maxHeight: "calc(100vh - 102px)"}}
            total={total}
            onRowClick={() => {}}
            onStatusChange={() => {}}
            onEdit={(value) => {}}
            onDelete={(id)=> {}}
            paginationProps={{
                limit: limit,
                page: page,
                onChangeLimit: (limit) => setLimit(limit),
                onChangePage: (page) => setPage(page),
            }}
        />

    );
}
