import React from "react";

export function VisitedIcon() {
    return (
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
         <path d="M18.7496 0C18.418 0 18.0999 0.131737 17.8654 0.366231C17.631 0.600724 17.4992 0.918766 17.4992 1.25039V6.25195C17.4992 6.58357 17.3675 6.90161 17.133 7.13611C16.8985 7.3706 16.5805 7.50234 16.2488 7.50234H4.25759L5.8831 5.88934C6.11855 5.65388 6.25083 5.33454 6.25083 5.00156C6.25083 4.66858 6.11855 4.34924 5.8831 4.11378C5.64765 3.87833 5.3283 3.74605 4.99532 3.74605C4.66234 3.74605 4.343 3.87833 4.10755 4.11378L0.356378 7.86495C0.242542 7.98387 0.153308 8.12409 0.0937961 8.27758C-0.0312654 8.582 -0.0312654 8.92345 0.0937961 9.22788C0.153308 9.38136 0.242542 9.52159 0.356378 9.6405L4.10755 13.3917C4.22379 13.5089 4.36208 13.6019 4.51445 13.6654C4.66682 13.7289 4.83026 13.7615 4.99532 13.7615C5.16039 13.7615 5.32382 13.7289 5.47619 13.6654C5.62857 13.6019 5.76686 13.5089 5.8831 13.3917C6.0003 13.2754 6.09332 13.1371 6.1568 12.9848C6.22028 12.8324 6.25296 12.669 6.25296 12.5039C6.25296 12.3388 6.22028 12.1754 6.1568 12.023C6.09332 11.8707 6.0003 11.7324 5.8831 11.6161L4.25759 10.0031H16.2488C17.2437 10.0031 18.1978 9.60791 18.9013 8.90443C19.6048 8.20095 20 7.24682 20 6.25195V1.25039C20 0.918766 19.8683 0.600724 19.6338 0.366231C19.3993 0.131737 19.0812 0 18.7496 0Z" />
     </svg>
    );
}


