/* eslint-disable */
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function DocumentPreviewCard({base64, name, message, mimetype}) {
    // Creating a style for the aspect ratio
    const aspectRatioStyle = {
        paddingTop: '50%', // for 2:1 aspect ratio, calculate as (height / width * 100)%
        position: 'relative',
        width: '100%'
    };

    const contentStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    return (
        <Card variant="outlined" sx={{ width: 200 }}>
            <Box sx={aspectRatioStyle}>
                <Box sx={contentStyle}>
                    {mimetype.split("/")[1] === "pdf" ? (
                        <PictureAsPdfIcon sx={{ fontSize: 60 }} />
                    ) : (
                        <img
                            src={`data:${mimetype};base64,${base64}`}
                            srcSet={`data:${mimetype};base64,${base64} 2x`}
                            loading="lazy"
                            alt="Image Loading Error"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </Box>
            </Box>
            <CardContent>
                <Typography variant="body2">
                    <Link href={`data:${mimetype};base64,${base64}`} download={name}>{name}</Link>
                </Typography>
            </CardContent>
            <Divider variant="middle" />
            <CardContent>
                <Typography variant="body2">{message}</Typography>
            </CardContent>
        </Card>
    );
}
