const initialState = [];

const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "COURSES":
            return (state = action.payload);
        default:
            return state;
    }
};


export default courseReducer;