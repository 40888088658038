/* eslint-disable */

import React, {useEffect, useState, useMemo, useRef,useCallback} from "react";
import {CircularProgress, Dialog, DialogProps, Grid, IconButton, Typography, Button} from "@mui/material";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L, {LatLngTuple, LeafletEventHandlerFnMap} from "leaflet";
import "leaflet/dist/leaflet.css";
import {Close} from "@mui/icons-material";
import moment from "moment";

const activeIcon = L.divIcon({
    className: 'custom-marker-icon',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    html: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="52" viewBox="0 0 256 256" xml:space="preserve">\n' +
        '\n' +
        '<defs>\n' +
        '</defs>\n' +
        '<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">\n' +
        '\t<path d="M 45 90 c -1.415 0 -2.725 -0.748 -3.444 -1.966 l -4.385 -7.417 C 28.167 65.396 19.664 51.02 16.759 45.189 c -2.112 -4.331 -3.175 -8.955 -3.175 -13.773 C 13.584 14.093 27.677 0 45 0 c 17.323 0 31.416 14.093 31.416 31.416 c 0 4.815 -1.063 9.438 -3.157 13.741 c -0.025 0.052 -0.053 0.104 -0.08 0.155 c -2.961 5.909 -11.41 20.193 -20.353 35.309 l -4.382 7.413 C 47.725 89.252 46.415 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #7600CC; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>\n' +
        '\t<path d="M 45 45.678 c -8.474 0 -15.369 -6.894 -15.369 -15.368 S 36.526 14.941 45 14.941 c 8.474 0 15.368 6.895 15.368 15.369 S 53.474 45.678 45 45.678 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>\n' +
        '</g>\n' +
        '</svg>'
});

const inActiveIcon = L.divIcon({
    className: 'custom-marker-icon',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    html: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="52" viewBox="0 0 256 256" xml:space="preserve">\n' +
        '\n' +
        '<defs>\n' +
        '</defs>\n' +
        '<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">\n' +
        '\t<path d="M 45 90 c -1.415 0 -2.725 -0.748 -3.444 -1.966 l -4.385 -7.417 C 28.167 65.396 19.664 51.02 16.759 45.189 c -2.112 -4.331 -3.175 -8.955 -3.175 -13.773 C 13.584 14.093 27.677 0 45 0 c 17.323 0 31.416 14.093 31.416 31.416 c 0 4.815 -1.063 9.438 -3.157 13.741 c -0.025 0.052 -0.053 0.104 -0.08 0.155 c -2.961 5.909 -11.41 20.193 -20.353 35.309 l -4.382 7.413 C 47.725 89.252 46.415 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #808080; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>\n' +
        '\t<path d="M 45 45.678 c -8.474 0 -15.369 -6.894 -15.369 -15.368 S 36.526 14.941 45 14.941 c 8.474 0 15.368 6.895 15.368 15.369 S 53.474 45.678 45 45.678 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>\n' +
        '</g>\n' +
        '</svg>'
});


export function MapModal({branches, branch, ...props}) {
    const [draggable, setDraggable] = useState(false)
    const [position, setPosition] = useState([]);
    const [map, setMap] = useState();
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                }
            },
        }),
        [],
    )
    const toggleDraggable = useCallback(() => {
        setDraggable((d) => !d)
    }, [])
    const getLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((coordinates) => {
                setPosition([coordinates.coords.latitude, coordinates.coords.longitude]);

            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }

    }

    useEffect(() => {
        if (branch?.id) {
            setPosition(branch?.location?.split(","));
        } else {
            getLocation()
        }
    }, [map, branches]);
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            {...props}
        >
            <Grid item container alignItems="center" className="p-2">
                <Grid item xs>
                    location
                </Grid>
                <IconButton color="primary" onClick={() => props.onClose(null, null)}><Close/></IconButton>
            </Grid>
            <Grid container direction="column">
                {
                    position ?
                        <MapContainer
                            ref={setMap}
                            // bounds={branches.filter(s => !!s.location).map(branch => branch.location?.split(",").map(Number))}
                            bounds={[]}
                            center={position}
                            zoom={13}
                            scrollWheelZoom={true}
                            style={{height: 400}}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {!!branch?.id ? <Marker
                                    position={position}
                                    icon={branch?.isActive ? activeIcon : inActiveIcon}
                                    eventHandlers={{
                                        click: () => {
                                        }
                                    }}
                                >
                                    <Popup>
                                        <Typography variant="subtitle1">Name: {branch?.name}</Typography>
                                        <Typography variant="subtitle1">City: {branch?.city || "N/A"}</Typography>
                                        <Typography variant="subtitle1">Address: {branch?.address || "N/A"}</Typography>
                                        <Typography variant="subtitle1">Mobile1: {branch?.mobile1 || "N/A"}</Typography>
                                        <Typography variant="subtitle1">Mobile2: {branch?.mobile2 || "N/A"}</Typography>
                                        <Typography
                                            variant="subtitle1">Status: {branch?.isActive ? "Active" : "InActive"}</Typography>

                                    </Popup>
                                </Marker> :
                                <Marker
                                    draggable={draggable}
                                    eventHandlers={eventHandlers}
                                    position={position}
                                    icon={inActiveIcon}
                                    ref={markerRef}
                                >
                                    <Popup minWidth={90}>
                                        <span onClick={toggleDraggable}>
                    {draggable
                        ? 'Marker is draggable'
                        : 'Click here to make marker draggable'}
        </span>
                                    </Popup>
                                </Marker>
                            }


                        </MapContainer>
                        :
                        <Grid container justifyContent="center" alignItems="center">
                            <CircularProgress size={30}/>
                            <Typography className="p-3">Getting your location</Typography>
                        </Grid>
                }
            </Grid>
            <Grid item xs={12} p={2}>
                <Button variant="contained" sx={{float: "right"}}>
                    Save
                </Button>
            </Grid>
        </Dialog>
    )
}















