/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  OutlinedInput,
  Box,
  Switch,
} from "@mui/material";
import moment from "moment";
import { $crud } from "../services/CrudFactory";
import { handleCopyLink } from "../helpers/functions";
import { ContentCopy, EmailOutlined } from "@mui/icons-material";
import { array } from "@amcharts/amcharts5";
import { useDataParams } from "../hooks";
import whatsAppIcon from "../images/whatsAppIcon.svg";


export function VisitsAndDemos({ lead }) {
  const dispatch = useDispatch();
  const [visits, setVisits] = useState([]);
  const [Demos, setDemos] = useState([]);
  const [visitDone, setVisitDone] = useState([]);
  const [demoDone, setDemoDone] = useState([]);
  const visitAndDemos = useSelector(state=>state.visitAndDemoReducer);
  const [link , setLink] = useState("");
  const message = encodeURIComponent(link);
  const [scheduleVisitAndDemos, setScheduleVisitAndDemo] = useState("");
  // console.log(visitDone, "-------visitDone");

  // const changeVisitStatus = async (isChecked, id) => {
  //   const { data } = await $crud.post("create/web/status/visited-lead", {
  //     visited: isChecked,
  //     id,
  //   });
  //   retrieveData();

  //   // setVisits((prev) => {
  //   //   const updatedVisits = prev?.map((visit) => {
  //   //     return visit.id === id ? data?.lead : visit;
  //   //   });
  //   //   return updatedVisits;
  //   // });
  // };
  // const changeDemoStatus = async (isChecked, id) => {
  //   const { data } = await $crud.post("create/web/status/demo-lead", {
  //     demoDone: isChecked,
  //     id,
  //   });
  //   retrieveData();
  //   // setDemos((prev) => {
  //   //   const updatedDemos = prev?.map((demo) => {
  //   //     return demo.id === id ? data?.lead : demo;
  //   //   });
  //   //   return updatedDemos;
  //   // });
  // };

  const mailVisitAndDemoLink = async (linkType, linkUrl) => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const { type, message } = await $crud.post(`create/web/send-link-gmail`, {
        id: lead.id,
        link : linkUrl,
        type:linkType
      });
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "success", message: message },
      });
    } catch (error) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: error.message },
      });
    }finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  }

  const retrieveData = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const { data } = await $crud.retrieve(`web/demo-leads`, {
        leadId: lead.id,
        
      });
      // console.log(data, "---------data");
      if (data) {
        setDemos(data.demoLeads);
        setVisits(data.scheduleVisitedLead);
        setVisitDone(data.visitedLead);
        setDemoDone(data.demoDoneLead);
      }
      return data;
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  useEffect(() => {
    if (lead?.id) {
      retrieveData();
    }
  }, [lead?.id, visitAndDemos?.result]);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    const divElement = document.getElementsByClassName("visitanddemo");

    Array.from(divElement).map((ele) => {
      if (ele) {
        if (windowWidth >= 770 && windowWidth < 1200) {
          ele.style.height = "400px";
        } else {
          ele.style.height = "650px";
        }
      }
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid container spacing={1} mt={3}>
      <Grid
        item
        xs={6}
        lg={3}
        xl={3}
        className="visitanddemo"
        sx={{ overflowY: "scroll" }}
      >
        <Grid item xs={12} my={2} sx={{ textAlign: "center" }}>
          <Typography variant="h4">Schedule Visit</Typography>
        </Grid>
        {visits?.map((visit, index) => {
          // console.log(visit?.visited, "---------visit?.visited");
          // setScheduleVisitAndDemo(visit?.link)
          return (
            <Card sx={{ mt: 1 }} className="message-box">
              {visit?.link && (
                <Grid item>
                  <OutlinedInput
                    fullWidth
                    size="small"
                    value={visit?.link}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCopyLink(visit?.link);
                        }}
                      >
                        <ContentCopy />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </Grid>

              )}
              {/* <CardContent> */}
                {/* <Grid container rowSpacing={1} columnSpacing={4}> */}
                  <Grid item mt={1} mb={1}>
                    Date: {moment(visit.visitedAt).format("DD-MM-YYYY")}
                  </Grid>
                {/* </Grid> */}
              {/* </CardContent> */}
              <Grid item sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <Grid item sx={{display:"flex"}}>
                  <Typography mr={1}>Is Visited Done</Typography>
                  <Switch
                    checked={visit?.visited}
                    // onChange={(e) =>  changeVisitStatus(index, e.target.checked, visit?.id)}
                    // onChange={() => changeDemoStatus(Demo?.demoDone, Demo?.id)}
                    size="small"
                    color="primary"
                  />
                </Grid>
                
              </Grid>
              <Grid item sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <Grid item sx={{display:"flex"}}>
                  <Typography mr={1}>Reach : {visit?.reach}</Typography>
                </Grid>
              </Grid>
              {
                !visit?.visitedAt && <Grid item sx={{display:"flex", alignItems:"center"}}>
                <Tooltip title="Send link to WhatsApp" placement="bottom" onClick={()=> setLink(visit?.link)}>
                      <a href={`https://wa.me/${lead?.mobile1}?text=${message}`} target="_blank">
                          {/*<WhatsApp sx={{mr: 2, cursor: "pointer", color: "green"}}/>*/}
                          <div style={{marginRight: 15, cursor: "pointer"}}>
                              <img src={whatsAppIcon} width={"30px"} height={"30px"}/>
                          </div>
                      </a>
                </Tooltip>
                <Tooltip  title="Send link to Email" placement="bottom" >
                  <img width="40" height="40" style={{cursor:"pointer"}} src="https://img.icons8.com/fluency/48/gmail-new.png" alt="gmail-new" 
                  onClick = {()=>{mailVisitAndDemoLink("visit", visit?.link)}}/>
                </Tooltip>
                </Grid>
              }
              {/* {visit?.visitedAt && (
                <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography mr={1}>Is Visited</Typography>
                  <Switch
                    checked={visit?.visited}
                    // onChange={(e) =>  changeVisitStatus(index, e.target.checked, visit?.id)}
                    // onChange={() =>
                    //   changeVisitStatus(visit?.visited, visit?.id)
                    // }
                    size="small"
                    color="primary"
                  />
                </Grid>
              )} */}
            </Card>
          );
        })}
      </Grid>
      {visitDone?.length > 0 && (
        <Grid
          item
          xs={6}
          lg={3}
          xl={3}
          className="visitanddemo"
          sx={{ overflowY: "scroll" }}
        >
          <Grid item xs={12} my={2} sx={{ textAlign: "center" }}>
            <Typography variant="h4">Visited</Typography>
          </Grid>
          {visitDone?.map((visit) => {
            return (
              <Card sx={{ mt: 1 }} className="message-box">
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={4}>
                    <Grid item>Branch: {visit?.branch?.name || "N/A"}</Grid>
                    <Grid item>
                      Date: {moment(visit.visitedAt).format("DD-MM-YYYY")}
                    </Grid>
                    <Grid item>
                      Attended By: {visit?.attendedby?.name || "N/A"}
                    </Grid>
                    <Grid item>Reason For Visit: {visit.reason}</Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Grid>
      )}

      <Grid
        item
        xs={6}
        lg={3}
        xl={3}
        className="visitanddemo"
        sx={{ overflowY: "scroll" }}
      >
        <Grid item xs={12} my={2} sx={{ textAlign: "center" }}>
          <Typography variant="h4">Schedule Demo</Typography>
        </Grid>
        {Demos?.map((Demo) => {
          return (
            <Card sx={{ mt: 1 }} className="message-box">
              {Demo.link && (
                <Grid item>
                  <OutlinedInput
                    fullWidth
                    size="small"
                    value={Demo.link}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setLink(Demo?.link);
                          handleCopyLink(Demo?.link);
                        }}
                      >
                        <ContentCopy />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </Grid>
                
              )}
              {/* <CardContent> */}
                {/* <Grid container rowSpacing={1} columnSpacing={4}> */}
                  {/* <Grid item>Branch: {Demo?.branch?.name}</Grid>
                  <Grid item>Branch: {Demo?.course?.name}</Grid> */}
                  <Grid item mt={1} mb={1}>
                    Date: {moment(Demo.demoOn).format("DD-MM-YYYY")}
                  </Grid>
                  {/* <Grid item>
                    Assigned By: {Demo?.assignee?.name || "Name"}
                  </Grid>
                  <Grid item>Assigned To: {Demo?.assignedTo || "Name"}</Grid>
                  <Grid item>Description: {Demo.description}</Grid> */}
                {/* </Grid> */}
              {/* </CardContent> */}
              <Grid item sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <Grid item sx={{display:"flex"}}>
                  <Typography mr={1}>Is Demo Done</Typography>
                  <Switch
                    checked={Demo?.demoDone}
                    // onChange={(e) =>  changeVisitStatus(index, e.target.checked, visit?.id)}
                    // onChange={() => changeDemoStatus(Demo?.demoDone, Demo?.id)}
                    size="small"
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid item sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <Grid item sx={{display:"flex"}}>
                  <Typography mr={1}>Reach : {Demo?.reach}</Typography>
                </Grid>
              </Grid>
              {!Demo?.demoOn && <Grid item sx={{display:"flex", alignItems:"center"}}>
                <Tooltip title="Send link to WhatsApp" placement="bottom" onClick={()=> setLink(Demo?.link)}>
                      <a href={`https://wa.me/${lead?.mobile1}?text=${message}`} target="_blank">
                          <div style={{marginRight: 15, cursor: "pointer"}}>
                              <img src={whatsAppIcon} width={"30px"} height={"30px"}/>
                          </div>
                      </a>
                </Tooltip>
                <Tooltip  title="Send link to Email" placement="bottom" onClick = {()=>{
                  mailVisitAndDemoLink("demo", Demo?.link);
                  }}>
                  <img width="40" height="40" style={{cursor:"pointer"}} src="https://img.icons8.com/fluency/48/gmail-new.png" alt="gmail-new"/>
                </Tooltip>
                </Grid>}
            </Card>
          );
        })}
      </Grid>
      {demoDone?.length > 0 && (
        <Grid
          item
          xs={6}
          lg={3}
          xl={3}
          className="visitanddemo"
          sx={{ overflowY: "scroll" }}
        >
          <Grid item xs={12} my={2} sx={{ textAlign: "center" }}>
            <Typography variant="h4">Demo Done</Typography>
          </Grid>
          {demoDone?.map((Demo) => {
            return (
              <Card sx={{ mt: 1 }} className="message-box">
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={4}>
                    <Grid item>Branch: {Demo?.branch?.name}</Grid>
                    <Grid item>CourseName: {Demo?.course?.name}</Grid>
                    <Grid item>
                      Date: {moment(Demo.demoOn).format("DD-MM-YYYY")}
                    </Grid>
                    <Grid item>
                      Assigned By: {Demo?.assignee?.name || "N/A"}
                    </Grid>
                    <Grid item>Assigned To: {Demo?.assignTo || "N/A"}</Grid>
                    <Grid item>Description: {Demo.description}</Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
