import * as React from "react";
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    DialogContentText,
    Slide,
    Box,
    Grid, Typography
} from "@mui/material";
import moment from "moment";
import socket from "../socket";
import {$crud} from "../services/CrudFactory";
import {useDispatch, useSelector} from "react-redux";
import {setFollowUpLead, setFollowUpLeadId, setFollowUpModalOpen} from "../redux/actions";

export function RealTimePopup(props) {

    const {title} = props;
    const openModal = useSelector(state => state.followupReducer).openModal;
    const data = useSelector(state => state.followupReducer).lead;
    const dispatch = useDispatch();

    const onOk = async (schedule) => {
        try {
            dispatch({
                type: "LOADER",
            });
            const {type, message} = await $crud.post("update/web/schedule", {id: data?.id, schedule});
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });
            socket.emit("Rescheduled", "hello");
            // dispatch(setFollowUpModalOpen(false));
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    socket.on("lead", (data) => {
        dispatch(setFollowUpLead(data?.data));
        dispatch(setFollowUpModalOpen(true));
    });

    socket.on("closeLeads", ({data}) => {
        // dispatch(setFollowUpLead({}));
        if (!data) dispatch(setFollowUpModalOpen(data));
    });

    return (
        <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            maxWidth={"md"}
            TransitionComponent={Slide}
            fullWidth
            aria-describedby="alert-dialog-description"
        >
            <Box className="tableHeader-color">
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component={"span"}>ID: </Typography>
                                <Typography variant="subtitle2"
                                            component={"span"}>{data?.id || "1111"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component={"span"}>Phone2: </Typography>
                                <Typography variant="subtitle2"
                                            component={"span"}>{data?.mobile1 || "9999999999"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component={"span"}>Phone2: </Typography>
                                <Typography variant="subtitle2"
                                            component={"span"}>{data?.mobile2 || "N/A"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {/*<Typography variant="subtitle1">Phone 2- {data?.mobile2 || "N/A"}</Typography>*/}
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component={"span"}>Name: </Typography>
                                <Typography variant="subtitle2"
                                            component={"span"}>{data?.name || "FirstName LastName"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component={"span"}>Future Schedule: </Typography>
                                <Typography variant="subtitle2"
                                            component={"span"}>{moment(data?.schedule).format("DD/MM/YYYY | HH:mm A") || moment().format("DD/MM/YYYY | hh:mm A")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component={"span"}>Name: </Typography>
                                <Typography variant="subtitle2"
                                            component={"span"}>{data?.name || "FirstName LastName"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component={"span"}>Last Response: </Typography>
                                <Typography variant="subtitle2"
                                            component={"span"}>{data?.lead_notes_follow_up?.sort((a, b) => a.id + b.id)[0].note || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component={"span"}>Global Note: </Typography>
                                <Typography
                                    variant="subtitle2"
                                    component={"span"}>{data?.globalNote || "N/A"}</Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => onOk(5)}>Remind In 5 minute</Button>
                    <Button variant="contained" onClick={() => onOk(10)}>Remind In 10 minute</Button>
                    <Button variant="contained" onClick={() => onOk(15)}>Remind In 15 minute</Button>
                    <Button variant="contained" onClick={() => {
                        dispatch(setFollowUpLeadId(data?.id));
                        dispatch(setFollowUpModalOpen(false));
                    }}>Take Followup</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}