/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    Checkbox,
    Select,
    MenuItem,
    FormControlLabel,
    ListItemText,
    Box, InputLabel, TextField
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputField} from "../components/inputs";
import classNames from "classnames";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";
import _ from "lodash";


export const RolePermissionModal = (props) => {
    const dispatch = useDispatch();
    const [permissions, setPermissions] = useState([]);
    const [allPermissionHeading, setAllPermissionHeading] = useState([]);
    const [partialPermissionHeading, setPartialPermissionHeading] = useState([]);
    const {params, handleChange, setParams} = useDataParams();
    const [permissionIds, setPermissionIds] = useState([]);
    const cancel = () => {
        props.onClose();
        setParams({});
        setPermissionIds([]);
    };

    const retrievePermissions = async () => {
        const {
            data: {permissions},
        } = await $crud.retrieve("web/permissions");
        return permissions;
    };

    const createRolePermission = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post(
                !props.rolePermission?.id
                    ? "create/web/role-permission"
                    : "update/web/role-permission",
                {
                    ...params,
                    permissionIds
                }
            );
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };

    const handleCheckedAll = (key) => {
        const permissionKeys = permissions?.find(p => p.heading === key)?.permission?.map(p => p.id);
        if (!allPermissionHeading.includes(key) || partialPermissionHeading.includes(key)) {
            setAllPermissionHeading([...allPermissionHeading, key])
            setPermissionIds(prev => {
                return _.union([...prev], permissionKeys);
            })
        } else {
            setPermissionIds(prev => {
                return _.pullAll([...prev], permissionKeys);
            })
            setAllPermissionHeading(_.pull(allPermissionHeading, key))
        }
    }


    useEffect(() => {
        permissions?.map(permission => {
            let length = _.difference(permission?.permission?.map(p => p.id), permissionIds).length;
            if (length === 0) {
                if (partialPermissionHeading.includes(permission?.heading)) setPartialPermissionHeading((prev) => _.pull([...prev], permission?.heading))
                setAllPermissionHeading((prev) => [...prev, permission?.heading]);
            } else if (length > 0 && length < 4) {
                if (allPermissionHeading.includes(permission?.heading)) setAllPermissionHeading((prev) => _.pull([...prev], permission?.heading))
                setPartialPermissionHeading((prev) => [...prev, permission?.heading])
            } else if (length === 4) {
                setPartialPermissionHeading((prev) => _.pull([...prev], permission?.heading))
                setAllPermissionHeading((prev) => _.pull([...prev], permission?.heading))
            }
        })
    }, [permissions, permissionIds])

    return (
        <Dialog
            maxWidth={"xs"}
            TransitionProps={{
                onEnter: () => {
                    retrievePermissions().then(res => setPermissions(res));
                    if (props.rolePermission?.id) {
                        setParams(props?.rolePermission);
                        setPermissionIds( 
                            props?.rolePermission.permissions.map((item) =>
                                item.id
                            )
                        );
                    }
                },
            }}
            TransitionComponent={Slide}
            fullWidth
            open={props.open}
        >
            <div className="tableHeader-color">
                <DialogTitle>
                    Add Role Permissions
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Name<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                value={params?.name || ""}
                                placeholder="Enter Name"
                                onChange={(e) => handleChange("name", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <label className={"form-label"}>
                                Permissions
                            </label>

                            <Select
                                margin="dense"
                                color="primary"
                                multiple
                                onChange={(e) => setPermissionIds(e.target.value)}
                                value={permissionIds || []}
                                renderValue={(selected) => selected.join(", ").toUpperCase()}
                                size="small"
                                fullWidth

                            >
                                {permissions?.map((permission) => (
                                    <div className="tableHeader-color" style={{padding: "16px"}}>
                                        <FormControlLabel
                                            label={permission?.heading?.toUpperCase()}

                                            control={
                                                <Checkbox
                                                    checked={allPermissionHeading.includes(permission?.heading)}
                                                    indeterminate={partialPermissionHeading.includes(permission?.heading)}
                                                    onChange={() => handleCheckedAll(permission?.heading)}
                                                />
                                            }
                                        />
                                        <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                            {

                                                permission?.permission?.map(p => {
                                                    return (
                                                        <FormControlLabel
                                                            label={p.name}
                                                            control={
                                                                <Checkbox
                                                                    name={p.name}
                                                                    value={p.id}
                                                                    checked={permissionIds?.includes(p.id)}
                                                                    onChange={(e) => {
                                                                        if (typeof +e.target.value === "number") {
                                                                            setPermissionIds(prev => {
                                                                                const data = [...prev];
                                                                                if (data?.includes(+e.target.value)) {
                                                                                    return _.pull(data, +e.target.value)
                                                                                } else {
                                                                                    return [...data, +e.target.value]
                                                                                }
                                                                            })
                                                                        }
                                                                    }}

                                                                />
                                                            }
                                                        />
                                                    )
                                                })
                                            }
                                        </Box>
                                    </div>
                                ))}
                            </Select>


                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs
                        className="d-flex justify-content-end align-items-center mt-3"
                    >
                        <Button
                            variant="outlined"
                            onClick={cancel}
                            color="primary"
                            size="small"
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ms-2"
                            onClick={() => {
                                createRolePermission();
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Submit
                        </Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};
