import React from "react";

export function Attachment() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" >
            <path d="M2.9166 10.2948C2.84031 10.3726 2.74927 10.4343 2.64883 10.4765C2.54838 10.5186 2.44054 10.5404 2.3316 10.5404C2.22267 10.5404 2.11483 10.5186 2.01438 10.4765C1.91393 10.4343 1.8229 10.3726 1.7466 10.2948C1.59131 10.1368 1.50429 9.92414 1.50429 9.7026C1.50429 9.48106 1.59131 9.26839 1.7466 9.1104L8.9838 1.8C10.6158 0.532802 12.2574 -0.0755981 13.8918 0.0072019C15.951 0.112802 17.5542 0.897602 18.915 2.1852C20.3022 3.498 21.1998 5.3712 21.1998 7.6416C21.1998 9.384 20.6934 10.9176 19.6242 12.2784L9.4206 22.7904C8.3886 23.6364 7.2414 24.0456 6.0126 23.9964C4.4526 23.9316 3.2922 23.4276 2.4294 22.5744C1.419 21.5772 0.799805 20.352 0.799805 18.7044C0.799805 17.394 1.2522 16.1844 2.1858 15.0492L11.1666 5.88C11.8866 5.112 12.591 4.656 13.311 4.5456C13.7916 4.46991 14.2834 4.51289 14.7435 4.67081C15.2036 4.82872 15.6182 5.09677 15.951 5.4516C16.5894 6.1272 16.863 6.9696 16.767 7.9284C16.701 8.5824 16.4334 9.1848 15.9414 9.762L7.6854 18.2196C7.60961 18.2976 7.51903 18.3598 7.41895 18.4025C7.31888 18.4452 7.21131 18.4675 7.10251 18.4682C6.99372 18.4688 6.88588 18.4478 6.78529 18.4064C6.6847 18.365 6.59336 18.3039 6.5166 18.2268C6.36034 18.0698 6.27203 17.8576 6.27068 17.6361C6.26933 17.4145 6.35507 17.2013 6.5094 17.0424L14.7258 8.628C14.9658 8.346 15.0918 8.0616 15.1218 7.7592C15.1698 7.2792 15.0546 6.9252 14.7558 6.6096C14.6049 6.44797 14.4164 6.32614 14.207 6.25496C13.9977 6.18378 13.774 6.16545 13.5558 6.2016C13.2642 6.246 12.8562 6.5112 12.3534 7.0464L3.4098 16.1748C2.7654 16.9608 2.4534 17.7948 2.4534 18.7056C2.4534 19.8504 2.8674 20.67 3.5838 21.3768C4.1538 21.9408 4.9278 22.2768 6.0798 22.3248C6.8958 22.3572 7.6518 22.0872 8.3094 21.5544L18.3858 11.1744C19.1598 10.182 19.5462 9.0144 19.5462 7.6428C19.5462 5.8644 18.8538 4.422 17.7846 3.4092C16.6926 2.3748 15.4446 1.7652 13.809 1.6812C12.5994 1.6188 11.331 2.0892 10.0686 3.0564L2.9166 10.296V10.2948Z"  />
        </svg>
    );
}

