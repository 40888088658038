/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    InputLabel,
    TextField,
    DialogActions,
    MenuItem,
    Autocomplete
} from "@mui/material"
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch, useSelector} from "react-redux";
import {$crud} from "../services/CrudFactory";
import {retrieveUsers} from "../pages";
import {users, visitAndDemoRetrieve} from "../redux/actions";
import moment from "moment";
import { formateDateAndTime } from "../helpers/functions";

export const DemoModal = (props) => {
    const [branches, setBranches] = useState([]);
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const attendents = useSelector(state=>state.usersReducer);
    const courses = useSelector(state=>state.courseReducer);
    const {leadId, open, onClose,onSubmit} = props;
    // console.log(params, "---------params");

    const cancel = () => {
        onClose();
        setParams({});
    }
    const getlastScheduleDemo = async () => {
        try {
          dispatch({
            type: "LOADER",
            loader: true,
          });
          const {
            data: { demoLeads },
          } = await $crud.retrieve("web/demoLead", { id:leadId });
          console.log(demoLeads, "---------lead");
          setParams({...demoLeads, demoOn:formateDateAndTime(demoLeads?.demoOn)})
          
        //   setParams({...demoLeads,demoOn:formateDateAndTime(demoLeads?.demoOn)});
        //   setParams({...demoLeads,demoOn:moment(demoLeads.demoOn).format("YYYY-MM-DD")});
        } catch (e) {
          dispatch({
            type: "SNACKBAR",
            snackBar: { type: "error", message: e.message },
          });
        } finally {
          dispatch({
            type: "LOADER",
            loader: false,
          });
        }
      };
    const retrieveBranches = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {branches, total}} = await $crud.retrieve("web/branches");
            setBranches(branches);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    const handleSubmit = async () =>{
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message, data: {demoLead}} = await $crud.post(
                "update/web/demo-lead",{
                    ...params,
                    leadId
                }
            );
            dispatch(visitAndDemoRetrieve());
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            retrieveUsers().then(res=>{
                dispatch(users(res.users))
            })
            onSubmit();
            cancel();
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
       open && retrieveBranches()
    }, []);

    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                    getlastScheduleDemo();
                    setParams({...params,leadId})
                }
            }
        } TransitionComponent={Slide} fullWidth open={open}>
            <div className="tableHeader-color">
                <DialogTitle>Lead's Demo Detail</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Demo At</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={`${params?.branchId}` || ""}
                                onChange={(e) => handleChange("branchId", e.target.value)}
                                sx={{
                                    svg: {color: "#fff"}
                                }}
                            >
                                {branches.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Demo On</InputLabel>
                            </Grid>
                            <TextField
                                size="small"
                                value={params?.demoOn || ""}
                                onChange={(e) => handleChange("demoOn", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                fullWidth
                                type="datetime-local"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Assigned From</InputLabel>
                            </Grid>

                            <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                size="small"
                                disableClearable
                                // value={params?.attendedBy || ""}
                                onChange={(event, newValue) => {
                                    handleChange("assignFrom", newValue.id);
                                }}
                                options={attendents}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                    return <Grid {...props}>{option?.name}</Grid>
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={(e) => {
                                            handleChange("assignFrom", e.target.value);
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Assigned To</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                onChange={(e)=>{
                                    handleChange("assignTo",e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Demo For</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                value={`${params?.courseId}` || ""}
                                onChange={(e) => handleChange("courseId", e.target.value)}
                                sx={{
                                    svg: {color: "#fff"}
                                }}
                            >
                                {courses.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Demo Description</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                size="small"
                                value={params?.description || ""}
                                onChange={(e)=>{
                                    handleChange("description",e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                    <Button className="ms-2" onClick={handleSubmit} variant="contained" color="primary" size="small">Submit</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};
