import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
const useStyles = makeStyles((theme)=>({
    textColor:{
        color:theme.palette.primary.light,
        fontSize:"14px"
    }
}));
export function SelectBox(props) {
    const styles = useStyles({});
    const {labelName,data,onChange} = props;
    return (
        <>
            <label htmlFor="exampleInputPhone" className={classNames(styles.textColor,"form-label")} style={{display:labelName?"block":"none"}}>
                {props.labelName}
            </label>
            <select onChange={e=>onChange(e.target.value)} className="form-select selectBox" style={props.style} aria-label="Default select example">
                <option selected>{props.selectName}</option>
                {
                    data?.map((selectBox, index) => {
                        return (
                            <option key={index} value={selectBox?.id}>{selectBox[props.keyAtt]}</option>
                        );
                    })
                }
            </select>
        </>
    );
}

