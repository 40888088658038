export const liData = [
    { liName: "News Letter" },
    { liName: "Development" },
    { liName: "Digital Marketing" },
    { liName: "Offer" },
    { liName: "Festival" },
    { liName: "Digital Marketing" },
    { liName: "Sent Mail" },
];


