import { useState } from "react";
import { ERRORS } from "../helpers/errors";

export const useDataParams = () => {
  const [params, setParams] = useState({});
  const [filters, setFilters] = useState({});
  const handleChange = (name, value) => {
    setParams((prev) => {
      return { ...prev, [name]: value };
    });
    handleBlur(name, value);
  };

  const validateEntries = (schema, { key, value }) => {
    const validatorForCurrentField = schema[key];
    if (
      validatorForCurrentField?.required &&
      (value === "" || value === undefined)
    ) {
      return `${validatorForCurrentField?.name} is Required`;
    } else if (validatorForCurrentField?.regex) {
      const isValid = validatorForCurrentField?.regex.test(value);
      if (!isValid) {
        return validatorForCurrentField?.errorText;
      }
    }
    return false;
  };
  const handleBlur = async (key, value, setParams, params) => {
    const isErrorOrValid = await validateEntries(ERRORS, { key, value });
    if (typeof isErrorOrValid === "string") {
      setParams((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            [key]: isErrorOrValid,
          },
        };
      });
    } else {
      if (params?.errors && params?.errors[key]) {
        delete params.errors[key];
      }
    }
  };

  const setFilter = (key, value) => {
    setFilters((prev) => {
      return { ...prev, [key]: value === "any" ? "" : value };
    });
  };

  return {
    params,
    setParams,
    handleChange,
    handleBlur,
    validateEntries,
    setFilter,
    filters,
  };
};
