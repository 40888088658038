import React from "react";

function InputField(props) {
    return (
        <>
            <div className="mb-3">
                <label htmlFor={props.id} className="form-label d-flex justify-content-between" style={{fontSize:"14px"}}>
                    {props.labelName}
                    {props.checkbox}
                </label>
                <input
                    type={props.Type}
                    className="form-control"
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    onChange={(e)=>props.onChange(e.target.value)}
                    aria-describedby="NameHelp"
                    placeholder={props.placeholderName}
                />
            </div>
        </>
    );
}

export default InputField;
