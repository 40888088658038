/* eslint-disable */
import "./App.css";
import Router from "./Route/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { CrudProvider } from "@crud/react";
import { store, persistor } from "./redux/store";
import { $crud } from "./services/CrudFactory";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const user_id = localStorage.getItem("user_id");
import socket from "./socket";

import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#fff",
          "& label.Mui-focused": {
            color: "#fff",
          },
          "&.MuiInputBase-root": {
            borderRadius: "0px",
            "& input": {
              color: "#fff",
            },
            "& fieldset": {
              borderColor: "#fff",
            },
            "&:hover fieldset": {
              borderColor: "#fff",
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small", // Set the default button size here (e.g., 'small', 'medium', 'large')
      },
    },
  },
  palette: {
    // primary: {
    //     light: "#7061f3",
    //     main: "#5C4FDD",
    //     dark: "#473cc1",
    // },
    primary: {
      light: "#a253ff",
      main: "#9747FF",
      dark: "#8d2afd",
    },
    secondary: {
      // light: "#29b30c",
      main: "#9EA2FF",
      // dark: "#1d6f0a",
    },
  },
  customColors: {
    green: {
      light: "#00BA341A",
      main: "#00BA34",
    },
    red: {
      light: "#FF3B3B1A",
      main: "#E92C2C",
    },
    yellow: {
      light: "#FF961B1A",
      main: "#FF9F2D",
    },
    purple: {
      light: "#9EA2FF1A",
      main: "#9EA2FF",
    },
    gray: {
      light: "#E8E8E8",
      main: "#585757",
    },
  },
});
// console.log(socket.connected,"socket state connected");
function App() {
  const loader = false;
  if (user_id && !socket.connected) {
    socket.auth.userId = user_id;
    socket.connect();
  }

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CrudProvider crud={$crud}>
            <ThemeProvider theme={theme}>
              <Router />
            </ThemeProvider>
          </CrudProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
