/* eslint-disable */
import React, {useEffect, useState} from "react";
import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, Grid,Slide} from "@mui/material";
import {$crud} from "../services/CrudFactory";




export function AnnouncementDetailsModal(props){
    // const [announcement, setAnnouncement] = useState([])
    const { open, close, announcement} = props;
    // const user_id = JSON.parse(localStorage.getItem('user_id'))
    // const retrieveAnnouncement = async () => {
    //     try {
    //         const {data:{announcements}} = await $crud.retrieve("web/show-announcements", { userId:user_id});
    //         return announcements;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };


    // useEffect(() => {
    //     retrieveAnnouncement().then(res => {
    //         setAnnouncement(res)
    //         // dispatch(current_user(user))
    //     });
    // }, [])
    
    return (
        <Dialog maxWidth={"sm"} TransitionComponent={Slide} fullWidth open={open}>
            <div className="tableHeader-color">
                <DialogTitle >Announcements</DialogTitle>
                <Divider/>
                <DialogContent sx={{maxHeight:"343px", overflowY:"scroll"}}>
                            {
                                announcement?.map((a) => (
                                    <Card sx={{width:"100%", mb:2, overflowX:"scroll",backgroundColor: '#673ab7', color: '#fff'}}>
                                    <CardContent>
                                        <Grid item xs={12} >
                                            <p>Topic : <span>{a.topic}</span></p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p>Topic Description : <span>{a.topicDescription}</span></p>
                                        </Grid>
                                    </CardContent>
                                    </Card>
                                ))
                            }
                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={()=>{close()}} color="primary" size="small">Cancel</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    )
}