const initialState = {
    render1: false,
};

const renderLead = (state = initialState, action) => {
    switch (action.type) {
        case "RENDERLEAD":
            return (state = {
                ...state,
                render1: !state.render1
            });
        default:
            return state;
    }
};

export default renderLead;
