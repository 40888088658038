import React from "react";

export function FeesChartIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM8 6C7.73478 6 7.48043 6.10536 7.29289 6.29289C7.10536 6.48043 7 6.73478 7 7C7 7.26522 7.10536 7.51957 7.29289 7.70711C7.48043 7.89464 7.73478 8 8 8H11C11.34 8 11.872 8.11 12.29 8.412C12.48 8.548 12.662 8.733 12.795 9H7.997C7.73178 9 7.47743 9.10536 7.28989 9.29289C7.10236 9.48043 6.997 9.73478 6.997 10C6.997 10.2652 7.10236 10.5196 7.28989 10.7071C7.47743 10.8946 7.73178 11 7.997 11H12.795C12.6784 11.2348 12.5052 11.4368 12.291 11.588C11.9104 11.849 11.4614 11.9923 11 12H7.997C7.78973 11.9999 7.58754 12.0642 7.41842 12.184C7.24929 12.3039 7.12158 12.4733 7.05295 12.6689C6.98431 12.8645 6.97815 13.0765 7.03531 13.2758C7.09246 13.475 7.21012 13.6516 7.372 13.781L12.375 17.781C12.4776 17.8631 12.5953 17.9241 12.7215 17.9607C12.8476 17.9973 12.9797 18.0087 13.1103 17.9942C13.2409 17.9797 13.3673 17.9396 13.4824 17.8763C13.5975 17.813 13.6989 17.7276 13.781 17.625C13.8631 17.5224 13.9241 17.4047 13.9607 17.2785C13.9973 17.1524 14.0087 17.0203 13.9942 16.8897C13.9797 16.7591 13.9396 16.6327 13.8763 16.5176C13.813 16.4026 13.7276 16.3011 13.625 16.219L10.848 14H10.998C11.659 14 12.627 13.81 13.458 13.211C14.1982 12.681 14.7117 11.8916 14.896 11H16C16.2652 11 16.5196 10.8946 16.7071 10.7071C16.8946 10.5196 17 10.2652 17 10C17 9.73478 16.8946 9.48043 16.7071 9.29289C16.5196 9.10536 16.2652 9 16 9H14.896C14.8211 8.65113 14.6976 8.3145 14.529 8H16C16.2652 8 16.5196 7.89464 16.7071 7.70711C16.8946 7.51957 17 7.26522 17 7C17 6.73478 16.8946 6.48043 16.7071 6.29289C16.5196 6.10536 16.2652 6 16 6H8Z" />
        </svg>
    );
}


