import React from "react";

export function ImageAttachment() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <g filter="url(#filter0_d_554_3228)">
                <rect x="10" y="6" width="50" height="50" rx="25" fill="#BE5ACB" />
                <path d="M60 31C60 24.3696 57.3661 18.0107 52.6777 13.3223C47.9893 8.63392 41.6304 6 35 6C28.3696 6 22.0107 8.63392 17.3223 13.3223C12.6339 18.0107 10 24.3696 10 31L35 31H60Z" fill="#AB45CB" />
                <path d="M44.3333 21.6666H25.6666C25.313 21.6666 24.9738 21.8071 24.7238 22.0572C24.4737 22.3072 24.3333 22.6463 24.3333 23V39C24.3333 39.3536 24.4737 39.6927 24.7238 39.9428C24.9738 40.1928 25.313 40.3333 25.6666 40.3333H44.3333C44.6869 40.3333 45.026 40.1928 45.2761 39.9428C45.5261 39.6927 45.6666 39.3536 45.6666 39V23C45.6666 22.6463 45.5261 22.3072 45.2761 22.0572C45.026 21.8071 44.6869 21.6666 44.3333 21.6666ZM28.9466 24.3333C29.3421 24.3333 29.7288 24.4506 30.0577 24.6704C30.3866 24.8901 30.643 25.2025 30.7943 25.5679C30.9457 25.9334 30.9853 26.3355 30.9082 26.7235C30.831 27.1114 30.6405 27.4678 30.3608 27.7475C30.0811 28.0272 29.7247 28.2177 29.3368 28.2949C28.9488 28.372 28.5467 28.3324 28.1812 28.1811C27.8158 28.0297 27.5034 27.7733 27.2836 27.4444C27.0639 27.1155 26.9466 26.7289 26.9466 26.3333C26.9466 25.8029 27.1573 25.2942 27.5324 24.9191C27.9074 24.544 28.4162 24.3333 28.9466 24.3333ZM26.9999 37V34.2666L30.9999 30.2133C31.1248 30.0891 31.2938 30.0194 31.4699 30.0194C31.646 30.0194 31.815 30.0891 31.9399 30.2133L33.6666 31.9L28.5466 37H26.9999ZM42.9999 37H30.4333L34.5866 32.8466L38.1866 29.2466C38.3115 29.1225 38.4805 29.0528 38.6566 29.0528C38.8327 29.0528 39.0017 29.1225 39.1266 29.2466L42.9999 33.12V37Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_554_3228" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_554_3228" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_554_3228" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
