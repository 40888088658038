/* eslint-disable */
import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, Divider, Grid, Button, Slide, InputLabel, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputField} from "../components/inputs";
import classNames from "classnames";
import {$crud} from "../services/CrudFactory";
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch} from "react-redux";
import {ImageUploader} from "../components/ImageUploader";
import {USER_PROFILE_BASE_URL} from "../constants";


export const BankDetailModal = (props) => {
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const cancel = () => {
        props.onClose();
        setParams({});
    }

    const createBranch = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const formData = new FormData();
            await Object.keys(params)?.map(key=>{
                formData.append(key,params[key]);
            })
            const {type, message} = await $crud.post(!props.bankDetail?.id ? "create/web/bank-detail" : "update/web/bank-detail", formData);
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                    if (props.bankDetail?.id){
                        setParams(props?.bankDetail);
                    }
                    // retrieveSources()
                }
            }
        } TransitionComponent={Slide} fullWidth open={props.open}>
            <div className="tableHeader-color">
                <DialogTitle >Add Bank Details</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Bank Logo<sup>*</sup></InputLabel>
                            </Grid>
                            <ImageUploader
                                required={true}
                                name="image"
                                id="image"
                                file={params?.image}
                                src={
                                    params?.image?.type ?
                                        `${URL.createObjectURL(params?.image)}`
                                        :
                                        params?.image ?
                                            `${USER_PROFILE_BASE_URL}${params?.image}`
                                            : ""
                                }
                                onClick={() =>
                                    setParams((prev) => {
                                        return {...prev, image: ""};
                                    })
                                }
                                onChange={(file) => {
                                    handleChange("image", file)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            {/*<InputField*/}
                            {/*    value={params?.accountName || ""}*/}
                            {/*    required={true}*/}
                            {/*    labelName="Account Number"*/}
                            {/*    onChange={value => handleChange("accountName", value)}*/}
                            {/*    placeholderName="Enter Account Number"/>*/}
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Account Number<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.accountNo || ""}
                                onChange={(e) => handleChange("accountNo", e.target.value)}
                                fullWidth
                                placeholder="Enter Account Number"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">IFSC Code<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.ifsc || ""}
                                onChange={(e) => handleChange("ifsc", e.target.value)}
                                fullWidth
                                placeholder="Enter IFSC Code"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Account Holder Name<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.holderName|| ""}
                                onChange={(e) => handleChange("holderName", e.target.value)}
                                fullWidth
                                placeholder="Enter Account Holder Name"
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Branch Name<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.branch|| ""}
                                onChange={(e) => handleChange("branch", e.target.value)}
                                fullWidth
                                placeholder="Enter Branch"
                                size="small"
                            />
                        </Grid>

                    </Grid>

                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                        <Button className="ms-2" onClick={() => {
                            createBranch();
                        }} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};
