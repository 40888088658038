/* eslint-disable*/
import React, {useEffect, useState} from "react";
import {Card, CardContent, Grid, Button, TextField, Typography, FormLabel, IconButton} from "@mui/material";
import {Visibility, Edit} from "@mui/icons-material";
import FirebaseRequestSend from "../services/firebase/FirebaseRequestSend";
import {useSelector, useDispatch} from "react-redux";
import {OtpModal, URLModal} from "../dialogs";
import FirebaseOtpCheck from "../services/firebase/FirebaseOtpCheck";
import {$crud} from "../services/CrudFactory";
import CryptoJS from "crypto-js";


export function URLs() {
    // const {url, userName, password} = props;
    const [urls, setUrls] = useState([]);
    const [id, setId] = useState(null);
    const [viewPassword, setViewPassword] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [showURLModal, setShowURLModal] = useState(false);
    const mobile = useSelector((state) => state.userReducer).mobileNo;
    const dispatch = useDispatch();
    const onShow = async () => {
        const response = await FirebaseRequestSend(mobile)
        if (response) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {
                    type: "success",
                    message: "OTP Sent Successfully"
                }
            })
            setShowOTPModal(true);
        } else {
            dispatch({
                type: "SNACKBAR",
                snackBar: {
                    type: "error",
                    message: "Something Went Wrong"
                }
            })
        }
    }
    const verifyOTP = async (otp) => {
        const {type, message} = await FirebaseOtpCheck(otp);
        if (type === "success") {
            dispatch({
                type: "SNACKBAR",
                snackBar: {
                    type: "success",
                    message: "OTP Verified Successfully"
                }
            })
            setViewPassword(true)
        } else {
            dispatch({
                type: "SNACKBAR",
                snackBar: {
                    type: "error",
                    message: "Wrong OTP"
                }
            })
        }
    }

    async function retrieveURLs() {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data} = await $crud.retrieve("web/server-user-urls");
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        retrieveURLs().then(res => {

            setUrls(res.map(r => {
                const bytes = CryptoJS.AES.decrypt(r.password, '123');
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                r.password = decryptedData
                return r
            }))
        });
    }, [])


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button variant="contained" onClick={() => setShowURLModal(true)}>ADD URL</Button>
            </Grid>
            {
                urls.map(({id, url, username, password}) => {
                    return <Grid item xs={3}> <Card className="tableHeader-color" sx={{position: "relative"}}>
                        <CardContent sx={{mt: 2}}>
                            {/*{url}*/}
                            <Typography variant="subtitle1">URL:- {url}</Typography>
                            <FormLabel>User Name</FormLabel>
                            <TextField
                                size="small"
                                sx={{color: "#fff"}}
                                disabled
                                fullWidth
                                value={username}
                                sx={{my: 1}}
                            />
                            <FormLabel>Password</FormLabel>
                            <TextField
                                size="small"
                                sx={{color: "#fff !important"}}
                                disabled
                                fullWidth
                                type={!viewPassword ? "password" : "text"}
                                value={password}
                                InputProps={{
                                    endAdornment: <Visibility id="eye-btn" onClick={onShow}></Visibility>
                                }}
                            />
                        </CardContent>
                        <IconButton
                            sx={{position: "absolute", right: 5, top: 5}}
                            onClick={() => {
                                setId(id);
                                setShowURLModal(true);
                            }}
                        >
                            <Edit/>
                        </IconButton>
                    </Card></Grid>
                })
            }
            <div id="recaptcha-container"></div>
            <OtpModal open={showOTPModal} onClose={() => setShowOTPModal(false)} onSave={(otp) => {
                verifyOTP(otp);
                setShowOTPModal(false);
            }}/>
            <URLModal
                open={showURLModal}
                onClose={() => {
                    setShowURLModal(false);
                    setId(null);
                }}
                onSubmit={(data) => {
                    setUrls(prev => [...([...prev].filter(d => d.id !== data?.id)), data])
                }}
                id={id}
            />
        </Grid>
    )
}