/* eslint-disable */
import React, {
    useEffect,
    // useRef,
    // useEffect
    useState
} from "react";
import {Dialog, DialogContent, DialogTitle, Divider, Slide} from "@mui/material";
import Box from "@mui/material/Box";
import {Document, Page, pdfjs} from "react-pdf";
import {COURSE_PDF_BASE_URL} from "../constants";
import IconButton from "@mui/material/IconButton";
import {ArrowBackIos, ArrowForwardIos} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {Loader} from "rsuite";
import CircularProgress from "@mui/material/CircularProgress";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();
export const PreviewCourseModule = (props) => {
    const {
        content,
        open, onClose, isPdf
    } = props;
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    const options = {
        standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
    };
    // const contentRef = useRef(null);
    const prevPage = () => {
        if (pageNumber > 1) setPageNumber(prev => prev - 1);
    }
    const nextPage = () => {
        if (pageNumber < numPages) setPageNumber(prev => prev + 1);
    }
    // useEffect(() => {
    //     let isCancelled = false;
    //     if (!isCancelled)
    //         pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    //
    //     return () => {
    //         isCancelled = true;
    //     }
    // }, []);
    return (
        <Dialog maxWidth={isPdf ? "sm" : "xs"} TransitionProps={
            {
                onEnter: () => {
                }
            }
        } TransitionComponent={Slide} fullWidth open={open} onClose={onClose}>
            <div className="tableHeader-color">
                <DialogTitle sx={{display: "flex", padding: 1, alignItems: "center", justifyContent: "space-between"}}>Course
                    Module Preview
                    {isPdf && <React.Fragment><Box>
                        <IconButton size="small" onClick={prevPage}>
                            <ArrowBackIos fontSize="inherit"/>
                        </IconButton>
                        <IconButton size="small" onClick={nextPage}>
                            <ArrowForwardIos fontSize="inherit"/>
                        </IconButton>
                    </Box>
                        <Typography variant="subtitle1">
                            Page {pageNumber} of {numPages}
                        </Typography></React.Fragment>}
                </DialogTitle>
                <Divider/>
                <DialogContent sx={{padding: isPdf ? "0" : null}}>
                    {!isPdf ?
                        <Box dangerouslySetInnerHTML={{__html: content}} sx={{width: "100%", height: "100%"}}></Box>
                        :
                        <div>
                            <Document file={`${COURSE_PDF_BASE_URL + content}`} onLoadSuccess={onDocumentLoadSuccess}
                                      options={options}>
                                <Page pageNumber={pageNumber} loading={<Box sx={{
                                    width: "595px",
                                    height: "842px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}><CircularProgress color="primary"/></Box>} renderTextLayer={false}
                                      renderAnnotationLayer={false}/>
                            </Document>

                        </div>
                    }
                </DialogContent>
            </div>
        </Dialog>
    );
};