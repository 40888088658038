import React from "react";

export function SelectBox2(props) {
    const { labelName } = props;
    return (
        <><div className="mb-2">
            <label htmlFor="exampleInputPhone" className="form-label mb-0" style={{ display: labelName ? "block" : "none" }}>
                {props.labelName}
            </label>
            <div className="input-group">
                <select className="form-select  " style={props.style} aria-label="Default select example" defaultValue="25">
                    <option selected>{props.selectName}</option>
                    {
                        props?.data?.map((selectBox, index) => {
                            return (
                                <option key={index} value={index + 1}>{selectBox}</option>
                            );
                        })
                    }
                </select>
            </div>
        </div>
        </>
    );
}

