/* eslint-disable */
import React, {useEffect, useState, useMemo} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Grid,
    Typography
} from "@mui/material";
import {
    AddIcCall,
    Call,
    CallEnd,
    Launch,
    OpenInBrowser,
    PhoneDisabled,
    PhoneEnabled,
    PhonePaused,
    VolumeOff,
    VolumeUp,
    Close, Mic, MicOff
} from "@mui/icons-material";
import socket from "../socket";
import IconButton from "@mui/material/IconButton";
import {
    changeCallHoldStatus,
    changeCallMuteStatus,
    changeCallSpeakerStatus,
    setCallData,
    setFollowUpLeadId,
    setLeadModalReducer,
    stopCallTimer,
    updateCallTimer
} from "../redux/actions";
import {$crud} from "../services/CrudFactory";
import {useDispatch, useSelector} from "react-redux";
import {formatTime} from "../helpers/functions";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {callDataInitialState} from "../redux/callDataReducer";
import Tooltip from "@mui/material/Tooltip";

export const CallProgressModal = React.memo((props) => {

    const {open, data} = props;
    const [lead, setLead] = useState({});
    const dispatch = useDispatch();
    const timer = useSelector(state => state.callTimerReducer)
    const callData = useSelector(state => state.callDataReducer)

    function callEnd() {
        localStorage.getItem("users") && socket.emit("callStatus", {
            status: "callEnd",
            socketId: JSON.parse(localStorage.getItem("users"))?.socketId,
            mode: "app"
        });
        dispatch(setFollowUpLeadId({onCallEnd: true}))
    }

    function pickCall() {
        localStorage.getItem("users") && socket.emit("callStatus", {
            status: "pickCall",
            socketId: JSON.parse(localStorage.getItem("users"))?.socketId,
            mode: "app"
        });
    }

    const retrieveLead = async (mobile1) => {
        const {data: {lead}} = await $crud.retrieve("web/lead-by-mobile", {mobile1});
        return lead;
    }

    const openLead = async () => {
        if (lead?.id) {
            dispatch(setFollowUpLeadId({id: lead?.id, openLeadModal: true}))
        }
    }
    const handleHold = () => {
        dispatch(changeCallHoldStatus())
        socket.emit("Hold", {
            hold: true,
            mode: "app"
        });
    }
    const handleMute = () => {
        dispatch(changeCallMuteStatus())
        socket.emit("Mute", {
            mute: true,
            mode: "app"
        });
    }
    const handleSpeaker = () => {
        dispatch(changeCallSpeakerStatus())
        socket.emit("Speaker", {
            speaker: true,
            mode: "app"
        });
    }
    useEffect(() => {
        let lastUpdateTime = Date.now()
        let callTimerInterval = timer.isRunning && setInterval(() => {
            const now = Date.now()
            const deltaTime = now - lastUpdateTime
            lastUpdateTime = now
            dispatch(updateCallTimer(deltaTime))
        }, 1000)

        return () => clearInterval(callTimerInterval)

    }, [timer.isRunning])

    useEffect(() => {
        if (data?.mobile) {
            retrieveLead(data?.mobile).then(lead => {
                if (lead) {
                    setLead(lead)
                }
            })
        }
    }, [data?.mobile])
    return (
        <Box sx={{
            position: "fixed",
            bottom: 80,
            left: 20,
            display: open ? "block" : "none",
            // display:"block",
            borderRadius: 5,
            maxWidth: 260,
            boxShadow: theme => theme.shadows[5],
            zIndex: "800000"
        }} className="tableHeader-color">
            <Tooltip title={"Force Close (Only When App Crashes Or Call Does not end)"}>
                <IconButton onClick={() => {
                    dispatch(setCallData(callDataInitialState));
                    dispatch(stopCallTimer());
                }} sx={{
                    position: "absolute",
                    left: 2,
                    top: 2,
                    zIndex: 1300
                }}><Close/></IconButton>
            </Tooltip>
            <IconButton onClick={openLead} sx={{
                position: "absolute",
                right: 2,
                top: 2,
                zIndex: 1300
            }}><Launch/></IconButton>

            <DialogContent sx={{position: "relative"}}>

                <Grid container spacing={1} textAlign="center">
                    <Grid item xs={12}>
                        <Typography variant="body1" component="span">{data?.name ?? lead?.name ??
                        <Skeleton animation="wave"/>}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {data?.callStatus}
                    </Grid>
                    {["Connected", "Call Connected"].includes(data?.callStatus) && <Grid item xs={12}>
                        {formatTime(timer.time)}
                    </Grid>}
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-around">

                        {data?.incoming && !data?.fromPhone &&
                        <Fab color='success' size="small" onClick={pickCall}>
                            <Call/>
                        </Fab>}
                        <Fab size="small" onClick={handleHold} color={!callData?.onHold ? "inherit" : "secondary"}>
                            <PhonePaused sx={{fill: "black"}}/>
                        </Fab>
                        <Fab size="small" onClick={handleMute} color={!callData?.onMute ? "inherit" : "secondary"}>
                            <MicOff sx={{fill: "black"}}/>
                        </Fab>
                        <Fab size="small" onClick={handleSpeaker}
                             color={!callData?.onSpeaker ? "inherit" : "secondary"}>
                            <VolumeUp sx={{fill: "black"}}/>
                        </Fab>
                        <Fab color='error' size="small" onClick={callEnd}>
                            <CallEnd/>
                        </Fab>
                    </Grid>
                </Grid>
            </DialogContent>

        </Box>
    )
})