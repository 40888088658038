/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    DialogActions,
} from "@mui/material"
import QRImg from "../images/qr-code.svg";
import {Person, Phone} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {$crud} from "../services/CrudFactory";
import {useDispatch} from "react-redux";
import socket from "../socket";

const WhatsAppQR = (props) => {
    const {open, onClose} = props;
    const [imageSrc, setImageSrc] = useState(null);
    const dispatch = useDispatch();
    const getQR = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {data: {url}} = await $crud.get("auth/getqr");
            return url;
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }
    useEffect(()=>{
        // getQR().then(res => setImageSrc(res));
        socket.on("qr",data=>{
        });

        return ()=>{
            socket.off("qr");
        }
    },[open])

    return (
        <Dialog maxWidth={"sm"} TransitionProps={
            {
                onEnter: () => {
                }
            }
        } TransitionComponent={Slide} fullWidth open={open} onClose={onClose}>
            <div className="tableHeader-color">
                <DialogTitle>WhatsApp Scanner</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Box className="whatsAppQRTextField d-flex">
                                <Person sx={{mt: 1, mr: 1}}/>
                                <Grid>
                                    <Typography variant="caption"> WhatsApp Name</Typography>
                                    <Typography variant="subtitle1">Abhishek Singh</Typography>
                                </Grid>
                            </Box>
                            <Box className="whatsAppQRTextField d-flex">
                                <Phone sx={{mt: 1, mr: 1}}/>
                                <Grid>
                                    <Typography variant="caption">WhatsApp Number</Typography>
                                    <Typography variant="subtitle1">9852364107</Typography>
                                </Grid>
                            </Box>
                            <Box className="d-flex">
                                <Button variant="contained" onClick={onClose} color="warning" size="small">Refresh
                                    QR</Button>
                                <Button className="ms-2" onClick={onClose} variant="contained" color="error"
                                        size="small">Logout</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={imageSrc || QRImg} alt="QR IMAGE"/>
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>

                </DialogActions>
            </div>
        </Dialog>
    );
};

export default WhatsAppQR;