import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";
import {CommonModal} from "../dialogs";
import {useDispatch} from "react-redux";
import {usePagination} from "../hooks";
import {$crud} from "../services/CrudFactory";
import {ConfirmationModal} from "../dialogs/ConfirmationModal";
import Typography from "@mui/material/Typography";

export const Colleges = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(null);
    const [idToRemove, setIdToRemove] = useState(null);
    const [colleges, setColleges] = useState([]);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();

    const retrieveColleges = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data} = await $crud.retrieve("web/colleges", {limit, page});
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    const statusChange = async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("create/web/status/statuses", {
                id
            });
            retrieveColleges().then(res => {
                setColleges(res.colleges);
            });
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };


    const remove = async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("delete/web/college", {id});
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });
            retrieveColleges().then(res => {
                setColleges(res.colleges);
                setTotal(res.total);
            });
            setIdToRemove(null);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    useEffect(() => {
        retrieveColleges().then(res => {
            setColleges(res.colleges);
            setTotal(res.total);
        });
    }, [limit, page]);
    return (
        <>
            <div>
                <div className='row w-100 g-2'>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setStatus({})} buttonName="Add College"/>
                    </div>
                </div>
            </div>
            <DynamicTable
                rows={colleges}
                columns={columns?.colleges}
                style={{maxHeight: "calc(100vh - 102px)"}}
                total={total}
                onDelete={(id) => {
                    setIdToRemove(id);
                }}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id);
                }}
                onEdit={(value) => {
                    setStatus(value);
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
            <CommonModal
                onSave={() => {
                    retrieveColleges().then(res => {
                        setColleges(res.colleges);
                        setTotal(res.total);
                    });
                }}
                title="College"
                apiUrl="web/college"
                data={status}
                onClose={() => setStatus(null)}
                open={!!status}
            />
            <ConfirmationModal
                open={!!idToRemove}
                title="Remove College"
                content={
                    <>
                        <Typography variant="subtitle1" >Removing College may lead to
                            Data Insufficiency In Lead | Make Sure to assign
                            Another College Ids To These College Leads.</Typography>
                        <Typography variant="subtitle2" > Press &apos;Yes&apos; To Remove
                            anyway.</Typography>
                    </>
                }
                onClose={() => {
                    setIdToRemove(null);
                }}
                onOk={() => remove(idToRemove)}
            />
        </>
    );
};