const initialState = {
  leadId: null,
  mobile1:null,
  lead: null,
  openModal: false,
  id: null,
  openLeadModal: false,
};

const followupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FOLLOWUP":
      return (state = { ...initialState, ...action.payload });
    //   if (action.payload.onCallEnd) {
    //     state = initialState;
    //   } else {
    //     state = { ...initialState, ...action.payload };
    //   }
    //   return state;
    case "MODALOPEN":
      return (state = {
        ...state,
        openModal: action.payload,
      });
    case "FOLLOWUPLEAD":
      return (state = {
        ...state,
        lead: action.payload,
      });
    case "FOLLOWUPLEADID":
      return (state = {
        ...state,
        leadId: action.payload,
      });
    default:
      return state;
  }
};

export default followupReducer;
