const initialState = "";

const newReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOADER":
            return {
                ...state,
                loader: action.loader,
            };
        case "SNACKBAR":
            return {
                ...state,
                snackBar: action.snackBar,
            };
        default:
            return state;
    }
};

export default newReducer;
