const initialState = [];

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "USERS":
            return (state = action.payload);
        default:
            return state;
    }
};


export default usersReducer;