import React, {useState} from "react";
import {Fab, IconButton, Tooltip} from "@mui/material";
import {Add, Delete, Edit} from "@mui/icons-material";
import {FeesModal} from "../dialogs";

export function CourseFormButton(props) {
    const {courseId, isEdit, selectedButton, i} = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            {
                !isEdit ?
                    <Tooltip title="Add New Course">
                        <Fab size="small" sx={{borderRadius: "5px", marginRight: "10px"}} color="primary"
                             onClick={() => setOpen(true)}><Add/></Fab>
                    </Tooltip>
                    :
                    <React.Fragment>
                        <IconButton
                            style={{
                                color: selectedButton === i ? "black" : "white",
                            }}
                            onClick={() => setOpen(true)}
                        >
                            <Edit/>
                        </IconButton>
                        <IconButton
                            style={{
                                color: selectedButton === i ? "black" : "white",
                            }}
                            onClick={() => setOpen(true)}
                        >
                            <Delete/>
                        </IconButton>
                    </React.Fragment>
            }
            <FeesModal
                courseId={courseId}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}

            />
        </React.Fragment>
    );
}