import React from "react";

export function InputField2(props) {
    const {labelName,Type,name,placeholderName,onChange,id,style} = props;
    return (
        <>
            <div className="mb-2">
                <label htmlFor={id} className="form-label mb-0" style={{ display: labelName ? "block" : "none" }}>
                    {labelName}
                    {/* {props.rightItem} */}
                </label>
                <input
                    type={Type}
                    id={id}
                    className="form-control"
                    style={style}
                    name={name}
                    aria-describedby="NameHelp"
                    placeholder={placeholderName}
                    onChange={onChange}
                />
            </div>
        </>
    );
}

