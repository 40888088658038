import {_} from "lodash";
const initialState = [];

const callQueueReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_QUEUE":
            state = [...state, action.payload];
            return state;
        case "REMOVE_FROM_QUEUE":
            _.remove(state , (item)=>item.mobile === action.payload);
            return state;

        default:
            return state;
    }
};


export default callQueueReducer;