const initialState = {
    render1: false,
};

const renderFieldMarketingUsersTasks = (state = initialState, action) => {
    switch (action.type) {
        case "RENDERTASKS":
            return (state = {
                ...state,
                render1: !state.render1
            });
        default:
            return state;
    }
};

export default renderFieldMarketingUsersTasks;
