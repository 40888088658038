import React from "react";
import { Grid, InputLabel, TextField, Button, MenuItem } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { styled } from "@mui/material";
// import {useSelector} from "react-redux";

export function Document({ formProps }) {
  const {
    params,
    handleChange,
    courses,
    // setParams
  } = formProps;
  // console.log(formProps, "--------courses document");
  // const courses = useSelector((state) => state.courseReducer)?.map(i=>i.name);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Course</InputLabel>
        </Grid>
        <TextField
          required
          name="course_name"
          value={params?.course_id || ""}
          onChange={(e, child) => {
            handleChange("course_name", child?.props?.children);
            handleChange("course_id", e.target.value);
          }}
          size="small"
          select
          fullWidth
        >
          {courses.map((item) => (
            <MenuItem key={item?.id} value={item?.id}>
              {item?.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Title</InputLabel>
        </Grid>
        <TextField
          required
          value={params?.title || ""}
          onChange={(e) => handleChange("title", e.target.value)}
          placeholder="Enter Title"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUpload />}
          fullWidth
        >
          Upload Document
          <VisuallyHiddenInput
            type="file"
            accept="application/pdf, application/vnd.ms-excel"
            onChange={(e) => handleChange("document", e.target.files[0])}
          />
        </Button>
      </Grid>
    </Grid>
  );
}
