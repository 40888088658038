export const valueDetails=[
    {
        id:"1",
        date:"12/05/2021",
        time:"11:06 A:M",
        reason:"Price is Very High",
        value:56000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"2",
        date:"12/05/2021",
        time:"11:06 A:M",
        reason:"Price is Very High",
        value:156000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"3",
        date:"12/05/2021",
        time:"11:06 A:M",
        reason:"Price is Very High",
        value:56000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"1",
        date:"18/05/2021",
        time:"02:06 P:M",
        reason:"Price is Very High",
        value:50000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"1",
        date:"21/05/2021",
        time:"11:26 A:M",
        reason:"Price is Very High",
        value:45000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"1",
        date:"27/05/2021",
        time:"11:26 A:M",
        reason:"Price is Very High",
        value:42000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"1",
        date:"05/06/2021",
        time:"11:26 A:M",
        reason:"Price is Very High",
        value:40000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"1",
        date:"08/06/2021",
        time:"11:26 A:M",
        reason:"Price is Very High",
        value:39000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"1",
        date:"10/06/2021",
        time:"11:26 A:M",
        reason:"Price is Very High",
        value:38000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"1",
        date:"10/06/2021",
        time:"01:26 P:M",
        reason:"Price is Very High",
        value:37000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
    {
        id:"1",
        date:"11/06/2021",
        time:"11:26 A:M",
        reason:"Price is Very High",
        value:39000,
        assigneeimg:"https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__340.jpg"
    },
];