import React, { useState } from "react";
import { IconButton } from "@mui/material";
import {
  // CheckRounded,
  Delete,
  // ZoomIn
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import { ContactCard } from "../ContactCard";
import BankDetails from "../BankDetails";
// import bob from "../../../images/bob.svg";
import { IMAGE_BASE_URL } from "../../../constants";
import { $crud } from "../../../services/CrudFactory";
// import { Location } from "../../forms";
import { Locationcard } from "../LocationCard";

export function WhatsappContentCard(props) {
  const { type, data, key, setWhatsappContent, url, setState, setMessage } =
    props;
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  // const [showCheckedButton, setShowCheckedButton] = useState({});
  // const [showCheckedButton, setShowCheckedButton] = useState(false);
  // const [selectedCardId, setSelectedCardId] = useState(null);
  // const [image, setImage] = useState(null);

  // useEffect(() => {
  //   const requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //     headers: {
  //       Accept:
  //         "image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8",
  //     },
  //   };
  //   image &&
  //     (async function () {
  //       const response = await fetch(image, requestOptions);
  //       const images1 = await response.blob();
  //       console.log(images1, "------------images1");
  //     })();
  // }, [image]);

  // function CheckedButton(isChecked) {
  //   return (
  //     <>
  //       {isChecked ? (
  //         <Box
  //           size="small"
  //           sx={{
  //             position: "absolute",
  //             top: 0,
  //             left: 0,
  //           }}
  //         >
  //           <IconButton>
  //             <CheckBoxRounded fontSize="small" />
  //           </IconButton>
  //         </Box>
  //       ) : null}
  //     </>
  //   );
  // }
  function DeleteButton(showDeleteButton, id, url) {
    const deleteData = async (id) => {
      try {
        await $crud.post(`delete/web/${url}`, { id });
        setState((prev) => {
          const updatedData = prev?.filter((s) => s.id !== id);
          return updatedData;
        });
      } catch (error) {
        console.log(error);
      }
    };
    return (
      <>
        {showDeleteButton && (
          // <Backdrop
          //   sx={{
          //     color: "#fff",
          //     zIndex: (theme) => theme.zIndex.drawer - 1,
          //     position: "absolute",
          //   }}
          //   open={showDeleteButton}
          //   // onClick={handleClose}
          // >
          <Box
            size="small"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            {/*<ZoomIn fontSize="small"/>*/}
            <IconButton onClick={() => deleteData(id)}>
              <Delete fontSize="small" />
            </IconButton>
          </Box>
          // </Backdrop>
        )}
      </>
    );
  }

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     const clickedElement = event.target;
  //     if (clickedElement.closest(".col-3.active") === null) {
  //       // setShowCheckedButton({});
  //     }
  //   };

  //   document.addEventListener("click", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);

  return (
    <React.Fragment>
      {type === "image" || type === "Document" ? (
        <div
          key={key}
          style={{
            // minWidth: 120,
            width: 120,
            height: 120,
            textAlign: "center",
            cursor: "pointer",
            position: "relative",
            border: "1px solid gray",
          }}
          onMouseEnter={() => setShowDeleteButton(true)}
          onMouseLeave={() => setShowDeleteButton(false)}
          onClick={
            type === "image"
              ? // ? () => setWhatsappContent(data?.imgsrc)
                () => {
                  // setShowCheckedButton(!showCheckedButton);
                  setWhatsappContent(
                    `${IMAGE_BASE_URL}course_images/${data.image}`
                  );
                  setMessage(`${IMAGE_BASE_URL}course_images/${data.image}`);
                }
              : () => {
                  setWhatsappContent(
                    `${IMAGE_BASE_URL}whatsapp_document/${data.document}`
                  );
                  setMessage(
                    `${IMAGE_BASE_URL}whatsapp_document/${data.document}`
                  );
                }
          }
        >
          {DeleteButton(showDeleteButton, data?.id, url)}
          {/* {CheckedButton(showCheckedButton)} */}
          {type === "image" ? (
            <img
              src={`${IMAGE_BASE_URL}course_images/${data.image}`}
              style={{ width: "100%", height: "90%" }}
            />
          ) : (
            <img
              src={
                "https://play-lh.googleusercontent.com/1EgLFchHT9oQb3KME8rzIab7LrOIBfC14DSfcK_Uzo4vuK-WYFs9dhI-1kDI7J0ZNTDr"
              }
              style={{ width: "100%", height: "90%" }}
            />
          )}
          {/* <img
            src={`${IMAGE_BASE_URL}course_images/${data.image}`}
            style={{ width: "100%", height: "90%" }}
          /> */}
          <p className="m-0 p-0 text-white" style={{ fontSize: "10px" }}>
            {data.title}
          </p>
        </div>
      ) : type === "Contact" ? (
        <div
          className="col-12 position-relative"
          // className={"col-3 position-relative"}
          key={key}
          onMouseEnter={() => setShowDeleteButton(true)}
          onMouseLeave={() => setShowDeleteButton(false)}
          onClick={() => {
            // {
            //   showCheckedButton?.id === data?.id
            //     ? setShowCheckedButton({})
            //     : setShowCheckedButton({ id: data?.id, isShow: true });
            // }
            // setShowCheckedButton(!showCheckedButton);
            // setSelectedCardId(data?.id);
            // setSelectedCardId(() =>
            //   selectedCardId === data?.id ? null : data?.id
            // );
            setWhatsappContent(
              `Name : ${data?.name}\nEmail : ${data?.email}\nMobile No : ${data?.phoneNo}`
            );
          }}
        >
          {DeleteButton(showDeleteButton, data?.id, url)}
          {/* {CheckedButton(data?.id)} */}
          {/* {CheckedButton(showCheckedButton)} */}
          {/* {CheckedButton(selectedCardId === data?.id)} */}
          <ContactCard data={data} setMessage={setMessage} />
        </div>
      ) : type === "Bank" ? (
        <div
          // className="col-lg-3 col-md-6 position-relative"
          className="col-12 position-relative"
          key={key}
          onMouseEnter={() => setShowDeleteButton(true)}
          onMouseLeave={() => setShowDeleteButton(false)}
          onClick={() => {
            const mode = JSON.parse(data?.paymentMode)?.map(
              (item) => item?.name
            );
            setWhatsappContent(
              `Account No : ${data?.accountNo}\nHolder Name : ${data?.holderName}\nIFSC : ${data?.ifsc}\nPayment Mode : ${mode}`
            );
          }}
        >
          {DeleteButton(showDeleteButton, data?.id, url)}
          {/* <BankDetails data={data} bankicon={bob} /> */}
          <BankDetails data={data} setMessage={setMessage} />
        </div>
      ) : type === "Location" ? (
        <div
          className="col-12 position-relative"
          key={key}
          onMouseEnter={() => setShowDeleteButton(true)}
          onMouseLeave={() => setShowDeleteButton(false)}
          onClick={() => {
            // console.log(JSON.parse(data?.latlng), "--data");
            const lat = JSON.parse(data?.latlng).lat;
            const lng = JSON.parse(data?.latlng).lng;
            // // console.log(lat, lng);
            setWhatsappContent(`https://www.google.com/maps?q=${lat},${lng}`);
          }}
        >
          {DeleteButton(showDeleteButton, data?.id, url)}
          {/* <BankDetails data={data} bankicon={bob} /> */}
          <Locationcard data={data} setMessage={setMessage} />
        </div>
      ) : null}
    </React.Fragment>
  );
}
