/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    Tooltip, Typography
} from "@mui/material";
import {ReactPagination} from "../components/Pagination";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch} from "react-redux";
import {Edit} from "@mui/icons-material"
import {DepartmentModal} from "../dialogs/Department";
import {DeviceModal} from "../dialogs/DeviceModal";
import {useDataParams} from "../hooks/useDataParams";
import {columns} from "../helpers/tableData";
import DynamicTable from "../components/DynamicTable";
export const Devices = () => {
    const dispatch = useDispatch();
    const [device,setDevice] = useState(null);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const [devices,setDevices] = useState([]);
    const {setFilter,filters} = useDataParams();
    const retrieveDevices =async () =>{
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {data:{devices,total}} = await $crud.retrieve("web/devices",{
                limit,
                page,
                ...filters
            });
            setDevices(devices);
            setTotal(total);
        }catch (e) {
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message:e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }
    const statusChange =async (id)=>{
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {type,message} = await $crud.post("create/web/status/device", {
                id
            });
            retrieveDevices();
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
        }catch (e) {
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message:e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    useEffect(()=>{
        retrieveDevices();
    },[limit,page,filters]);

    return (
        <>
            <div>
                <div className='row w-100 g-2'>
                    <div className='mb-3 col-6 d-flex'>
                        <ActionButton onClick={() => setDevice({})} buttonName="Add Device"/>
                    </div>
                    <div className='mb-3 col-3'>
                        <div className="input-group">
                            <select onChange={(e) =>setFilter("status",e.target.value)} className="form-select" style={{backgroundColor: "#283046",border:"1px solid #5c3fdd"}}
                                    aria-label="Default select example">
                                <option value="any" selected>Choose Status</option>
                                <option value="1" selected>Active</option>
                                <option value="0" selected>InActive</option>
                            </select>
                        </div>
                    </div>
                    <div className='mb-3 col-3'>
                        <input onChange={(e)=>setFilter("search",e.target.value)} className="form-control InputBox" type="search" placeholder="Search" aria-label="Search" />
                    </div>
                </div>
            </div>
            <DynamicTable
                rows={devices}
                columns={columns?.device}
                total={total}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}
                onEdit={(value) => {
                    setDevice(value)
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
                <DeviceModal device={device} onSave={retrieveDevices} onClose={()=>setDevice(null)} open={!!device}/>
        </>
    );
};

