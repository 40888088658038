import React from "react";
import { Box, Button,Dialog, DialogContent, DialogTitle, Divider, Grid,Slide} from "@mui/material";
import { Map } from "../components";

export const FieldMarketingMapModal = (props) => {
    const{open, close, handleChange, params} = props;
  return (
    <Dialog maxWidth={"md"} TransitionComponent={Slide} fullWidth open={open}>
      <div className="tableHeader-color" >
        <DialogTitle>Get Location</DialogTitle>
        <Divider />
        <DialogContent sx={{ height: "500px", overflowY: "scroll", width:"100%"}}>
            <Grid item sm={12} sx={{height:"400px", width:"100%"}}>
                <Box sx={{height:"100%", width:"100%"}} className="tableHeader-color">
                 <Map handleChange = {handleChange} params= {params}/>
                </Box>
            </Grid>
          
          <Grid
            item
            xs
            className="d-flex justify-content-end align-items-center mt-3"
          >
            <Button
              variant="outlined"
              sx={{mr:2}}
              onClick={() => {
                close();
              }}
              color="primary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                close();
              }}
              color="primary"
              size="small"
            >
              Confirm
            </Button>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
};
