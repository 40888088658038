import React, { useEffect } from "react";
// import { preRecordedMessages } from "../../data/preRecordedMessages";
import {IoIosArrowForward} from "react-icons/io";
import { $crud } from "../../services/CrudFactory";

export function LeadModalSidebar(props) {
  const {data, url, setState, setCourseId = ()=> {}} = props;

  const retrieveData = async (id)=>{
    if(url){
      try {
          const {data:{data}} = await $crud.retrieve(`web/${url}`, {id});
          setState(data);
          setCourseId(id);
      } catch (error) {
          console.log(error);
      }
    }
};

useEffect(()=>{
  retrieveData(data[0]?.id);
}, [data]);

  return (
    <ul>
        {
            data.map((data,index)=>{
                return (
                    <li key={index} onClick={()=>retrieveData(data?.id)} >{data.name || data?.label}<IoIosArrowForward className='arrow-icon'/></li>
                );
            })
        }

    </ul>
  );
}

 