/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    Tooltip, Typography
} from "@mui/material";
import {ReactPagination} from "../components/Pagination";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch} from "react-redux";
import {Edit} from "@mui/icons-material"
import {BankDetailModal} from "../dialogs/BankDetailModal";
import {ContactDetailModal} from "../dialogs/ContactDetailModal";
import {columns} from "../helpers/tableData";
import DynamicTable from "../components/DynamicTable";
export const ContactDetails = () => {
    const dispatch = useDispatch();
    const [contactDetail,setContactDetail] = useState(null);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const [contactDetails,setContactDetails] = useState([]);

    const retrieveContactDetails = async () =>{
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {data:{contact_details,total}} = await $crud.retrieve("web/contact-details",{
                limit,
                page
            });
            setContactDetails(contact_details);
            setTotal(total);
        }catch (e) {
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message:e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    const statusChange = async (id)=>{
        try {
            dispatch({
                type: "LOADER",
                loader:true
            });
            const {type,message} = await $crud.post("create/web/status/contact", {
                id
            });
            retrieveContactDetails();
            dispatch({
                type:"SNACKBAR",
                snackBar:{type,message}
            })
        }catch (e) {
            dispatch({
                type:"SNACKBAR",
                snackBar:{type:"error",message:e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader:false
            });
        }
    }

    useEffect(()=>{
        retrieveContactDetails();
    },[limit,page]);

    return (
        <>
            <div>
                <div className='row w-100 g-2 '>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setContactDetail({})} buttonName="Add Contact Details"/>
                    </div>
                </div>
            </div>
            <DynamicTable
                rows={contactDetails}
                columns={columns?.contactDetail}
                total={total}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}
                onEdit={(value) => {
                    setContactDetail(value)
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
                <ContactDetailModal onSave={retrieveContactDetails} contactDetail={contactDetail} onClose={()=>setContactDetail(null)} open={!!contactDetail}/>
        </>
    );
};

