/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Grid,
    Menu,
    MenuItem
} from "@mui/material";
import {$crud} from "../services/CrudFactory";
import {useSelector, useDispatch} from "react-redux";
import {usePagination} from "../hooks/usePagination";
import {useDataParams} from "../hooks";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";
import {FilterChipContainer} from "../components";
import {FeesModal, PreviewCourseModule} from "../dialogs";

export function FeesChart() {
    const [open, setOpen] = useState(false);
    const [isPdf, setIsPdf] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [filtersWithValues, setFiltersWithValues] = useState({});
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [openPreview, setOpenPreview] = useState(null);
    const {
        limit,
        page,
        setTotal,
        total,
        setLimit,
        setPage
    } = usePagination();
    const dispatch = useDispatch();
    const {setParams} = useDataParams();

    const renderState = useSelector((state) => state.renderLead.render1);
    const currentUser = useSelector((state) => state.userReducer);
    const filters = useSelector(state => state.filterReducer);

    // console.log(typeof courses[1]?.images, "------courses");

    const retrieveCourses = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data: {courses, total},
            } = await $crud.retrieve("web/course", {
                page,
                limit,
            });
            return {courses, total};
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    const deleteCourse = async (id) => {
        await $crud.confirm();
        const {type, message} = await $crud.post("delete/web/course", {
            courseId: id
        });
        retrieveCourses().then(res => setCourses(res?.courses));
        dispatch({
            type: "SNACKBAR",
            snackBar: {type, message},
        });
        setAnchorEl(null);
    }
    const menuOptions = [
        {
            title:"Edit",
            onClick:()=>setOpen(true)
        },{
        title:"Delete",
            onClick:(value)=>deleteCourse(value.id)
        }
    ]
    useEffect(() => {
        retrieveCourses().then(res => {
            setCourses(res?.courses);
            setTotal(res?.total);
        }).catch(error => console.error(error));
    }, [limit, page, filtersWithValues, renderState]);

    useEffect(() => {
        setFiltersWithValues(filters?.withValues);
    }, [filters]);

    // useEffect(() => {
    //     const handleResize = () => {
    //       const windowWidth = window.innerWidth;
    //       const divElement = document.getElementById("table"); 
    
    //       if (divElement) {
    //         if (windowWidth >= 768 && windowWidth <= 865) {
    //           divElement.style.top = "175px";
    //         } else if (windowWidth >= 866 && windowWidth <= 1186) {
    //           divElement.style.top = "131px";
    //         } else {
    //           divElement.style.top = "85px";
    //         }
    //       }
    //     };
    //     handleResize();
    //     window.addEventListener("resize", handleResize);
    //     return () => {
    //       window.removeEventListener("resize", handleResize);
    //     };
    //   }, []);

    return (
        <React.Fragment>
            <Grid className="container-fluid top-navbar ">
                <FilterChipContainer/>
                <DynamicTable
                    id = "feesChart"
                    rows={courses}
                    columns={columns?.course}
                    total={total}
                    onRowClick={() => {
                    }}
                    style={{
                        maxHeight: "calc(100vh - 250px)",
                        position: "fixed",
                        top: "150px",
                        width: "calc(100% - 24px)",
                    }}
                    anchorEl={anchorEl}
                    onOpen={(event, course) => {
                        setAnchorEl(event.currentTarget);
                        setSelectedCourse(course);
                    }}
                    onFeeEmiChange={(id, emiObj) => {
                        let parsedObj = emiObj && JSON.parse(emiObj);
                        setCourses(prev => {
                            const temp = [...prev];
                            const exist = temp.find(s => s.id === parsedObj?.courseId);
                            if (exist) {
                                exist.selectedEmi = parsedObj?.monthlyEMI;
                                return temp;
                            }
                        });
                    }}
                    onEdit={(course) => {
                        setOpen(true);
                    }}
                    onStatusChange={(value,isPdf) => {
                        setOpenPreview(value);
                        setIsPdf(isPdf);
                    }}
                    onDelete={(id) => deleteCourse(id)}
                    paginationProps={{
                        limit: limit,
                        page: page,
                        onChangeLimit: (limit) => setLimit(limit),
                        onChangePage: (page) => setPage(page),
                    }}
                    onClose={() => {
                        setSelectedCourse(null);
                        setAnchorEl(null);
                    }}
                    menuOptions={menuOptions}
                />

            </Grid>

            <FeesModal
                onSave={() => retrieveCourses()}
                course={selectedCourse}
                open={open}
                onClose={() => setOpen(null)}
            />
            <PreviewCourseModule
                isPdf={isPdf}
                open={!!openPreview}
                content={openPreview}
                onClose={() => setOpenPreview(null)}
            />
        </React.Fragment>
    );
}


