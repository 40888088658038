import React, {
    // useLayoutEffect,
    useEffect, useState
} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {ChartContainer} from "./ChartContainer";
import {useDispatch} from "react-redux";
import {$crud} from "../../services/CrudFactory";
// import {formatTime} from "../../helpers/functions";


export function BarChart2(props) {
    const {chartID,chartTitle, style, userId} = props;
    const [chartData, setChartData] = useState([]);
    const [filterType, setFilterType] = useState("monthly");
    const dispatch = useDispatch();
    const retrieveChartData = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data
            } = await $crud.retrieve(`web/${url}`, {
                [filterType]: true,
                userId,
            });
            return data;
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    useEffect(()=>{
        retrieveChartData("user-report-working-hours").then(res=>setChartData(res.workingHoursReport));
    },[userId, filterType]);

    useEffect(() => {
        var root = am5.Root.new(chartID);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        var legend = chart.children.push(
            am5.Legend.new(root, {
                nameField: "name",
                fillField: "color",
                strokeField: "color",
                centerX: am5.p50,
                x: am5.p50,
            })
        );

        function generateData(priorDate) {
            var value = 0;
            var value1 = 0;
            am5.time.add(priorDate, "day", 1);
            const exist = chartData?.find(d => new Date(d.date).setHours(0,0,0,0) == priorDate.getTime());
            if (exist) {
                value = +exist.totalWorkingHours / 3600000;
                value1 = +exist.totalBreakTime / 3600000;
            }
            return {
                date: priorDate.getTime(),
                workingHours: value,
                break:value1
            };
        }

        function generateDatas(count) {
            var date = new Date();
            let daysToBack = filterType === "monthly" ? 30 : 7;
            date.setHours(0, 0, 0, 0);
            let priorDate = new Date(new Date(date).setDate(date.getDate() - daysToBack));
            var data = [];
            for (var i = 0; i < count; ++i) {
                data.push(generateData(priorDate));
            }
            return data;
        }


        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },

            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 60,
                labels:{
                    template:{
                        fill: am5.color(0xFF0000),
                    }
                }
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let xRenderer = xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            fill: am5.color("#FFF"),
        });
        xAxis.data.setAll(generateDatas(filterType === "monthly" ? 30 : 7));

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1,
            })
        }));

        let yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fill: am5.color("#FFF"),
        });
        function makeSeries(name, fieldName, fillColor) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                valueXField: "date"
            }));
            var tooltip = am5.Tooltip.new(root, {
                getFillFromSprite: false,
                labelText: "{valueY}"
            });

            tooltip.get("background").setAll({
                fill: am5.color(fillColor.first),
                fillOpacity: 0.8
            });

            series.set("tooltip", tooltip);

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}:{valueY}",
                width: am5.percent(90),
                tooltipY: 0,
                strokeOpacity: 0
            });
            series.columns.template.set("fillGradient", am5.LinearGradient.new(root, {
                stops: [{
                    color: am5.color(fillColor.first)
                }, {
                    color: am5.color(fillColor.second)
                }],
                rotation: 90
            }));

            series.data.setAll(generateDatas(filterType === "monthly" ? 30 : 7));

            series.appear();

            series.bullets.push(function() {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });
            legend.data.push(series);
        }

        legend.labels.template.setAll({
            fill:am5.color("#fff"),
            fontSize: 16,
            fontWeight: "300"
        });

        makeSeries("Working Hours", "workingHours",{first:"#07690f",second:"#15811d"});
        makeSeries("Break", "break",{first:"#af0000", second:"#bf1c1c"});

        chart.appear(1000, 100);


        return () => {
            root.dispose();
        };
    }, [chartID, chartData]);

    return (
        <ChartContainer chartID={chartID} chartTitle={chartTitle} style={style} filterProps={{setFilterType}}/>
    );
}
