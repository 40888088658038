/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton, ThemeSwitcher} from "../components/buttons";
import {InputField} from "../components/inputs";
import loginImg from "../images/login-v2.svg";
import {useNavigate} from "react-router-dom";
import {useDataParams} from "../hooks/useDataParams";
import {$crud} from "../services/CrudFactory";
import {useDispatch} from "react-redux";
import {current_user, statuses, users, sources, courses, leadConvertTypes, setSystemSettings} from "../redux/actions";
import {Box, Button, Typography} from "@mui/material";
import socket from "../socket";
import Lottie from 'react-lottie-player'
import DownloadAppLottie from "../images/lottie/downloadPhoneApp.json";
import {Download} from "@mui/icons-material";
import { DownloadBtnModal } from "../dialogs/DownloadBtnModal";

export const retrieveUser = async (id) => {
    const {data} = await $crud.retrieve("web/user", {id});
    return data;
};

export const retrieveSystemSettings = async () => {
    const {data: {systemSettings}} = await $crud.get("retrieve/web/system-settings");
    return systemSettings[0];
}
export const retrieveUsers = async () => {
    const {data} = await $crud.retrieve("web/users", {isActive: true});
    return data;
};

export const retrieveStatuses = async () => {
    const {data} = await $crud.retrieve("web/statusall");
    return data;
};

export const retrieveSources = async () => {
    const {data} = await $crud.retrieve("web/lead-source-all");
    return data;
};

export const retrieveCourses = async () => {
    const {data} = await $crud.retrieve("web/course-all");
    return data;
};

export const retrieveLeadConvertTypes = async () => {
    const {data} = await $crud.retrieve("web/lead-convert-types");
    return data;
};

export function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {params, handleChange} = useDataParams();
    const [isOtp, setIsOtp] = React.useState(false);
    const [open, setOpen] = useState(false)
    const [windowSize, setWindowSize] = React.useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const handleClose = ()=>{
        setOpen(false);
    };
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    // const loginCredentialsForTesting = {
    //     mobileNo: "1111111111",
    //     password: "admin@123"
    // }
    const login = async (e) => {
        await e.preventDefault()
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });

            const {data: {data: {token, user}, type}} = await $crud.post("create/web/login", {
                ...params
            });
            if (type === "success") {
                await localStorage.setItem("token", `Bearer ${token}`);
                await localStorage.setItem("user_id", user?.id);
                await localStorage.setItem("showContact", user?.showContact);
                await localStorage.setItem("role", JSON.stringify(user?.role))
                // await localStorage.setItem("crmWhatsAppInfo", JSON.stringify({accessKey:user?.crmWhatsAppAccessKey, crmWhatsApp:user?.crmWhatsApp}))
                await localStorage.setItem("branchInfo", JSON.stringify({id:user?.branchInfo?.id, name : user?.branchInfo?.name}))
                socket.auth.userId = user?.id;
                await socket.connect();
                retrieveUser(user?.id).then(user => {
                    dispatch(current_user(user))
                });
                retrieveUsers().then(res => {
                    dispatch(users(res?.users));
                });
                retrieveStatuses().then(res => {
                    dispatch(statuses(res?.statuses));
                });
                retrieveSources().then(res => {
                    dispatch(sources(res?.leadSources));
                });
                retrieveCourses().then(res => {
                    dispatch(courses(res));
                });
                retrieveLeadConvertTypes().then(res => {
                    dispatch(leadConvertTypes(res?.leadConvertTypes));
                });
                retrieveSystemSettings().then(res => dispatch(setSystemSettings(res)));
                navigate("/leads/lead");
            }
            dispatch({
                type: "SNACKBAR",
                snackBar: {
                    type,
                    message: "Logged In"
                }
            });

        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    const SendOtp = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {status, type, message} = await $crud.post("create/web/otp", {
                ...params
            });
            if(type === "success"){
                setIsOtp(true)
            }
            dispatch({
                type: "SNACKBAR",
                snackBar: {
                    type,
                    message: message
                }
            });
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
           
        }
    };
// const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("theme")) localStorage.setItem("theme", "dark-theme");
    }, [])
    // console.log(document.cookie.replace(/(?:(?:^|.*;\s*)otpCookie\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
    return (
        <>
            {
                windowSize[0] > 768 ?
                    <>
                        <div className='container-fluid ' style={{height: "100vh"}}>
                            <div className='row d-flex loginformParent'>
                                <div className='col-sm-12 col-md-8 d-flex justify-content-center align-items-center'>
                                    <div className='loginformimg'>
                                        <img src={loginImg} alt="" width={"100%"}/>
                                    </div>
                                </div>
                                <div
                                    className='col-sm-12 col-md-4 loginform d-flex justify-content-center align-items-center '>
                                    <div className='loginformchild'>
                                        {/*<Typography variant="h4">No Need to Enter Credentials Just Press submit Button (Testing*/}
                                        {/*    Only)</Typography>*/}
                                        {isOtp && <Button onClick={()=>navigate(-2)}>go back</Button> }
                                        <h4 className='mb-4 mt-2'>CBSL</h4>
                                        <h5 style={{fontSize: "22px", fontWeight: "400"}}>Welcome to CBSL!</h5>
                                        <p className=''>Please sign-in to your account and start the
                                            adventure</p>

                                        {!isOtp ?
                                            <div>
                                                <InputField
                                                    labelName="Phone Number"
                                                    onChange={value => handleChange("mobileNo", value)}
                                                    type="text" placeholderName="Enter Your Phone Number"
                                                    style={{borderRadius: "5px"}}
                                                />

                                                <InputField
                                                    onChange={value => handleChange("password", value)}
                                                    labelName="Password"
                                                    rightItem={
                                                        <label
                                                            className="form-check-label"
                                                            style={{color: "#5C4FDD"}}
                                                            htmlFor="exampleCheck1">
                                                            Forget your password?
                                                        </label>
                                                    }
                                                    type="password" name="mobile2"
                                                    placeholderName="Enter Your Password"
                                                    style={{borderRadius: "5px"}}/>
                                                <p className='mt-2'>Remember Me</p>

                                                <ActionButton
                                                    buttonName="Submit"
                                                    style={{width: "100%"}}
                                                    onClick={() => {
                                                        SendOtp();
                                                    }}/>
                                                <ActionButton
                                                    buttonName="Go To Website"
                                                    style={{width: "100%", marginTop: "15px"}}
                                                />
                                                <ActionButton
                                                    buttonName="Download App"
                                                    style={{width: "100%", marginTop: "15px"}}
                                                />
                                            </div>
                                            :
                                            <div>
                                                <InputField
                                                    onChange={value => handleChange("otp", value)}
                                                    labelName="Enter OTP"
                                                    type="number"
                                                    name="otp"
                                                    onInput = {(e) =>{
                                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                                                    }}
                                                    placeholderName="Enter Your OTP"
                                                    style={{borderRadius: "5px"}}
                                                />

                                                <ActionButton
                                                    buttonName="Submit"
                                                    style={{width: "100%", marginTop: "15px"}}
                                                    onClick={(e) => {
                                                        login(e)
                                                    }}
                                                />

                                                <ActionButton
                                                    buttonName="Resend OTP"
                                                    style={{width: "100%", marginTop: "15px"}}
                                                    onClick={() => {
                                                        SendOtp()
                                                    }}/>
                                                
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ThemeSwitcher style={{position: "absolute", left: "20px", top: "20px"}}/>
                    </> :
                    <Box sx={{
                        width: "100%",
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        {/*<Typography variant="h4" component={"span"} sx={{p: 2}}>Download CRM App</Typography>*/}
                        <Lottie
                            loop
                            animationData={DownloadAppLottie}
                            play
                            style={{width: "100%", height: "100%"}}
                        />
                        <Button variant="contained" color="primary" onClick={()=>setOpen(true)} startIcon={<Download/>}
                                sx={{mt: 2, color: "#fff"}} size="large">Download App</Button>
                    </Box>
            }
            <DownloadBtnModal open = {open} close = {handleClose}/>

        </>
    );
}

