import React from "react";
import {Dialog, DialogContent, DialogTitle, Divider, Slide} from "@mui/material";
import {useDataParams} from "../hooks";
// import MenuItem from "@mui/material/MenuItem";
// import Typography from "@mui/material/Typography";
import {Contact, Document, Image, Bank, Location, Text} from "../components/forms";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { $crud } from "../services/CrudFactory";
import { useDispatch } from "react-redux";

export function ContentForm(props) {
    const {type, formProps} = props;
    const contentForm = {
        "Image": <Image formProps={formProps}/>,
        "Contact": <Contact formProps={formProps}/>,
        "Document": <Document formProps={formProps}/>,
        "Banks": <Bank formProps={formProps}/>,
        "Location": <Location formProps={formProps}/>,
        "Text": <Text formProps={formProps}/>
    };

    return contentForm[type];
}

export function WhatsappContent(props) {
    const {open,type, onClose, url, courses, setState, state} = props;
    const dispatch = useDispatch();
    const {
        params,
        handleChange,
        setParams
    } = useDataParams();

    const uploadData = async ()=>{
        try { 
            const formData = new FormData();
            Object.entries(params).map(([key, value])=>{
                formData.append(key, value);
            });
            
            const {data, message, type} = await $crud.post(`create/web/${url}`, (url === "whatsapp-location" ? {...params} : formData));
            setState([...state, data?.data]);
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Dialog
            maxWidth={type === "Location" ? "md" : "xs"}
            TransitionProps={{
                onEnter: () => {

                },
            }}
            TransitionComponent={Slide}
            PaperProps={{
                className: "tableHeader-color",
                style:{
                    width:"100%"
                }
            }}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <Grid container justifyContent="space-between">
                    <Typography variant="h6">Add Content ({type})</Typography>
                    {/*<TextField*/}
                    {/*    required*/}
                    {/*    value={type || ""}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        handleChange("type", e.target.value);*/}
                    {/*        setType(e.target.value);*/}
                    {/*    }}*/}
                    {/*    label="Content Type"*/}
                    {/*    size="small"*/}
                    {/*    select*/}
                    {/*    sx={{minWidth: 200}}*/}
                    {/*>*/}
                    {/*    {["Image", "Document", "Contact", "Location", "Banks", "Text"].map(item => <MenuItem*/}
                    {/*        key={item} value={item}>{item}</MenuItem>)}*/}
                    {/*</TextField>*/}
                </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <ContentForm type={type} formProps={{handleChange, setParams, params, courses}}/>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" onClick={()=>uploadData()}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}