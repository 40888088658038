/* eslint-disable */
import React, { useEffect, useState, memo } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Slide,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDataParams } from "../hooks";
import { formatDate } from "../helpers/functions";
import { useDispatch, useSelector } from "react-redux";
import { $crud } from "../services/CrudFactory";
import { FieldMarketingMapModal } from "./FieldMarketingMapModal";
import { renderFieldMarketingUsersTasks } from "../redux/actions";

export const  FieldMarketingModal  = memo((props)=> {
  const { open, close,branches } = props;
  const [mapVisible, setMapVisible] = useState(false);
  // const [branches, setBranches] = useState([]);
  const [fieldUsers, setFieldUsers] = useState([]);
  const renderState = useSelector((state )=> state.renderFieldMarketingUsersTasks.render1);
  const { params, handleChange, setParams } = useDataParams();
  const dispatch = useDispatch();
  // let role = localStorage.getItem("users");
  // role = JSON.parse(role)?.user?.name;
  let role = localStorage.getItem("role");
  role = JSON.parse(role)?.name;

  const cancel = () => {
    close();
    setParams({});
  };

//   const retrieveBranches = async () => {
//     try {
//         dispatch({
//             type: "LOADER",
//             loader: true
//         });
//         const {data:{branches}} = await $crud.retrieve("web/branches");
//         setBranches(branches);
//     } catch (e) {
//         dispatch({
//             type: "SNACKBAR",
//             snackBar: {type: "error", message: e.message}
//         })
//     } finally {
//         dispatch({
//             type: "LOADER",
//             loader: false
//         });
//     }
// }

  const retrieveFieldMarketingUsers = async (id) => {
    try {
        dispatch({
            type: "LOADER",
            loader: true
        });
        // if(role === "super admin"){
          const {data:{Users}} = await $crud.retrieve("web/field-market-user", {branchId:id});
          setFieldUsers(Users);
        // }else{
        //   let branchId = await localStorage.getItem("branchId");
        //   branchId = JSON.parse(branchId);
        //   const {data:{Users}} = await $crud.retrieve("web/field-market-user", {branchId});
        //   setFieldUsers(Users);
        // }
    } catch (e) {
        dispatch({
            type: "SNACKBAR",
            snackBar: {type: "error", message: e.message}
        })
    } finally {
        dispatch({
            type: "LOADER",
            loader: false
        });
    }
}


const createTask = async () => {
    try {
        dispatch({
            type: "LOADER",
            loader:true
        });
        // const {type, message} = await $crud.post(!props.department?.id ? "create/web/department" : "update/web/department", {
        const {data, type, message} = await $crud.post( "create/web/add-task", {
            ...params,
        });

        dispatch(renderFieldMarketingUsersTasks({render1: !renderState}))
        dispatch({
            type:"SNACKBAR",
            snackBar:{type,message}
        })
        // props.onSave();
        close();
    } catch (e) {
        const {message} = e;
        dispatch({
            type:"SNACKBAR",
            snackBar:{type:"error",message}
        })
    } finally {
        dispatch({
            type: "LOADER",
            loader:false
        });
    }
}


const handleOpenMap = () => {
  setMapVisible(true);
};

const handleCloseMap = () => {
  setMapVisible(false);
}

const getBranchId =  async()=>{
  let branchId = await localStorage.getItem("branchInfo");
  branchId = JSON.parse(branchId)?.id;
  if(role !== "superAdmin"){
    retrieveFieldMarketingUsers(branchId);
  }
}


useEffect(()=>{
  getBranchId();
},[]);



  return (
    <Dialog maxWidth={"sm"} TransitionComponent={Slide} fullWidth open={open}>
      <div className="tableHeader-color">
        <DialogTitle>Task</DialogTitle>
        <Divider />
        <DialogContent sx={{ overflowY: "scroll" }}>
          <Grid container spacing={1}>
            {(role === "superAdmin" || role === "admin") && (
              <>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <InputLabel className="mb-1">Branches <span> *</span></InputLabel>
                  </Grid>
                  <TextField
                  required
                    fullWidth
                    select
                    size="small"
                    value={`${params?.branchId}` || ""}
                    // onChange={(e) => handleChange("branchId", e.target.value)}
                    onChange={(e) => {
                        handleChange("branchId", e.target.value);
                        retrieveFieldMarketingUsers(e.target.value);
                    }}
                  >
                    {branches?.map((branch) => (
                                    <MenuItem key={branch.id} value={branch.id}>
                                        {branch.name}
                                    </MenuItem>
                                ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <InputLabel className="mb-1">Users<span> *</span></InputLabel>
                  </Grid>
                  <TextField
                  required
                    fullWidth
                    select
                    size="small"
                    value={`${params?.userId}` || ""}
                    onChange={(e) => handleChange("userId", e.target.value)}
                  >
                    {fieldUsers?.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                  </TextField>
                </Grid>
              </>
            )}
            
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <InputLabel className="mb-1">Destination<span> *</span></InputLabel>
              </Grid>
              <TextField
                required
                value={params?.destination || ""}
                onChange={(e) => handleChange("destination", e.target.value)}
                fullWidth
                placeholder="Enter destination"
                size="small"
                // onClick={openMap}
              />
              
              
            </Grid>
            <Grid item xs={12}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <InputLabel className="mb-1">Get Location<span> *</span></InputLabel>
              </Grid>
              <Button
                variant="outlined"
                onClick={handleOpenMap}
                color="primary"
                size="small"
                fullWidth
              >
                Choose on Map
              </Button>
            <FieldMarketingMapModal open = {mapVisible}  close = {handleCloseMap} handleChange = {handleChange} params = {params}/>
          </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <InputLabel className="mb-1">
                  Date <span>*</span>
                </InputLabel>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  value={params?.date || null}
                  onChange={(e) => {
                    handleChange("date", formatDate(e));
                  }}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          
          <Grid
            item
            xs
            className="d-flex justify-content-end align-items-center mt-3"
          >
            <Button
              variant="outlined"
              onClick={cancel}
              color="primary"
              size="small"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button variant="outlined" color="primary" size="small" onClick={createTask}>
              Submit
            </Button>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
})
