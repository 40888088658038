/* eslint-disable */
import React, {
    useState,
    useEffect,
    useRef
} from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Slide,
    DialogActions,
    Button,
    TableRow,
    TableCell, TableHead
} from "@mui/material";
import {ExpandLess, ExpandMore, Upload} from "@mui/icons-material";
import Papa from "papaparse";
import {useDataParams} from "../hooks";
import {ImageUploader} from "../components/ImageUploader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {$crud} from "../services/CrudFactory";
import {useDispatch} from "react-redux";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import {baseUrl, IMAGE_BASE_URL, REACT_APP_BASE_URL} from "../constants";

export const CSVPreview = (props) => {
    const {
        open,
        onClose,
    } = props;
    const [rows, setRows] = useState([]);
    const [openSubList, setOpenSubList] = useState(false);
    const {params, handleChange, setParams} = useDataParams();
    const dispatch = useDispatch();
    const uploadCsv = async () => {
        const formData = new FormData();
        Object.entries(params).map(([key, value]) => {
            formData.append("csv", value)
        })
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post("create/web/upload-csv", formData);
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }
    useEffect(() => {
        params?.csv && Papa.parse(params?.csv, {
            step: function (row) {
                setRows(prev => [...prev, row.data])
            }
        });
    }, [params?.csv])

    return (
        <Dialog maxWidth="lg" TransitionProps={
            {
                onEnter: () => {
                }
            }
        } TransitionComponent={Slide} fullWidth open={open} onClose={() => {
            setRows([]);
            setParams({});
            onClose()
        }}>
            <div className="tableHeader-color">
                <DialogTitle sx={{display: "flex", padding: 1, alignItems: "center", justifyContent: "space-between"}}>CSV
                    Preview</DialogTitle>
                <Divider/>
                <DialogContent>
                    {
                        !params?.csv ?
                            <Grid container>
                                <Grid item xs={6}>
                                    <ImageUploader
                                        name="csv"
                                        onChange={(file) => handleChange("csv", file)}
                                        accept={".csv"}
                                        id="csv"
                                        onClick={() => setParams({})}
                                        file={params?.csv}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={6} px={4}>
                                    <Typography variant="subtitle1">Instructions</Typography>
                                    <List
                                        sx={{
                                            listStyleType: 'disc',
                                            pl: 4,
                                        }}
                                    >
                                        <ListItem disablePadding sx={{
                                            display: 'list-item',
                                        }}>
                                            <ListItemText
                                                primary={<><Link href="" underline="hover" color="primary" variant="body2">Download</Link><Typography variant="caption" > Sample CSV For Field Names.</Typography></>}

                                            />
                                        </ListItem>
                                        <ListItem disablePadding sx={{
                                            display: 'list-item',
                                        }}>
                                            <ListItemText primary={<><Typography variant="caption" > Go Through Panel For ID's of Records Where ID is Necessary, You Will Find IDs in  </Typography><Link href={`${REACT_APP_BASE_URL}settings`} target="_blank" variant="body2" underline="hover" color="primary"> Settings</Link><Typography variant="caption"> Section Of The Panel</Typography></>}/>
                                            <ListItemText
                                                primary={
                                                    <Table size="small" padding="none">
                                                        <TableHead >
                                                            <TableRow >
                                                                <TableCell sx={{ color:"inherit",fontWeight:"bold"}}>Field Name</TableCell>
                                                                <TableCell sx={{ color:"inherit",fontWeight:"bold"}}>Tab Name</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ color:"inherit"}}>statusId</TableCell>
                                                                <TableCell sx={{ color:"inherit"}}>Statuses</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ color:"inherit"}}>branchId</TableCell>
                                                                <TableCell sx={{ color:"inherit"}}>Branches</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ color:"inherit"}}>Id</TableCell>
                                                                <TableCell sx={{ color:"inherit"}}>Statuses</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                }
                                            />
                                        </ListItem>

                                    </List>
                                </Grid>
                            </Grid>
                            :
                            !!rows.length ? <Table>
                                <TableHead>
                                    <TableRow>
                                        {
                                            rows[0].map((column, index) => {
                                                return (
                                                    <TableCell key={index}
                                                               className="tableHeader-color">{column}</TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows.slice(1, 10).map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    {
                                                        row.map((column, index) => {
                                                            return (
                                                                <TableCell key={index}
                                                                           className="tableHeader-color">{column}</TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table> : "No Data Found"
                    }
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={uploadCsv} variant="contained" startIcon={<Upload/>}>Upload</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};