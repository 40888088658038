import {useDispatch} from "react-redux";
import {useDataParams} from "../hooks";
import {$crud} from "../services/CrudFactory";
import {Button, Dialog, DialogContent, DialogTitle, Divider, Grid, InputLabel, Slide, TextField} from "@mui/material";
import React from "react";

export const CommonModal = (props) => {
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const cancel = () => {
        props.onClose();
        setParams({});
    };

    const create = async () => {
        const url = props?.data?.id  ? `update/${props?.apiUrl}` :`create/${props?.apiUrl}`;
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post(url, {
                ...params,
                isUpdated: true
            });

            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });
            props.onSave();
            cancel();
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    // useEffect(() => {
    //     switch (props?.title) {
    //         case "College":
    //             setModalMeta({
    //                 title: props?.title,
    //                 url: props?.apiUrl
    //             });
    //             break;
    //         case "Degree":
    //             setModalMeta({
    //                 title: props?.title,
    //                 url: props?.apiUrl
    //             })
    //             break;
    //         case "University":
    //             setModalMeta({
    //                 title: props?.title,
    //                 url: props?.apiUrl
    //             })
    //             break;
    //
    //     }
    // }, [])

    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                    if (props.data?.id) {
                        setParams(props?.data);
                    }
                }
            }
        } TransitionComponent={Slide} fullWidth open={props.open}>
            <div className="tableHeader-color">
                <DialogTitle>{props?.title}</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Name<sup>*</sup></InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                value={params?.name || ""}
                                placeholder="Enter Name"
                                size="small"
                                onChange={(e) => handleChange("name", e.target.value)}
                            />
                        </Grid>

                    </Grid>

                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                        <Button className="ms-2" onClick={() => {
                            create();
                        }} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
};