import {
    Menu, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {ReactPagination} from "./Pagination";
import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {ADMIN_ROLE, SUPER_ADMIN_ROLE} from "../constants";
import {ConfirmationModal} from "../dialogs/ConfirmationModal";
import {$crud} from "../services/CrudFactory";
import {renderLead} from "../redux/actions";
import socket from "../socket";
import {TransferLeadsConfirmationModal} from "../dialogs";


 function DynamicTable(props) {
    const [selectedButton, setSelectedButton] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showTransferLeadsConfirmationModal, setShowTransferLeadsConfirmationModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const {
        rows, style = {}, columns, total = 0, id, onRowClick = () => {
        }, onStatusChange, onShowContactStatus, onDefaultStatusChange = () => {
        }, onEdit, onOpen, onPermissionEdit, anchorEl, onDelete, onFeeEmiChange, onClose, paginationProps: {onChangeLimit, onChangePage, limit, page}
    } = props;
    const userPermissions = useSelector((state) => state.userReducer).userPermissions?.map(p => p.permissionId);
    const userRole = useSelector((state) => state.userReducer)?.role?.name;
    const isMobileHide = useSelector(state => state.systemSettingsReducer.isMobileHide);
    const routeName = window.location.href.split("/").at(-1);
    const userMobileHide = localStorage.getItem("showContact");

    const dispatch = useDispatch();

    useEffect(() => {
        socket.on("isFavoriteU", (data) => {
            setData(prev => {
                return prev.map(d => {
                    if (d.id === data.id) {
                        d.isFavorite = data.isFavorite;
                    }
                    return d;
                });
            });
        });
        socket.on("isHotU", (data) => {
            setData(prev => {
                return prev.map(d => {
                    if (d.id === data.id) {
                        d.isHot = data.isHot;
                    }
                    return d;
                });
            });

        });
        socket.on("isFavoriteH", (data) => {
            setData(prev => {
                return prev.map(d => {
                    if (d.id === data.id) {
                        d.isFavorite = data.isFavorite;
                    }
                    return d;
                });
            });
        });
        socket.on("isHotH", (data) => {
            setData(prev => {
                return prev.map(d => {
                    if (d.id === data.id) {
                        d.isHot = data.isHot;
                    }
                    return d;
                });
            });
        });
        return () => {
            socket.off("isFavoriteU");
            socket.off("isHotU");
            socket.off("isFavoriteH");
            socket.off("isHotH");
        };
    }, []);

    const handleSelectRow = (id) => {
        selectedRows.includes(id) ? setSelectedRows(selectedRows.filter(sId => sId !== id)) : setSelectedRows(prev => [...prev, id]);
    };

    const handleSelectRowAll = (event) => {
        event.target.checked ? setSelectedRows(rows.map(row => row.id)) : setSelectedRows([]);
    };

    const removeLeads = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                type, message
            } = await $crud.post("delete/web/leads", {ids: selectedRows});
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            dispatch(renderLead(true));
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
            setShowConfirmationModal(false);
            setSelectedRows([]);
        }
    };
    const transferLeads = async (user) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                type, message
            } = await $crud.post("update/web/some-transfer-leads", {ids: selectedRows, ...user});
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            dispatch(renderLead(true));
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
            setShowTransferLeadsConfirmationModal(false);
            setSelectedRows([]);
        }
    };

    useEffect(() => {
        setData(rows);
    }, [rows]);

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            const divElement = document.getElementById("table");

            if (divElement) {
                if (windowWidth >= 750 && windowWidth <= 865) {
                    divElement.style.top = "230px";
                } else if (windowWidth >= 866 && windowWidth <= 919) {
                    divElement.style.top = "230px";
                } else if (windowWidth >= 920 && windowWidth <= 1240) {
                    divElement.style.top = "200px";
                } else {
                    divElement.style.top = "145px";
                }
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    //
    // useEffect(() => {
    //     const handleResize = () => {
    //         const windowWidth = window.innerWidth;
    //         const divElement = document.getElementById(id);
    //
    //         if (divElement) {
    //             if (windowWidth >= 750 && windowWidth <= 773) {
    //                 divElement.style.top = "180px";
    //             } else if (windowWidth >= 774 && windowWidth <= 1020) {
    //                 divElement.style.top = "131px";
    //             } else {
    //                 divElement.style.top = "85px";
    //             }
    //         }
    //     };
    //     handleResize();
    //     window.addEventListener("resize", handleResize);
    //     return () => {
    //         window.removeEventListener("resize", handleResize);
    //     };
    // }, []);

    return (
        <TableContainer
            id={id ? id : "table"}
            component={Paper}
            style={style}
            className="table-container tableHeader-color"
            elevation={1}
        >
            {/* <Table sx={{minWidth: 650}} size="small" stickyHeader> */}
            <Table sx={{minWidth: 500}} size="small" stickyHeader>
                <TableHead className="tableHeader-color" sx={{zIndex: 1050}}>
                    <TableRow className="tableHeader-color">
                        {
                            columns?.map((column, i) => {
                                const checked = rows?.every(r => selectedRows.includes(r.id));
                                const indeterminate = rows?.some(r => selectedRows.includes(r.id)) && !checked;
                                return (
                                    <TableCell key={column.id + i} align={column.align || "center"}
                                               className="tableHeader-color">
                                        {column.title === "selectAllRows" && [ADMIN_ROLE, SUPER_ADMIN_ROLE].includes(userRole) && ["unsaved", "unAssigned","lead"].includes(routeName) ?
                                            <Checkbox
                                                checked={checked}
                                                indeterminate={indeterminate}
                                                onChange={handleSelectRowAll}
                                            />
                                            :
                                            column.title !== "selectAllRows" ?
                                                column.title
                                                :
                                                null
                                        }
                                    </TableCell>
                                );
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody style={{height: "100%"}}>
                    {data?.map((row, i) =>
                        (
                            <TableRow
                                key={row?.id + `index-${i}`}
                                // key={row?.id + i}
                                className={row?.color ? null : selectedButton !== i ? "tableHeader-color hoveredRow" : "tableHeader-color-active-class hoveredRow"}
                                style={{backgroundColor: row?.color}}
                                onClick={() => {
                                    setSelectedButton(i);
                                    onRowClick(row?.id);
                                }}
                            >
                                {
                                    columns?.map((column, i) => {
                                        let value;
                                        if (column?.id === "mobile") {
                                            value = {first: row["mobile1"], second: row["mobile2"]};
                                        } else if (column?.id === "action" || column?.id === "photo") {
                                            value = row;
                                        } else {
                                            value = row[column.id];
                                        }
                                        if (column?.renderCell) {
                                            return (
                                                <>
                                                    <TableCell key={i} align="center">
                                                        {column?.renderCell({
                                                            row,
                                                            value,
                                                            selectedButton,
                                                            i,
                                                            userPermissions,
                                                            onEdit,
                                                            id: row?.id,
                                                            onOpen,
                                                            anchorEl,
                                                            onDelete,
                                                            onFeeEmiChange,
                                                            onClose,
                                                            onStatusChange,
                                                            onDefaultStatusChange,
                                                            onPermissionEdit,
                                                            isMobileHide,
                                                            onShowContactStatus,
                                                            userRole,
                                                            routeName,
                                                            userMobileHide,
                                                            setIsHot: (isHot) => socket.emit("isHot", {
                                                                id: row.id,
                                                                name: row.name || "UnSaved",
                                                                mobile: row.mobile1,
                                                                socketId: localStorage.getItem("users") != "undefined" && JSON.parse(localStorage.getItem("users") || "{}")?.socketId,
                                                                isHot,
                                                                isFavorite: row?.isFavorite
                                                            }),
                                                            setIsFavorite: (isFavorite) => socket.emit("isFavorite", {
                                                                id: row.id,
                                                                name: row.name || "UnSaved",
                                                                mobile: row.mobile1,
                                                                socketId: localStorage.getItem("users") != "undefined" && JSON.parse(localStorage.getItem("users") || "{}")?.socketId,
                                                                isFavorite,
                                                                isHot: row?.isHot
                                                            }),
                                                            handleSelectRow,
                                                            isChecked: selectedRows.includes(row.id),
                                                            menuProps: {
                                                                open,
                                                                onClick: (event, value) => {
                                                                    onOpen(event, value);
                                                                    setSelectedRow(value);
                                                                    setOpen(true);
                                                                }
                                                            },
                                                            ...props
                                                        })}
                                                    </TableCell>
                                                </>
                                            );
                                        } else {
                                            return (<TableCell key={i} align={"center"}>
                                                {value}
                                            </TableCell>);
                                        }
                                    })
                                }
                            </TableRow>
                        ))}
                </TableBody>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={() => setOpen(false)}
                    onClick={() => setOpen(false)}
                    PaperProps={{
                        elevation: 0,
                        className: "tableHeader-color",
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&:before": {
                                content: "''",
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "inherit",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{horizontal: "right", vertical: "top"}}
                    anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                >
                    {
                        props?.menuOptions?.map(({title, onClick}) => {
                            return (
                                <MenuItem
                                    key={title}
                                    onClick={() => onClick(selectedRow)}
                                >
                                    <Typography component="span">{title}</Typography>
                                </MenuItem>
                            );
                        })
                    }

                    {/* {
                            props?.menuOptions?.map(({ title, onClick, submenu }) => {
                                if (submenu && submenu.length > 0) {
                                    return (
                                        <React.Fragment key={title}>
                                            <MenuItem onClick={() => onClick(selectedRow)}>
                                                <Typography component="span">{title}</Typography>
                                            </MenuItem>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={handleCloseSubmenu}
                                            >
                                                {submenu.map((subItem) => (
                                                    <MenuItem
                                                        key={subItem.title}
                                                        onClick={() => {
                                                            handleCloseSubmenu();
                                                            subItem.onClick(selectedRow)}
                                                        }
                                                    >
                                                        <Typography component="span">{subItem.title}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <MenuItem key={title} onClick={() => onClick(selectedRow)}>
                                            <Typography component="span">{title}</Typography>
                                        </MenuItem>
                                    );
                                }
                            })
                        } */}
                </Menu>
            </Table>
            {!rows?.length &&
            <Typography className="text-center text-white font-weight-bold pt-2">
                No Records Found
            </Typography>
            }
            <ReactPagination
                style={{position: "sticky", bottom: "0", zIndex: "1050"}}
                total={total}
                page={page}
                actionProps={{
                    selectedRows,
                    removeLeads: () => {
                        setShowConfirmationModal(true);
                    },
                    transferLeads: () => {
                        setShowTransferLeadsConfirmationModal(true);
                    },
                }}
                setPageLimit={(limit) => onChangeLimit(limit)}
                onPageChange={(page) => onChangePage(page)}
                limit={limit}
            />
            {
                showConfirmationModal && <ConfirmationModal
                    open={showConfirmationModal}
                    onClose={() => setShowConfirmationModal(false)}
                    title={"Remove Leads From DataBase"}
                    content={<Typography variant="body1" component="span">Press &apos;Yes&apos; To delete Selected
                        Leads.</Typography>}
                    onOk={removeLeads}
                />
            }
            {
                showTransferLeadsConfirmationModal && <TransferLeadsConfirmationModal
                    open={showTransferLeadsConfirmationModal}
                    onClose={() => setShowTransferLeadsConfirmationModal(false)}
                    title={"Transfer Leads To"}
                    // content={<Typography variant="body1" component="span">Press &apos;Yes&apos; To Transfer Selected
                    //     Leads.</Typography>}
                    onOk={(user) => {
                        transferLeads(user);
                    }}
                />
            }
        </TableContainer>
    );
}
export default React.memo(DynamicTable);