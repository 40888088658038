// import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DynamicTable from "../components/DynamicTable";
import { usePagination } from "../hooks";
import { columns } from "../helpers/tableData";
import { useDispatch, useSelector } from "react-redux";
import { $crud } from "../services/CrudFactory";
// import { FieldMarketingModal } from "../dialogs";
import { FilterChipContainer } from "../components";
// import { FieldMarketingModal } from "../dialogs";

export const Task = () => {
  const { limit, setLimit, page, setPage, total, setTotal } = usePagination();
  const [fieldMarketingUsersTasks, setFieldMarketingUsersTasks] = useState([]);
  const dispatch = useDispatch();
  const [filtersWithValues, setFiltersWithValues] = useState({});
  const renderState = useSelector((state )=> state.renderFieldMarketingUsersTasks.render1);
  const filters = useSelector(state => state.filterReducer);
  // console.log(filtersWithValues, "----------filtersWithValues");

  const retrieveUsersTasks = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const { data } = await $crud.retrieve("web/tasks", {
        ...filtersWithValues,
        limit,
        page,
      });
      return data;
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  useEffect(() => {
    setFiltersWithValues((prev) => ({
      ...prev,
      ...filters?.withValues,
    }));
  }, [filters]);

  useEffect(() => {
    if(filtersWithValues?.branchId){
      retrieveUsersTasks().then((res) => {
        setFieldMarketingUsersTasks(res?.tasks);
        setTotal(res?.total);
      });
    }else{
      setFieldMarketingUsersTasks([]);
    }
  }, [limit, page, filtersWithValues, renderState]);

  


  return (
    <>
      <Grid container spacing={2} className="container-fluid top-navbar">
        <FilterChipContainer setFiltersWithValues = {setFiltersWithValues}/>
        {/* <FilterChipContainer /> */}
        <DynamicTable
          rows={fieldMarketingUsersTasks} 
          columns={columns?.task}
          style={{
            maxHeight: "calc(100vh - 250px)",
            position: "fixed",
            top: "150px",
            width: "calc(100% - 24px)",
          }}
          total={total}
          //   anchorEl={anchorEl}
          onRowClick={() => {}}
          onStatusChange={() => {
            //   statusChange(id)
          }}
          onEdit={() => {
            //   setOpenEmployeeDialog(true)
          }}
          onPermissionEdit={() => {
            //   setOpenPermissionDialog(true);
          }}
          onDelete={() => {
            //   setShowConfirmationModal(true);
            //   setCurrentId(id);
          }}
          onClose={() => {
            //   setAnchorEl(null);
            //   setEmployee(null);
            //   setPermission(null);
          }}
          onOpen={() => {
            //   setAnchorEl(event.currentTarget);
            //   setEmployee(value);
            //   setPermission(value);
          }}
          //   menuOptions={menuOptions}
          paginationProps={{
            limit: limit,
            page: page,
            onChangeLimit: (limit) => setLimit(limit),
            onChangePage: (page) => setPage(page),
          }}
        />

        {/* <FieldMarketingModal
        // setFieldMarketingUsersTasks = {setFieldMarketingUsersTasks}
        // onSave={() => {
        //     retrieveUsers().then(res => {
        //         setFieldMarketingUsers(res.tasks);
        //         setTotal(res.total);
        //     });
        // }}
        /> */}
      </Grid>
    </>
  );
};
