/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    Tooltip, Grid, Typography
} from "@mui/material";
import {ReactPagination} from "../components/Pagination";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch} from "react-redux";
import {Edit} from "@mui/icons-material"
import {LeadTypeTemplate} from "../dialogs/LeadTypeTemplate";
import {makeStyles} from "@mui/styles";
import {useDataParams} from "../hooks/useDataParams";
import {CourseModal} from "../dialogs/CourseModal";
import {DemoLeads} from "./DemoLeads";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";


export const LeadType = () => {
    const dispatch = useDispatch();
    const [leadTypeTemplate, setLeadTypeTemplate] = useState(null);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const [templates, setTemplates] = useState([]);
    const [courses, setCourses] = useState([]);
    const {setFilter, filters, params, setParams, handleChange} = useDataParams();
    const [templateType, setTemplateType] = useState([
        {
            name: "WhatsApp"
        },
        {
            name: "Sms"
        },
        {
            name: "email"
        }
    ]);
    const retrieveCourses = async () => {
        const {data} = await $crud.retrieve("web/course-all");
        setCourses(data);
    };

    const retrieveLeadTypeTemplates = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {templates, total}} = await $crud.retrieve("web/templates_type_msg", {
                limit,
                page,
                ...filters
            });
            setTemplates(templates);
            setTotal(total);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    const statusChange = async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("create/web/status/template_type_msg", {
                id
            });
            retrieveLeadTypeTemplates();
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }
    useEffect(() => {
        retrieveLeadTypeTemplates();
    }, [limit, page, filters]);
    useEffect(() => {
        retrieveCourses();
    }, [])

    return (
        <>
            <div>
                <div className='row w-100 g-2'>
                    <div className='mb-3 col-3'>
                        <ActionButton onClick={() => setLeadTypeTemplate({})} buttonName="Add Message Template"/>
                    </div>
                    <div className='mb-3 col-3'>
                        <div className="input-group">

                            <div className="input-group">
                                <select
                                    name="status"
                                    value={""}
                                    onChange={(e) => {
                                        handleChange("sourceId", e.target.value);
                                    }}
                                    className="form-select form-select-top backtransparent"
                                    id="inputGroupSelect04"
                                    aria-label="Example select with button addon"
                                >
                                    <option value="any" selected>Choose Course</option>
                                    {courses?.map((item, index) => {
                                        return (
                                            <option key={index} value={item?.id}>
                                                {item?.name}
                                            </option>
                                        );
                                    })}
                                </select>

                            </div>
                        </div>
                    </div>
                    <div className='mb-3 col-3'>
                        <div className="input-group">

                            <div className="input-group">
                                <select
                                    name="status"
                                    value={params?.templateTypeId}
                                    onChange={(e) => {
                                        handleChange("templateTypeId", e.target.value);
                                    }}
                                    className="form-select form-select-top backtransparent"
                                    id="inputGroupSelect04"
                                    aria-label="Example select with button addon"
                                >
                                    <option value="any" selected>Choose Template Type</option>
                                    {templateType?.map((item, index) => {
                                        return (
                                            <option key={index} value={item?.name}>
                                                {item?.name}
                                            </option>
                                        );
                                    })}
                                </select>

                            </div>
                        </div>
                    </div>

                    <div className='mb-3 col-3'>
                        <input onChange={(e) => setFilter("search", e.target.value)} className="form-control InputBox"
                               type="search" placeholder="Search" aria-label="Search"/>
                    </div>
                </div>
            </div>

            <DynamicTable
                rows={templates}
                columns={columns?.courseTemplate}
                total={total}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}
                onEdit={(value) => {
                    setLeadTypeTemplate(value)
                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
            <LeadTypeTemplate
                onSave={retrieveLeadTypeTemplates}
                leadTemplateType={leadTypeTemplate}
                onClose={() => {
                    setLeadTypeTemplate(null);
                }}
                open={!!leadTypeTemplate}
            />
        </>
    );
};

// const style  = { 
//     "@media screen and (min-width:768px) and (max-width:910px)":{
//         fontSize:"10px"
//     }
// }