import React, {useState} from "react";
import { IconButton,  Tooltip} from "@mui/material";
import {Add, Edit} from "@mui/icons-material";
import LeadForm from "../dialogs/LeadForm";
import Button from "@mui/material/Button";

export function LeadFormButton(props) {
    const {leadId,isEdit, selectedButton, i, modalProps:{ onClose, onOpen=()=>{}, onNew, anchor}} = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            {
                !isEdit ?
                    <Tooltip title="Add New Lead">
                        <Button size="small"  color="primary" variant="contained" sx={{minWidth:"30px",marginRight:"10px"}} onClick={()=>setOpen(true)}><Add/></Button>
                    </Tooltip>
                    :
                    <IconButton
                        style={{
                            color: selectedButton === i ? "black" : "white",
                        }}
                        onClick={()=>setOpen(true)}
                    >
                        <Edit/>
                    </IconButton>
            }
            <LeadForm
                leadId={leadId}
                anchor={anchor}
                open={open}
                onClose={()=> {
                    setOpen(false);
                    onClose();
                }}
                onOpen ={()=>onOpen()}
                onNew={()=>onNew()}
            />
        </React.Fragment>
    );
}