import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../../../../constants";
import { WhatsappContentCard } from "../../../../components/cards";
import { AddCard } from "../../../../components/cards/whatsappContents/AddCard";
import { $crud } from "../../../../services/CrudFactory";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export function WhatsAppLocation(props) {
  const { setWhatsappContent, setMessage } = props;
  const userRole = useSelector((state) => state.userReducer).role.name;
  const [positions, setPositions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleRadioChange = (index) => {
    setSelectedLocation((prevSelected) =>
      prevSelected === index ? null : index
    );
  };

  const getLocations = async () => {
    try {
      const { data } = await $crud.retrieve("web/whatsapp-locations");
      // console.log(data, "------data");
      setPositions(data?.Locations);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <RadioGroup sx={{ display: "flex", width: "100%" }}>
      <div className="row g-2" style={{ display: "flex", width: "100%" }}>
        {positions?.map((position, index) => {
          return (
            <div key={index} style={{ width: "50%"}} >
            <FormControlLabel
            sx={{ width: "100%", position:"relative" }}
            className="content-card-with-redio"
            key={index}
            value={position?.name}
            control={<Radio sx={{position:"absolute", top:0, left:0}} checked={selectedLocation === index}
            onClick={() => handleRadioChange(index)}/>}
            label={
              <WhatsappContentCard
              type="Location"
                data={position}
                key={index}
                setMessage={setMessage}
                url="whatsapp-location"
                setWhatsappContent={setWhatsappContent}
                setState={setPositions}
              />
            }
          />
          </div>
            // <WhatsappContentCard
            //   type="Location"
            //   data={position}
            //   key={index}
            //   url="whatsapp-location"
            //   setWhatsappContent={setWhatsappContent}
            //   setState={setPositions}
            // />
          );
        })}
        {(userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE) && (
            <div className="col-3">
              <AddCard
                type="Location"
                url="whatsapp-location"
                className="contact-card"
                containerStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
                state={positions}
                setState={setPositions}
              />
            </div>
          )}
      </div>
    </RadioGroup>
  );
}
