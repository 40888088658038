import * as React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import DynamicTable from "../components/DynamicTable";
import { columns } from "../helpers/tableData";

export function FooterDynamicTableModal(props) {
  const {
    footerData,
    open,
    onClose,
    setLimit,
    setPage,
    total,
    limit,
    page,
    title,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{
        className: "tableHeader-color",
      }}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "100%",
          height:"70vh",
          background:"red"
        },
      }}
      aria-describedby="alert-dialog-description"
    >
      <div className="tableHeader-color" style={{height:"100%"}}>
        <DialogTitle id="alert-dialog-title"  style={{height:"13%"}}>{title}</DialogTitle>
        <DialogContent  style={{height:"70%", overflow:"hidden"}}>
          <DynamicTable
            rows={footerData}
            columns={
              title !== "Total Follow-Ups"
                ? columns?.callDetails
                :  columns?.totalFollowups
            }
            total={total}
            paginationProps={{
              limit: limit,
              page: page,
              onChangeLimit: (limit) => setLimit(limit),
              onChangePage: (page) => setPage(page),
            }}
          />
        </DialogContent>
        <DialogActions  style={{height:"13%"}}>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={onClose} autoFocus variant="contained">
            Ok
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
