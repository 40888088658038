import React, {useEffect, useState} from "react";
import {Pagination, IconButton, Box, Typography, Tooltip, TextField, MenuItem} from "@mui/material";
import {Delete, SwapHoriz} from "@mui/icons-material";

export const ReactPagination = (props) => {
    const {onPageChange, page, total, limit, setPageLimit, style, actionProps: {selectedRows, removeLeads, transferLeads}} = props;

    const [toRecord, setToRecord] = useState(12);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);


    useEffect(() => {
        setFrom(+limit * (+page - 1) || 1);
    }, [page]);

    useEffect(() => {
        setTo((+limit * (+page - 1)) + +limit);
    }, [page, limit, total]);


    return (
        <div className="tablebody-color d-flex justify-content-between align-items-center p-2" style={style}>
            <div className="d-flex align-items-center">
                <span style={{fontSize: "12px"}}>Showing</span>
                <TextField
                    id="standard-select-currency"
                    sx={{
                        minWidth: 60,
                        mx: 1
                    }}
                    size="small"
                    select
                    value={toRecord}
                    onChange={(e) => {
                        setToRecord(e.target.value);
                        setPageLimit(e.target.value);
                    }}
                    variant="standard"
                >
                    {["12", "25", "50", "100", "500", "1000"].map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <span style={{fontSize: "12px"}}>({from} - {to})  of {total} Entries</span>

            </div>
            {
                Boolean(selectedRows.length) && <Box sx={{
                    display: "flex",
                    gap: 1,
                    backgroundColor: theme => theme.palette.primary.main,
                    padding: "2px 10px",
                    borderRadius: 5,
                    alignItems: "center"
                }}>
                    <Typography variant="caption" component="span">{selectedRows.length} Rows Selected</Typography>
                    <Tooltip title={"Delete Selected"}>
                        <IconButton size="small" onClick={removeLeads}>
                            <Delete fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Transfer Lead"}>
                        <IconButton size="small" onClick={transferLeads}>
                            <SwapHoriz/>
                        </IconButton>
                    </Tooltip>
                </Box>
            }
            <Pagination onChange={(e, page) => onPageChange(page)} count={Math.ceil(total / limit)} color="primary"/>
        </div>

    );
};
