import React from "react";
import {Grid, InputLabel, TextField} from "@mui/material";
// import {useDataParams} from "../../../hooks";

export function Text({formProps}) {
    const { params, handleChange } = formProps;
    // const {
    //     params, handleChange,
    //     // setParams
    // } = useDataParams();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <InputLabel className="mb-1">Message Title</InputLabel>
                </Grid>
                <TextField
                    value={params?.title || ""}
                    onChange={(e) => handleChange("title", e.target.value)}
                    placeholder="Enter Message Title"
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <InputLabel className="mb-1">Message Body</InputLabel>
                </Grid>
                <TextField
                    value={params?.body || ""}
                    multiline
                    rows={3}
                    onChange={(e) => handleChange("body", e.target.value)}
                    placeholder="Enter Email"
                    fullWidth
                    size="small"
                />
            </Grid>
        </Grid>
    );
}