/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    Tooltip,
    Typography,
    Button
} from "@mui/material";
import {ReactPagination} from "../components/Pagination";
import {SourceModal} from "../dialogs/SourceModal";
import {SpendSourceModal} from "../dialogs/SpendSourceModal";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch, useSelector} from "react-redux";
import {Edit} from "@mui/icons-material"
import {retrieveSources} from "./LoginPage";
import {sources} from "../redux/actions";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";

export const Sources = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(null);
    const [spend, setSpend] = useState(null);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const [leadSourceExpenses, setLeadSourceExpenses] = useState([]);
    const allSources = useSelector(state => state.sourcesReducer);

    const retrieveLeadSourceExpenses = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {leadSourceExpense}} = await $crud.retrieve("web/lead-source-expenses");
            setLeadSourceExpenses(leadSourceExpense);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    const statusChange=async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("create/web/status/lead-sources", {
                id
            });
            retrieveSources().then(res=>{
                dispatch(sources(res?.leadSources))
            })
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            })
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        retrieveLeadSourceExpenses();
    }, [limit, page]);

    return (
        <>
            <div>
                <div className='row w-100 g-2 '>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setOpen({})} buttonName="Add Lead Source"/>
                        <ActionButton onClick={() => setSpend({})} buttonName="Add Spend on Lead Source"/>
                    </div>
                </div>
            </div>
            <DynamicTable
                rows={allSources}
                columns={columns?.source}
                style={{maxHeight: "calc(100vh - 250px)"}}
                total={total}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}

                onEdit={(value) => {
                    setOpen(value)

                }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
            <SourceModal
                source={open}
                onSave={() => {
                }}
                open={!!open}
                onClose={() => setOpen(null)}
            />
            <SpendSourceModal
                onSave={retrieveLeadSourceExpenses}
                open={!!spend}
                onClose={() => setSpend(null)}
            />
        </>
    );
};

