/* eslint-disable */
import React, {useState} from "react";
import {Grid, Typography, Box, Collapse} from "@mui/material";
import {Apps, KeyboardArrowRight, KeyboardArrowDown} from "@mui/icons-material";
import {
    Sources,
    LeadType,
    Branch,
    Department,
    RolePermissions,
    Devices,
    Employee,
    Statuses,
    BankDetails,
    ContactDetails,
    LeadConvertType,
    Colleges,
    Universities,
    Degrees,
    WhatsAppForm,
    CBSLApps, TransferData, SystemSettings, TemplateGenerator, Announcement, Tags
} from "./";
import {URLs} from "./URLs";
import {useSelector} from "react-redux";
import {MANAGER_ROLE, ADMIN_ROLE,SUPER_ADMIN_ROLE} from "../constants";
import Chip from "@mui/material/Chip";
import {IoIosArrowForward} from "react-icons/io";


export function Settings() {
    const [active, setActive] = useState(0);
    const [checked, setChecked] = useState(false);
    const userRole = useSelector(state => state.userReducer).role.name;
    const [selectedItem, setSelectedItem] = useState(userRole === ADMIN_ROLE || SUPER_ADMIN_ROLE ? "Courses & Templates" : "Users");
    const data = [
        {
            title: "Courses & Templates",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Sources",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Branch",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Statuses",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Department",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Lead Convert Type",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "College",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "University",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Degree",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Roles & Permissions",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Users",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, MANAGER_ROLE],
        },
        {
            title: "Bulk",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
            subLinks: [
                {
                    title: "WhatsApp",
                    access: [SUPER_ADMIN_ROLE,ADMIN_ROLE]
                },
                {
                    title: "SMS",
                    access: [SUPER_ADMIN_ROLE,ADMIN_ROLE]
                },
                {
                    title: "Email",
                    access: [SUPER_ADMIN_ROLE,ADMIN_ROLE]
                }
            ]
        },
        {
            title: "Phone/Mobile",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Bank Details",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Contact Details",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "WhatsApp",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, MANAGER_ROLE],
        },
        {
            title: "URL",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Apps",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Transfer Data",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "System Settings",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title: "Generate Template",
            access: [SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title:"Announcements",
            access:[SUPER_ADMIN_ROLE,ADMIN_ROLE],
        },
        {
            title:"Tags",
            access:[SUPER_ADMIN_ROLE,ADMIN_ROLE],
        }
    ];

    const renderComponent = {
        "Courses & Templates": <LeadType/>,
        "Sources": <Sources/>,
        "Branch": <Branch/>,
        "Statuses": <Statuses/>,
        "Department": <Department/>,
        "Lead Convert Type": <LeadConvertType/>,
        "College": <Colleges/>,
        "Degree": <Degrees/>,
        "University": <Universities/>,
        "Roles & Permissions": <RolePermissions/>,
        "Users": <Employee/>,
        "Phone/Mobile": <Devices/>,
        "Bank Details": <BankDetails/>,
        "Contact Details": <ContactDetails/>,
        "WhatsApp": <WhatsAppForm/>,
        "URL": <URLs/>,
        "Apps": <CBSLApps/>,
        "Transfer Data": <TransferData/>,
        "System Settings": <SystemSettings/>,
        "Generate Template" :<TemplateGenerator/>,
        "Announcements":<Announcement/>,
        "Tags":<Tags/>
    }

    return (
        <Grid container spacing={2} p={2} sx={{height: "calc(100vh + 16px)"}}>
            <Grid item sx={{height: "100%"}}>
                <Box className="setting-sidebar" sx={{maxHeight: "100%", overflow: "scroll"}}>
                    <Typography className="setting-sidebar-heading" variant="h6">Settings</Typography>
                    {
                        data.map((item, index) => {
                            if (item.access.includes(userRole)) {
                                return (
                                    <React.Fragment>
                                        <Box style={{cursor: "pointer"}} onClick={() => {
                                            setActive(index)
                                            item.title !== "Bulk" && setSelectedItem(item?.title);
                                            item.title === "Bulk" && setChecked(!checked)
                                        }}
                                             className={index === active ? "active d-flex p-2 justify-content-between align-items-center" : "d-flex p-2 justify-content-between align-items-center"}>
                                            <div className="d-flex align-items-center">
                                                <Apps className=""/>
                                                <Typography className="ms-1 nav-links">{item.title}</Typography>
                                            </div>
                                            <div>
                                                {item.title === "Bulk" && checked  ? <KeyboardArrowDown className="iconColor"/> :<KeyboardArrowRight className="iconColor"/>}
                                            </div>
                                        </Box>

                                        {
                                            item?.subLinks && <Collapse in={checked} sx={{ml:1}}>
                                                {item?.subLinks.map(item => {
                                                    return (
                                                        <Box style={{cursor: "pointer"}} onClick={() => {
                                                            // setActive(index)
                                                            setSelectedItem(item?.title);
                                                        }}
                                                             className={ "d-flex p-2 justify-content-between align-items-center"}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <Apps className=""/>
                                                                <Typography
                                                                    className="ms-1 nav-links">{item.title}</Typography>
                                                            </div>
                                                            <div>
                                                                <KeyboardArrowRight className="iconColor"/>
                                                            </div>
                                                        </Box>
                                                    )
                                                })}
                                            </Collapse>
                                        }
                                    </React.Fragment>
                                )
                            }
                        })
                    }
                </Box>

            </Grid>
            <Grid item xs sx={{overflowX:"scroll"}}>
                {renderComponent[selectedItem]}
            </Grid>
        </Grid>
    );
}
