import {Chip, Grid, IconButton} from "@mui/material";
import {removeFilter} from "../redux/actions";
import {Close} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import { useLocation } from "react-router-dom";


export const FilterChipContainer = React.memo(function FilterChipContainer(props){
    const {id, setFiltersWithValues} = props;
    const [filtersWithLabel, setFiltersWithLabel] = useState({});
    const filters = useSelector(state=>state.filterReducer);
    const dispatch = useDispatch();
    const location = useLocation();

    const fieldEmpty = (key)=>{
      dispatch(removeFilter(key));
      setFiltersWithValues({branchId:1});
    };

    useEffect(()=>{
        setFiltersWithLabel(filters?.withLabels);
    },[filters]);

    useEffect(() => {
        const handleResize = () => {
          const windowWidth = window.innerWidth;
          const divElement = document.getElementById("filterField");

          if (divElement) {
            if (windowWidth >= 750 && windowWidth <= 919) {
              divElement.style.top = "180px";
            } else if(windowWidth >= 920 && windowWidth <= 1240) {
              divElement.style.top = "145px";
            }else{
                divElement.style.top = "90px";
            }
          }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    useEffect(()=>{
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            const divElement = document.getElementById(id);

            if (divElement) {
              if (windowWidth >= 750 && windowWidth <= 910) {
                divElement.style.top = "120px";
              }else{
                divElement.style.top = "90px";
              }
            }
          };
          handleResize();
          window.addEventListener("resize", handleResize);
          return () => {
            window.removeEventListener("resize", handleResize);
          };
    },[]);

    const defaultPosition ={position:"fixed", top:"90px",left:"0"};
    return (
        <Grid container item xs={6} style={props?.style || defaultPosition} id = {id ? id : "filterField"}>
            <Grid item xs={12} mx={1}>
                {
                    Object.entries(filtersWithLabel).map(([key, value]) => {
                        return <Chip
                            key={key}
                            color="primary"
                            sx={{marginRight: "5px"}}
                            label={
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs>{key}</Grid>
                                    <Grid item
                                          xs>{["leadScore", "leadValue", "followupCount", "leadCount", "date"].includes(key) ? JSON.parse(value).from + "-" + JSON.parse(value).to : value}</Grid>
                                    <Grid item xs>
                                        <IconButton onClick={() => {
                                          location.pathname !== "/field-marketing/task" ? dispatch(removeFilter(key)) : fieldEmpty(key);
                                          }} size="small"><Close/></IconButton>
                                    </Grid>
                                </Grid>
                            }
                        />;
                    })
                }
            </Grid>
        </Grid>
    );
});