import React from "react";

export function PersonAttachment() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
<g filter="url(#filter0_d_554_3219)">
<rect x="10" y="6" width="50" height="50" rx="25" fill="#19ABF0"/>
<path d="M60 31C60 24.3696 57.3661 18.0107 52.6777 13.3223C47.9893 8.63392 41.6304 6 35 6C28.3696 6 22.0107 8.63392 17.3223 13.3223C12.6339 18.0107 10 24.3696 10 31L35 31H60Z" fill="#1295D8"/>
<path d="M30.5 25.5C30.5 27.981 32.519 30 35 30C37.481 30 39.5 27.981 39.5 25.5C39.5 23.019 37.481 21 35 21C32.519 21 30.5 23.019 30.5 25.5ZM43 40H44V39C44 35.141 40.859 32 37 32H33C29.14 32 26 35.141 26 39V40H43Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_554_3219" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_554_3219"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_554_3219" result="shape"/>
</filter>
</defs>
</svg>
  );
}
