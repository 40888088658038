/* eslint-disable */
import React, {
  useEffect,
  useState,
  // useEffect
} from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import Sidebar from "../components/SideBar";
import {
  LoginPage,
  Sms,
  Dashboard,
  FeesChart,
  Media,
  Settings,
  Unsaved,
  TodayFollowUp,
  Visited,
  Report,
  Announcement,
  Email,
  Sources,
  PasswordChange,
  ConvertedLeads,
  RetargetedLeads,
  DemoLeads,
  ArchivedLeads,
  MissedFollowUp,
  FutureScheduleLeads,
  Leads,
  WhatsAppForm,
  DndLeads,
  UploadCSV,
  TeacherScheduler,
  Lead,
  TransferData,
  TemplateGenerator,
  FieldMarketing,
  Task,
} from "../pages";
import SmsForm from "../dialogs/SmsForm";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress, ThemeProvider } from "@mui/material";
import Snackbar from "../components/Snackbar";
import { UnAssignedLeads } from "../pages/UnAssignedLeads";
import Footer from "../components/Footer";
import { Header } from "../components";
import { CallProgressModal, RealTimePopup } from "../dialogs";
import { Test } from "../pages/Test";
import socket from "../socket";
import {
  setCallData,
  setCallLog,
  setFollowUpLeadId,
  setSystemSettings,
  startCallTimer,
  stopCallTimer,
} from "../redux/actions";
import { $crud } from "../services/CrudFactory";

function Router() {
  const [count, setCount] = useState(null);
  const [data, setData] = useState({
    name: "",
    callStatus: "",
    incoming: false,
  });
  const snackBar = useSelector((state) => state.newReducer.snackBar);
  const loader = useSelector((state) => state.newReducer.loader);
  const locked = useSelector((state) => state.sidebarLockerReducer);
  const callData = useSelector((state) => state.callDataReducer);

  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("sessionId")) {
      socket.auth.sessionId = localStorage.getItem("sessionId");
    }
  }, []);

  return (
    <>
      {loader && (
        <LinearProgress
          sx={{
            zIndex: "5555",
            position: "fixed",
            top: "0",
            height: "3px",
            width: "100%",
          }}
        />
      )}
      <Snackbar
        open={!!snackBar}
        type={snackBar?.type}
        message={snackBar?.message}
      />
      <RealTimePopup title="Lead Followup Reminder" />
      <CallProgressModal open={!!callData.name} data={callData} />

      <Routes>
        {/* Protected Routes  */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route
            element={
              <Sidebar locked={locked} count={count} setCount={setCount} />
            }
          >
            <Route
              element={
                <Footer
                  pageName={location.pathname.replace("/", " ")}
                  count={count}
                  callData={callData}
                />
              }
            >
              <Route element={<Header count={count} />}>
                <Route path="/" element={<Navigate replace to="dashboard" />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="leads/:type" element={<Leads count={count} />} />
                <Route
                  path="customFiltersPage/:id"
                  element={<TodayFollowUp count={count} />}
                />
                <Route
                  path="todayFollowUp"
                  element={<TodayFollowUp count={count} />}
                />
                <Route
                  path="missedFollowUp"
                  element={<MissedFollowUp count={count} />}
                />
                <Route
                  path="futureSchedules"
                  element={<FutureScheduleLeads count={count} />}
                />
                <Route path="lead" element={<Lead count={count} />} />
                <Route path="visited" element={<Visited count={count} />} />
                <Route
                  path="converted"
                  element={<ConvertedLeads count={count} />}
                />
                <Route path="demo" element={<DemoLeads count={count} />} />
                <Route
                  path="retargeted"
                  element={<RetargetedLeads count={count} />}
                />
                <Route
                  path="doNotDisturb"
                  element={<DndLeads count={count} />}
                />
                <Route
                  path="archived"
                  element={<ArchivedLeads count={count} />}
                />
                <Route path="report" element={<Report />} />
                {/* <Route path="field-marketing" element={<FieldMarketing />} /> */}
                <Route path="field-marketing">
                  <Route index element={<FieldMarketing />} />
                  <Route path="task" element={<Task />} />
                </Route>
                <Route path="sms" element={<Sms />} />
                {/*<Route path="whatsAppForm" element={<WhatsAppForm/>}/>*/}
                <Route path="email" element={<Email />} />
                <Route path="Annoucement" element={<Announcement />} />
                <Route path="feesChart" element={<FeesChart />} />
                <Route path="media" element={<Media />} />
                <Route path="unsaved" element={<Unsaved count={count} />} />
                <Route
                  path="unassigned"
                  element={<UnAssignedLeads count={count} />}
                />
                <Route path="smsForm" element={<SmsForm />} />
                <Route path="sources" element={<Sources />} />
                <Route path="scheduler" element={<TeacherScheduler />} />
                <Route path="uploadCSV" element={<UploadCSV />} />
                <Route path="test" element={<Test />} />
                {/*<Route path="template-generator" element={<TemplateGenerator/>}/>*/}
                {/*<Route path="test" element={<TransferData/>}/>*/}
              </Route>
            </Route>
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route path="passwordChange" element={<PasswordChange />} />
        </Route>

        {/* Public Routes  */}
        <Route path="/" element={<PublicRoute />}>
          <Route path="/login-page" element={<LoginPage />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default Router;
