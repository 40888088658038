import { useState } from "react";

export const usePagination = () => {
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [assignTo, setAssignTo] = useState(localStorage.getItem("user_id"));
  return {
    limit,
    setLimit,
    page,
    setPage,
    total,
    setTotal,
    assignTo,
    setAssignTo,
  };
};
