/* eslint-disable */
import React, { useEffect, useState } from "react";
import {Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider,Autocomplete, Chip, FormControlLabel, Grid, InputLabel, Select, Slide, TextField} from "@mui/material";
import {useDataParams} from "../hooks";
import { useDispatch } from "react-redux";
import { $crud } from "../services/CrudFactory";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formateDateAndTime } from "../helpers/functions";
import { useLocation } from "react-router-dom";

export function AnnouncementModal(props){

    const {params, handleChange,setParams} = useDataParams();
    const [branches, setBranches] = useState([]);
    const [branchIds, setBranchIds] = useState([])
    const dispatch = useDispatch();
    const location = useLocation();
    const { open, onClose,data} = props
    // console.log(location.pathname, "--------location");

    // const [selectedBranchNames, setSelectedBranchNames] = useState([]);

    

    const cancel = () => {
        onClose();
        setParams({});
        setBranchIds([]);
    }

    const retrieveBranches = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data: {branches}} = await $crud.retrieve("web/branches");
            setBranches(branches);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    const handleSubmit = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post(
                !props?.data?.id ? "create/web/announcement" : "update/web/announcement", params
            );
            
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            if(location.pathname === "/settings"){
                props.onSave();
            }
            cancel();
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        open && retrieveBranches();
    }, []);

    // useEffect(() => {
    //     const names = branches.filter(branch => branchIds.includes(branch.id)).map(branch => branch.name);
    //     setSelectedBranchNames(names);
    // }, [branches, branchIds]);

    // console.log(selectedBranchNames , "-----------------selectedBranchNames");
    // console.log(branchIds , "------------branchIds");
    // console.log(params, "-------params");

    // useEffect(() => {
    //     data?.id && setParams(data)
    // }, [data])

    
    return (
        <Dialog maxWidth={"xs"}  TransitionProps={
            {
                onEnter: () => {
                    // if (props.data?.id){
                    //     setBranchIds(prev => {
                            
                    //     })
                    // }
                    if (data?.id) {
                        setParams(data);
                        setBranchIds( 
                            [data]?.map((item) =>
                                item.branch_id
                            )
                        );
                    }
                }
            }
        } TransitionComponent={Slide} fullWidth open={open}>
            <div className="tableHeader-color">
                <DialogTitle >Add Announcement</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Topic</InputLabel>
                            </Grid>
                            <TextField
                                required
                                value={params?.topic || ""}
                                onChange={(e) => handleChange("topic", e.target.value)}
                                fullWidth
                                placeholder="Enter Topic"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Topic Description</InputLabel>
                            </Grid>
                            <TextField
                                multiline
                                rows={3}
                                required
                                value={params?.topicDescription || ""}
                                onChange={(e) => handleChange("topicDescription", e.target.value)}
                                fullWidth
                                placeholder="Enter Topic Description"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6} md={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Start Date</InputLabel>
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker 
                                    required
                                    value={params?.startDate || null}
                                    onChange = {(e)=>{
                                        handleChange("startDate", formateDateAndTime(e))
                                    }}
                                    fullWidth
                                    renderInput={(props) => <TextField {...props} />}
                                    size="small"
                                />
                            </LocalizationProvider>
                            
                            {/* <DateTimePicker 
                                 required
                                 value={params?.startDate || ""}
                                 onChange={(e) => handleChange("startDate", e.target.value)}
                                 fullWidth
                                 placeholder="Enter Start Date"
                                 size="small"
                            /> */}

                            {/* <TextField
                                required
                                value={params?.startDate || ""}
                                onChange={(e) => handleChange("startDate", e.target.value)}
                                fullWidth
                                placeholder="Enter Start Date"
                                size="small"
                                // type="date"
                                type=""
                            /> */}

                        </Grid>
                        <Grid item xs={6} sm={6} lg={6} md={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">End Date</InputLabel>
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker 
                                    required
                                    value={params?.endDate || null}
                                    onChange = {(e)=>{
                                        handleChange("endDate", formateDateAndTime(e))
                                    }}
                                    fullWidth
                                    renderInput={(props) => <TextField {...props} />}
                                    // label="Enter Start Date"
                                    size="small"
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <label className={"form-label"}>
                                Branches
                            </label>

                            {/* <Autocomplete
                multiple
                id="tags-filled"
                options={branches?.map((option) => option.name)}
                value={branchIds}
                onChange={(e,value)=>{
                    setBranchIds(value)
                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  )})
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="freeSolo"
                    // placeholder="Favorites"
                  />
                )}
              /> */}

                            <Select
                                margin="dense"
                                color="primary"
                                multiple
                                // onChange={(e) => setBranchIds(e.target.value)}
                                onChange={(e) => {
                                    const selectedValues = e.target.value.filter(value => typeof value === "number");
                                    setBranchIds(selectedValues);
                                }}
                                value={branchIds || []}
                                // renderValue={(selected) =>{ 
                                //     return selected.join(",")}}
                                renderValue={(selected) => {
                                    const selectedNames = branches
                                        .filter(branch => selected.includes(branch.id))
                                        .map(branch => branch.name);
                                    return selectedNames.join(",");
                                }}
                                size="small"
                                fullWidth

                            >
                                <div className="tableHeader-color" style={{padding: "16px"}}>
                                {branches?.map((branch) => (
                                    
                                        <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                            {
                                                <FormControlLabel
                                                    label={branch.name}
                                                    control={
                                                        <Checkbox
                                                            name={branch.name}
                                                            value={branch.id}
                                                            checked={branchIds?.includes(branch?.id)}
                                                            onChange={(e) => {
                                                                if (typeof +e.target.value === "number") {
                                                                    setParams((prev) => {
                                                                        const existingBranch = prev?.branches?.find(b => b.id === +e.target.value);
                                                                        if (existingBranch) {
                                                                            return {
                                                                                ...prev,
                                                                                branches: prev?.branches?.filter(b => b.id !== +e.target.value)
                                                                            };
                                                                        } else {
                                                                            return {
                                                                                ...prev,
                                                                                branches: [...(prev.branches || []), { id: +e.target.value, name: e.target.name }]
                                                                            };
                                                                        }
                                                                    });

                                                                    setBranchIds(prev => {
                                                                        const data = [...prev];
                                                                        if (data?.includes(+e.target.value)) {
                                                                            return _.pull(data, +e.target.value)
                                                                        } else {
                                                                            return [...data, +e.target.value]
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                        </Box>
                                ))}
                                </div>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                        <Button variant="outlined" onClick={()=>{
                            props.onClose();
                            setParams({});
                            }} color="primary" size="small">Cancel</Button>
                        {/* <Button variant="outlined" onClick={()=>{close ? close() : props.onClose()}} color="primary" size="small">Cancel</Button> */}
                        <Button className="ms-2" onClick={handleSubmit} variant="contained" color="primary" size="small">Submit</Button>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    )
}