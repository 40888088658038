import React, {
    // useLayoutEffect,
    useState,
    useEffect
} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {ChartContainer} from "./ChartContainer";
import {$crud} from "../../services/CrudFactory";
import {useDispatch} from "react-redux";

export function BarChart(props) {
    const {chartID,chartTitle, style, userId} = props;
    const [chartData, setChartData] = useState([]);
    const [filterType, setFilterType] = useState("monthly");
    const dispatch = useDispatch();
    const retrieveChartData = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data
            } = await $crud.retrieve(`web/${url}`, {
                [filterType]: true,
                userId,
            });
            return data;
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    useEffect(()=>{
        retrieveChartData("user-report-follow-up").then(res=>setChartData(res.followUpReport));
    },[userId, filterType]);

    useEffect(() => {
        var root = am5.Root.new(chartID);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX"
        }));

        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomX"
        }));
        cursor.lineY.set("visible", false);


        var value = 100;

        function generateData(priorDate) {
            value = 0;
            am5.time.add(priorDate, "day", 1);
            const exist = chartData?.find(d => new Date(d.date).setHours(0,0,0,0) == priorDate.getTime());
            if (exist) {
                value = exist.count;
            }
            return {
                date: priorDate.getTime(),
                value: value
            };
        }

        function generateDatas(count) {
            var date = new Date();
            let daysToBack = filterType === "monthly" ? 30 : 7;
            date.setHours(0, 0, 0, 0);
            let priorDate = new Date(new Date(date).setDate(date.getDate() - daysToBack));
            var data = [];
            for (var i = 0; i < count; ++i) {
                data.push(generateData(priorDate));
            }
            return data;
        }

        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 60
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));
        let xRenderer = xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            fill: am5.color("#FFF"),
        });
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));
        let yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fill: am5.color("#FFF"),
        });
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            // fill:am5.color("#fff"),
            stroke:am5.color("#9747FF"),
            // tooltip: am5.Tooltip.new(root, {
            //     labelText: "{valueY}",
            //
            // }).get("background").setAll({
            //     fill:am5.color("#9747FF")
            // })
        }));
        var tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            labelText: "{valueY}"
        });

        tooltip.get("background").setAll({
            fill: am5.color("#9747FF"),
            fillOpacity: 0.8
        });

        series.set("tooltip", tooltip);
        series.columns.template.set("fillGradient", am5.LinearGradient.new(root, {
            stops: [{
                color: am5.color("#9747FF")
            }, {
                color: am5.color("#954bd1")
            }],
            rotation: 90
        }));
        // series.columns.template.set({strokeOpacity: 1});
        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //     orientation: "horizontal",
        //
        // }));

        var data = generateDatas(filterType === "monthly" ? 30 : 7);
        series.data.setAll(data);

        series.appear(1000);
        chart.appear(1000, 100);


        return () => {
            root.dispose();
        };
    }, [chartID, chartData]);
    // console.log({follow:chartData});
    return (
        <ChartContainer chartID={chartID} chartTitle={chartTitle} style={style} filterProps={{setFilterType}}/>
    );
}
