import React, { useState } from "react";
import { LeadModalSidebar } from "../../../../components/leadSidebar";
// import {shareableImages} from "../../../../data/shareableFiles";
import { WhatsappContentCard } from "../../../../components/cards";
import { AddCard } from "../../../../components/cards/whatsappContents/AddCard";
import { useSelector } from "react-redux";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../../../../constants";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export function WhatsAppImages(props) {
  const { setWhatsappContent, courses, setMessage } = props;
  // const [messageType, setMessageType] = useState("dm");
  const userRole = useSelector((state) => state.userReducer).role.name;
  // let data = shareableImages.find(file => file.type === messageType);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [courseId , setCourseId] = useState("");
  // console.log(courseId, "--------courseId");

  const handleRadioChange = (index) => {
    setSelectedImage((prevSelected) =>
      prevSelected === index ? null : index
    );
    setCourseId((prevSelected) => 
    prevSelected === courseId ? null : courseId
    );
  };

  const containerStyle = {
    minWidth: 120,
    textAlign: "center",
    cursor: "pointer",
    position: "relative",
    border: "1px solid gray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className="row">
      <div className="col-3">
        <div className="lead-modal-sidebar">
          {/* <LeadModalSidebar setMessageType={setMessageType} data={shareableImages}/> */}
          <LeadModalSidebar
            data={courses}
            setState={setImages}
            url="course-images"
            setCourseId = {setCourseId}
          />
        </div>
      </div>
      <div className="col-9">
        <RadioGroup sx={{ display: "flex", width: "100%" }}>
          <div className="lead-modal-message-container">
            <div
              className="inner"
              style={{ display: "flex", flexWrap: "wrap", gap:10 }}
            >
              {images?.map((image, index) => {
                return (
                    <div key={index} >
                  <FormControlLabel
                    sx={{ width: "50%", position: "relative" }}
                    className="content-card-with-redio"
                    key={index}
                    value={image?.id}
                    control={
                      <Radio sx={{ position: "absolute", top: 0, left: 0 }} checked={selectedImage === index}
                      onClick={() => handleRadioChange(index)}/>
                    }
                    label={
                      <WhatsappContentCard  
                        type="image"
                        data={image}
                        key={index}
                        setMessage={setMessage}
                        url="course-image"
                        setWhatsappContent={setWhatsappContent}
                        setState={setImages}
                      />
                    }
                  />
                  </div>
                  // <WhatsappContentCard type="image" data={image} url="course-image" key={index} setWhatsappContent = {setWhatsappContent} setState={setImages}/>
                );
              })}
              {(userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE) && (
                  <AddCard
                    courses={courses}
                    type="Image"
                    url="course-image"
                    containerStyle={containerStyle}
                    state={images}
                    setState={setImages}
                  />
                )}
            </div>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}
