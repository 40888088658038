import React, {useEffect, useState, useCallback, useRef} from "react";
import {Box, Stack, CircularProgress} from "@mui/material";
import WhatsappChatBubble from "./WhatsappChatBubble";
import axios from "axios";
import moment from "moment";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {WEB_API} from "../constants";
import {useDispatch}  from "react-redux";

export const WhatsappChatContainer = (props) => {
    const {mobileNumber, messages, setMessages} = props;
    const [isLoading, setIsLoading] = useState(false);

    const user = useSelector((state) => state.userReducer);
    const dispatch =  useDispatch();
    const messagesEndRef = useRef(null);

    const getUserChats = useCallback(async () => {
        setIsLoading(true);
        try {
            const {data: {data}} = await axios.get(`${WEB_API}/thirdpartychats?receiverNumber=91${mobileNumber}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.crmWhatsAppAccessKey}`
                }
            });
            setMessages(data?.map((d) => {
                if (d.sendMessage) {
                    return d.hasMedia ? {
                            id: d.message_id,
                            timestamp: moment(`${d.create_date}`).format("YYYY-MM-DD HH:mm"),
                            sender: "You",
                            ack: d.ack,
                            attachment: {
                                fileName: d.fileName,
                                caption: d.sendMessage,
                                mimetype: d.mimetype,
                                file: d.mediaFile
                            },
                            content: d.sendMessage,
                        } :
                        {
                            id: d.message_id,
                            timestamp: moment(`${d.create_date}`).format("YYYY-MM-DD HH:mm"),
                            sender: "You",
                            ack: d.ack,
                            content: d.sendMessage,
                        };
                } else {
                    return d.hasMedia ? {
                            id: d.message_id,
                            timestamp: moment(`${d.create_date}`).format("YYYY-MM-DD HH:mm"),
                            sender: d.receiverNumber,
                            ack: null,
                            attachment: {
                                fileName: d.fileName,
                                caption: d.sendMessage,
                                mimetype: d.mimetype,
                                file: d.mediaFile
                            },
                            content: d.receiverMessage,
                        } :
                        {
                            id: d.message_id,
                            timestamp: moment(`${d.create_date}`).format("YYYY-MM-DD HH:mm"),
                            sender: d.receiverNumber,
                            ack: null,
                            content: d.receiverMessage,
                        };
                }
            }));
            setIsLoading(false);
        } catch (e) {
            console.error(e.message);
            setIsLoading(false);
            dispatch({
                type: "SNACKBAR",
                snackBar: {type:"error", message:e.message},
            });

        }
    }, [mobileNumber]);


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    useEffect(() => {
        getUserChats();
    }, []);

    console.log({isLoading});
    return (
        <Box
            sx={{
                display: "flex",
                flex: 1,
                minHeight: 0,
                overflowY: "scroll",
                flexDirection: "column-reverse",
            }}
        >


            {isLoading ?
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    height: "58vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress color="primary"/>
                </Box>
                :
                <Stack spacing={2} >
                    {messages.length ? messages.map((message, index) => {
                            const isYou = message.sender === "You";
                            return (
                                <Stack
                                    key={index}
                                    direction="row"
                                    spacing={2}
                                    flexDirection={isYou ? "row-reverse" : "row"}
                                >
                                    <WhatsappChatBubble variant={isYou ? "sent" : "received"} {...message} />
                                </Stack>
                            );
                        })
                        :
                        <Box sx={{
                            display: "flex",
                            width: "100%",
                            height: "58vh",
                            justifyContent: "center",
                            alignItems: "center"
                        }}><Typography variant="h5">{"Let's Chat"}</Typography></Box>
                    }
                    <div ref={messagesEndRef} />
                </Stack>
            }
        </Box>
    );
};