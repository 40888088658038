
const initialState = {
    startTime: "",
    breakDuration: {
        milliseconds: 0,
        database: 0,
        string: "00:00:00"
    },
    workingDuration: {
        milliseconds: 0,
        database: 0,
        string: "00:00:00"
    },
    timerId: "",
    endTime: "",
    isStarted: false,
    isResumed: false,
    isRunning: false,
    isPaused: false,
    isStopped: true,
    totalBreak: 0,
    totalWork: 0,
    // currentWorkingDuration: 0,
    // currentBreakDuration: 0,
};

const timerReducer = (state = initialState, action) => {
    switch (action.type) {

        case "START_TIMER":
            return (state = {...state, startTime: action.payload, isStarted: true, isRunning: true, isStopped: false});

        case "PAUSE_TIMER":
            return (state = {...state, isRunning: false, isResumed: false, isPaused: true,});

        case "STOP_TIMER":
            return (state = {
                ...initialState,
                timerId: state.timerId,
                totalBreak: action.payload.break,
                totalWork: action.payload.work
            });

        case "RESUME_TIMER":
            return (state = {...state, isStarted: true, isRunning: true, isPaused: false, isResumed: true});

        // case "ADD_CURRENT_WORK_DURATION":
        //     return (state.currentWorkingDuration +=1);
        //
        // case "ADD_CURRENT_BREAK_DURATION":
        //     return (state.currentBreakDuration += 1);
        //
        // case "EMPTY_CURRENT_WORK_DURATION":
        //     return (state.currentWorkingDuration = initialState.currentWorkingDuration);
        //
        // case "EMPTY_CURRENT_BREAK_DURATION":
        //     return (state.currentBreakDuration = initialState.currentBreakDuration);


        case "TIMER_ID":
            return (state = {...state, timerId: action.payload});

        case "ADD_WORKING_TIME":
            return (state = {
                ...state,
                workingDuration: {
                    milliseconds: action.payload.milliseconds,
                    database: action.payload.database ?? state.workingDuration.database,
                    string: action.payload.string
                }
            });

        case "ADD_BREAK_TIME":
            return (state = {
                ...state,
                breakDuration: {
                    milliseconds: action.payload.milliseconds,
                    database: action.payload.database ?? state.breakDuration.database,
                    string: action.payload.string
                }
            });

        default:
            return state;
    }
};


export default timerReducer;
