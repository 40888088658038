/* eslint-disable */
import React from "react";
import { Grid, InputLabel, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
// import {useDataParams} from "../../../hooks";
import { ImageUploader } from "../../ImageUploader";
import { USER_PROFILE_BASE_URL } from "../../../constants";
import UPI from "../../../images/upiicon.svg";
import PHONEPE from "../../../images/phonepeicon.svg";
import PAYTM from "../../../images/paytmicon.svg";
import GPAY from "../../../images/gpayicon.svg";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

export function Bank({ formProps }) {
  // const {
  //     params, handleChange,
  //     setParams
  // } = useDataParams();
  const { params, setParams, handleChange } = formProps;

  const [personName, setPersonName] = React.useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      className: "tableHeader-color",
      style: {
        backgroundColor: "red",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const upis = [
    { id: 1, name: "UPI", render: () => <img src={UPI} height="20px" /> },
    { id: 2, name: "PAYTM", render: () => <img src={PAYTM} height="20px" /> },
    {
      id: 3,
      name: "PHONEPE",
      render: () => <img src={PHONEPE} height="20px" />,
    },
    { id: 4, name: "GPAY", render: () => <img src={GPAY} height="20px" /> },
  ];
  // const handleChange2 = (event) => {
  //     const {
  //         target: { value },
  //     } = event;
  //     setPersonName(
  //         typeof value === "string" ? value.split(",") : value,
  //     );
  // };

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    const selectedUpis = upis
      .filter((upi) => value.includes(upi.name))
      .map((upi) => ({ id: upi.id, name: upi.name }));

    setPersonName(value);

    setParams((prev) => {
      return { ...prev, mode: JSON.stringify(selectedUpis) };
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageUploader
          name="image"
          id="logo"
          file={params?.image}
          src={
            params?.image?.type
              ? `${URL.createObjectURL(params?.image)}`
              : params?.image
              ? `${USER_PROFILE_BASE_URL}${params?.image}`
              : ""
          }
          onClick={() =>
            setParams((prev) => {
              return { ...prev, image: "" };
            })
          }
          onChange={(file) => {
            handleChange("image", file);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Name Of Account Holder</InputLabel>
        </Grid>
        <TextField
          value={params?.holderName || ""}
          onChange={(e) => handleChange("holderName", e.target.value)}
          placeholder="Enter Holder Name"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Account Number</InputLabel>
        </Grid>
        <TextField
          required
          value={params?.accountNo || ""}
          onChange={(e) => handleChange("accountNo", e.target.value)}
          placeholder="Enter Account Number"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">IFSC</InputLabel>
        </Grid>
        <TextField
          value={params?.ifsc || ""}
          onChange={(e) => handleChange("ifsc", e.target.value)}
          placeholder="Enter IFSC"
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Branch</InputLabel>
        </Grid>
        <TextField
          required
          value={params?.branch || ""}
          onChange={(e) => handleChange("branch", e.target.value)}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Accepted Payment Modes</InputLabel>
        </Grid>
        <FormControl sx={{ width: "100%" }}>
          <Select
            size="small"
            multiple
            fullWidth
            value={personName}
            onChange={handleChange2}
            input={<OutlinedInput />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {upis.map((upi) => (
              <MenuItem key={upi.name} value={upi.name}>
                <Checkbox checked={personName.indexOf(upi.name) > -1} />
                <ListItemText primary={upi?.render()} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
