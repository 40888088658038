export const dataColumns = [
    { id: "creater", label: "Creater", minWidth: 100 },
    { id: "Quantity", label: "Qty.", minWidth: 20 },
    {
        id: "campingName",
        label: "Camping name",
        minWidth: 200,
        align: "right",
    },
    {
        id: "status",
        label: "Status",
        minWidth: 100,
        align: "right",
    },
    {
        id: "createAt",
        label: "Create At",
        minWidth: 100,
        align: "right",
    },
    {
        id: "action",
        label: "Action",
        minWidth: 100,
        align: "right",
    },
];

export const dataColumns2 = [
        { id: "name", label: "Name", minWidth: 100 },
        { id: "contect_no", label: "Cont.No.", minWidth: 20 },
        {
            id: "message",
            label: "Message",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: "lead_value",
            label: "Lead Value",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: "lead_type",
            label: "Lead Type",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toFixed(2),
        },
        {
            id: "assign",
            label: "Assign",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: "sourse",
            label: "Sourse",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toFixed(2),
        },
        {
            id: "recording",
            label: "Rec..",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: "status",
            label: "Status",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toFixed(2),
        },
        {
            id: "follow_up",
            label: "Follow Up",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toFixed(2),
        },
        {
            id: "action",
            label: "Action",
            minWidth: 100,
            align: "right",
            // format: (value) => value.toFixed(2),
        },
    ];