/*eslint-disable*/
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";
import { TagsModal} from "../dialogs";
import {useDispatch} from "react-redux";
import {usePagination} from "../hooks";
import {$crud} from "../services/CrudFactory";

export const Tags =  () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(null);
    const [tags, setTags] = useState([]);
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    let branchId =  localStorage.getItem("branchInfo");
    let branchInfo = JSON.parse(branchId);

    const retrieveTags = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data} = await $crud.retrieve("web/sub-tags", {limit, page, branchId:branchInfo?.id});
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    const statusChange = async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("create/web/status/subTag", {
                id
            });
            retrieveTags().then(res => {
                setTags(res?.tags);
            });
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };


    // const remove = async (id) => {
    //     try {
    //         dispatch({
    //             type: "LOADER",
    //             loader: true
    //         });
    //         const {type, message} = await $crud.post("delete/web/announcement", {id});
    //         dispatch({
    //             type: "SNACKBAR",
    //             snackBar: {type, message}
    //         });
    //         retrieveTags().then(res => {
    //             setTags(res.tag);
    //             setTotal(res.total);
    //         });
    //         setIdToRemove(null);
    //     } catch (e) {
    //         dispatch({
    //             type: "SNACKBAR",
    //             snackBar: {type: "error", message: e.message}
    //         });
    //     } finally {
    //         dispatch({
    //             type: "LOADER",
    //             loader: false
    //         });
    //     }
    // };
    
    useEffect(() => {
        if(branchInfo?.id){
            retrieveTags().then(res => {
                setTags(res?.tags);
                setTotal(res?.total);
            });
        }else{
            dispatch({type: "SNACKBAR", snackBar: {type: "error", message: "You don't support any branch."}})
        }
    }, [limit, page]);
    return (
        <>
            <div>
                <div className='row w-100 g-2'>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setStatus({})} buttonName="Add Tag"/>
                    </div>
                </div>
            </div>
            <DynamicTable
                rows={tags}
                columns={columns?.tags}
                style={{maxHeight: "calc(100vh - 102px)"}}
                total={total}
                // onDelete={(id) => {
                //     setIdToRemove(id);
                // }}
                // onRowClick={() => {
                // }}
                onStatusChange={(id) => {
                    statusChange(id);
                }}
                // onEdit={(value) => {
                    
                //     setStatus(value);
                // }}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
            <TagsModal
                onSave={() => {
                    retrieveTags().then(res => {
                        setTags(res?.tags);
                        setTotal(res?.total);
                    });
                }}
                data={status}
                onClose={() => setStatus(null)}
                open={!!status}
            />
            
        </>
    );
};