import React from "react";
import Divider from "@mui/material/Divider";
import Table from "../components/DynamicTable";
import {ActionButton} from "../components/buttons";
import {SelectBox} from "../components/inputs";
import Footer from "../components/Footer";
// import LeadForm from "../dialogs/LeadForm.js";
import {dataColumns} from "../data/SmsData";
// import SideBar from "../components/SideBar";
// import SmsForm from "../dialogs/SmsForm";
import { Link } from "react-router-dom";

export function Sms() {
  // const [LeadFormValue2, setLeadFormValue2] = useState([]);

  return (
    <>
      {/* <SideBar /> */}
      <div className="container-fluid  top-navbar ">
        <div className=' row w-100   g-2'>
          <div className=' mb-3 col-3 d-flex'>
            <ActionButton buttonName={<Link to="/smsForm">Send SMS</Link>}  />
            <ActionButton buttonName={<i className="bi bi-file-bar-graph"></i>} />
              <Divider orientation="vertical" className='TopNavBardivider' variant="middle" flexItem />
          </div>
          <div className=' col-2 offset-2'>
            <SelectBox selectName="Creater" style={{borderRadius:"5px", border:"1px solid #5C4FDD"}} />
          </div>
          <div className=' col-2'>
            <SelectBox selectName="Status" style={{borderRadius:"5px", border:"1px solid #5C4FDD"}}/>
          </div>
          <div className=' col-3'>
            <input className="form-control InputBox" type="search" placeholder="Search" aria-label="Search" />
          </div>
        </div>
        <Table columns={dataColumns} rows={[]}/>
        <Footer pageName={<Link to="#">Sms</Link>}/>
        {/* {showSmsForm && <SmsForm  showsmsform={"animate__slideInRight"} />} */}
      </div>
    </>
  );
}

