import React, { useEffect, useState } from "react";
import {AddCard} from "../../../../components/cards/whatsappContents/AddCard";
import {useSelector} from "react-redux";
import {ADMIN_ROLE, SUPER_ADMIN_ROLE} from "../../../../constants";
import {WhatsappContentCard} from "../../../../components/cards/whatsappContents";
import { $crud } from "../../../../services/CrudFactory";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export function WhatsAppAccounts(props) {
    const {setWhatsappContent, setMessage} = props;
    const userRole = useSelector(state=>state.userReducer).role.name;
    const [accountDetails, setAccountDetails] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const handleRadioChange = (index) => {
      setSelectedAccount((prevSelected) =>
        prevSelected === index ? null : index
      );
    };

    const retrieveData = async ()=>{
      try {
          const {data} = await $crud.retrieve("web/bank-details");
          setAccountDetails(data?.bank_details);
      } catch (error) {
          console.log(error);
      }
  };
  
  useEffect(()=>{
    retrieveData();
  }, []);

  return (
    <RadioGroup sx={{ display: "flex", width: "100%" }}>
    <div className="row g-2"  style={{ display: "flex", width: "100%",  }}>
      {/* <div className="row g-2 flex-wrap"> */}
      {
        accountDetails.map((account,index)=>{
          return (
            // <div key={index} style={{ width: "25%"}} >
            <div key={index} className = "col-xl-3 col-lg-4 col-md-6" >
            <FormControlLabel
            sx={{ width: "100%", position:"relative" }}
            className="content-card-with-redio"
            key={index}
            value={account?.holderName}
            control={<Radio sx={{position:"absolute", top:0, left:0}} checked={selectedAccount === index}
            onClick={() => handleRadioChange(index)}/>}
            label={
              <WhatsappContentCard
                type="Bank"
                data={account}
                key={index}
                setMessage = {setMessage}
                url="bank-detail"
                setWhatsappContent={setWhatsappContent}
                setState={setAccountDetails}
              />
            }
          />
          </div>
              // <WhatsappContentCard type="Bank" data={account} key={index} url="bank-detail" setWhatsappContent = {setWhatsappContent} setState = {setAccountDetails}/>
          );
        })
      }
          {(userRole === ADMIN_ROLE || userRole == SUPER_ADMIN_ROLE ) && <div className="col-xl-3 col-lg-4 col-md-6" style={{marginLeft:-10}}>
              <AddCard
                  type="Banks"
                  url="bank-detail"
                  className="bank-card bg-light w-100 h-100 d-flex justify-content-center align-items-center"
                  containerStyle={{
                      minHeight: 200
                  }}
                  state = {accountDetails}
                  setState={setAccountDetails}
              />
          </div>}
      </div>
    {/* </div> */}
    </RadioGroup>
  );
}

