import React from "react";

export function BankAttachment() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <g filter="url(#filter0_d_554_3211)">
                <rect x="10" y="6" width="50" height="50" rx="25" fill="#547D92" />
                <path d="M60 31C60 24.3696 57.3661 18.0107 52.6777 13.3223C47.9893 8.63392 41.6304 6 35 6C28.3696 6 22.0107 8.63392 17.3223 13.3223C12.6339 18.0107 10 24.3696 10 31L35 31H60Z" fill="#486B7D" />
                <path d="M35.0001 21.3999L26.6001 26.1999V27.3999H43.4001V26.1999L35.0001 21.3999ZM29.0001 28.5999L28.7601 36.9999H31.7601L31.4001 28.5999H29.0001ZM33.8001 28.5999L33.5601 36.9999H36.5601L36.2001 28.5999H33.8001ZM38.6001 28.5999L38.3601 36.9999H41.3601L41.0001 28.5999H38.6001ZM26.6001 40.5999H43.4001V38.1999H26.6001V40.5999Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_554_3211" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_554_3211" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_554_3211" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
