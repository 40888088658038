import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./";

const persistConfig = {
    key: "root",
    storage,
    // You can whitelist specific reducers to be persisted, if needed
    // whitelist: ['reducer1', 'reducer2'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);