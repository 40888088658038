import { Chip, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AnnouncementDetailsModal } from "../dialogs";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../constants";
import { $crud } from "../services/CrudFactory";

export const AnnouncementPopUp =  React.memo(function AnnouncementPopUp() {
  const [open, setOpen] = useState(false);
  const role = JSON.parse(localStorage.getItem("role"));
  const [announcement, setAnnouncement] = useState([]);
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  
  const handleClose = () => {
    setOpen(false);
  };


  const retrieveAnnouncement = async () => {
    try {
      const {
        data: { announcements },
      } = await $crud.retrieve("web/show-announcements", { userId: user_id });
      return announcements;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    retrieveAnnouncement().then((res) => {
      setAnnouncement(res);
      // dispatch(current_user(user))
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const divElement = document.getElementById("announcementpopup");

      if (divElement) {
        if (windowWidth >= 750 && windowWidth <= 919) {
          divElement.style.top = "180px";
        } else if (windowWidth >= 920 && windowWidth <= 1240) {
          divElement.style.top = "145px";
        } else {
          divElement.style.top = "90px";
        }
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultPosition = {
    position: "fixed",
    top: "90px",
    right: "0",
    zIndex: 1,
  };
  return (
    <Grid
      container
      item
      xs={6}
      style={defaultPosition}
      id="announcementpopup"
      spacing={1}
      display="flex"
      justifyContent="flex-end"
    >
      {announcement?.length >= 1 && (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
          mr={1}
        >
          <Grid item mb={1}>
            {(role?.name != ADMIN_ROLE || role?.name != SUPER_ADMIN_ROLE) ? (
              <Chip
                color="success"
                label={
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs>
                      You have got a new Announcement
                    </Grid>
                    <Grid item xs>
                      <Chip
                        label={
                          <IconButton
                            size="small"
                            onClick={() => setOpen(true)}
                          >
                            Open
                          </IconButton>
                        }
                      />
                    </Grid>
                  </Grid>
                }
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      )}

      <AnnouncementDetailsModal announcement = {announcement} open={open} close={handleClose} />
    </Grid>
  );
});
