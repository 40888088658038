const initialState = { result: false };

const visitAndDemoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RETRIEVE":
      state.result = !state.result;
      return state;
    default:
      return state;
  }
};

export default visitAndDemoReducer;
