import React, { useState, useRef, useEffect } from "react";
import { SendRightArrow } from "../../../components/icons";
import MessageBox from "../../../components/MessageBox";
import { LeadModalSidebar } from "../../../components/leadSidebar";
import { preRecordedMessages } from "../../../data/preRecordedMessages";

export function Sms() {
  const [messageType, setMessageType] = useState("intro");
  const [copiedMessage, setCopiedMessage] = useState("");
  const inputValueRef = useRef();
  useEffect(() => {
    inputValueRef.current.value = copiedMessage;
  }, [copiedMessage]);

  const messagesToShow = preRecordedMessages.filter(object => object.type === messageType);
  return (
    <div className="row">
      <div className="col-3">
        <div className="lead-modal-sidebar">
          <LeadModalSidebar setMessageType={setMessageType} data={preRecordedMessages} />
        </div>
      </div>
      <div className="col-9">
        <div className="lead-modal-message-container">
          {
            messagesToShow[0].messages.map((message,index) => {
              return (
                <MessageBox key={index} title={message.title} desc={message.desc} setCopiedMessage={setCopiedMessage} />
              );
            })
          }
        </div>
      </div>

      <div className="col-12 d-flex custom-message-container">
        <div className="sms-input">
          <input type="text" name="" id="" ref={inputValueRef} placeholder="Type a message.." />
        </div>
        <div className="msg-send-btn">
          <SendRightArrow />
        </div>
      </div>
    </div>
  );
}

