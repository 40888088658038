import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import DynamicTable from "../components/DynamicTable";
import {columns} from "../helpers/tableData";
// import {CommonModal} from "../dialogs";
import {useDispatch} from "react-redux";
import {usePagination} from "../hooks";
import {$crud} from "../services/CrudFactory";
import { LeadConvertTypeModal } from "../dialogs/LeadConvertTypeModal";

export const LeadConvertType = () =>{
    const dispatch = useDispatch();
    const [status, setStatus] = useState(null);
    const [leadConvertType, setLeadConvertType] = useState([]);
    const {limit, setLimit, page, setPage, total} = usePagination();

    const statusChange=async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("update/web/status/lead-convert-type", {
                id
            });
            retrieveLeadConvertTypes().then(res => {
                setLeadConvertType(res.leadConvertTypes);
            });
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    const retrieveLeadConvertTypes=async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data } = await $crud.retrieve("web/lead-convert-types",{limit,page});
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    const remove = async(id)=>{
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message } = await $crud.post("delete/web/lead-convert-type",{id});
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message}
            });
            retrieveLeadConvertTypes().then(res=>setLeadConvertType(res.leadConvertTypes));
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    };

    useEffect(()=>{
        retrieveLeadConvertTypes().then(res=>setLeadConvertType(res.leadConvertTypes));
    },[]);

    return (
        <>
            <div>
                <div className='row w-100 g-2'>
                    <div className='mb-3 d-flex'>
                        <ActionButton onClick={() => setStatus({})} buttonName="Add Lead Convert Type"/>
                    </div>
                </div>
            </div>
            <DynamicTable
                rows={leadConvertType}
                columns={columns?.leadConvertTypes}
                total={total}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id);
                }}
                onEdit={(value) => {
                    setStatus(value);
                }}
                onDelete={remove}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />

            <LeadConvertTypeModal 
                onSave={() => {
                    retrieveLeadConvertTypes().then(res=>setLeadConvertType(res.leadConvertTypes));
                }}
                status={status}
                onClose={() => setStatus(null)}
                open={!!status}

            />
            {/* <CommonModal
                onSave={() => {
                    retrieveLeadConvertTypes().then(res=>setLeadConvertType(res.leadConvertTypes));
                }}
                title="Lead Conversion Type"
                apiUrl="web/lead-convert-type"
                data={status}
                onClose={() => setStatus(null)}
                open={!!status}
            /> */}
        </>
    );
};