import React from "react";
import { CopyToClipboard } from "../icons";
import gpayicon from "../../images/gpayicon.svg";
import paytmicon from "../../images/paytmicon.svg";
import upiicon from "../../images/upiicon.svg";
import phonepeicon from "../../images/phonepeicon.svg";
import { IMAGE_BASE_URL } from "../../constants";

function BankDetails(props) {
    const { holderName,
        accountNo,
        ifsc,
        bankName,
        branch,
        paymentMode,
        image
        } = props.data;

        let parseData = JSON.parse(paymentMode);
        const mode = parseData?.map(
            (item) => item?.name
        );
        
    return (

            <div className="bank-card bg-light w-100 " onClick={()=>props?.setMessage(`Account No : ${accountNo}\nHolder Name : ${holderName}\nIFSC : ${ifsc}\nPayment Mode : ${mode}`)}>
                <div className="bank-card-header d-flex justify-content-between" style={{width:"100%"}}>
                    <div className="bank-icon" style={{width:"100%"}}>
                        {<img src={`${IMAGE_BASE_URL}bank_logo/${image}`} alt={bankName} style={{width:"100%", height:"60px", objectFit:"cover"}}/>}
                    </div>
                    <div className="copy-icon">
                        <CopyToClipboard />
                    </div>
                </div>
                <hr />
                <div className="bank-card-body">
                    <div className='d-flex justify-content-between'>
                        <div className="title">{"Account"}</div>
                        <div className="value">{accountNo}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="title">{"IFSC"}</div>
                        <div className="value">{ifsc}</div>
                    </div>
                    <div className='d-flex justify-content-between text-truncate'>
                        <div className="title">{"Holder Name"}</div>
                        <div className="value">{holderName}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="title">{"Branch"}</div>
                        <div className="value">{branch}</div>
                    </div>

                </div>
                <hr />
                <div className="bank-card-footer d-flex" style={{width:"100%"}}>
                    {parseData?.map((data, index)=>{
                        if(data?.name === "PAYTM"){
                            return <div className="payment-method-icon" key = {index} style={{width:"25%"}}><img src={paytmicon} style={{width:"100%", height:"25px"}}  alt="PayTm" /></div>;
                        }
                        if(data?.name === "GPAY"){
                            return <div className="payment-method-icon" key = {index} style={{width:"25%"}}><img src={gpayicon} style={{width:"100%", height:"25px"}} alt="Gpay" /></div>;
                        }
                        if(data?.name === "PHONEPE"){
                            return <div className="payment-method-icon" key = {index} style={{width:"25%"}}><img src={phonepeicon} style={{width:"100%", height:"25px"}} alt="PhonePe" /></div>;
                        }
                        if(data?.name === "UPI"){
                            return <div className="payment-method-icon" key = {index} style={{width:"25%"}}><img src={upiicon} style={{width:"100%", height:"25px"}} alt="UPI" /></div> ;
                        }
                    })}
                    {/* <div className="payment-method-icon">{!!paytm && <img src={paytmicon} alt="PayTm" />}</div>
                    <div className="payment-method-icon">{!!gpay && <img src={gpayicon} alt="Gpay" />}</div>
                    <div className="payment-method-icon">{!!phonepe && <img src={phonepeicon} alt="PhonePe" />}</div>
                    <div className="payment-method-icon">{!!upi && <img src={upiicon} alt="UPI" />}</div> */}
                </div>
            </div>

    );
}

export default BankDetails;