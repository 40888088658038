/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    ActivityLog,
    CallDetails,
    LeadDetails,
    LeadScore,
    LeadValue,
    NotesFollowUps,
    WhatsAppNew,
    Email, Sms
} from "./pages";
import {CustomSelect} from "../../components/inputs";
import {useDataParams} from "../../hooks/useDataParams";
import {$crud} from "../../services/CrudFactory";
import {useDispatch, useSelector} from "react-redux";
import {
    renderLead,
    setFollowUpLead,
    setFollowUpLeadId,
    setFollowUpModalOpen,
    setLeadModalReducer,
    statuses
} from "../../redux/actions";
import {capitalize} from "../../helpers/functions";
import {Call, Close, LocalFireDepartment, Star, WhatsApp} from '@mui/icons-material';
import {Fab, SwipeableDrawer, Typography, Grid, IconButton, Tooltip} from '@mui/material';
import Button from "@mui/material/Button";
import {retrieveStatuses} from "../../pages";
import {VisitModal} from "../VisitModal";
import {LocalFireDepartmentGradient} from "../../helpers/tableData";
import {DemoModal} from "../DemoModal";
import {VisitsAndDemos} from "../VisitsAndDemos";
import socket from "../../socket";
import whatsAppIcon from "../../images/whatsAppIcon.svg";

export const LeadModal = React.memo(function LeadModal(props) {
    const dispatch = useDispatch();
    const {leadId, formProps} = props;
    const [page, setPage] = useState("Notes & Follow Ups");
    const [showVisitModal, setShowVisitModal] = useState(false);
    const [showDemoModal, setShowDemoModal] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isHot, setIsHot] = useState(false);
    const [isUnavoidable, setIsUnavoidable] = useState(false);
    const [visitStatus, setVisitStatus] = useState(null);
    const [demoStatus, setDemoStatus] = useState(null);
    const {params, setParams, handleChange} = useDataParams();
    const renderState = useSelector((state) => state.renderLead).render1;
    const isMobileHide = useSelector(state => state.systemSettingsReducer.isMobileHide);
    const userMobileHide = localStorage.getItem("showContact");
    const allStatuses = useSelector((state) => state.statusReducer).filter(s => !!s.isActive);
    const leadConvertTypes = useSelector((state) => state.leadConvertTypeReducer).filter(s => !!s.isActive);
    const employeesData = useSelector((state) => state.usersReducer).filter(s => !!s.isActive);
    const currentUser = useSelector((state) => state.userReducer);
    // const followupLeadId = useSelector((state) => state.followupReducer).leadId;
    const followupLeadId = useSelector((state) => state.followupReducer).id;
    const followupMobile = useSelector((state) => state.followupReducer).mobile1;
    // const leadModalLeadId = useSelector((state) => state.leadModalReducer).openLeadModal;
    const [inputData, setInputData] = useState({});
    const [viewCount, setViewCount] = useState(0);
    // const [link, setLink] = useState("");
    const [theme, setMyTheme] = useState({name: localStorage.getItem("theme")});
    const showContact = isMobileHide ? (userMobileHide == "true" ? true : false) : true;
    // console.log(inputData, "-----inputData");

    const message = encodeURIComponent(`Dear *${capitalize(inputData?.name)}*,
   
Welcome To *Technosters Technologies*.

Our Services & Courses:
-> Website Designing
-> Software Development
-> Digital Marketing
-> Mobile App Development
-> UI/UX Development

Add: 8/195 Engineer's Colony, Opp. Omaxe SRK Mall, Near SarkariZone Library, Agra, Uttar Pradesh, 282005

Website: www.technosters.com

Call & WhatsApp on: 08868834596, 08077187959

G-Page: https://g.page/technosters?share`);


    useEffect(() => {
        setMyTheme({name: localStorage.getItem("theme")})
    }, [localStorage.getItem("theme")])

    const headers = [
        "Lead Details",
        "Lead Value",
        "Notes & Follow Ups",
        "Visits/Demos",
        "Email",
        "Sms",
        "WhatsApp",
        "WhatsApp Online",
        "Call Details",
        "Lead Score",
        "Activity Log",

    ];

    const sendVistingScheduleLink = async (id) => {
        try {
            const {data, type, message} = await $crud.post("create/web/send-visiting-schedule-link", {id});
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (error) {
            const {message, type} = error;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            console.log(error);
        }
    }
    const sendDemoScheduleLink = async (id) => {
        try {
            const {type, message} = await $crud.post("create/web/send-demo-schedule-link", {id});
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (error) {
            const {message, type} = error;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            console.log(error);
        }
    }

    const insertStatus = async (name, value, description) => {
        setInputData({
            ...inputData,
            [name]: value,
        });
        try {
            const {type, message} = await $crud.post("update/web/lead", {
                id: leadId,
                [name]: value,
            });

            await $crud.post("create/web/assign", {
                leadId,
                leadConvertTypeId: name === "leadConvertTypeId" ? value : null,
                statusId: name === "statusId" ? value : null,
                assignTo: currentUser?.id,
                userId: name === "assignTo" ? value : null,
                description
            });

            dispatch(renderLead(true));

            retrieveStatuses().then(res => {
                dispatch(statuses(res?.statuses))
            })

            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (error) {
            console.error(error);
        }
    };

    function makeCall(callingName, mobile) {
        // const dispatch = useDispatch();
        let name = callingName || "UnSaved";
        localStorage.getItem("users") && socket.emit("makeCall", {
            name,
            mobile,
            socketId: JSON.parse(localStorage.getItem("users"))?.socketId
        });
        // dispatch(setCallData({mobile, name,  socketId: JSON.parse(localStorage.getItem("users"))?.socketId}))
        // callProgressModal({open:true, data:{name, mobile, socketId: JSON.parse(localStorage.getItem("users"))?.socketId}})
    }

    const updateLeadViews = async () => {
        const {data} = await $crud.post("update/web/lead-views", {
            id: leadId || followupLeadId,
            mobile1: followupMobile
        });
        setViewCount(data);
    }

    const retrieveLead = async () => {
        dispatch({
            type: "LOADER",
            loader: true,
        });
        const {data: {lead}} = await $crud.retrieve("web/lead", {
            id: leadId || followupLeadId,
            mobile1: followupMobile
        });
        return lead;
        dispatch({
            type: "LOADER",
            loader: false,
        });
    }

    useEffect(() => {
        if (leadId || followupLeadId || followupMobile) retrieveLead().then(res => {
            setInputData(res)
            dispatch({
                type: "LOADER",
                loader: false,
            });
        })
        if (followupLeadId) setIsUnavoidable(true);
    }, [leadId, followupLeadId, renderState, followupMobile])

    useEffect(() => {

        props?.open && updateLeadViews();
    }, [props?.open])


    return (
        <SwipeableDrawer
            anchor={props?.anchor}
            open={!!followupLeadId || (props?.open && inputData?.mobile1)}
            // open={!!leadModalLeadId || (props?.open && inputData?.mobile1)}
            onOpen={() => {
            }}
            onClose={() => {
                props?.onClose()
                setInputData({})
                setDemoStatus(null);
                setVisitStatus(null);
                // followupLeadId && dispatch(setFollowUpLeadId(null));
                dispatch(setFollowUpLeadId({onCallEnd: true, id: null, mobile1: null}));
                // leadModalLeadId && dispatch(setLeadModalReducer({openLeadModal: false}));
            }}
            PaperProps={{
                sx: {
                    width: "80vw",
                    overflowY: "inherit",
                }
            }}
        >
            <div className="flag d-flex align-items-center ps-4">
                <Grid> #{inputData?.id} </Grid>
                <Typography>{capitalize(inputData?.name)}</Typography>
                <span></span>
            </div>
            {/*<div style={{position:"absolute",left:-80,top:100,zIndex:"150",backgroundColor:"#e78b37",width:80, height:80}}>*/}
            {/*    <IconButton  sx={{width:80,height:80, borderRadius:"0px"}} variant="square" disabled={!!followupLeadId && isUnavoidable}*/}
            {/*                onClick={() => {*/}
            {/*                    props?.onClose();*/}
            {/*                    setInputData({});*/}
            {/*                    dispatch(setFollowUpLeadId(null));*/}
            {/*                }}><Close sx={{fontSize:"70px"}} /></IconButton>*/}
            {/*</div>*/}
            <div className="lead-modal">
                <div className="lead-modal-header">
                    <div className="row mt-3">
                        <div className="col-xxl-3 col-xl-4 col-md-6 mb-3  position-relative d-flex align-items-center">

                            <div
                                style={{position: "absolute", right: "0"}}

                            >

                                {/*{isFavorite ? <Star sx={{fill: "gold",cursor:"pointer"}} onClick={() => setIsFavorite(false)}/> :*/}
                                {/*    <Star onClick={() => setIsFavorite(true)} sx={{cursor:"pointer"}}/>}*/}
                                {/*    {isHot ? <LocalFireDepartmentGradient sx={{cursor:"pointer"}} onClick={() => setIsHot(false)}/> :*/}
                                {/*    <LocalFireDepartment onClick={() => setIsHot(true)} sx={{cursor:"pointer"}}/>}*/}
                                {!showContact ?
                                    <Grid sx={{display: "flex"}}>
                                        <Tooltip title="Chat with WhatsApp" placement="bottom">
                                            <a href={`https://wa.me/${inputData?.mobile1}?text=${message}`}
                                               target="_blank">
                                                {/*<WhatsApp sx={{mr: 2, cursor: "pointer", color: "green"}}/>*/}
                                                <div style={{marginRight: 15, cursor: "pointer"}}>
                                                    <img src={whatsAppIcon} width={"50px"} height={"50px"}/>
                                                </div>
                                            </a>
                                        </Tooltip>
                                        <Tooltip title="Make Call" placement="bottom">
                                            <Fab color="primary"
                                                 onClick={(e) => {
                                                     e.stopPropagation();
                                                     makeCall(inputData?.name, inputData?.mobile1)
                                                     // let callWindow = window.open(
                                                     //     `tel:${inputData?.mobile1}`
                                                     // );
                                                     // setTimeout(() => {
                                                     //     callWindow.close();
                                                     // }, 2000);
                                                 }}
                                            >
                                                <Call style={{fill: "white", fontSize: "30px"}}
                                                      className="animate__animated animate__tada animate__infinite infinite"/>
                                            </Fab>
                                        </Tooltip>
                                    </Grid>
                                    :
                                    <Grid sx={{display: "flex"}}>
                                        <Tooltip title="Chat with WhatsApp" placement="bottom">
                                            <a href={`https://wa.me/${inputData?.mobile1}?text=${message}`}
                                               target="_blank">
                                                {/*<WhatsApp sx={{mr: 2, cursor: "pointer", color: "green"}}/>*/}
                                                <div style={{marginRight: 15, cursor: "pointer"}}>
                                                    <img src={whatsAppIcon} width={"50px"} height={"50px"}/>
                                                </div>
                                            </a>
                                        </Tooltip>
                                        <Tooltip title="Make Call" placement="bottom">
                                            <Button
                                                variant="contained"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    makeCall(inputData?.name, inputData?.mobile1)
                                                }}>{inputData?.mobile1}
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                }
                            </div>
                        </div>
                        <div className="col-xxl-2 col-xl-2 col-md-3 mb-3">
                            <div style={{width: "100%", height: "50px"}}>
                                <div className="lead-type">
                                    <span>Lead Source</span>
                                    <div className="content">{inputData?.source?.name || "N/A"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-1 col-xl-2 col-md-3">
                            <div style={{width: "100%", height: "50px"}}>
                                <div className="lead-type">
                                    <span>Lead Value</span>
                                    <div className="content">{inputData?.leadValue || "N/A"}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-2 col-xl-2 col-md-4">
                            <div style={{width: "100%", height: "50px"}}>
                                <CustomSelect
                                    value={inputData?.statusId}
                                    onClick={(name, value, description) => {
                                        insertStatus(name, value, description);
                                    }}
                                    title="Status"
                                    options={allStatuses}
                                />
                            </div>
                        </div>

                        <div className="col-xxl-2 col-xl-2 col-md-4">
                            <div style={{width: "100%", height: "50px"}}>
                                <CustomSelect
                                    value={inputData?.leadConvertTypeId}
                                    link={inputData?.link}
                                    onClick={(name, value, description) => {
                                        if (value === 2) {
                                            setShowVisitModal(true);
                                            setVisitStatus({name, value, description});
                                        } else if (value === 3) {
                                            setShowDemoModal(true)
                                            setDemoStatus({name, value, description});
                                        }
                                            // else if(value === 9){
                                            //     sendVistingScheduleLink(inputData?.id);
                                            //     insertStatus(name, value, description);
                                            // }else if (value === 10){
                                            //     sendDemoScheduleLink(inputData?.id);
                                            //     insertStatus(name, value, description);
                                        // } 
                                        else {
                                            insertStatus(name, value, description);
                                        }
                                    }}
                                    title="Lead Convert To"
                                    options={leadConvertTypes}
                                />
                            </div>
                        </div>

                        <div className="col-xxl-2 col-xl-3 col-md-4">
                            <div style={{width: "100%", height: "50px"}}>
                                <CustomSelect
                                    value={inputData?.assignTo}
                                    title="asignee"
                                    options={employeesData}
                                    onClick={(name, value, description) => {
                                        insertStatus(name, value, description);
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                    <hr style={{background: "#161D31", marginTop: "5px"}}/>
                    <div
                        className={`navigation-links ${theme.name === 'dark-theme' ? 'dark-background' : 'light-background'}`}>
                        <ul className="d-flex align-items-center">
                            {headers.map((item, index) => (
                                <li
                                    className={item === page ? "active" : undefined}
                                    onClick={() => setPage(item)}
                                    key={item}
                                >
                                    {item + " "}
                                    {
                                        item === "Lead Score" &&
                                        <Typography
                                            variant="underline "> {!!inputData?.followUp?.filter(f => !!f.leadScore)?.length && (inputData?.followUp?.filter(f => !!f.leadScore)?.reduce((acc, cur) => {
                                            if (cur?.leadScore) {
                                                acc += Number(cur?.leadScore)
                                            }
                                            return acc
                                        }, 0) / Number(inputData?.followUp?.filter(f => !!f.leadScore)?.length)) + "%"}</Typography> || ""
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                    <hr style={{background: "#161D31"}}/>
                </div>
                <div className="main-content">
                    {(!!followupLeadId || (props?.open && inputData?.mobile1))
                    && <React.Fragment>
                        {page === "Lead Details" && <LeadDetails lead={inputData} formProps={formProps}/>}
                        {page === "Lead Value" && <LeadValue lead={inputData}/>}
                        {page === "Notes & Follow Ups" &&
                        <NotesFollowUps lead={inputData} setLead={setInputData} viewCount={viewCount} onSubmit={() => {
                            dispatch(setFollowUpLead({}));
                            dispatch(setFollowUpModalOpen(false));
                            dispatch(setFollowUpLeadId(null));
                        }}/>}
                        {page === "WhatsApp" && <WhatsAppNew mobileNumber={inputData?.mobile1} page={page}/>}
                        {page === "WhatsApp Online" &&
                        <WhatsAppNew mobileNumber={inputData?.mobile1} online page={page}/>}
                        {page === "Email" && <Email/>}
                        {page === "Sms" && <Sms/>}
                        {page === "Call Details" && <CallDetails lead={inputData}/>}
                        {page === "Activity Log" && <ActivityLog lead={inputData}/>}
                        {page === "Lead Score" && <LeadScore lead={inputData}/>}
                        {page === "Visits/Demos" && <VisitsAndDemos lead={inputData}/>}
                    </React.Fragment>
                    }
                    {/*<Grid container justifyContent="flex-end">*/}
                    {/*    <Grid item >*/}
                    {/*        <Button*/}
                    {/*            variant="contained"*/}
                    {/*            disabled={!!followupLeadId && isUnavoidable}*/}
                    {/*            onClick={() => {*/}
                    {/*                props?.onClose();*/}
                    {/*                setInputData({});*/}
                    {/*                dispatch(setFollowUpLeadId(null));*/}
                    {/*            }}>*/}
                    {/*            Close*/}
                    {/*        </Button>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </div>
            </div>
            {/*<DialogAction>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        disabled={!!followupLeadId && isUnavoidable}*/}
            {/*        onClick={() => {*/}
            {/*            props?.onClose();*/}
            {/*            setInputData({});*/}
            {/*            dispatch(setFollowUpLeadId(null));*/}
            {/*        }}>*/}
            {/*        Cancel*/}
            {/*    </Button>*/}
            {/*</DialogAction>*/}
            {showVisitModal && <VisitModal
                open={showVisitModal}
                onClose={() => setShowVisitModal(false)}
                onSubmit={() => insertStatus(visitStatus.name, visitStatus.value, visitStatus.description)}
                leadId={leadId}
            />}
            {showDemoModal && <DemoModal
                open={showDemoModal}
                onClose={() => setShowDemoModal(false)}
                onSubmit={() => {
                    insertStatus(demoStatus.name, demoStatus.value, demoStatus.description)
                }}
                leadId={leadId}
            />}
        </SwipeableDrawer>
    );
});
