import { Avatar, Box,Typography } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import { Map } from "../components";

export const FieldMarketing = () => {

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 130,
          bottom: 70,
          width: "calc(100%)",
          overflow: "scroll",
          padding: "15px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} lg={8} sx={{height:"75vh"}}>
            <Box sx={{height:"100%"}} className="tableHeader-color">
             <Map/>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <Box sx={{height:"100%", width:"100%"}}>
                <Grid container sx={{width:"100%", mb:1}} className="tableHeader-color">
                    <Grid item sx={{display:"flex", justifyContent:"space-around", width:"100%"}}> 
                        <Typography variant="h6">Name</Typography>
                        <Typography variant="h6">Duration</Typography>
                        <Typography variant="h6">Image</Typography>
                    </Grid>
                </Grid>
                <Grid sx={{width:"100%", height:"68.2vh", overflow:"scroll", paddingLeft:1}}>
                    <Grid item className="marketing-dark-background" sx={{display:"flex",height:"41px", justifyContent:"space-around",mb:1,alignItems:"center", width:"98%", borderRadius:"4px"}}> 
                        <Typography variant="p">sdhfdjf</Typography>
                        <Typography variant="p">20min</Typography>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/3.jpg"/>
                    </Grid>
                </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
