/* eslint-disable */
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import DocumentPreviewCard from "./DocumentPreviewCard";



export default function WhatsappChatBubble(props) {
    const { content, variant, timestamp, attachment = undefined, sender } = props;
    const isSent = variant === 'sent';
    const [isHovered, setIsHovered] = React.useState(false);
    const [isLiked, setIsLiked] = React.useState(false);
    const [isCelebrated, setIsCelebrated] = React.useState(false);
    return (
        <Box sx={{ maxWidth: '80%', minWidth: 'auto' }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{ mb: 0.25 }}
            >
                <Typography level="body-xs">
                    {sender === 'You' ? sender : sender?.name}
                </Typography>
                <Typography level="body-xs">{timestamp}</Typography>
            </Stack>
            {attachment ? (
                <Paper
                    sx={{
                        borderRadius: 'lg',
                        borderTopRightRadius: isSent ? 0 : 'lg',
                        borderTopLeftRadius: isSent ? 'lg' : 0,
                        color:"#fff",
                    }}
                >

                    <DocumentPreviewCard message={attachment.caption} name={attachment.fileName} base64={attachment.file}
                                         mimetype={attachment.mimetype}/>
                </Paper>
            ) : (
                <Box
                    sx={{ position: 'relative' }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Paper
                        color={isSent ? 'primary' : 'secondary'}
                        variant={isSent ? 'solid' : 'soft'}
                        sx={{
                            p: 1.25,
                            borderRadius: 'lg',
                            borderTopRightRadius: isSent ? 0 : 'lg',
                            borderTopLeftRadius: isSent ? 'lg' : 0,
                            backgroundColor:theme=> isSent
                                ? theme.palette.primary.main
                                : theme.palette.secondary.main,
                            color:"#fff",
                        }}
                    >
                        <Typography
                            level="body-sm"
                            sx={{
                                color: isSent
                                    ? 'var(--joy-palette-common-white)'
                                    : 'var(--joy-palette-text-primary)',
                            }}
                        >
                            {content}
                        </Typography>
                    </Paper>
                    {(isHovered || isLiked || isCelebrated) && (
                        <Stack
                            direction="row"
                            justifyContent={isSent ? 'flex-end' : 'flex-start'}
                            spacing={0.5}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                p: 1.5,
                                ...(isSent
                                    ? {
                                        left: 0,
                                        transform: 'translate(-100%, -50%)',
                                    }
                                    : {
                                        right: 0,
                                        transform: 'translate(100%, -50%)',
                                    }),
                            }}
                        >
                            <IconButton
                                variant={isLiked ? 'soft' : 'plain'}
                                color={isLiked ? 'danger' : 'neutral'}
                                size="small"
                                onClick={() => setIsLiked((prevState) => !prevState)}
                            >
                                {isLiked ? '❤️' : <FavoriteBorderIcon />}
                            </IconButton>
                            <IconButton
                                variant={isCelebrated ? 'soft' : 'plain'}
                                color={isCelebrated ? 'warning' : 'neutral'}
                                size="small"
                                onClick={() => setIsCelebrated((prevState) => !prevState)}
                            >
                                {isCelebrated ? '🎉' : <CelebrationOutlinedIcon />}
                            </IconButton>
                        </Stack>
                    )}
                </Box>
            )}
        </Box>
    );
}