import React from "react";
import { SelectBox2, InputField2 } from "../components/inputs";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Footer from "../components/Footer";
import {
    Attachment, BankAttachment,
    DocumentAttachment, ImageAttachment,
    LocationAttachment, PersonAttachment,
    SendRightArrow, TextAttachment
} from "../components/icons";
import { Link } from "react-router-dom";

function SmsForm() {
    const [open, setOpen] = React.useState(false);
    // const { setSmsFormValue2, smsFormValue2 } = props;
    const suggestion = [
        {
            LeadName: "lead_name",
            FatherName: "Father's_name",
            EmailId: "email_id",
            LeadValue: "lead_value",
            AssigneeName: "assignee_name",
            City: "city",
            Course: "course"
        }
    ];
    const actions = [
        { icon: <TextAttachment />, name: "Text", bg: "linear-gradient(to right,  red, green)" },
        { icon: <BankAttachment />, name: "Banks", bg: "linear-Gradient(blue ,red)" },
        { icon: <LocationAttachment />, name: "Location", bg: "linear-Gradient(blue ,red)" },
        { icon: <PersonAttachment />, name: "Contact", bg: "linear-Gradient(blue ,red)" },
        { icon: <DocumentAttachment />, name: "Document", bg: "linear-Gradient(blue ,red)" },
        { icon: <ImageAttachment />, name: "Image", bg: "linear-Gradient(blue ,red)" },

    ];
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className='row px-4'>
                <div className="col-12 smsForm">
                    <div className='row g-2'>
                        <div className="col-12 d-flex align-items-center mt-3"><div style={{ marginRight: "10px" }}>Send SMS </div> <div style={{ width: "240px" }}><SelectBox2 selectName="CustomSelect" /></div>  </div>
                        <div className="col-3 "><SelectBox2 labelName="Source" selectName="CustomSelect" /></div>
                        <div className="col-3">
                            <div className='row g-1'>
                                <div className='col-6'>
                                    <InputField2 labelName="Lead Value(To)" placeholderName="Value(To)" />
                                </div>
                                <div className='col-6'>
                                    <InputField2 labelName="Lead Value(From)" placeholderName="Value(From)" />
                                </div>
                            </div>
                        </div>
                        <div className="col-3"><SelectBox2 labelName="Follow Up" selectName="CustomSelect" /></div>
                        <div className="col-3"><SelectBox2 labelName="Intersted In" selectName="CustomSelect" /></div>
                        <div className="col-3"><SelectBox2 labelName="Status" selectName="CustomSelect" /></div>
                        <div className="col-3">
                            <div className='row g-1'>
                                <div className='col-6'>
                                    <InputField2 labelName="Lead Value(To)" placeholderName="Value(To)" />
                                </div>
                                <div className='col-6'>
                                    <InputField2 labelName="Lead Value(From)" placeholderName="Value(From)" />
                                </div>
                            </div>
                        </div>
                        <div className="col-3"><SelectBox2 labelName="City" selectName="CustomSelect" /></div>
                        <div className="col-3"><SelectBox2 labelName="Known About Technosters Through" selectName="CustomSelect" /></div>
                        <div className="col-3"><SelectBox2 labelName="Assignee" selectName="CustomSelect" /></div>
                        <div className="col-3"><SelectBox2 labelName="Lead Type(Course)" selectName="CustomSelect" /></div>
                        <div className="col-3"><SelectBox2 labelName="Degree/Diploma Certification" selectName="CustomSelect" /></div>
                        <div className="col-3"><SelectBox2 labelName="Year Of Passing" selectName="CustomSelect" /></div>
                    </div>
                </div>
                <div className="col-12 smsForm2 d-flex justify-content-between align-items-center">
                    <div>Total Selected Contacts</div> <div>501</div>
                </div>
                <div className="col-12 smsForm3">
                    <p className='mb-5'>
                        {suggestion.map((value) => {
                            return (
                                <>
                                    <span>Lead Name:{value.LeadName} </span>
                                    <span>Fathers Name:{value.FatherName} </span>
                                    <span>Email Id:{value.EmailId} </span>
                                    <span>Lead value:{value.LeadValue} </span>
                                    <span>Assignee Name:{value.AssigneeName} </span>
                                    <span>City:{value.City} </span>
                                    <span>Course:{value.Course} </span>
                                </>
                            );
                        })}
                    </p>
                    <div className='d-flex'>
                        <TextareaAutosize
                            className='smstexterea'
                            aria-label={100}
                            minRows={4}
                            maxlength="200"
                            placeholder="Type a message"
                            style={{ width: "95%" }}
                        />
                        <div style={{ marginLeft: "10px" }}>
                            <div style={{ marginRight: "5px", width: "40px", height: "40px" }}>
                                <Box sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
                                    <SpeedDial
                                        ariaLabel="SpeedDial controlled open example"
                                        sx={{ position: "absolute", bottom: -40, right: -18, "& .MuiFab-primary": { width: 40, height: 40, background: "transparent", display: "flex", justifyContent: "center", alignItems: "center", "&:hover": { background: "transparent" } } }}
                                        icon={<span className="attachments-toggler"><Attachment /></span>}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        open={open}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action?.name}
                                                icon={action?.icon}
                                                tooltipTitle={action.name}
                                                sx={{ background: "transparent", width: 50, height: 50, marginTop: "-10px", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "none", "&:hover": { background: "transparent" } }}
                                                onClick={() => handleClose(action?.name)}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Box>
                            </div>
                            <div className="msg-send-btn">
                                <SendRightArrow />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer pageName={<Link to="/sms">SMS</Link>} />
            </div>
        </>
    );
}

export default SmsForm;
