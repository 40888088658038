import React, {useState} from "react";
import {Fab, IconButton, Tooltip} from "@mui/material";
import {Add, Delete, Edit} from "@mui/icons-material";
import {FieldMarketingModal} from "../dialogs";

export function FieldMarketingModalButton(props) {
    const {courseId, isEdit, selectedButton, i, branches} = props;
    const [open, setOpen] = useState(false);

    // const retrieveUsersTasks = async () => {
    //     try {
    //       dispatch({
    //         type: "LOADER",
    //         loader: true,
    //       });
    //       const { data } = await $crud.retrieve("web/tasks", {
    //         ...filtersWithValues,
    //         limit,
    //         page,
    //       });
    //       return data;
    //     } catch (e) {
    //       dispatch({
    //         type: "SNACKBAR",
    //         snackBar: { type: "error", message: e.message },
    //       });
    //     } finally {
    //       dispatch({
    //         type: "LOADER",
    //         loader: false,
    //       });
    //     }
    //   };

    const handleClose = ()=>{
        setOpen(false);
    };

    return (
        <React.Fragment>
            {
                !isEdit ?
                    <Tooltip title="Add New Course">
                        <Fab size="small" sx={{borderRadius: "5px", marginRight: "10px"}} color="primary"
                             onClick={() => setOpen(true)}><Add/></Fab>
                    </Tooltip>
                    :
                    <React.Fragment>
                        <IconButton
                            style={{
                                color: selectedButton === i ? "black" : "white",
                            }}
                            onClick={() => setOpen(true)}
                        >
                            <Edit/>
                        </IconButton>
                        <IconButton
                            style={{
                                color: selectedButton === i ? "black" : "white",
                            }}
                            onClick={() => setOpen(true)}
                        >
                            <Delete/>
                        </IconButton>
                    </React.Fragment>
            }
            <FieldMarketingModal
                courseId={courseId}
                open={open}
                close={handleClose}
                branches = {branches} 
                // onSave={() => {
                // retrieveUsersTasks().then(res => {
                //     setFieldMarketingUsers(res.tasks);
                //     setTotal(res.total);
                //     });
                // }}

            />
        </React.Fragment>
    );
}