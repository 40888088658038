/* eslint-disable */
import React from "react";
import CardContent from "@mui/material/CardContent";
import {Avatar, Grid, Tooltip, Typography, Divider, Card} from "@mui/material";
import {formatTime, stringAvatar} from "../../helpers/functions";
import {EMPLOYEE_IMAGE_BASE_URL} from "../../constants";

export function DashboardUserCard(props) {
    const {value} = props;

    // const callLogs = value?.callLogs && !!value?.callLogs.length ? value?.callLogs[0] : null;
    // const timings = value?.dailyWorkingHours && !!value?.dailyWorkingHours.length ? value?.dailyWorkingHours[0] : null;
    // const followUps = value?.leadNotesFollowup && !!value?.leadNotesFollowup.length ? value?.leadNotesFollowup[0] : null;
    // const visited = value?.visited && !!value?.visited.length ? value?.visited[0] : null;
    // const demos = value?.demoLeads && !!value?.demoLeads.length ? value?.demoLeads[0] : null;
    // const leads = value?.leads && !!value?.leads.length ? value?.leads[0] : null;



    return ( 
        <Card className="tableHeader-color" sx={{borderRadius: 3}}>
            <CardContent sx={{paddingBottom: "0px !important"}}>
                <Grid container spacing={2} mb={1}>
                    <Grid item xs={12} display="flex">
                        <Tooltip title={value?.name} placement="top-end">
                            {value?.name ? <Avatar
                                {...stringAvatar(value?.name)}
                                src={value?.photo ? EMPLOYEE_IMAGE_BASE_URL + `${value?.photo}` : ""}
                                alt={value?.name}
                            /> : <Avatar/>}
                        </Tooltip>
                        <Typography variant="body1" component="span" fontWeight={500}
                                    ml={1}>
                            {value?.name}
                        </Typography>
                    </Grid>
                    <Divider variant="middle" sx={{margin: 0, width: "calc(100% + 16px)", marginTop: 2}}/>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={5.5}>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Follow Up: {followUps ? followUps?.count : "0"}*/}
                                    Raw: { value.rawLeads || "0"}
                                </Typography>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Follow Up: {followUps ? followUps?.count : "0"}*/}
                                    Follow Up: { value.todayFollowUp || "0"}
                                </Typography>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Visit: {visited ? visited.count : "0"}*/}
                                    Visit: { value.visitedLeads || "0"}
                                </Typography>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Demo: {demos ? demos?.count : "0"}*/}
                                    Demo: {value.demoLeads || "0"}
                                </Typography>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Customer: {leads ? leads?.convertedLeads : 0}*/}
                                    Customer: {value.convertedLeads || "0"}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Divider orientation="vertical"
                                         sx={{height: "calc(100% + 32px) !important", width: "2px", marginTop: "-16px"}}
                                         flexItem/>
                            </Grid>
                            <Grid item xs={5.5}>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Calls: {callLogs ? (+callLogs?.incomings + +callLogs?.outgoings + +callLogs?.missedCalls) : "0"}*/}
                                    Calls: {+value.incomings + +value.outgoings + +value.missedCalls || "0"}
                                </Typography>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Incoming: {callLogs ? +callLogs?.incomings : "0"}*/}
                                    Incoming: {value.incomings || "0"}
                                </Typography>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Missed: {callLogs ? +callLogs?.missedCalls : "0"}*/}
                                    Missed: {value.missedCalls || "0"}
                                </Typography>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Outgoing: {callLogs ? +callLogs?.outgoings : "0"}*/}
                                    Outgoing: {value.outgoings || "0"}
                                </Typography>
                                <Typography variant="caption" component="p" ml={1}>
                                    {/*Total Talk: {callLogs ? formatTime(+callLogs?.totalCallingTime * 1000) : "0"}*/}
                                    Total Talk: {value.totalCallingTime ? formatTime(+value?.totalCallingTime * 1000) : "0"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" sx={{margin: 0, width: "calc(100% + 16px)", marginTop: 2}}/>
                    <Grid item xs={12}>
                        <Typography variant="caption" component="p" ml={1}>
                            {/*Working Day Duration: {timings ? formatTime(timings?.workingHours) : "0"}*/}
                            Working Day Duration: {!!value.dailyWorkingHours.length ? formatTime(value?.dailyWorkingHours[0]?.workingHours) : "0"}
                        </Typography>
                        <Typography variant="caption" component="p" ml={1}>
                            {/*Break: {timings ? formatTime(timings?.breakTime) : "0"}*/}
                            Break: {!!value.dailyWorkingHours.length ? formatTime(value?.dailyWorkingHours[0]?.breakTime) : "0"}
                        </Typography>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
}