/* eslint-disable*/
import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {MuiOtpInput} from 'mui-one-time-password-input'
import {Box, Dialog, DialogContent, DialogContentText, DialogTitle, Slide, DialogActions, Button} from "@mui/material";

export function OtpModal({onSave, open, onClose}) {
    const [otp, setOtp] = useState("");

    const handleChange = (newValue) => {
        setOtp(newValue);
    }



    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            maxWidth={"sm"}
            TransitionComponent={Slide}
            fullWidth
            aria-describedby="alert-dialog-description"
            onClose={onClose}
        >
            <Box className="tableHeader-color">
                <DialogTitle id="alert-dialog-title">
                    <Typography>Enter OTP</Typography>

                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <MuiOtpInput value={otp} length={6} onChange={handleChange}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={()=>{
                        if(otp.length === 6) onSave(otp)
                    }}>Verify</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
