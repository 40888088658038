/* eslint-disable */
import React, { useCallback, useState } from "react";
// import { GoogleMap, Marker, Polyline, withGoogleMap, withScriptjs } from "react-google-maps";
import {
  GoogleMap,
  MarkerF,
  PolylineF,
  useLoadScript,
} from "@react-google-maps/api";
import { useLocation } from "react-router-dom";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 27.1767,
  lng: 78.0081,
};

export const Map = (props) => {
  const { handleChange, params, position } = props;
  // console.log(params, "--------params");
  const location = useLocation();

  const [path, setPath] = useState( [
    { lat: 27.1767, lng: 78.0081 },
    { lat: 26.4499, lng: 80.3319 },
    { lat: 28.367, lng: 79.4304 },
  ],);
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAWD3lIQVFCPg6eYxQeEG7fqqUoFbHXimo",
  });

  const mapRef = React.useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) {
    console.error("Error loading Google Maps:", loadError);
    return "Error";
  }

  if (!isLoaded) {
    return "Maps are still loading...";
  }

  const addPointToPath = (e) => {
    try {
      const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      if (location.pathname === "/field-marketing/task" || location.pathname === "/leads/lead")
        handleChange("latlng", latLng);
      else setPath((prevPath) => [...prevPath, latLng]);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(path, "---------path");
  // console.log(params, "-------------map");

  return (
    <div style={containerStyle}>
      <GoogleMap
        onClick={location.pathname !== "/field-marketing" ? addPointToPath : ""}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        options={{
          streetViewControl:false,
          mapTypeControl:false
        }}
      >
        {
          location.pathname === "/field-marketing" && 
           <PolylineF
          path={path}
          options={{
            geodesic: true,
            strokeColor: "red",
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
        }
       
        {location.pathname === "/field-marketing" &&
          path?.length >= 1 &&
          path?.map((item, index) => {
            return (
              <>
               { (index === 0 || index === path.length - 1) &&
               <div>
                  <MarkerF position={item} />
                </div>}
              </>
            );
          })}

        {(location.pathname === "/field-marketing/task" || location.pathname === "/leads/lead") && params?.latlng && (
          <div>
            <MarkerF position={params?.latlng} />
          </div>
        )}

        <div>
          <MarkerF position={position} />
        </div>

      </GoogleMap>
    </div>
  );
}
