/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    Button,
    Slide,
    InputLabel,
    TextField,
    DialogActions,
    MenuItem,
    Autocomplete
} from "@mui/material"
import {useDataParams} from "../hooks/useDataParams";
import {useDispatch, useSelector} from "react-redux";
import {styled} from '@mui/material/styles';
import {$crud} from "../services/CrudFactory";
import {retrieveUsers} from "../pages";
import {users} from "../redux/actions";
import {CloudUpload} from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";


export const AppModal = (props) => {
    const [branches, setBranches] = useState([]);
    const dispatch = useDispatch();
    const {params, handleChange, setParams} = useDataParams();
    const attendents = useSelector(state => state.usersReducer);
    const { open, onClose, onSubmit, data} = props;

    const cancel = () => {
        onClose();
        setParams({});
    }
    // const retrieveBranches = async () => {
    //     try {
    //         dispatch({
    //             type: "LOADER",
    //             loader: true
    //         });
    //         const {data: {branches, total}} = await $crud.retrieve("web/branch");
    //         setBranches(branches);
    //     } catch (e) {
    //         dispatch({
    //             type: "SNACKBAR",
    //             snackBar: {type: "error", message: e.message}
    //         })
    //     } finally {
    //         dispatch({
    //             type: "LOADER",
    //             loader: false
    //         });
    //     }
    // }

    const handleSubmit = async () => {
        const formData = new FormData();
        Object.entries(params).map(([key, value])=>{
            formData.append(key, value)
        })
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message, data: {apps}} = await $crud.post(
                !props?.data?.id ? "create/web/crm-app" : "update/web/crm-app",formData
            );
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            // retrieveUsers().then(res=>{
            //     dispatch(users(res.users))
            // })
            // onSubmit();
            cancel();
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        data?.id && setParams(data)
    }, [data])
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    return (
        <Dialog maxWidth={"xs"} TransitionProps={
            {
                onEnter: () => {
                    // setParams({...params,leadId})
                }
            }
        } TransitionComponent={Slide} fullWidth open={open}>
            <div className="tableHeader-color">
                <DialogTitle>Upload New App</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={3} >

                        <Grid item xs={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">Name</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                value={params?.name || ""}
                                onChange={(e) => {
                                    handleChange("name", e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <InputLabel className="mb-1">App Version</InputLabel>
                            </Grid>
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                value={params?.version || ""}
                                onChange={(e) => {
                                    handleChange("version", e.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            {/*<Grid container justifyContent="space-between" alignItems="center">*/}
                            {/*    <InputLabel className="mb-1">Force Update</InputLabel>*/}
                            {/*</Grid>*/}

                            {/*<TextField*/}
                            {/*    fullWidth*/}
                            {/*    size="small"*/}
                            {/*    type="number"*/}
                            {/*    value={params?.version || ""}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        handleChange("version", e.target.value)*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <FormControlLabel
                                value="top"
                                control={<Checkbox checked={!!params.forceUpdate || false} onChange={(e)=>handleChange("forceUpdate", e.target.checked)}/>}
                                label="Force Update"
                                labelPlacement="left"
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <Button component="label" fullWidth variant="contained" size="large"
                                    startIcon={<CloudUpload/>}>
                                Upload App
                                <VisuallyHiddenInput type="file" accept=".apk" onChange={(e) => {
                                    handleChange("file", e.target.files[0]);
                                    handleChange("fileName", e.target.files[0].name)
                                }}/>
                            </Button>
                        </Grid>
                        {params?.fileName && <Grid item xs={12}>
                            Selected File - {params?.fileName}
                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cancel} color="primary" size="small">Cancel</Button>
                    <Button className="ms-2" onClick={handleSubmit} variant="contained" color="primary"
                            size="small">Submit</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};
