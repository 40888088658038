import React from "react";
import { Grid, InputLabel, TextField, MenuItem } from "@mui/material";
// import { useSelector } from "react-redux";
import { ImageUploader } from "../../ImageUploader";
import { USER_PROFILE_BASE_URL } from "../../../constants";

export function Image({ formProps }) {
  const { params, handleChange, setParams, courses } = formProps;
  // const courses = useSelector((state) => state.courseReducer);
  // console.log(courses, "---------courses");

  // console.log(params, "--------params");

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Course</InputLabel>
        </Grid>
        <TextField
          required
          name = "courseName"
          value={params?.courseId || ""}
          onChange={(e, child) => {
            // console.log(e,child, "--e");
            handleChange("courseName", child?.props?.children);
            handleChange("courseId", e.target.value);
          }}
          size="small"
          select
          fullWidth
        >
          {courses.map((item) => (
            <MenuItem key={item?.id} value={item?.id}>
              {item?.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="space-between" alignItems="center">
          <InputLabel className="mb-1">Title</InputLabel>
        </Grid>
        <TextField
          required
          value={params?.title || ""}
          onChange={(e) => handleChange("title", e.target.value)}
          placeholder="Enter Title"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUploader
          name="image"
          id="image"
          file={params?.image}
          src={
            params?.image?.type
              ? `${URL.createObjectURL(params?.image)}`
              : params?.image
              ? `${USER_PROFILE_BASE_URL}${params?.image}`
              : ""
          }
          onClick={() =>
            setParams((prev) => {
              return { ...prev, image: "" };
            })
          }
          onChange={(file) => {
            handleChange("image", file);
          }}
        />
      </Grid>
    </Grid>
  );
}
