/* eslint-disable  */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert  from '@mui/material/Alert';
import {useDispatch} from "react-redux";

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarShow(props) {
    const dispatch = useDispatch();

    const {open,type,message} = props;
    const handleClose = () => {
        dispatch({
            type:"SNACKBAR",
            snackBar:null
        })
    };
    return (
        <>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            </>
    );
}
