const initialState = false;

const reRender = (state = initialState, action) => {
    switch (action.type) {
        case "RERENDER":
            return (state = action.payload);
        default:
            return state;
    }
};

export default reRender;
