/* eslint-disable */
import React, {useEffect, useState} from "react";
import {BsFillCaretDownFill} from "react-icons/bs";
import {Divider, IconButton, Avatar} from "@mui/material";
import {EMPLOYEE_IMAGE_BASE_URL} from "../../constants";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import { ContentCopy } from "@mui/icons-material";
import { stringAvatar } from "../../helpers/functions";

export function CustomSelect({title, options, onClick, value, link}) {
    const [showOptions, setShowOptions] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        switch (title) {
            case "asignee":
                setContent({
                    className: "asignee select-box leadConvert-select-box",
                    hasImage: true,
                    label: "Assigned To",
                    defaultSelectText: "Select Asignee",
                    name: "assignTo",
                    value: value,
                    displayName: options?.filter((o) => o?.id === Number(value))[0]?.name,
                    image_url: options?.filter((o) => o?.id === Number(value))[0]?.photo ? `${EMPLOYEE_IMAGE_BASE_URL}${options?.filter((o) => o?.id === Number(value))[0]?.photo}` : "https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png",
                    listOptions: options => {
                        return options?.map((option, index) => {
                        return <React.Fragment key={option?.id}>
                                <li onClick={(e) => {
                                     e.preventDefault();
                                    setContent({...content, value: option?.id, displayName: option?.name})
                                    onClick("assignTo", option?.id, `Lead is Now Assigned To ${option?.name} From ${options?.filter(o => o.id === Number(value))[0]?.name || "UnAssigned"}`)
                                }}>
                                    {/* <img src={option?.photo ? `${EMPLOYEE_IMAGE_BASE_URL}${option.photo}` : content?.image_url} alt=""
                                         style={{width: "40px", aspectRatio: "1"}}/>{" "} */}

                                    {option?.photo  ? (
                                                  <Avatar
                                                    {...stringAvatar(option?.name)}
                                                    src={EMPLOYEE_IMAGE_BASE_URL + `${option?.photo}`}
                                                    alt={option?.name}
                                                  />
                                                ) : (
                                                  <Avatar 
                                                  {...stringAvatar(option?.name)}/>
                                                )}     
                                    <a href="#" style={{textDecoration:"none"}}>{option.name}</a>
                                </li>
                                {index < options?.length - 1 && <Divider className="my-1"/>}
                            </React.Fragment>}
                        )
                    }
                })
                break;

            case "Lead Convert To":
                setContent({
                    className: "select-box leadConvert-select-box",
                    hasImage: false,
                    label: "Lead Convert To",
                    defaultSelectText: "Lead Convert To",
                    name: "leadConvertTypeId",
                    value: value,
                    style: {
                        background: options?.filter((o) => o?.id === Number(value))[0]?.color + "30",
                        borderColor: options?.filter((o) => o?.id === Number(value))[0]?.color
                    },
                    displayName: options?.filter((o) => o?.id === Number(value))[0]?.name,
                    listOptions: options => {
                        return options?.map((option, index) => <React.Fragment key={option?.id}> 
                                {/* {console.log(option, "----------option")} */}
                                <Tooltip title={value >= "5" ? "Enabled" : option?.id > value ? "Enabled" : "Disabled"}>
                                    <li
                                        onClick={value >= "5" ? () => {
                                            setContent({...content, value: option?.id, displayName: options?.name})
                                            onClick("leadConvertTypeId", option?.id, `Lead is Converted to ${option?.name} From ${options?.filter((o) => o?.id === Number(value))[0]?.name || "UnAssigned"}`)
                                        } : option?.id > value ? () => {
                                            setContent({...content, value: option?.id, displayName: options?.name})
                                            onClick("leadConvertTypeId", option?.id, `Lead is Converted to ${option?.name} From ${options?.filter((o) => o?.id === Number(value))[0]?.name || "UnAssigned"}`)
                                        } : () => {
                                        }}
                                        style={{background:value >= "5" ? option?.color : option?.id > value ? option?.color : "gray"}}
                                    >  {option?.name}</li>
                                </Tooltip>
                                {index < options?.length - 1 && <Divider className="my-1"/>}
                            </React.Fragment>
                        )
                    }
                })
                break;

            case "Call Status":
                setContent({
                    className: "select-box leadConvert-select-box",
                    hasImage: false,
                    label: "Call Status",
                    defaultSelectText: "Select",
                    name: "callStatus",
                    value: "",
                    displayName: value,
                    listOptions: options => {
                        return options?.map((option, index) => <React.Fragment key={option}>
                                <li onClick={() => {
                                    onClick("callStatus", option)
                                    setContent({...content, value: option})
                                }}>{option} </li>
                                {index < options?.length - 1 && <Divider className="my-1"/>}
                            </React.Fragment>
                        )
                    }
                })
                break;

            case "Status":
                setContent({
                    className: "select-box leadConvert-select-box",
                    hasImage: false,
                    label: "Status",
                    defaultSelectText: "Select Status",
                    name: "statusId",
                    value: value,
                    style: {
                        background: options?.filter((o) => o?.id === Number(value))[0]?.color + "30",
                        borderColor: options?.filter((o) => o?.id === Number(value))[0]?.color
                    },
                    displayName: options?.filter((o) => o?.id === Number(value))[0]?.name,
                    listOptions: options => {
                        return options?.map((option, index) => <React.Fragment key={option?.id}>

                                    <li
                                        onClick={() => {
                                            setContent({...content, value: option?.id, displayName: option?.name})
                                            onClick("statusId", option.id, `Status is Now Changed From ${options?.filter((o) => o?.id === Number(value))[0]?.name || "UnAssigned"} To ${option?.name}`)
                                        }}
                                        style={{background:option?.color}}
                                    > {option?.name} </li>

                                {index < options?.length - 1 && <Divider className="my-1"/>}
                            </React.Fragment>
                        )
                    }
                })
                break;
        }
    }, [title, options, value])
    return (
        <div
            className={content?.className}
            style={content?.style}
            onClick={() => setShowOptions(!showOptions)}
        >
            {content?.hasImage && <div className="asignee-img">
                <img style={{height: "100%", aspectRatio: "1", objectFit: "cover"}} src={content?.image_url} alt=""/>
            </div>}
            <div className="option-title">
                <span>{content?.label}</span>
                {!!content?.displayName ? content?.displayName : content?.defaultSelectText}
            </div>
            <BsFillCaretDownFill color={content?.style?.borderColor || "inherit"}/>

            <Backdrop
                sx={{ color: '#fff',backgroundColor:"transparent", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showOptions}
                onClick={()=>setShowOptions(false)}
            >
            </Backdrop>
                <div
                    className="options-container"
                    style={{display: showOptions ? "block" : "none"}}
                >
                    <ul style={{padding: "5px", maxHeight:"75vh",width:"100%", overflowY:"scroll"}}>
                        {!!content && content?.listOptions(options)}
                    </ul>
                </div>
        </div>
    );
}