import React, {
    // useLayoutEffect,
    useEffect, useState
} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import MenuItem from "@mui/material/MenuItem";
import {ChartContainer} from "./ChartContainer";
import {useDispatch} from "react-redux";
import {$crud} from "../../services/CrudFactory";

//chart type
// import * as am5percent from "@amcharts/amcharts5/percent";

export function BarChart3(props) {
    const {chartID,chartTitle, style, userId} = props;
    const [chartData, setChartData] = useState([]);
    const [filterType, setFilterType] = useState("monthly");
    const dispatch = useDispatch();
    const retrieveChartData = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data
            } = await $crud.retrieve(`web/${url}`, {
                [filterType]: true,
                userId,
            });
            return data;
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    function formatLogData(data) {
        const organizedData = [];

        data.forEach(entry => {
            const {type, date, count} = entry;

            let dateObject = organizedData?.find(obj => obj.date === date);

            if (!dateObject) {
                dateObject = {date};
                organizedData.push(dateObject);
            }

            dateObject[type] = +count;
        });
        return organizedData;
    }
    useEffect(()=>{
        retrieveChartData("user-report-call-log").then(res=>setChartData(formatLogData(res.callLogReport)));
    },[userId, filterType]);

    useEffect(() => {
        var root = am5.Root.new(chartID);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));
        chart.events.on("beforevalidated", (event) => {
            // check if there's data
            if (event.target.data.length === 0) {
                chart.closeAllPopups();
                chart.modal.container = document.body;
                chart.openModal("<strong>No data found!</strong>");
            }
        });
        var legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            })
        );


        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },

            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 60,
                labels:{
                    template:{
                        fill: am5.color(0xFF0000),
                    }
                }
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let xRenderer = xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            fill: am5.color("#FFF"),
        });
        xAxis.data.setAll(generateDatas(filterType === "monthly" ? 30 : 7));
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            })
        }));
        let yRenderer = yAxis.get("renderer");

        yRenderer.labels.template.setAll({
            fill: am5.color("#FFF"),
        });

        function generateData(priorDate) {
            var value = 0;
            var value1 = 0;
            var value2 = 0;
            var value3 = 0;
            am5.time.add(priorDate, "day", 1);
            const exist =Boolean(chartData.length) && chartData?.find(d => new Date(d.date).setHours(0,0,0,0) == priorDate.getTime());
            if (exist) {
                value = +exist["Incoming"] ?? 0;
                value1 = exist["Outgoing Connected"] ?? 0;
                value2 = exist["Missed Call"] ?? 0;
                value3 = (+exist["Incoming"] ?? 0) + (+exist["Outgoing Connected"] ?? 0) + (+exist["Missed Call"] ?? 0);
            }
            return {
                date: priorDate.getTime(),
                incoming:value || 0,
                outgoing:value1 || 0,
                missed:value2 || 0,
                total:value3 || 0
            };
        }

        function generateDatas(count) {
            var date = new Date();
            let daysToBack = filterType === "monthly" ? 30 : 7;
            date.setHours(0, 0, 0, 0);
            let priorDate = new Date(new Date(date).setDate(date.getDate() - daysToBack));
            var data = [];
            for (var i = 0; i < count; ++i) {
                data.push(generateData(priorDate));
            }
            return data;
        }


        function makeSeries(name, fieldName, fillColor) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                valueXField: "date",
                fill: am5.color(`${fillColor}`)
            }));

            series.columns.template.setAll({
                tooltipText: "{name}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0,
                strokeOpacity: 0
            });
            let c= generateDatas(filterType === "monthly" ? 30 : 7);
            series.data.setAll(c);
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });
            legend.data.push(series);
        }
        legend.labels.template.setAll({
            fill:am5.color("#fff"),
            fontSize: 16,
            fontWeight: "300"
        });
        makeSeries("Total Calls", "total", "#158542");
        makeSeries("Missed Calls", "missed", "#cd2018");
        makeSeries("Incoming Calls", "incoming", "#9747FF");
        makeSeries("Outgoing Calls", "outgoing", "#397dff");

        chart.appear(1000, 100);
        return () => {
            root.dispose();
        };
    }, [chartID, chartData]);
    return (
        <ChartContainer chartID={chartID} chartTitle={chartTitle} style={style} filterProps={{setFilterType}}/>
    );
}
