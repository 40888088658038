import React from "react";
import CardContent from "@mui/material/CardContent";
import {Tooltip, Typography} from "@mui/material";
import {Info} from "@mui/icons-material";
import Card from "@mui/material/Card";

export function DashboardStatusCard(props){
    const {status, dashboardData} = props;
    return (
        <Card sx={{backgroundColor: status?.color, borderRadius: 3}}>
            <CardContent sx={{textAlign: "center", position: "relative"}}>
                <Typography variant="h6" ml={1}>
                    {status?.name}
                </Typography>
                <Typography variant="h6" sx={{ml: 1}}>
                    {dashboardData[status?.countKey]}
                </Typography>
                <Tooltip sx={{position: "absolute", right: 5, bottom: 5}}
                         title={status?.description} placement="top-end">
                    <Info/>
                </Tooltip>
            </CardContent>
        </Card>
    );
}