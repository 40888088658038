import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import { faker } from "@faker-js/faker";
import { $crud } from "../../../services/CrudFactory";
import { useDispatch } from "react-redux";

export function LeadScore({ lead }) {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [score, setScore] = useState();

  const retrieveNotesFollowUp = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const {
        data: { lead_notes_follow_up },
      } = await $crud.retrieve("web/add/notes-follows-up", {
        leadId: lead?.lead ? lead?.lead?.id : lead?.id,
      });
      setDate(lead_notes_follow_up);
      setScore(lead_notes_follow_up);
    } catch (e) {
      const { message } = e;
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  const storeDate = date?.map((a) => a?.date);
  const storeScore = score?.map((a) => a?.leadScore);

  useEffect(() => {
    retrieveNotesFollowUp();
  }, [lead]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  const labels = storeDate;

  const data = {
    labels,
    datasets: [
      {
        label: "Lead Score",
        // data: labels?.map(() => faker.datatype.number({ min: 0, max: 100 })),
        data: labels?.map((l, index) => storeScore[index]),
        backgroundColor: function (context) {
          var value = context.dataset.data[context.dataIndex];
          return value >= 75
            ? "#91FF9C"
            : value >= 50
            ? "#9AEDFF"
            : value >= 25
            ? "#FFFBA2"
            : "#FFA9A9";
        },
        barPercentage: 0.4,
        categoryPercentage: 1.5,
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
