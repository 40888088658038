/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Button,
  Slide,
  DialogActions,
  Checkbox,
  MenuItem,
  Chip,
  Box,
  OutlinedInput,
  Select,
  InputLabel,
  Autocomplete,
  TextField
} from "@mui/material";
import { useDataParams } from "../hooks/useDataParams";
import { useDispatch, useSelector } from "react-redux";
import { $crud } from "../services/CrudFactory";
import { Visibility } from "@mui/icons-material";
import { valueDetails } from "../data/leadValueData";

export const LeadTagsModal = (props) => {
  const { open, onClose, leadId, assignLeadTags } = props;
  const dispatch = useDispatch();
  const { params, handleChange, setParams } = useDataParams();
  const [tags, setTags] = useState([]);
  const [tagsIds, setTagsIds] = useState([]);
  let isChecked = [];
  let branchId =  localStorage.getItem("branchInfo");
  let branchInfo = JSON.parse(branchId);


  assignLeadTags?.map((data) => {
    isChecked.push(data.tag);
  });
  //   console.log(isChecked, "------------isChecked");
//   console.log(tagsIds, "-----tagsIds");

  const cancel = () => {
    onClose();
    setParams({});
    setTagsIds([]);
  };

  const retrieveData = async (url) => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const { data } = await $crud.retrieve(url, {branchId:branchInfo?.id});
      return data;
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const { type, message } = await $crud.post("create/web/lead-tag", {
        leadId,
        tagsIds: tagsIds?.map((i) =>{
            return tags.find(t=>t.name === i)?.id
        }),
      });

      dispatch({
        type: "SNACKBAR",
        snackBar: { type, message },
      });
      cancel();
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  return (
    <Dialog
      maxWidth={"xs"}
      TransitionProps={{
        onEnter: () => {
          setTagsIds(isChecked?.map(s=>s.name));
            if(!branchInfo?.id){
              dispatch({type: "SNACKBAR", snackBar: {type: "error", message: "You don't support any branch."}})
            }else{
                retrieveData("web/sub-tags").then((res) => setTags(res?.tags));
            }
        },
      }}
      TransitionComponent={Slide}
      fullWidth
      open={open}
    >
      <div className="tableHeader-color">
        <DialogTitle>Lead Tags</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <Select
                multiple
                color="primary"
                fullWidth
                value={tagsIds || []}
                input={<OutlinedInput id="select-multiple-chip" />}
                size="small"
                onChange={(e) => {
                  const { value } = e.target;
                 
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected?.map((value) => (
                      <Chip
                        color="primary"
                        size="small"
                        key={value?.id}
                        label={value?.name}
                      />
                    ))}
                  </Box>
                )}
              >
                {tags?.map((item, index) => (
                  <MenuItem key={item?.name} value={item}>
                    <Checkbox
                      checked={
                        !!tagsIds?.length &&
                        !!tagsIds.find((s) => s.id == item?.id)
                        // !!tagsIds.find((i) => i?.id === item?.id)
                      }
                    />
                    {item?.name}
                  </MenuItem>
                ))}
                
              </Select> */}
              <Autocomplete
                multiple
                id="tags-filled"
                options={tags?.map((option) => option.name)}
                value={tagsIds}
                onChange={(e,value)=>{
                    setTagsIds(value)

                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="freeSolo"
                    placeholder="Favorites"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={cancel}
            color="primary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            className="ms-2"
            onClick={() => {
              handleSubmit(params);
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            Submit
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
